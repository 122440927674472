import './style.scss'

import React, { useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { I18n, Translate } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'

import Icon from 'components/common/Fontello/index'
import FilesPreviewer from 'components/common/FilesViewer/components/FilesPreviewer'

import { filePreviewAsUrl } from './helpers'
import { SingleFileUploadProps } from './schemes'
import FileUploadService from './services'

const SingleFileUpload = props => {
  const [files, setFiles] = useState(
    props.initialFile ? [props.initialFile] : [],
  )

  useEffect(() => {
    if (props.initialFile !== null && props.initialFile.id) {
      setFiles([props.initialFile])
    } else {
      setFiles([])
    }
  }, [props.initialFile])

  const handleUpload = (accepted, rejected) => {
    const filesBeforeUpload = files

    if (rejected.length > 0) {
      toastr.error(
        I18n.t('fileUpload.rejectedToast.title'),
        I18n.t('fileUpload.rejectedToast.text', {
          maxSize: Math.round(props.maxSize / 1000000),
        }),
      )
    }

    accepted.forEach(file => {
      filePreviewAsUrl(file)
        .then(result => {
          const fileWithPreview = file
          fileWithPreview.file = result
          setFiles([fileWithPreview])
        })
        .catch(() => {
          // We do not need to do anything here, we just do not display a preview.
        })
    })

    return FileUploadService.uploadFiles(accepted)
      .then(uploadedFiles => {
        setFiles([...uploadedFiles])
        if (props.onFilesUploaded) props.onFilesUploaded(uploadedFiles)
      })
      .catch(err => {
        setFiles(filesBeforeUpload)
        console.error(
          I18n.t('fileUpload.uploadErrorToast.title'),
          I18n.t('fileUpload.uploadErrorToast.text', { description: err }),
        )
      })
  }

  return (
    <div className={`fileupload single ${props.className}`}>
      <Dropzone
        className={`dropzone uk-border-rounded ${
          props.displayEditIcon ? 'pointer' : ''
        }`}
        onDrop={handleUpload}
        accept={props.accept}
        maxSize={props.maxSize}
        activeClassName='drag-active'
        rejectClassName='drag-reject'
        disabled={!props.displayEditIcon}
      >
        {props.displayEditIcon && (
          <div className='edit-icon'>
            <Icon name='pencil' />
          </div>
        )}

        {!files.length > 0 && (
          <div className='cta cta-upload uk-flex-center'>
            <Icon name={props.iconName} />
          </div>
        )}

        <FilesPreviewer files={files} />

        <div className='cta cta-drop'>
          <Icon name='upload' />
          <p className='uk-text-contrast'>
            <Translate value='fileUpload.dropHint' />
          </p>
        </div>
        <div className='cta cta-reject'>
          <Icon name='warning' />
          <p className='uk-text-contrast'>
            <Translate value='fileUpload.rejectHint' />
          </p>
        </div>
      </Dropzone>
    </div>
  )
}

SingleFileUpload.propTypes = SingleFileUploadProps

SingleFileUpload.defaultProps = {
  className: null,
  initialFile: null,
  accept: 'image/*',
  maxSize: 5 * 1024 * 1024,
  iconName: 'plus',
  displayEditIcon: false,
  showPreviewMetaData: false,
}

export default SingleFileUpload
