export const INVOICE_CHECK_STATUS = {
  INVOICE_CHECK_PENDING: 1,
  INVOICE_CHECK_STATUS_ACCEPTED: 2,
  INVOICE_CHECK_STATUS_REJECTED: 3,
  INVOICE_CHECK_STATUS_REOPENED: 4,
}

export enum INVOICE_CHECK_STATUSES {
  STATUS_PENDING = 1,
  STATUS_ACCEPTED,
  STATUS_REJECTED,
  STATUS_REOPENED,
}

export const INVOICE_CHECK_STATES = [
  {
    id: INVOICE_CHECK_STATUSES.STATUS_PENDING,
    name: 'invoiceCheckTranslations.status.pending',
  },
  {
    id: INVOICE_CHECK_STATUSES.STATUS_ACCEPTED,
    name: 'invoiceCheckTranslations.status.accepted',
  },
  {
    id: INVOICE_CHECK_STATUSES.STATUS_REJECTED,
    name: 'invoiceCheckTranslations.status.rejected',
  },
  {
    id: INVOICE_CHECK_STATUSES.STATUS_REOPENED,
    name: 'invoiceCheckTranslations.status.reopened',
  },
]

export enum INVOICE_CHECK_POSITIONS_CATEGORIES {
  DEADHEAD = 1,
  SORTING_COSTS,
  TAKEOVER_SLIP,
  DISCOUNT,
  OTHER,
  PLACEMENT_PERMIT,
  RENT,
  STREET_CHARGE,
}

export const INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES = [
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.DEADHEAD,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.deadhead',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.PLACEMENT_PERMIT,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.placementPermit',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.RENT,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.rent',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.STREET_CHARGE,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.streetCharge',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.SORTING_COSTS,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.sortingCosts',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.TAKEOVER_SLIP,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.takeoverSlip',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.DISCOUNT,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.discount',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.OTHER,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.other',
  },
]
