import { connect } from 'react-redux'

import { getAddressListForCompany } from 'actions/address'
import {
  addCertificateType,
  createCertificate,
  getCompanyLatestCertificates,
} from 'actions/certificate'
import { getCompany, updateCompany } from 'actions/company'
import { getCountries } from 'actions/country'
import { getCertificateSelector } from 'selectors/certificate'
import { getCompanyFormSelector, getCompanySelector } from 'selectors/company'
import { getCountriesSelector } from 'selectors/country'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const getCountriesLoadingSelector = createLoadingSelector(['GET_COUNTRIES'])
const getCompanyLoadingSelector = createLoadingSelector(['GET_COMPANY'])
const updateCompanyLoadingSelector = createLoadingSelector(['UPDATE_COMPANY'])
const getCompanyLatestCertificatesLoadingSelector = createLoadingSelector([
  'GET_COMPANY_LATEST_CERTIFICATES',
])
const createCertificateLoadingSelector = createLoadingSelector([
  'CREATE_CERTIFICATE',
])
const getCompanyAddressesLoadingSelector = createLoadingSelector([
  'GET_ADDRESSES_FOR_COMPANY',
])

const getCountriesErrorSelector = createErrorSelector(['GET_COUNTRIES'])
const getCompanyErrorSelector = createErrorSelector(['GET_COMPANY'])
const updateCompanyErrorSelector = createErrorSelector(['UPDATE_COMPANY'])
const getCompanyLatestCertificatesErrorSelector = createErrorSelector([
  'GET_MY_LATEST_CERTIFICATES',
])
const createCertificateErrorSelector = createErrorSelector([
  'CREATE_CERTIFICATE',
])
const getCompanyAddressesErrorSelector = createErrorSelector([
  'GET_ADDRESSES_FOR_COMPANY',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  company: getCompanySelector(state),
  countries: getCountriesSelector(state),
  companyForm: getCompanyFormSelector(state),
  certificateItem: getCertificateSelector(state),
  isLoading: {
    getCountries: getCountriesLoadingSelector(state),
    getCompany: getCompanyLoadingSelector(state),
    updateCompany: updateCompanyLoadingSelector(state),
    getCompanyLatestCertificates:
      getCompanyLatestCertificatesLoadingSelector(state),
    createCertificate: createCertificateLoadingSelector(state),
    getCompanyAddresses: getCompanyAddressesLoadingSelector(state),
  },
  error: {
    getCountries: getCountriesErrorSelector(state),
    getCompany: getCompanyErrorSelector(state),
    updateCompany: updateCompanyErrorSelector(state),
    getCompanyLatestCertificates:
      getCompanyLatestCertificatesErrorSelector(state),
    createCertificate: createCertificateErrorSelector(state),
    getCompanyAddresses: getCompanyAddressesErrorSelector(state),
  },
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getCountries: () => dispatch(getCountries()),
  getCompany: company => dispatch(getCompany(company)),
  updateCompany: company => dispatch(updateCompany(company)),
  getCompanyLatestCertificates: companyId =>
    dispatch(getCompanyLatestCertificates(companyId)),
  createCertificate: certificate => dispatch(createCertificate(certificate)),
  addCertificateType: certificateType =>
    dispatch(addCertificateType(certificateType)),
  getCompanyAddresses: (companyId, filters) =>
    dispatch(getAddressListForCompany(companyId, filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)
