import { useFormikContext } from 'formik'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'
import Spinner from 'react-spinkit'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import createMarkup from 'components/common/TooltipIcon/helpers'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'

export const CollectionAddressSummary: FC = () => {
  const { values } = useFormikContext<InvoiceCheckPositionsFormValues>()
  const { editStep } = useContext(InvoiceCheckPositionsFormContext)

  if (!values.collection_address_label) return <Spinner name='circle' />

  const displayName = values.collection_address_label.replace(',', '<br />')

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t('inquiry.address')}
          value={
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(`${displayName}`)}
            />
          }
        />
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
