import uniqueId from 'lodash.uniqueid'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'

import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { APP_CONSTANTS } from 'constants/app'

import { ProgressButton } from '../../common/ProgressButton'

import { MarkupPageContext } from './MarkupPage'

export const Modals: FC = () => {
  const idAsyncExportModalHeadline = uniqueId()
  const idAsyncImportModalHeadline = uniqueId()
  const {
    setShouldUpdateExport,
    exportModalOpen,
    setExportModalOpen,
    importModalOpen,
    setImportModalOpen,
    postFileAPI,
  } = useContext(MarkupPageContext)

  const importMarkupID = 'import-markups-file'

  function onCloseExportModel() {
    setExportModalOpen(false)
  }
  function onCloseImportModel() {
    setImportModalOpen(false)
  }
  function importSubmit() {
    const fileInput = document.getElementById(
      importMarkupID,
    ) as HTMLInputElement
    // @ts-ignore
    const file = fileInput.files[0]

    postFileAPI(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/zipcode/import/`, file)
  }

  return (
    <MarkupPageContext.Consumer>
      {() => (
        <>
          <Modal
            ariaDescribedBy={idAsyncExportModalHeadline}
            isOpen={exportModalOpen}
            onClose={onCloseExportModel}
          >
            <ModalHeader
              onClose={onCloseExportModel}
              title={'Export PLZ'}
              titleId={idAsyncExportModalHeadline}
            />
            <div className='uk-padding'>
              <p className='uk-text'>
                Postleitzahl und Aufschläge exportieren (Excel)
              </p>
            </div>

            <div className='uk-modal-footer uk-text-right'>
              <span className='uk-margin-right'>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={onCloseExportModel}
                >
                  {I18n.t('general.button.cancel')}
                </Button>
              </span>

              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                onClick={() => {
                  setShouldUpdateExport(true)
                  onCloseExportModel()
                }}
                type={BUTTON_TYPE.SUBMIT}
                dataTestId='export-submit-button'
              >
                {I18n.t('general.button.submit')}
              </ProgressButton>
            </div>
          </Modal>

          <Modal
            ariaDescribedBy={idAsyncImportModalHeadline}
            isOpen={importModalOpen}
            onClose={onCloseImportModel}
          >
            <ModalHeader
              onClose={onCloseImportModel}
              title='Import PLZ'
              titleId={idAsyncImportModalHeadline}
            />

            <div className='universal-file-upload'>
              <div className='uk-padding'>
                <p className='uk-text'>
                  Postleitzahl und Aufschläge importieren (Excel)
                </p>
              </div>
              <input
                id={importMarkupID}
                style={{ margin: '3rem' }}
                name='file'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                type='file'
              />

              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                onClick={() => {
                  importSubmit()
                  onCloseImportModel()
                }}
                type={BUTTON_TYPE.SUBMIT}
                dataTestId='export-submit-button'
              >
                {I18n.t('general.button.submit')}
              </ProgressButton>
            </div>
          </Modal>
        </>
      )}
    </MarkupPageContext.Consumer>
  )
}
