import { Form, Formik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import { createLoadingSelector } from 'selectors/loading'
import { getInvoiceCheckPositionReviewSelector } from 'selectors/invoicecheck'
import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import {
  getInvoiceCheckPositionReview,
  updateReviewedInvoiceCheckPosition,
} from 'actions/invoicecheck'

import { InvoiceCheckPositionReview } from '../../common/DocumentReview/InvoiceCheckPositionReview'
import { InvoiceCheckPositionReviewDetail } from '../../common/DocumentReview/InvoiceCheckPositionReview/InvoiceCheckPositionReviewDetail'
import { NotFoundComponent } from '../../common/RoutesHandler/components/NotFoundComponent'
import { OrderType } from '../InvoiceCheckOverview/InvoiceCheckPositionsForm/Step2'

import SaveInternalCommentModal from './components/SaveInternalCommentModal'
import { INVOICE_CHECK_POSITION_STATUS } from './constants'

export type InvoiceCheckPositionReviewFormValues = Partial<{
  internal_note: string
  status: React.ReactText
  review_message: string
  reject_reason: string
}>

/**
 * @description This component is called accessed from the WorkloadPage and displays an invoiceCheckPosition which
 * requires a review. This could be anything from a redeclaration, changed container, additional rent to an
 * otherService entry.
 */
export const InvoiceCheckPositionReviewPage: FC = () => {
  // @ts-ignore
  const { invoiceCheckPositionId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const invoiceCheckPosition = useSelector(
    getInvoiceCheckPositionReviewSelector,
  )
  const [displayModal, setDisplayModal] = useState(false)
  const [isReview, setIsReview] = useState(false)
  const [isRelabeled, setIsRelabeled] = useState(false)
  const [isContainerChanged, setIsContainerChanged] = useState(false)
  const [isOtherService, setIsOtherService] = useState(false)
  const [isAdditionalRent, setIsAdditionalRent] = useState(false)

  useEffect(() => {
    dispatch(
      getInvoiceCheckPositionReview(Number(invoiceCheckPositionId), [
        'invoice_check__company_object__main_address_object',
      ]),
    )
  }, [dispatch, invoiceCheckPositionId])

  const isLoading = {
    getInvoiceCheckPosition: useSelector(
      createLoadingSelector(['GET_INVOICE_CHECK_POSITION']),
    ),
    updateReviewedInvoiceCheckPosition: useSelector(
      createLoadingSelector(['UPDATE_REVIEWED_INVOICE_CHECK_POSITION']),
    ),
  }

  useEffect(() => {
    if (invoiceCheckPosition.loaded) {
      setIsReview(
        invoiceCheckPosition.item.status ===
          INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING ||
          !invoiceCheckPosition.item.reviewed_at,
      )
      if (
        invoiceCheckPosition.item.relabeled &&
        invoiceCheckPosition.item.order_type === OrderType.OPEN_ORDER
      ) {
        setIsRelabeled(true)
      }
      if (invoiceCheckPosition.item.order_type === OrderType.OTHER_SERVICE) {
        setIsOtherService(true)
      }
      if (
        (invoiceCheckPosition.item.container_changed ||
          invoiceCheckPosition.item.container_count_changed) &&
        invoiceCheckPosition.item.order_type === OrderType.OPEN_ORDER
      ) {
        setIsContainerChanged(true)
      }
      if (
        invoiceCheckPosition.item.additional_rent &&
        invoiceCheckPosition.item.order_type === OrderType.OPEN_ORDER
      ) {
        setIsAdditionalRent(true)
      }
    }
  }, [
    invoiceCheckPosition,
    invoiceCheckPosition.item,
    invoiceCheckPosition.loaded,
  ])

  const handleSaveInternalNote = internalNoteValue => {
    dispatch(
      updateReviewedInvoiceCheckPosition(
        {
          id: invoiceCheckPosition.item.id,
          internal_note: internalNoteValue,
        },
        invoiceCheckPosition.item.id,
        history,
      ),
    )
    setDisplayModal(!displayModal)
  }

  const getPageInformation = (): {
    breadcrumb: string
    pageTitle: string
    documentTitle: string
  } => {
    const titles: string[] = []

    if (
      !isRelabeled &&
      !isContainerChanged &&
      !isAdditionalRent &&
      !isOtherService
    ) {
      return {
        breadcrumb: '',
        pageTitle: '',
        documentTitle: '',
      }
    }

    // prettier-ignore
    if (invoiceCheckPosition) {
      if (isRelabeled)        {titles.push(I18n.t('InvoiceCheckPositionReviewPageTranslations.title.redeclaration'   ))}
      if (isContainerChanged) {titles.push(I18n.t('InvoiceCheckPositionReviewPageTranslations.title.containerChanged'))}
      if (isAdditionalRent)   {titles.push(I18n.t('InvoiceCheckPositionReviewPageTranslations.title.additionalRent'  ))}
      if (isOtherService)     {titles.push(I18n.t('InvoiceCheckPositionReviewPageTranslations.title.otherService'    ))}
    }

    const title = titles.join(' | ')
    return {
      breadcrumb: title,
      pageTitle: title,
      documentTitle: I18n.t(
        'InvoiceCheckPositionReviewPageTranslations.documentTitle',
        { number: invoiceCheckPosition.item.order_object.id, title: title },
      ),
    }
  }

  const pageInformation = getPageInformation()

  if (isLoading.getInvoiceCheckPosition || !invoiceCheckPosition.loaded) {
    return (
      <div className='uk-flex uk-flex-center uk-margin-large-top'>
        <Spinner name='circle' />
      </div>
    )
  }

  if (
    !isRelabeled &&
    !isOtherService &&
    !isContainerChanged &&
    !isAdditionalRent
  ) {
    return <NotFoundComponent />
  }

  return (
    <>
      <div className='invoice_check_position_redeclarion-review-page'>
        <Formik
          initialValues={{
            internal_note: invoiceCheckPosition.item.internal_note ?? '',
            status: String(INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING),
            review_message: '',
            reject_reason: '',
          }}
          validationSchema={() =>
            yup.object().shape({
              internal_note: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
              status: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
              review_message: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
              reject_reason: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
            })
          }
          validate={(values: InvoiceCheckPositionReviewFormValues) => {
            const errors: {
              internal_note?: string
              status?: string
              review_message?: string
              reject_reason?: string
            } = {}

            if (
              Number(values.status) ===
              INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
            ) {
              errors.status = I18n.t(
                'InvoiceCheckPositionReviewPageTranslations.validation.status',
              )
            }

            if (
              Number(values.status) ===
              INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED
            ) {
              if (values.reject_reason === '') {
                errors.reject_reason = I18n.t(
                  'orderClaimDetailsPageTranslations.validation.rejectReason',
                )
              }
            }

            return errors
          }}
          onSubmit={values => {
            const valuesToSend = {
              ...values,
            }
            if (
              Number(valuesToSend.status) !==
              INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED
            ) {
              valuesToSend.reject_reason = ''
            }
            dispatch(
              updateReviewedInvoiceCheckPosition(
                valuesToSend,
                invoiceCheckPosition.item.id,
                history,
              ),
            )
          }}
        >
          {({ submitCount, handleSubmit, isSubmitting, values, isValid }) => (
            <>
              <InvoiceCheckPositionReview
                breadcrumb={{
                  breadcrumbTitle: pageInformation.breadcrumb,
                  prevLinkTitle: I18n.t(
                    'InvoiceCheckPositionReviewPageTranslations.breadcrumb.prevLinkTitle',
                  ),
                  prevLinkTo: '/workload',
                  teaserText: '',
                  teaserTitle: '',
                }}
                pageTitle={pageInformation.pageTitle}
                documentTitle={pageInformation.documentTitle}
                formSubmissionButton={{
                  buttonText:
                    isReview &&
                    Number(values.status) ===
                      INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
                      ? I18n.t(
                          'InvoiceCheckPositionReviewPageTranslations.submitButtonText.isSaving',
                        )
                      : I18n.t(
                          'InvoiceCheckPositionReviewPageTranslations.submitButtonText.isReviewing',
                        ),
                  action:
                    isReview &&
                    Number(values.status) ===
                      INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
                      ? () => setDisplayModal(!displayModal)
                      : handleSubmit,
                  isLoading:
                    isSubmitting &&
                    isLoading.updateReviewedInvoiceCheckPosition,
                  isDisabled:
                    (submitCount > 0 && !isValid) ||
                    (isSubmitting &&
                      isLoading.updateReviewedInvoiceCheckPosition),
                }}
                showFormSubmitButton={isReview}
                showOrderDetails
                reviewDocument={invoiceCheckPosition.item}
                isRelabeled={isRelabeled}
                isContainerChanged={isContainerChanged}
                isOtherService={isOtherService}
                isAdditionalRent={isAdditionalRent}
              >
                <Form
                  className='uk-panel order-claim-review-form'
                  data-testid='order-claim-review-form'
                  noValidate
                >
                  <InvoiceCheckPositionReviewDetail
                    invoiceCheckPosition={invoiceCheckPosition.item}
                    isReview={isReview}
                  />
                </Form>
              </InvoiceCheckPositionReview>

              <SaveInternalCommentModal
                openModal={displayModal}
                handleSaveInternalNote={() =>
                  handleSaveInternalNote(values.internal_note)
                }
                handleModalToggle={() => setDisplayModal(!displayModal)}
                isRelabeled={isRelabeled}
                isContainerChanged={isContainerChanged}
                isAdditionalRent={isAdditionalRent}
              />
            </>
          )}
        </Formik>
      </div>
    </>
  )
}

export const InvoiceCheckPositionReviewPageComponent = withErrorBoundary(
  withApiErrorHandling(
    InvoiceCheckPositionReviewPage,
    ['GET_INVOICE_CHECK_POSITION'],
    ['GET_INVOICE_CHECK_POSITION'],
  ),
)
