import { OfferPipelinePagination } from '../reducers/maklerpremium'

export const getCustomInvoiceNetGrossPricesVatSelector: (state: any) => {
  [key: string]: number
} = state => state.maklerpremium.customInvoiceNetGrossPricesAndVat

export const getCustomInvoiceValidatedIBANErrorMessage: (
  state: any,
) => string = state =>
  state.maklerpremium.customInvoiceValidatedIBANErrorMessage

export const getFrameworkContractPositionsSelector: (state: any) => {
  positions: [FrameworkContractPosition]
  loaded: boolean
} = state => state.maklerpremium.frameworkContractPosition

export const getFrameworkContractPositionsCountSelector: (
  state: any,
) => number = state => state.maklerpremium.frameworkContractPositionsCount

export const getCancelledPaidDocumentSelector: (
  state: any,
) => CancelledPaidDocument = state => state.maklerpremium.cancelledPaidDocument

export const getOfferPipelineSelector: (
  state: any,
) => CancelledPaidDocument[] = state => state.maklerpremium.offerPipeline.list

export const getOfferPipelinePaginationSelector: (
  state: any,
) => OfferPipelinePagination = state =>
  state.maklerpremium.offerPipeline.pagination
