import React from 'react'
import ReduxToastr from 'react-redux-toastr'

const Toastr = () => {
  return (
    <ReduxToastr
      timeOut={5000}
      position='top-right'
      transitionIn='fadeIn'
      transitionOut='fadeOut'
      progressBar
      preventDuplicates
      newestOnTop={false}
    />
  )
}

export default Toastr
