export const InvoiceCheckPositionReviewPageTranslations = {
  title: {
    redeclaration: 'Umdeklarierung',
    otherService: 'Sonstige Dienstleistung',
    containerChanged: 'Behälteränderung',
    additionalRent: 'Stellzeit',
  },
  breadcrumb: {
    prevLinkTitle: 'Arbeitsvorrat',
  },
  documentTitle: '%{title} für Auftrag %{number}',
  submitButtonText: {
    isSaving: 'Prüfung speichern',
    isReviewing: 'Prüfung abschließen',
  },
  saveInternalNote: {
    confirm: {
      redeclaration:
        'Möchten Sie die Umdeklarierung zwischenspeichern? Der interne Kommentar wird beibehalten',
      otherService:
        'Möchten Sie die Sonstige Dienstleistung zwischenspeichern? Der interne Kommentar wird beibehalten',
      containerChanged:
        'Möchten Sie die Behäteränderung zwischenspeichern? Der interne Kommentar wird beibehalten',
      additionalRent:
        'Möchten Sie die Stellzeit zwischenspeichern? Der interne Kommentar wird beibehalten',
      cancelledPaidDocument:
        'Möchten Sie die Stornierung des bezahlten Beleges zwischenspeichern? ' +
        'Der interne Kommentar wird beibehalten',
    },
    warning: 'Bitte schreiben Sie eine internen Kommentar',
  },
  validation: {
    status: 'Bitte ändern Sie den Status.',
    rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
  },
}
