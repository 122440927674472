import './style.scss'
import classNames from 'classnames'
import { arrayOf, bool, node, oneOf, oneOfType, string } from 'prop-types'
import React from 'react'
import Media from 'react-media'

import { BREAKPOINT } from 'constants/design'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_SHADOW,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from '../../common/Headline'

/**
 * @description This component renders a page header with optional title, subtitle, and children content.
 * @function
 * @param {Object} props the component props
 */
const PageHeader = ({
  title = null,
  subtitle = null,
  children = null,
  sticky = false,
  dataTestId = null,
  shadow = HEADLINE_SHADOW.NONE,
  uppercase = false,
  noMargin = true,
}) => {
  const renderPageTitle = () => {
    if (!title) return null

    return (
      <div className='page-header__title' data-testid={dataTestId}>
        <Headline
          tag={HEADLINE_TAG.H2}
          noMargin={noMargin}
          fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
          headlineStyle={HEADLINE_STYLE.H3}
          color={HEADLINE_COLOR.GRAYISH_BROWN}
          fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
          shadow={shadow}
          uppercase={uppercase}
        >
          {title}
        </Headline>

        {subtitle &&
          (typeof subtitle === 'string' ? (
            <p className='subtitle'>{subtitle}</p>
          ) : (
            <div className='subtitle'>{subtitle}</div>
          ))}
      </div>
    )
  }

  const renderContent = () => (
    <>
      {renderPageTitle()}
      {children && <div className='page-header__content'>{children}</div>}
    </>
  )

  if (sticky) {
    return (
      <Media query={{ maxWidth: BREAKPOINT.SMALL }}>
        {isSmallScreen => (
          <div
            data-uk-sticky={isSmallScreen ? 'offset: 75' : 'offset:91'}
            className={classNames('page-header', 'page-header--sticky')}
          >
            {renderContent()}
          </div>
        )}
      </Media>
    )
  }

  return <div className='page-header'>{renderContent()}</div>
}

PageHeader.propTypes = {
  title: oneOfType([node, string]),
  subtitle: oneOfType([node, string]),
  children: oneOfType([node, arrayOf(node)]),
  sticky: bool,
  dataTestId: string,
  shadow: oneOf(Object.values(HEADLINE_SHADOW)),
  uppercase: bool,
  noMargin: bool,
}

export default PageHeader
