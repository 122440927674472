import { arrayOf, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { actions } from 'react-redux-form'

import FileUploadControl from 'components/common/Form/components/FileUploadControl'
import SelectControl from 'components/common/Form/components/SelectControl'
import TextboxControl from 'components/common/Form/components/TextboxControl'
import SingleFileUpload from 'components/common/SingleFileUpload'
import Icon from 'components/common/Fontello'
import ControlEditToggle from 'components/common/Form/components/ControlEditToggle'
import { FieldScheme } from 'components/common/Form/schemes'
import { SectionHeader } from 'components/common/SectionHeader'
import { Textarea } from 'components/common/Textarea'
import { CompanyScheme } from 'schemes/company'
import { UserScheme } from 'schemes/user'

import { CompanyFormValidators } from '../../constants'

import connector from './connector'
import { getListLabelForUser } from './helper'

export class ProfileInfosComponent extends Component {
  /**
   * @description Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    if (this.props.company.id) {
      this.props.actions.user.getCompanyUsers(Number(this.props.company.id))
    } else {
      this.props.actions.user.getCompanyUsers()
    }
  }

  render() {
    return (
      <section className='uk-card uk-padding-remove profile-infos'>
        <SectionHeader>
          <Icon name='user-id' />
          <Translate value='companyProfilePage.profileInfos' />
        </SectionHeader>

        <div className='uk-padding details-container'>
          <div className='uk-grid'>
            <div className='uk-width-1-1 uk-width-1-3@l'>
              <div className='profile-img uk-margin'>
                <FileUploadControl
                  addMapProps={{
                    displayEditIcon: true,
                    iconName: 'photo',
                    initialFile: this.props.company.profile_image_object,
                  }}
                  field={this.props.companyForm.profile_image}
                  singleFormField
                  uploadComponent={SingleFileUpload}
                />
              </div>
            </div>

            <div className='uk-width-1-1 uk-width-1-3@l'>
              <ControlEditToggle
                className='uk-margin-small-bottom'
                label={I18n.t('companyProfilePage.label.contactPerson')}
                value={getListLabelForUser(
                  this.props.company.contact_person_object,
                )}
                triggerEdit={
                  this.props.companyForm.contact_person &&
                  !this.props.companyForm.contact_person.valid
                }
                editable
              >
                <SelectControl
                  className='uk-select'
                  field={this.props.companyForm.contact_person}
                  validators={CompanyFormValidators.contact_person}
                  options={this.props.userList.map(item => ({
                    id: item.id,
                    label: getListLabelForUser(item),
                  }))}
                  errorMessages={{
                    isRequired: I18n.t(
                      'companyProfilePage.validation.contactPerson',
                    ),
                  }}
                  placeholder={I18n.t(
                    'companyProfilePage.placeholder.contactPerson',
                  )}
                />
              </ControlEditToggle>
            </div>

            <div className='uk-width-1-1 uk-width-1-3@l'>
              <ControlEditToggle
                className='uk-margin-small-bottom'
                label={I18n.t('companyProfilePage.label.websiteUrl')}
                value={this.props.company.website_url}
                triggerEdit={
                  this.props.companyForm.website_url &&
                  !this.props.companyForm.website_url.valid
                }
                editable
              >
                <TextboxControl
                  className='uk-input'
                  field={this.props.companyForm.website_url}
                  validators={CompanyFormValidators.website_url}
                  placeholder={I18n.t(
                    'companyProfilePage.placeholder.websiteUrl',
                  )}
                  errorMessages={{
                    isURL: I18n.t('companyProfilePage.validation.websiteUrl'),
                  }}
                  maxLength={100}
                />
              </ControlEditToggle>
              <ControlEditToggle
                className='uk-margin-medium-bottom uk-text-break'
                label={I18n.t('companyProfilePage.label.aboutUs')}
                value={this.props.company.about_us}
                editable
              >
                <Textarea
                  maxLength={500}
                  name='companyForm.about_us'
                  onChange={event =>
                    this.props.dispatch(
                      actions.change(
                        this.props.companyForm.about_us.model,
                        event,
                      ),
                    )
                  }
                  placeholder={I18n.t('companyProfilePage.placeholder.aboutUs')}
                  showRemainingCharacters
                  value={this.props.companyForm.about_us.value}
                />
              </ControlEditToggle>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

ProfileInfosComponent.propTypes = {
  actions: shape({
    user: shape({
      getCompanyUsers: func.isRequired,
    }).isRequired,
  }).isRequired,
  company: shape(CompanyScheme).isRequired,
  companyForm: shape({
    about_us: FieldScheme,
    contact_person: FieldScheme.isRequired,
    eta_service: FieldScheme,
    profile_image: FieldScheme,
    website_url: FieldScheme,
  }).isRequired,
  dispatch: func.isRequired,
  userList: arrayOf(shape(UserScheme)),
}

ProfileInfosComponent.defaultProps = {
  userList: [],
}

export default connector(ProfileInfosComponent)
