import { shape } from 'prop-types'
import React from 'react'

import { AttachmentScheme } from 'components/common/SingleFileUpload/schemes'
import Icon, { ICON_NAME } from 'components/common/Fontello'

/**
 * This is a small container that displays an image or "document" icon, depending on the file type.
 * @param props
 * @returns {*}
 * @constructor
 */
const FileThumbnail = props => (
  <>
    {props.file.type && props.file.type.split('/')[0] === 'image' ? (
      <img
        src={props.file.file}
        className='uk-border-rounded'
        alt={props.file.name}
      />
    ) : (
      <div className='uk-flex-center uk-margin-auto'>
        {props.file.type && props.file.type.split('/')[1] === 'pdf' && (
          <Icon name={ICON_NAME.FILE_PDF} />
        )}
        {props.file.type && props.file.type.split('/')[1] === 'csv' && (
          <Icon name={ICON_NAME.DOCUMENT} />
        )}
      </div>
    )}
  </>
)

FileThumbnail.propTypes = {
  file: shape(AttachmentScheme).isRequired, // The file to display
}

export default FileThumbnail
