import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { getCompany } from 'actions/company'
import { getArchivedCertificates } from 'actions/certificate'
import { getArchivedCertificatesSelector } from 'selectors/certificate'
import { getCompanySelector } from 'selectors/company'

import { DisposerCustomerInfo } from '../../common/DisposerCustomerInfo'
import PageHeader from '../../layout/PageHeader'

import { CompanyArchiveTable } from './components/companyArchiveTable'

export const CompanyArchivePage: FC<
  RouteComponentProps<{ companyId: string }>
> = ({
  match: {
    params: { companyId },
  },
}) => {
  // Redux
  const dispatch = useDispatch()

  const company = useSelector(getCompanySelector)
  const certificates = useSelector(getArchivedCertificatesSelector)

  // Retrieve current company
  useEffect(() => {
    if (companyId) {
      if (!company || company.id !== Number(companyId)) {
        dispatch(
          getCompany({
            id: Number(companyId),
          }),
        )
      }
    }
  }, [company, companyId, dispatch])

  // Retrieve archived certificates belonging to current company
  useEffect(() => {
    if (companyId) {
      dispatch(
        getArchivedCertificates(
          null,
          {
            company_id: companyId,
          },
          { order_by: '[{"id": "lastmodified_at", "desc": true}]' },
        ),
      )
    }
  }, [company, companyId, dispatch])

  return (
    <>
      <PageHeader
        title={I18n.t('companyArchiveTranslations.page.headline')}
        subtitle={
          <>
            <p>{I18n.t('companyArchiveTranslations.page.introduction')}</p>
            <DisposerCustomerInfo />
          </>
        }
      ></PageHeader>
      <CompanyArchiveTable
        data={certificates}
        filters={{ company_id: companyId }}
      />
    </>
  )
}
