import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { lockUnlockAutoOffers } from 'actions/autooffer'
import { resetApiFetchErrors } from 'actions/app'
import { getCurrentUserSelector } from 'selectors/user'

import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { ProgressButton } from '../../common/ProgressButton'

interface LockUnlockAutoOffersWarningModalProps {
  isOpen?: boolean
  onClose?: () => void
  isLocking?: boolean
}

export const LockUnlockAutoOffersWarningModal: FC<
  LockUnlockAutoOffersWarningModalProps
> = ({ isOpen = false, onClose = () => undefined, isLocking = false }) => {
  const dispatch = useDispatch()

  const user = useSelector(getCurrentUserSelector)

  const idLockAutoOfferModalHeadline = uniqueId()

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('UPDATE_COMPANY'))
    },
    [dispatch],
  )

  return (
    <Modal
      ariaDescribedBy={idLockAutoOfferModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          isLocking
            ? I18n.t('myAutoOffersPageTranslations.lockAutoOffers.modal.title')
            : I18n.t(
                'myAutoOffersPageTranslations.unlockAutoOffers.modal.title',
              )
        }
        titleId={idLockAutoOfferModalHeadline}
      />
      <div className='my-auto-offers-page__lock-unlock-modal'>
        <div className='uk-modal-body my-auto-offers-page__lock-unlock-modal'>
          {isLocking
            ? I18n.t(
                'myAutoOffersPageTranslations.lockAutoOffers.warningMessage',
              )
            : I18n.t(
                'myAutoOffersPageTranslations.unlockAutoOffers.warningMessage',
              )}
        </div>
        <div className='uk-modal-footer uk-text-right'>
          <span className='uk-margin-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={() => onClose()}
            >
              {isLocking
                ? I18n.t('general.button.cancel')
                : I18n.t('general.no')}
            </Button>
          </span>

          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='lock-unlock-auto-offer-submit'
            onClick={() => {
              dispatch(lockUnlockAutoOffers(user.company))
              onClose()
            }}
          >
            {isLocking
              ? I18n.t('myAutoOffersPageTranslations.actionButtons.lock')
              : I18n.t('general.yes')}
          </ProgressButton>
        </div>
      </div>
    </Modal>
  )
}
