import './style.scss'

import React, { FC, useEffect, useState } from 'react'

import Icon, { ICON_NAME } from '../../common/Fontello'

import FilesPreviewer from './components/FilesPreviewer'
import ImageViewer from './components/ImageViewer'
import PdfViewer from './components/PdfViewer'

interface FilesViewerProps {
  files: Attachment[]
  allowRotate?: boolean
  showPreview?: boolean
  withFilenameTooltip?: boolean
}

export const FilesViewer: FC<FilesViewerProps> = ({
  files = [],
  allowRotate = false,
  showPreview = true,
  withFilenameTooltip = false,
}) => {
  const [rotate, setRotate] = useState<number>(0)
  const [selectedFile, setSelectedFile] = useState<Attachment>({
    id: undefined,
    file: '',
    name: '',
    type: '',
    size: undefined,
    text: '',
    lastmodified_at: '',
    thumbnail: '',
    orientation: 0,
  })
  const [selectedFileType, setSelectedFileType] = useState<string>('')

  useEffect(() => {
    if (files.length > 0) setSelectedFile(files[0])
  }, [files, files.length])

  // For some reason, when the image is uploaded through the Admin, no type is returned in the object.
  // For that reason, in case the type is null, we are attributing the type according to the file extension
  useEffect(() => {
    if (selectedFile) {
      if (selectedFile.type) setSelectedFileType(selectedFile.type)
      else {
        if (selectedFile.file.includes('pdf')) setSelectedFileType('pdf')
        if (selectedFile.file.includes('bmp')) setSelectedFileType('image')
        if (selectedFile.file.includes('flif')) setSelectedFileType('image')
        if (selectedFile.file.includes('jpeg')) setSelectedFileType('image')
        if (selectedFile.file.includes('jpg')) setSelectedFileType('image')
        if (selectedFile.file.includes('png')) setSelectedFileType('image')
        if (selectedFile.file.includes('svg+xml')) setSelectedFileType('image')
        if (selectedFile.file.includes('webp')) setSelectedFileType('image')
      }
    }
  }, [selectedFile])

  return (
    <div className='files-viewer details-container uk-position-relative uk-padding-small uk-border-rounded'>
      {/* eslint-disable-next-line no-mixed-operators */}
      {(showPreview === false || (files.length > 1 && selectedFile)) && (
        <div className='uk-float-left'>
          <FilesPreviewer
            files={files}
            selectedFile={selectedFile}
            onClick={file =>
              showPreview
                ? setSelectedFile(file)
                : window.open(file.file, '_blank')
            }
            withFilenameTooltip={withFilenameTooltip}
          />
        </div>
      )}
      <div className='uk-float-right'>
        {selectedFile && showPreview && (
          <div className='files-viewer-toolbar'>
            {!selectedFileType.includes('pdf') && allowRotate && (
              <button
                type='button'
                className='rotate uk-icon-button uk-button-secondary'
                onClick={() => setRotate(rotate + 90)}
              >
                <Icon name={ICON_NAME.CLOCKWISE} />
              </button>
            )}

            <a
              href={selectedFile.file}
              target='_blank'
              rel='noopener noreferrer'
              className='open uk-icon-button uk-button-secondary uk-margin-small-left'
            >
              <Icon name='expand' />
            </a>
          </div>
        )}
      </div>
      <div className='uk-clearfix' />

      {selectedFile && showPreview && (
        <div className='uk-flex uk-flex-center uk-padding'>
          {selectedFileType.includes('image') && (
            <ImageViewer file={selectedFile} rotate={rotate} />
          )}

          {selectedFileType.includes('pdf') && (
            <PdfViewer url={selectedFile.file} />
          )}
        </div>
      )}
    </div>
  )
}
