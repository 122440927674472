import { BookkeepingList } from '../components/maklerpremium/BookkeepingList'
import { AddInvoicePage } from '../components/maklerpremium/BookkeepingList/AddInvoicePage'
import { CollectiveInvoicingPage } from '../components/maklerpremium/CollectiveInvoicing'
import { CommunicationCenterPage } from '../components/maklerpremium/CommunicationCenterPage'
import { CreateMaklerPremiumOfferPage } from '../components/maklerpremium/CreateMaklerPremiumOfferPage'
import { DisposerProducerPage } from '../components/maklerpremium/DisposerProducerPage'
import { OfferPipelinePage } from '../components/maklerpremium/OfferPipelinePage'
import { UpcomingOrdersList } from '../components/maklerpremium/UpcomingOrders'
import { UserPermission } from '../constants/user'

/**
 * @constant
 * @description A constant to define maklerPremium routes.
 */
export const maklerpremiumRoutes = [
  {
    path: ['/maklerpremium/customer', '/maklerpremium/partner'],
    component: DisposerProducerPage,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/offer/create',
    component: CreateMaklerPremiumOfferPage,
    exact: true,
  },
  {
    path: '/maklerpremium/offer/pipeline',
    component: OfferPipelinePage,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/bookkeeping',
    component: BookkeepingList,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_INVOICE],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/bookkeeping/add_invoice',
    component: AddInvoicePage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_INVOICE],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/upcoming_orders',
    component: UpcomingOrdersList,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/communicationcenter/:companyId/',
    component: CommunicationCenterPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_COMMUNICATIONCENTER],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/collective-invoicing',
    component: CollectiveInvoicingPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_COLLECTIVE_INVOICING],
    showErrorPage: true,
  },
]
