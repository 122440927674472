import './style.scss'

import { Form, Formik, FormikErrors } from 'formik'
import moment from 'moment'
import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

import { resetApiFetchLoading } from 'actions/app'
import {
  createCombinedOffer,
  resetPriceComparisonTable,
} from 'actions/maklerpremium'
import { FormStep } from 'components/common/FormSteps'
import {
  progressStepStatus,
  STEP_STATUS,
} from 'components/common/FormSteps/helper'
import { RuntimeOfInquiryValues } from 'components/common/GenericInquiryFields/RuntimeOfInquiry'
import { COMPANY_STATUS } from 'components/company/constants'
import {
  OFFER_ORDER_TYPE,
  OFFER_SERVICE_INTERVAL,
  OFFER_TIME_OF_DAY,
} from 'components/inquiry/constants'
import { validateInquiryDates } from 'components/inquiry/CreateInquiryPage/helpers'
import { USER_GENDER } from 'helper/user'
import { getFrameworkContractPositionsSelector } from 'selectors/maklerpremium'

import { contractApplies } from '../../helpers'
import { TaxSectionValues } from '../../TaxSectionFields'

import { CustomerInfoForm, CustomerInfoSummary } from './Step1'
import { FractionAndContainerForm, FractionAndContainerSummary } from './Step2'
import { GenerateOfferForm } from './Step3'

export enum CUSTOMER_TYPE {
  RETURNING_CUSTOMER,
  NEW_CUSTOMER,
}

export enum CUSTOMER_SUBTYPE {
  PRIVATE_CUSTOMER = 1,
  BUSINESS_CUSTOMER = 2,
}

type CreateMaklerPremiumOfferContextType = {
  stepStatus: STEP_STATUS
  saveStep: () => void
  editStep: () => void
  autoOfferCompany?: ReactNode
  setAutoOfferCompany: (company: ReactNode) => void
}

export const CreateMaklerPremiumOfferContext =
  createContext<CreateMaklerPremiumOfferContextType>({
    stepStatus: STEP_STATUS.INITIAL,
    saveStep: () => undefined,
    editStep: () => undefined,
    autoOfferCompany: undefined,
    setAutoOfferCompany: () => undefined,
  })

export interface MaklerPremiumOfferValues
  extends Partial<RuntimeOfInquiryValues>,
    Partial<TaxSectionValues> {
  // Step 1: CustomerInfo
  customer_type?: CUSTOMER_TYPE
  collection_address?: number
  existing_customer?: number
  existing_customer_status?: number
  search_field?: string
  zipcode?: string
  // Step 2: Fraction & Container / Pricing
  auto_offer?: number
  discount?: number
  fraction?: number
  fine_fraction?: number
  security_group?: number
  container?: number
  best_container?: number
  number_of_containers?: number
  quantity_in_cubic_meters?: number
  position_on_public_ground?: boolean
  offer_end_price?: number
  // Step 3: Create Offer
  // OrderTypeDetailsValues are extended here for compatibility with Inquiry Form
  email_note?: string
  customer_subtype?: CUSTOMER_SUBTYPE
  company_name?: string
  customer_email?: string
  customer_telephone?: string
  first_name?: string
  last_name?: string
  gender?: USER_GENDER
  street?: string
  house_number?: string
  city?: string
  different_invoicing_address?: boolean
  /**
   * if different_invoicing_address is True, value from company_name and address_description will be swapped
   * to used logic from epd shop.
   *
   * company_name -> address_description
   * address_description -> company_name
   */
  address_description?: string
  invoice_recipient?: string
  invoice_street?: string
  invoice_house_number?: string
  invoice_zipcode?: string
  invoice_city?: string
  is_compulsory_offer?: boolean
  is_order_of_framework_contract: boolean
  non_field_errors: any
}

export const CreateMaklerPremiumOfferFormSteps: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<{ company: CompanySmall }>()

  const [stepsStatus, setStepsStatus] = useState<STEP_STATUS[]>([
    STEP_STATUS.COMPLETED,
    STEP_STATUS.CREATING,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
  ])

  const scrollOffsets = {
    1: -100, // Step 1 needs to offset the header
    2: 150, // No reason for Step 2 to need this offset, but it works across resolutions (MDPI/zentek, 1080p/default)
  }

  const [autoOfferCompany, setAutoOfferCompany] = useState<ReactNode>()

  const formSteps = {
    // Step 1: CustomerInfo
    1: { form: <CustomerInfoForm />, summary: <CustomerInfoSummary /> },
    // Step 2: Fraction & Container / Pricing
    2: {
      form: <FractionAndContainerForm />,
      summary: <FractionAndContainerSummary />,
    },
    // Step 3: Create Offer
    3: { form: <GenerateOfferForm />, summary: <></> },
  }

  function tempStep(
    index: string,
    step: { summary: ReactNode; form: ReactNode },
  ) {
    return stepsStatus[Number(index)] > STEP_STATUS.INITIAL &&
      stepsStatus[Number(index)] === STEP_STATUS.COMPLETED
      ? step.summary
      : step.form
  }

  const frameworkContractPositions = useSelector(
    getFrameworkContractPositionsSelector,
  )

  // On unmount, if price comparison table is stored in the current state,
  // reset theprice comparison table so that we receive a fresh instance of the price comparison table
  useEffect(
    () => () => {
      dispatch(resetPriceComparisonTable())
      dispatch(resetApiFetchLoading('GET_PRICE_COMPARISON_TABLE'))
    },
    [dispatch],
  )
  const company = useMemo(() => location?.state?.company, [location])

  return (
    <Formik
      initialValues={{
        // Step 1: CustomerInfo
        collection_address: undefined,
        customer_type: undefined,
        existing_customer: company ? company.id : undefined,
        existing_customer_status: undefined,
        search_field: 'company_name',
        zipcode: undefined,
        // Step 2: Fraction & Container / Pricing
        fraction: undefined,
        container: undefined,
        auto_offer: undefined,
        offer_end_price: undefined,
        discount: company?.permanent_discount ?? 0,
        fine_fraction: undefined,
        security_group: undefined,
        number_of_containers: 1,
        position_on_public_ground: undefined,
        // -- Insert values here
        // Step 3: Create Offer
        order_type: OFFER_ORDER_TYPE.TYPE_ONE_TIME,
        delivery_date: '',
        collection_date: '',
        time_of_day_delivery: String(OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY),
        time_of_day_collection: String(OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY),
        rythm_begin: '',
        turn_begin: '',
        turn_end: '',
        interval: undefined,
        interval_weekday_first: undefined,
        interval_weekday_second: undefined,
        customer_email: '',
        email_note: undefined,
        customer_subtype: undefined,
        company_name: undefined,
        street: undefined,
        house_number: undefined,
        city: undefined,
        gender: undefined,
        first_name: undefined,
        last_name: undefined,
        tax_id: undefined,
        tax_number: undefined,
        runtime_of_inquiry: '',
        different_invoicing_address: false,
        address_description: undefined,
        invoice_recipient: '',
        invoice_street: '',
        invoice_house_number: '',
        invoice_zipcode: '',
        invoice_city: '',
        is_compulsory_offer: false,
        is_order_of_framework_contract: false,
        non_field_errors: undefined, // dummy value for non-field-errors, DO NOT USE in Form
      }}
      validationSchema={() =>
        yup.object().shape({
          // Step 1: CustomerInfo
          collection_address: yup.number(),
          customer_type: yup.number().required(),
          existing_customer: yup.number(),
          search_field: yup.string(),
          zipcode: yup.string(),
          // Step 2: Fraction & Container / Pricing
          discount: yup
            .number()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.permanent_discount',
              ),
            ),
          fraction: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.permanent_discount',
              ),
            ),
          position_on_public_ground: yup.boolean(),
          // Step 3: Create Offer
          order_type: yup.string().required(),
          delivery_date: yup.string(),
          time_of_day_delivery: yup.string(),
          collection_date: yup.string(),
          rythm_begin: yup.string(),
          turn_begin: yup.string(),
          turn_end: yup.string(),
          interval: yup.string(),
          interval_weekday_first: yup.string(),
          interval_weekday_second: yup.string(),
          time_of_day_collection: yup.string(),
          customer_email: yup.string().email(),
          email_note: yup.string().trim().min(1).max(500),
          customer_subtype: yup.number(),
          street: yup.string(),
          house_number: yup.string(),
          postal_code: yup.string(),
          city: yup.string(),
          gender: yup.number().oneOf(Object.values(USER_GENDER).map(Number)),
          first_name: yup.string(),
          last_name: yup.string(),
          tax_id: yup
            .string()
            .matches(/^DE\d{9}$/i, I18n.t('message.validation.germanTaxId')),
          tax_number: yup.string().matches(
            // Regex derived from examples in https://de.wikipedia.org/wiki/Steuernummer
            /(^\d{10,11}$)|(^\d{2,3}[/ ]?\d{3,4}[/ ]\d{4,5}$)|(^\d{13}$)/,
            I18n.t('message.validation.germanTaxNumber'),
          ),
          runtime_of_inquiry: yup.string().required(),
          different_invoicing_address: yup.boolean().required(),
          address_description: yup.string(),
          invoice_recipient: yup.string(),
          invoice_street: yup.string(),
          invoice_house_number: yup.string(),
          invoice_zipcode: yup.number(),
          invoice_city: yup.string(),
          is_compulsory_offer: yup.boolean().required(),
        })
      }
      validate={(values: MaklerPremiumOfferValues) => {
        const isFieldValid = name => !!(values[name] && values[name].length > 0)

        let errors: FormikErrors<MaklerPremiumOfferValues> = {}

        // Step 1: CustomerInfo
        if (values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER) {
          if (
            values.existing_customer_status ===
            COMPANY_STATUS.STATUS_INACTIVE_BY_EPD
          ) {
            errors.existing_customer = I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.1.fields.existing_customer.error',
            )
          }
          if (!values.collection_address) {
            errors.collection_address = I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.1.fields.collection_address.error',
            )
          }
        } else if (!values.zipcode || `${values.zipcode}`.length !== 5) {
          errors.zipcode = I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.1.fields.zipcode.error',
          )
        }

        // Step 2: Fraction & Container / Pricing

        // Step 3: Create Offer
        // (Only check fields required for non-binding offer, extras are checked separately in GenerateOfferForm)
        errors = {
          ...errors,
          ...validateInquiryDates(values),
          ...(!isFieldValid('customer_email') && {
            customer_email: I18n.t('general.fieldError'),
          }),
        }

        if (values.interval) {
          if (!values.interval_weekday_first) {
            errors = {
              ...errors,
              ...{ interval_weekday_first: I18n.t('general.fieldError') },
            }
          }

          if (
            Number(values.interval) ===
            OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
          ) {
            if (!values.interval_weekday_second) {
              errors = {
                ...errors,
                ...{ interval_weekday_first: I18n.t('general.fieldError') },
              }
            }
          }
        }

        if (values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER) {
          errors = {
            ...errors,
            ...(!isFieldValid('gender') && {
              gender: I18n.t('general.fieldError'),
            }),
            ...(!isFieldValid('first_name') && {
              first_name: I18n.t('general.fieldError'),
            }),
            ...(!isFieldValid('last_name') && {
              last_name: I18n.t('general.fieldError'),
            }),
          }

          if (values.different_invoicing_address) {
            errors = {
              ...errors,
              ...(!isFieldValid('address_description') && {
                address_description: I18n.t('general.fieldError'),
              }),
              ...(!isFieldValid('invoice_street') && {
                invoice_street: I18n.t('general.fieldError'),
              }),
              ...(!isFieldValid('invoice_house_number') && {
                invoice_house_number: I18n.t('general.fieldError'),
              }),
              ...(!isFieldValid('invoice_city') && {
                invoice_city: I18n.t('general.fieldError'),
              }),
              ...(!isFieldValid('invoice_zipcode') && {
                invoice_zipcode: I18n.t('general.fieldError'),
              }),
            }
          }

          if (!values.customer_subtype) {
            errors = {
              ...errors,
              ...(!isFieldValid('customer_subtype') && {
                customer_subtype: I18n.t('general.fieldError'),
              }),
            }
          }
        }

        return errors
      }}
      onSubmit={(values: MaklerPremiumOfferValues, formikHelpers) => {
        let { company_name } = values
        let address_description = values.company_name
        if (
          values.different_invoicing_address &&
          values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER
        ) {
          if (values.customer_subtype === CUSTOMER_SUBTYPE.PRIVATE_CUSTOMER) {
            address_description = `${values.first_name} ${values.last_name}`
          } else {
            address_description = values.company_name
          }
          company_name = values.address_description
        }

        let valuesToSend: Partial<MaklerPremiumOfferValues> = {
          ...values,
          collection_date: values.collection_date
            ? moment(values.collection_date).format('L')
            : '',
          delivery_date: values.delivery_date
            ? moment(values.delivery_date).format('L')
            : '',
          interval: values.interval ? values.interval : undefined,
          interval_weekday_first: values.interval_weekday_first
            ? values.interval_weekday_first
            : undefined,
          interval_weekday_second:
            values.interval_weekday_second &&
            Number(values.interval) ===
              OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
              ? values.interval_weekday_second
              : undefined,
          time_of_day_delivery: values.time_of_day_delivery
            ? values.time_of_day_delivery
            : `${OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY}`,
          time_of_day_collection: values.time_of_day_collection
            ? values.time_of_day_collection
            : `${OFFER_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY}`,
          rythm_begin: values.rythm_begin
            ? moment(values.rythm_begin).format('L')
            : '',
          turn_begin: values.turn_begin
            ? moment(values.turn_begin).format('L')
            : '',
          turn_end: values.turn_end ? moment(values.turn_end).format('L') : '',
          is_order_of_framework_contract:
            values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER &&
            contractApplies(frameworkContractPositions, values),
          company_name,
          address_description,
        }

        valuesToSend = Object.fromEntries(
          Object.entries(valuesToSend).filter(([, value]) => value !== ''),
        )

        const callback = (error = false, errorObj: any = {}) => {
          if (!error) {
            history.push('/dashboard')
          } else {
            formikHelpers.setSubmitting(false)
            if (errorObj) {
              const errors = Object.fromEntries(
                Object.entries(errorObj).map(([key, value]) => {
                  const newKey = key === 'email' ? 'customer_email' : key
                  if (Array.isArray(value)) {
                    return [newKey, value[0]]
                  }
                  if (typeof value === 'string') {
                    return [newKey, value]
                  }
                  return [newKey, '']
                }),
              )
              formikHelpers.setErrors(errors)
            }
          }
        }

        dispatch(createCombinedOffer(valuesToSend, callback))
      }}
    >
      <Form>
        {Object.entries(formSteps).map(([index, step]) => (
          <FormStep
            key={`step_${index}`}
            isDisabled={stepsStatus[Number(index) - 1] < STEP_STATUS.COMPLETED}
            showDivider
            showProgressLine
            stepNumber={
              stepsStatus[Number(index)] < STEP_STATUS.COMPLETED &&
              Number(index)
            }
            title={`createMaklerPremiumOfferPageTranslations.steps.${index}.title`}
          >
            <CreateMaklerPremiumOfferContext.Provider
              value={{
                stepStatus: stepsStatus[Number(index)],
                saveStep: () => {
                  progressStepStatus(
                    stepsStatus,
                    setStepsStatus,
                    Number(index),
                    scrollOffsets,
                  )
                },
                editStep: () => {
                  progressStepStatus(
                    stepsStatus,
                    setStepsStatus,
                    Number(index),
                    scrollOffsets,
                  )
                },
                autoOfferCompany,
                setAutoOfferCompany,
              }}
            >
              {tempStep(index, step)}
            </CreateMaklerPremiumOfferContext.Provider>
          </FormStep>
        ))}
      </Form>
    </Formik>
  )
}
