import './styles.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { ReactComponent as MoneyGradientIcon } from 'assets/svg/detailspage/money-gradient.svg'
import { ReactComponent as DocumentIcon } from 'assets/svg/detailspage/document.svg'
import { getCurrentUserSelector } from 'selectors/user'

import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import {
  BUTTON_BACKGROUND_COLOR,
  ICON_POSITION,
  IconButton,
} from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import PageHeader from '../../layout/PageHeader'
import { getBusinessSegments, getGroups } from '../../maklerpremium/helpers'

import { ActivitiesTable } from './activitiesTable/ActivitiesTable'
import { AddPriceModalFormikWrapper } from './AddPriceModal'
import { MinimumTurnoverTable } from './minimumTurnoverTable/MinimumTurnoverTable'
import { PricesTable } from './pricesTable/PricesTable'

const PriceManagementPageComponent: FC = () => {
  const user = useSelector(getCurrentUserSelector)
  const { isDSZ } = getBusinessSegments(user)
  const { isAdministrator } = getGroups(user)

  const idAddPriceModalHeadline = uniqueId()
  const [isAddPriceModalOpen, setIsAddPriceModalOpen] = useState(false)
  const [editingInstance, setEditingInstance] = useState<
    DszFractionPrice | undefined
  >(undefined)

  const handleOpenCloseIsAddPriceModal = () => {
    setIsAddPriceModalOpen(!isAddPriceModalOpen)
  }

  return (
    <>
      <div className='uk-margin-small-top'>
        <Helmet>
          <title>{I18n.t('pageTitles.dszPriceManagement')}</title>
        </Helmet>
        <PageHeader
          title={I18n.t('priceManagementPageTranslations.headline')}
          subtitle={
            <Translate
              value='priceManagementPageTranslations.introduction'
              dangerousHTML
              tag={'p'}
              className='uk-margin-remove'
            />
          }
        >
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <RequiredPermissions requiredPermissions={[]}>
              <IconButton
                iconName='export'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => undefined}
                isDisabled={false}
                isLoading={false}
              >
                <Translate value='general.export' />
              </IconButton>
            </RequiredPermissions>
            {isDSZ && isAdministrator ? (
              <RequiredPermissions requiredPermissions={[]}>
                <IconButton
                  iconName='plus'
                  iconPosition={ICON_POSITION.RIGHT}
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  onClick={() => {
                    setEditingInstance(undefined)
                    handleOpenCloseIsAddPriceModal()
                  }}
                  isDisabled={false}
                  isLoading={false}
                >
                  <Translate value='priceManagementPageTranslations.addPriceModal.buttonText' />
                </IconButton>
              </RequiredPermissions>
            ) : null}
          </ButtonBar>
        </PageHeader>
        <PricesTable
          handleOpenCloseIsAddPriceModal={handleOpenCloseIsAddPriceModal}
          setEditingInstance={instance => {
            setEditingInstance(instance)
          }}
        />
        <div className='dsz-price-management-page--section'>
          <span className='dsz-price-management-page--section--title'>
            <MoneyGradientIcon className='icon--color-default icon--background-color-default dsz-price-management-page--section--icon' />
            <Translate value='priceManagementPageTranslations.minimumTurnoverTable.headline' />
          </span>
          <MinimumTurnoverTable />
        </div>
        <div className='dsz-price-management-page--section'>
          <span className='dsz-price-management-page--section--title'>
            <DocumentIcon className='icon--color-default icon--background-color-default dsz-price-management-page--section--icon' />
            <Translate value='priceManagementPageTranslations.activitiesTable.headline' />
          </span>
          <ActivitiesTable />
        </div>
      </div>

      <Modal
        ariaDescribedBy={idAddPriceModalHeadline}
        isOpen={isAddPriceModalOpen}
        onClose={handleOpenCloseIsAddPriceModal}
      >
        <ModalHeader
          onClose={handleOpenCloseIsAddPriceModal}
          title={I18n.t(
            'priceManagementPageTranslations.addPriceModal.buttonText',
          )}
          titleId={idAddPriceModalHeadline}
        />
        <div className='uk-modal-body'>
          <p>
            {I18n.t('priceManagementPageTranslations.addPriceModal.introText')}
          </p>
        </div>
        <AddPriceModalFormikWrapper
          handleCloseModal={() => {
            handleOpenCloseIsAddPriceModal()
          }}
          editingInstance={editingInstance}
        />
      </Modal>
    </>
  )
}

export const PriceManagementPage = withErrorBoundary(
  withApiErrorHandling(PriceManagementPageComponent, [
    'GET_DSZ_FRACTIONS',
    'GET_INVOICES',
  ]),
)
