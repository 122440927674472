import React from 'react'

import ActionTypes from '../actiontypes/invoicecheck'

/** @function
 * @name createInvoiceCheck
 * @description Creates invoiceCheck for makler premium
 * @param createInvoiceCheckData CreateInvoiceCheckScheme
 * @param callback
 * @return {Object}
 */
export const createInvoiceCheck = (
  createInvoiceCheckData,
  callback?: () => void,
) => ({
  type: ActionTypes.CREATE_INVOICE_CHECK_REQUEST,
  payload: {
    createInvoiceCheckData,
    callback,
  },
})

/** @function
 * @name updateInvoiceCheck
 * @description Updates invoiceCheck for makler premium
 * @param updateInvoiceCheckData CreateInvoiceCheckScheme
 * @return {Object}
 */
export const updateInvoiceCheck = updateInvoiceCheckData => ({
  type: ActionTypes.UPDATE_INVOICE_CHECK_REQUEST,
  payload: {
    updateInvoiceCheckData,
  },
})

/**
 * @function
 * @name getInvoiceChecks
 * @description Loads a list of invoicecheck objects.
 * @return {Object}
 */
export const getInvoiceChecks = (page?: number, filters?: any) => ({
  type: ActionTypes.GET_INVOICE_CHECKS_REQUEST,
  payload: {
    page,
    filters,
  },
})

/**
 * @function
 * @name getInvoiceCheck
 * @return {Object}
 * @param orderId
 * @param expand
 */
export const getInvoiceCheckForAlreadyCoveredOrder = (
  orderId: number,
  expand?: string[],
) => ({
  type: ActionTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_REQUEST,
  payload: {
    orderId,
    expand,
  },
})

/**
 * @function
 * @name getInvoiceCheck
 * @param invoiceCheckId
 * @param expand
 * @return {Object}
 */
export const getInvoiceCheck = (
  invoiceCheckId: number,
  expand: string[] = [],
) => ({
  type: ActionTypes.GET_INVOICE_CHECK_REQUEST,
  payload: {
    invoiceCheckId,
    expand,
  },
})

/**
 * @function
 * @name resetInvoiceCheckError
 * @return {Object}
 */
export const resetInvoiceCheckError = () => ({
  type: ActionTypes.RESET_INVOICE_CHECK_ERROR,
})

/** @function
 * @name createInvoiceCheckPosition
 * @description Creates invoiceCheckPosition for makler premium
 * @param data CreateInvoiceCheckPositionType
 * @return {Object}
 */
export const createInvoiceCheckPosition = data => ({
  type: ActionTypes.CREATE_INVOICE_CHECK_POSITION_REQUEST,
  payload: {
    data,
  },
})

/** @function
 * @name updateInvoiceCheckPosition
 * @description Updates invoiceCheck for makler premium
 * @return {Object}
 */
export const updateInvoiceCheckPosition = (data, id: React.ReactText) => ({
  type: ActionTypes.UPDATE_INVOICE_CHECK_POSITION_REQUEST,
  payload: {
    data,
    id,
  },
})

/** @function
 * @name updateInvoiceCheckPosition
 * @description Updates invoiceCheck for makler premium
 * @return {Object}
 */
export const deleteInvoiceCheckPosition = (id: React.ReactText) => ({
  type: ActionTypes.DELETE_INVOICE_CHECK_POSITION_REQUEST,
  payload: {
    id,
  },
})

/**
 * @name updateReviewedInvoiceCheckPosition
 * @description updates the status and review fields of an invoice check position.
 * @return {Object}
 */
export const updateReviewedInvoiceCheckPosition = (
  invoiceCheckPosition,
  id,
  history,
) => ({
  type: ActionTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_REQUEST,
  payload: {
    data: invoiceCheckPosition,
    id,
    history,
  },
})

/**
 * @function
 * @name getInvoiceCheckPositions
 * @description Loads a list of invoicecheckposition objects.
 * @return {Object}
 */
export const getInvoiceCheckPositions = (page?: number, filters?: any) => ({
  type: ActionTypes.GET_INVOICE_CHECK_POSITIONS_REQUEST,
  payload: {
    page,
    filters,
  },
})

/**
 * @function
 * @name getInvoiceCheckPosition
 * @param invoiceCheckId
 * @return {payload: *}
 */
export const getInvoiceCheckPosition = (invoiceCheckId: number) => ({
  type: ActionTypes.GET_INVOICE_CHECK_POSITION_REQUEST,
  payload: {
    invoiceCheckId,
  },
})

/**
 * @function
 * @name getInvoiceCheckPositionReview
 * @return {payload: *}
 * @param id
 * @param expand
 */
export const getInvoiceCheckPositionReview = (
  id: number,
  expand?: string[],
) => ({
  type: ActionTypes.GET_INVOICE_CHECK_POSITION_REQUEST,
  payload: {
    id,
    expand,
  },
})

/**
 * @function
 * @name getInvoiceCheckPositionPrice
 * @param data
 * @return {payload: *}
 */
export const getInvoiceCheckPositionPrice = data => ({
  type: ActionTypes.GET_INVOICE_CHECK_POSITION_PRICE_REQUEST,
  payload: {
    data,
  },
})

export const exportInvoiceCheckBatchFile = (filters, sortedBy) => ({
  type: ActionTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_REQUEST,
  payload: {
    filters,
    sortedBy,
  },
})

/**
 * @function
 * @name exportInvoiceCheck
 * @return {Object}
 */
export const exportInvoiceCheck = filters => ({
  type: ActionTypes.EXPORT_INVOICE_CHECK_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name reopenInvoiceCheck
 * @param invoiceCheckId
 * @return {Object}
 */
export const reopenInvoiceCheck = (invoiceCheckId?: number) => ({
  type: ActionTypes.REOPEN_INVOICE_CHECK_REQUEST,
  payload: {
    invoiceCheckId,
  },
})
