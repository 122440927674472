import { Form, Formik } from 'formik'
import React, { FC, ReactText, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import moment from 'moment/moment'

import { changeIntervalOffer, postponeIntervalOffer } from 'actions/offer'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { ProgressButton } from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import {
  OFFER_SERVICE_INTERVAL,
  OFFER_SERVICE_INTERVALS,
} from 'components/inquiry/constants'
import {
  getIntervalName,
  getWeekdayName,
} from 'components/inquiry/CreateInquiryPage/helpers'
import { WEEKDAY, WEEKDAYS } from 'constants/app'
import { createLoadingSelector } from 'selectors/loading'
import InputDate from 'components/common/InputDate'
import { getHolidaysSelector } from 'selectors/holiday'
import { getHolidays } from 'actions/holiday'
import { filterDate } from 'components/common/GenericInquiryFields/helpers'

interface PostponeIntervalFormProps {
  onCancel: () => void
  offer: Offer
}

export interface PostponeIntervalFormValues {
  interval: ReactText
  weekday_first: ReactText
  weekday_second: ReactText
  rythm_begin: string
}

/**
 * @description This component renders and controls convert form component to send an converting email.
 * @param onCancel
 * @param inquiry
 * @constructor
 */
export const PostponeIntervalForm: FC<PostponeIntervalFormProps> = ({
  onCancel,
  offer,
}) => {
  const dispatch = useDispatch()
  const holidays = useSelector(getHolidaysSelector)
  const offerIsLoading = useSelector(createLoadingSelector(['GET_OFFER']))
  const [intervalSelected, setIntervalSelected] = useState<{
    label: string
    value: string | number
  }>()
  const [weekdayFirstSelected, setWeekdayFirstSelected] = useState<{
    label: string
    value: string | number
  }>()
  const [weekdaySecondSelected, setWeekdaySecondSelected] = useState<{
    label: string
    value: string | number
  }>()

  useEffect(() => {
    if (offer) {
      setIntervalSelected({
        label: I18n.t(getIntervalName(Number(offer.interval))),
        value: offer.interval,
      })

      setWeekdayFirstSelected({
        label: I18n.t(getWeekdayName(offer.interval_weekday_first)),
        value: offer.interval_weekday_first,
      })

      setWeekdaySecondSelected({
        label: I18n.t(getWeekdayName(offer.interval_weekday_second)),
        value: offer.interval_weekday_second,
      })
    }
    dispatch(getHolidays())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!offer.id) return null

  return (
    <Formik
      initialValues={{
        interval: offer.interval ?? '',
        weekday_first: offer.interval_weekday_first ?? undefined,
        weekday_second: offer.interval_weekday_second ?? undefined,
        rythm_begin: '',
      }}
      onSubmit={(values: PostponeIntervalFormValues) => {
        if (offer.interval !== values.interval || values.rythm_begin) {
          const { rythm_begin, ...valuesToSend } = values
          if (rythm_begin !== '') {
            valuesToSend['rythm_begin'] = rythm_begin
              ? moment(rythm_begin).format('L')
              : ''
          }
          // Change interval and rythem. All existing orders in the future will be canceled and new created with the new parameters.
          // Also cancel all orders and create new, in case of the `rythem_begin` is given
          dispatch(changeIntervalOffer(offer.id, valuesToSend))
        } else {
          // Only weekdays changed and every order will be postponed
          dispatch(postponeIntervalOffer(offer.id, values))
        }

        onCancel()
      }}
      validate={(values: PostponeIntervalFormValues) => {
        const errors: {
          interval?: string
          weekday_first?: string
          weekday_second?: string
        } = {}
        const weekday_first = String(values.weekday_first)
        const weekday_second = String(values.weekday_second)

        // Check if `weekday_first` is empty
        if (weekday_first === '') {
          errors.weekday_first = `${I18n.t(
            'message.validation.selectionRequired',
          )}`
        }

        // Check if `weekday_first` is same as `weekday_second`
        if (weekday_first === weekday_second) {
          errors.weekday_first = `${I18n.t(
            'message.validation.invalidSelection',
          )}`
          errors.weekday_second = `${I18n.t(
            'message.validation.invalidSelection',
          )}`
        }

        // Check if `weekday_second` is empty and `interval` is twice a week
        if (
          weekday_second === '' &&
          values.interval === OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
        ) {
          errors.weekday_second = `${I18n.t(
            'message.validation.selectionRequired',
          )}`
        }

        return errors
      }}
      validationSchema={() =>
        yup.object().shape({
          weekday_first: yup.string().typeError(''),
          weekday_second: yup.string().typeError(''),
        })
      }
    >
      {({
        setFieldValue,
        handleSubmit,
        submitCount,
        touched,
        errors,
        isValid,
        isSubmitting,
        values,
      }) => (
        <>
          <Form
            className='postpone-interval-form'
            data-testid='postpone-interval-form'
            onSubmit={handleSubmit}
            noValidate
          >
            <div className='uk-modal-body'>
              <StaticCombobox
                error={
                  submitCount > 0 && touched.interval ? errors.interval : ''
                }
                dataTestId='postpone-interval-form-interval'
                label={I18n.t(
                  'postponeIntervalFormTranslations.interval.label',
                )}
                isLoading={offerIsLoading}
                name='interval'
                noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                options={OFFER_SERVICE_INTERVALS.map(item => ({
                  value: `${item.id}`,
                  label: I18n.t(item.name),
                }))}
                onSelectionChange={value => {
                  const selectedInterval = OFFER_SERVICE_INTERVALS.filter(
                    interval => String(interval.id) === value.target.value,
                  )
                  setFieldValue('interval', selectedInterval[0].id)
                  if (
                    value.target.value !==
                    `${OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK}`
                  ) {
                    setFieldValue('weekday_second', undefined)
                  }

                  setIntervalSelected({
                    label: I18n.t(getIntervalName(selectedInterval[0].id)),
                    value: selectedInterval[0].id,
                  })
                }}
                selectedOption={intervalSelected}
                placeholder={I18n.t(
                  'postponeIntervalFormTranslations.interval.placeholder',
                )}
              />

              <StaticCombobox
                error={
                  submitCount > 0 && touched.weekday_first
                    ? errors.weekday_first
                    : ''
                }
                dataTestId='postpone-interval-form-weekday_first'
                label={I18n.t(
                  'postponeIntervalFormTranslations.weekday_first.label',
                )}
                isLoading={offerIsLoading}
                name='weekday_first'
                noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                options={WEEKDAYS.filter(
                  item =>
                    item.id !== WEEKDAY.SATURDAY && item.id !== WEEKDAY.SUNDAY,
                ).map(item => ({
                  value: `${item.id}`,
                  label: I18n.t(item.name),
                }))}
                onSelectionChange={value => {
                  const selectedWeekday = WEEKDAYS.filter(
                    weekday => String(weekday.id) === value.target.value,
                  )
                  setFieldValue('weekday_first', selectedWeekday[0].id)
                  setWeekdayFirstSelected({
                    label: I18n.t(getWeekdayName(selectedWeekday[0].id)),
                    value: selectedWeekday[0].id,
                  })
                }}
                selectedOption={weekdayFirstSelected}
                placeholder={I18n.t(
                  'postponeIntervalFormTranslations.weekday_first.placeholder',
                )}
              />

              {intervalSelected?.value ===
                OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK && (
                <StaticCombobox
                  error={
                    submitCount > 0 && touched.weekday_second
                      ? errors.weekday_second
                      : ''
                  }
                  dataTestId='postpone-interval-form-weekday_second'
                  label={I18n.t(
                    'postponeIntervalFormTranslations.weekday_second.label',
                  )}
                  isLoading={offerIsLoading}
                  name='weekday_second'
                  noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                  options={WEEKDAYS.filter(
                    item =>
                      item.id !== WEEKDAY.SATURDAY &&
                      item.id !== WEEKDAY.SUNDAY,
                  ).map(item => ({
                    value: `${item.id}`,
                    label: I18n.t(item.name),
                  }))}
                  onSelectionChange={value => {
                    const selectedWeekday = WEEKDAYS.filter(
                      weekday => String(weekday.id) === value.target.value,
                    )
                    setFieldValue('weekday_second', selectedWeekday[0].id)
                    setWeekdaySecondSelected({
                      label: I18n.t(getWeekdayName(selectedWeekday[0].id)),
                      value: selectedWeekday[0].id,
                    })
                  }}
                  selectedOption={weekdaySecondSelected}
                  placeholder={I18n.t(
                    'postponeIntervalFormTranslations.weekday_second.placeholder',
                  )}
                />
              )}
              <InputDate
                label={I18n.t(
                  'orderTypeDetailsGroupTranslations.form.label.rythmBegin',
                )}
                minDate={moment().add(1, 'days')}
                maxDate={offer.turn_end}
                name='rythm_begin'
                onChange={value => {
                  setFieldValue('rythm_begin', value)
                }}
                placeholder={I18n.t(
                  'postponeIntervalFormTranslations.rythm_begin.placeholder',
                )}
                value={values.rythm_begin}
                filterDate={date =>
                  filterDate(
                    holidays,
                    date,
                    true,
                    values.weekday_first,
                    values.weekday_second,
                    true,
                  )
                }
              />
            </div>
            <div className='uk-modal-footer uk-text-right'>
              <span className='uk-margin-right'>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  dataTestId='convert-inquiry-form-close'
                  onClick={onCancel}
                >
                  <Translate value='general.button.cancel' />
                </Button>
              </span>
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='postpone-interval-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) ||
                  (isSubmitting && offerIsLoading)
                }
                isLoading={isSubmitting && offerIsLoading}
                onClick={handleSubmit}
                type={BUTTON_TYPE.SUBMIT}
              >
                <Translate value='postponeIntervalFormTranslations.buttons.submit' />
              </ProgressButton>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}
