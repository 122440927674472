import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Person } from 'assets/svg/detailspage/person.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { ReadOnlyField } from 'components/common/ReadOnly'

interface WasteDisposerSectionProps {
  addScrollElement: string
  offer: Offer
}

export const WasteDisposerSection: FC<WasteDisposerSectionProps> = ({
  addScrollElement,
  offer: { company_object: company },
}) => {
  const iconTitle = (
    <>
      <Person className='inquiry-details-page__section-header' />
      {I18n.t('agreementDetailsTranslations.section.wasteDisposer.title')}
    </>
  )

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        {/* COMPANY NAME */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.name',
          )}
          linkTo={`/company/${company.id}`}
          value={company.name}
        />
        {/* COMPANY CREATED_AT */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.createdAt',
          )}
          value={moment(company.created_at).format('L')}
        />
        {/* COMPANY POSITIVE REVIEWS COUNT */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.positiveReviews',
          )}
          value={
            company.evaluation_rate === null
              ? I18n.t('companyInfoPage.noRatingYet')
              : I18n.t('companyInfoPage.rating', {
                  positivePercentage: (
                    (company.evaluation_rate ?? 0) * 100
                  ).toFixed(0),
                  numberEvaluations: company.number_of_evaluations,
                })
          }
        />
        {/* COMPANY INQUIRIES COUNT */}
        <ReadOnlyField
          label={I18n.t(
            'agreementDetailsTranslations.section.wasteDisposer.fields.offersCount',
          )}
          value={company.offers_count}
        />
        {/* INQUIRY CREATED BY */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.wasteProducer.fields.createdBy',
          )}
          value={company && `${company.created_by_name}`}
        />
      </div>
    </AccordionItem>
  )
}
