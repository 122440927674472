import './style.scss'

import classNames from 'classnames'
import { isString, useFormikContext } from 'formik'
import React, { FC, useContext, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { InvoiceCheckDocumentType } from 'components/invoicecheck/InvoiceCheckPage/InvoiceCheckAcquisition'
import {
  getContainerPackagePrice,
  getIndexes,
} from 'components/common/DetailsPage/helper'
import { DropDownInput } from 'components/common/DropDownInput'
import { InputDecimal } from 'components/common/InputDecimal'
import { RadioButton } from 'components/common/RadioButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { InvoiceCheckPositionsFormContext } from 'components/invoicecheck/InvoiceCheckOverview/InvoiceCheckPositionsForm/InvoiceCheckPositionsFormSteps'
import { BREAKPOINT } from 'constants/design'
import {
  CREDIT_MATERIAL_DISPOSE,
  CREDIT_MATERIAL_RECYCLE,
} from 'reducers/offer'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'

import { PriceDetailsValues } from '../constants'

interface PriceDetailFieldsProps {
  reviewDocument?: {
    container?: number | string
    rent_price_container_month?: number | string
    disposal_cost_container?: number | string
    transport_price_piece?: number | string
    disposal_cost_ton?: number | string
    compensation_ton?: number | string
    compensation_container?: number | string
    index?: number | string
    surcharge?: number | string
    discount?: number | string
    handle_cost_ton?: number | string
    credit_material?: number | string
  }
  forcePackagePrice?: boolean
  disableCreditMaterialFields?: boolean
  isEditing?: boolean
  // TODO @devteam: this is to force the old price field layout for execution proof review and auto offer.
  //  Probably won't be needed after redesign.
  reviewLayout?: boolean
  // TODO @devteam: this is to force the old price field layout for execution proof review and auto offer.
  //  Probably won't be needed after redesign.
  reviewDetailsLayout?: boolean
  // TODO @devteam: also to force showing errors the tradicional way, not being dependnet of the screen size.
  //  Probably won't be needed after redesign.
  showErrors?: boolean
  // TODO @devteam: also to force showing error dot the tradicional way, not being dependnet of the screen size
  //  or design. Probably won't be needed after redesign.
  showRequiredDot?: boolean
  withCheckmarks?: boolean
  isInvoiceCheckForm?: boolean
}

export const PriceDetailFields: FC<PriceDetailFieldsProps> = ({
  reviewDocument = {
    rent_price_container_month: '',
    disposal_cost_container: '',
    transport_price_piece: '',
    disposal_cost_ton: '',
    compensation_ton: '',
    compensation_container: '',
    index: '',
    surcharge: '',
    discount: '',
    handle_cost_ton: '',
    credit_material: '',
  },
  forcePackagePrice = false,
  disableCreditMaterialFields = false,
  isEditing = true,
  reviewLayout = false,
  reviewDetailsLayout = false,
  showErrors = false,
  showRequiredDot = false,
  withCheckmarks = false,
  isInvoiceCheckForm = false,
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<PriceDetailsValues>()

  const containerList = useSelector(getContainersSelector)
  const fractionList = useSelector(getFractionsSelector)
  const indexList = useSelector(getIndexesSelector)

  const currentContainer = reviewLayout
    ? reviewDocument.container
    : values.container
  const containerHasPackagePrice =
    forcePackagePrice ||
    getContainerPackagePrice(currentContainer, containerList)

  const { invoiceCheck } = useContext(InvoiceCheckPositionsFormContext)

  const [chosenCreditMaterial, setChosenCreditMaterial] = useState(
    values.compensation_ton ? CREDIT_MATERIAL_RECYCLE : CREDIT_MATERIAL_DISPOSE,
  )

  const [indexListNames, setIndexListNames] = useState<any>([])

  useEffect(() => {
    setIndexListNames(
      getIndexes(
        values.fraction,
        values.fine_fraction,
        fractionList,
        indexList,
      ),
    )
  }, [values.fraction, values.fine_fraction, fractionList, indexList])

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < BREAKPOINT.XLARGE - 1,
  )

  useEffect(() => {
    /**
     * @description method to get window width
     */
    const handleWindowResize = () => {
      setIsSmallScreen(window.innerWidth < BREAKPOINT.XLARGE - 1)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const [surchargeSelected, setSurchargeSelected] = useState<boolean>(true)

  const showErrorMsg = fieldErr => {
    if (submitCount > 0 && (isSmallScreen || showErrors) && fieldErr) {
      return isString(fieldErr)
        ? fieldErr
        : `${I18n.t('priceDetailFieldsTranslations.validation.required')}`
    }
    return ''
  }

  /**
   * @description: Method to get the selected index value name to show on execution proof review details
   * @param: id
   * @param: list
   * */
  const selectedIndexValue = (id, list) => {
    if (id && list.length > 0) {
      const desiredIndex = list.find(
        theIndex => Number(theIndex.id) === Number(id),
      )
      if (desiredIndex) return desiredIndex.name
    }
    return I18n.t('priceDetailFieldsTranslations.index.noIndex')
  }

  /**
   * @description: Method to get the correct compensation label to show on execution proof review details
   * @param: index
   * @param: surcharge
   * @param: discount
   * @returns: {object}
   * */
  const getCompensationLabelandValue = (index, surcharge, discount) => {
    if (index) {
      if (
        (Number(surcharge) > 0 && discount === '0.00') ||
        (discount === '0,00' && surcharge === '0.00')
      ) {
        return {
          label: I18n.t('priceDetailFieldsTranslations.compensation.surcharge'),
          value: Number(surcharge),
        }
      }
      if (Number(discount) > 0 && surcharge === '0.00') {
        return {
          label: I18n.t('priceDetailFieldsTranslations.compensation.discount'),
          value: Number(discount),
        }
      }
    }
    return { label: '', value: Number(surcharge) }
  }

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false

  return (
    <div className='price-detail-fields'>
      <div
        className={classNames(
          {
            'price-detail-fields__review-layout-form-fields--side-by-side':
              reviewLayout,
          },
          {
            'price-detail-fields__review-details-layout-package-group':
              reviewDetailsLayout,
          },
        )}
      >
        {/* RENT PRICE PER CONTAINER AND MONTH */}
        {'rent_price_container_month' in reviewDocument && (
          <ReadOnlyField
            editable={isEditing}
            label={I18n.t(
              'priceDetailFieldsTranslations.rentPriceContainerMonth',
            )}
            value={reviewDocument.rent_price_container_month}
          >
            <InputDecimal
              dataTestId='rent-price-container-month-input'
              error={showErrorMsg(errors.rent_price_container_month)}
              label={I18n.t(
                'priceDetailFieldsTranslations.rentPriceContainerMonth',
              )}
              maxValue={999.99}
              name='rent_price_container_month'
              onChange={handleChange}
              placeholder={I18n.t(
                'priceDetailFieldsTranslations.pricePlaceholder',
              )}
              value={values.rent_price_container_month}
              removeBrowserStyling
              isRequired={showRequiredDot && !values.rent_price_container_month}
              withCheckmark={withCheckmarks}
              showCheckmark={isFieldValid}
            />
          </ReadOnlyField>
        )}

        {containerHasPackagePrice && (
          /* DISPOSAL COST PER CONTAINER */
          <ReadOnlyField
            editable={isEditing}
            label={I18n.t(
              'priceDetailFieldsTranslations.disposalCostContainer',
            )}
            value={reviewDocument.disposal_cost_container}
          >
            <InputDecimal
              dataTestId='disposal-cost-container-input'
              error={showErrorMsg(errors.disposal_cost_container)}
              label={I18n.t(
                'priceDetailFieldsTranslations.disposalCostContainer',
              )}
              maxValue={5000}
              name='disposal_cost_container'
              onChange={handleChange}
              placeholder={I18n.t(
                'priceDetailFieldsTranslations.disposalCostPlaceholder',
              )}
              value={values.disposal_cost_container}
              removeBrowserStyling
              isRequired={showRequiredDot && !values.disposal_cost_container}
              withCheckmark={withCheckmarks}
              showCheckmark={isFieldValid}
            />
          </ReadOnlyField>
        )}

        {!containerHasPackagePrice &&
          invoiceCheck?.document_type !==
            InvoiceCheckDocumentType.CREDIT_NOTE && (
            /* TRANSPORT PRICE */
            <ReadOnlyField
              editable={isEditing}
              label={I18n.t(
                'priceDetailFieldsTranslations.transportPricePiece',
              )}
              value={reviewDocument.transport_price_piece}
            >
              <InputDecimal
                dataTestId='transport-price-piece-input'
                error={showErrorMsg(errors.transport_price_piece)}
                label={I18n.t(
                  'priceDetailFieldsTranslations.transportPricePiece',
                )}
                maxValue={999.99}
                name='transport_price_piece'
                onChange={handleChange}
                placeholder={I18n.t(
                  'priceDetailFieldsTranslations.pricePlaceholder',
                )}
                value={values.transport_price_piece}
                removeBrowserStyling
                isRequired={showRequiredDot && !values.transport_price_piece}
                withCheckmark={withCheckmarks}
                showCheckmark={isFieldValid}
              />
            </ReadOnlyField>
          )}
      </div>

      {!disableCreditMaterialFields && !containerHasPackagePrice && (
        <>
          {/* CREDIT MATERIAL (DISPOSE/RECYCLE) */}
          {isEditing && (
            <div
              className={classNames(
                {
                  'price-detail-fields__review-layout-radio-choices':
                    reviewLayout,
                },
                {
                  'price-detail-fields__review-layout-radio-choices--margin-regular-top':
                    reviewLayout,
                },
                { 'price-detail-fields__details-page-layout': !reviewLayout },
              )}
            >
              <RadioButton
                dataTestId='credit-material-dispose-input'
                isChecked={chosenCreditMaterial === CREDIT_MATERIAL_DISPOSE}
                label={I18n.t(
                  'priceDetailFieldsTranslations.creditMaterial.dispose',
                )}
                name='credit_material'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setChosenCreditMaterial(CREDIT_MATERIAL_DISPOSE)
                    setFieldValue('compensation_ton', '0')
                  }
                }}
              />
              <RadioButton
                dataTestId='credit-material-recycle-input'
                isChecked={chosenCreditMaterial === CREDIT_MATERIAL_RECYCLE}
                label={I18n.t(
                  'priceDetailFieldsTranslations.creditMaterial.recycle',
                )}
                name='credit_material'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setChosenCreditMaterial(CREDIT_MATERIAL_RECYCLE)
                    setFieldValue('disposal_cost_ton', '0')
                  }
                }}
              />
            </div>
          )}
        </>
      )}

      {!containerHasPackagePrice &&
        ((!reviewDetailsLayout &&
          chosenCreditMaterial === CREDIT_MATERIAL_DISPOSE) ||
          (reviewDetailsLayout &&
            reviewDocument &&
            reviewDocument.disposal_cost_ton &&
            `${reviewDocument.credit_material}` === CREDIT_MATERIAL_DISPOSE)) &&
        invoiceCheck?.document_type !==
          InvoiceCheckDocumentType.CREDIT_NOTE && (
          /* DISPOSAL COST PER TON */
          <div
            className={classNames({
              'price-detail-fields__review-details-layout-dispose-group':
                reviewDetailsLayout,
            })}
          >
            <ReadOnlyField
              editable={isEditing}
              label={I18n.t('priceDetailFieldsTranslations.disposalCostTon')}
              value={reviewDocument.disposal_cost_ton}
            >
              <InputDecimal
                dataTestId='disposal-cost-ton-input'
                error={showErrorMsg(errors.disposal_cost_ton)}
                label={I18n.t('priceDetailFieldsTranslations.disposalCostTon')}
                maxValue={5000}
                name='disposal_cost_ton'
                onChange={handleChange}
                placeholder={I18n.t(
                  'priceDetailFieldsTranslations.disposalCostTon',
                )}
                value={values.disposal_cost_ton}
                removeBrowserStyling
                isRequired={showRequiredDot && !values.disposal_cost_ton}
                withCheckmark={withCheckmarks}
                showCheckmark={isFieldValid}
              />
            </ReadOnlyField>
          </div>
        )}

      {isInvoiceCheckForm &&
        !containerHasPackagePrice &&
        'handle_cost_ton' in reviewDocument && (
          /* HANDLE COST PER TON */
          <div
            className={classNames({
              'price-detail-fields__review-details-layout-dispose-group':
                reviewDetailsLayout,
            })}
          >
            <ReadOnlyField
              editable={isEditing}
              label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
              value={reviewDocument.handle_cost_ton}
            >
              <InputDecimal
                dataTestId='handle-cost-ton-input'
                error={showErrorMsg(errors.handle_cost_ton)}
                label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
                maxValue={5000}
                name='handle_cost_ton'
                onChange={handleChange}
                placeholder={I18n.t(
                  'priceDetailFieldsTranslations.handleCostTon',
                )}
                value={values.handle_cost_ton}
                removeBrowserStyling
                isRequired={showRequiredDot && !values.handle_cost_ton}
                withCheckmark={withCheckmarks}
                showCheckmark={isFieldValid}
              />
            </ReadOnlyField>
          </div>
        )}

      {!containerHasPackagePrice &&
        ((!reviewDetailsLayout &&
          chosenCreditMaterial === CREDIT_MATERIAL_RECYCLE) ||
          (reviewDetailsLayout &&
            reviewDocument &&
            reviewDocument.handle_cost_ton &&
            `${reviewDocument.credit_material}` ===
              CREDIT_MATERIAL_RECYCLE)) && (
          <div
            className={classNames({
              'price-detail-fields__review-details-layout-recycle-group':
                reviewDetailsLayout,
            })}
          >
            <div
              className={classNames({
                'price-detail-fields__review-layout-form-fields--side-by-side':
                  reviewLayout,
              })}
            >
              <ReadOnlyField
                editable={isEditing}
                label={I18n.t('priceDetailFieldsTranslations.index.label')}
                value={selectedIndexValue(reviewDocument.index, indexList)}
              >
                <>
                  {indexListNames.length > 0 ? (
                    <DropDownInput
                      dataTestId='index-input'
                      choices={indexListNames.map(index => ({
                        optionValue: index.id,
                        optionLabel: index.name,
                      }))}
                      isPlaceholderClickable
                      label={I18n.t(
                        'priceDetailFieldsTranslations.index.label',
                      )}
                      name='index'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder={I18n.t('general.placeholder.empty')}
                      value={values.index}
                      withCheckmark={withCheckmarks}
                      showCheckmark={isFieldValid}
                    />
                  ) : (
                    <ReadOnlyField
                      label={I18n.t(
                        'priceDetailFieldsTranslations.index.label',
                      )}
                      value={I18n.t(
                        'priceDetailFieldsTranslations.index.noValues',
                      )}
                    />
                  )}
                </>
              </ReadOnlyField>

              {values.index && Number(values.index) > 1 && (
                <ReadOnlyField
                  editable={isEditing}
                  label={
                    getCompensationLabelandValue(
                      reviewDocument.index,
                      reviewDocument.surcharge,
                      reviewDocument.discount,
                    ).label
                  }
                  value={
                    getCompensationLabelandValue(
                      reviewDocument.index,
                      reviewDocument.surcharge,
                      reviewDocument.discount,
                    ).value
                  }
                >
                  <InputDecimal
                    dataTestId='compensation-input'
                    error={showErrorMsg(errors.surcharge || errors.discount)}
                    label={
                      <div
                        className={classNames({
                          'price-detail-fields__review-layout-radio-choices--special-compensation':
                            reviewLayout,
                        })}
                      >
                        {/* COMPENSATION (Surcharge / Discount) */}
                        <RadioButton
                          dataTestId='compensation-surcharge-input'
                          isChecked={surchargeSelected}
                          label={
                            reviewLayout
                              ? I18n.t('offerForm.priceModel.surchargeSwitch')
                              : I18n.t(
                                  'priceDetailFieldsTranslations.compensation.surcharge',
                                )
                          }
                          name='compensation'
                          onChange={(event: React.ChangeEvent<any>) => {
                            if (event.target.value === 'on') {
                              setSurchargeSelected(true)
                              setFieldValue('discount', '')
                            }
                          }}
                        />
                        <RadioButton
                          dataTestId='compensation-discount-input'
                          isChecked={!surchargeSelected}
                          label={
                            reviewLayout
                              ? I18n.t('offerForm.priceModel.discountSwitch')
                              : I18n.t(
                                  'priceDetailFieldsTranslations.compensation.discount',
                                )
                          }
                          name='compensation'
                          onChange={(event: React.ChangeEvent<any>) => {
                            if (event.target.value === 'on') {
                              setSurchargeSelected(false)
                              setFieldValue('surcharge', '')
                            }
                          }}
                        />
                        {reviewLayout && (
                          <div className='price-detail-fields__review-layout-radio-choices--special-compensation-units'>
                            {I18n.t('offerForm.priceModel.unitSwitch')}
                          </div>
                        )}
                      </div>
                    }
                    maxValue={999.99}
                    name={surchargeSelected ? 'surcharge' : 'discount'}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'priceDetailFieldsTranslations.pricePlaceholder',
                    )}
                    value={
                      surchargeSelected ? values.surcharge : values.discount
                    }
                    removeBrowserStyling
                    isRequired={
                      showRequiredDot &&
                      (surchargeSelected ? !values.surcharge : !values.discount)
                    }
                    withCheckmark={withCheckmarks}
                    showCheckmark={isFieldValid}
                  />
                </ReadOnlyField>
              )}

              {(!values.index ||
                (values.index && Number(values.index) <= 1)) && (
                <ReadOnlyField
                  editable={isEditing}
                  label={
                    getCompensationLabelandValue(
                      reviewDocument.index,
                      reviewDocument.surcharge,
                      reviewDocument.discount,
                    ).label
                  }
                  value={
                    getCompensationLabelandValue(
                      reviewDocument.index,
                      reviewDocument.surcharge,
                      reviewDocument.discount,
                    ).value
                  }
                >
                  <InputDecimal
                    dataTestId='compensation-ton-input'
                    error={showErrorMsg(errors.compensation_ton)}
                    label={
                      <Translate value='priceDetailFieldsTranslations.compensation.compensation' />
                    }
                    maxValue={1999.99}
                    name={'compensation_ton'}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'priceDetailFieldsTranslations.pricePlaceholderCompensation',
                    )}
                    value={values.compensation_ton}
                    removeBrowserStyling
                    isRequired={showRequiredDot && !values.compensation_ton}
                    withCheckmark={withCheckmarks}
                    showCheckmark={isFieldValid}
                  />
                </ReadOnlyField>
              )}
            </div>
            <ReadOnlyField
              editable={isEditing}
              label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
              value={reviewDocument.handle_cost_ton}
            >
              <InputDecimal
                dataTestId='handle-cost-ton-input'
                error={showErrorMsg(errors.handle_cost_ton)}
                label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
                maxValue={999.99}
                name='handle_cost_ton'
                onChange={handleChange}
                placeholder={I18n.t(
                  'priceDetailFieldsTranslations.handleCostPlaceholder',
                )}
                value={values.handle_cost_ton}
                removeBrowserStyling
                isRequired={showRequiredDot && !values.handle_cost_ton}
                withCheckmark={withCheckmarks}
                showCheckmark={isFieldValid}
              />
            </ReadOnlyField>
          </div>
        )}
    </div>
  )
}
