import './style.scss'
import React from 'react'
import { Control } from 'react-redux-form'

import { TooltipIcon } from 'components/common/TooltipIcon'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import CheckboxControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
const CheckboxControl = props => {
  const getField = () => {
    if (!props.field) return null

    return (
      <div className={`checkbox-control${props.disabled ? ' disabled' : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={props.field.model}>
          <Control.checkbox // eslint-disable-line react/jsx-pascal-case
            className={props.className}
            data-testid={props.dataTestId}
            id={props.field.model}
            model={props.field.model}
            onChange={props.onChange}
            tabIndex={props.tabIndex}
            type='checkbox'
          />
          <span>{props.label}</span>
        </label>
        {props.tooltip && <TooltipIcon {...props.tooltip} />}
      </div>
    )
  }

  return (
    <FormField
      {...props}
      label={null}
      tooltip={null}
      showLabelColumn={false}
      fieldComponent={getField()}
    />
  )
}

CheckboxControl.propTypes = CheckboxControlProps

CheckboxControl.defaultProps = {
  ...ControlDefaultProps,
  onChange: () => undefined,
}

export default connector(CheckboxControl)
