import { bool, func, number, oneOfType, shape, string } from 'prop-types'

import { AddressScheme } from 'schemes/address'
import { CompanyScheme } from 'schemes/company'
import { ApiErrorScheme } from 'schemes/error'
import { UserScheme } from 'schemes/user'

import { FieldScheme } from '../../common/Form/schemes'

export const CompanyFormScheme = shape({
  $form: FieldScheme.isRequired,
  name: FieldScheme.isRequired,
  main_address_object: shape({
    $form: FieldScheme.isRequired,
    street: FieldScheme.isRequired,
    house_number: FieldScheme.isRequired,
    zipcode: FieldScheme.isRequired,
    location: FieldScheme.isRequired,
    country: FieldScheme.isRequired,
  }),
  registration_number: FieldScheme,
  invoice_recipient: FieldScheme.isRequired,
  tax_number: FieldScheme.isRequired,
  tax_id: FieldScheme.isRequired,
  commercial_registry_number: FieldScheme,
  profile_image: FieldScheme,
  about_us: FieldScheme,
  contact_person: FieldScheme.isRequired,
  website_url: FieldScheme,
  eta_service: FieldScheme,
  payment_method: FieldScheme,
  iban: FieldScheme,
  addresses: shape([AddressScheme]),
})

export const CompanyProfilePageScheme = {
  getCountries: func.isRequired,
  getCompany: func.isRequired,
  updateCompany: func.isRequired,
  createCertificate: func.isRequired,
  addCertificateType: func.isRequired,
  getCompanyLatestCertificates: func.isRequired,
  isLoading: shape({
    getCompany: bool,
    updateCompany: bool,
    getMyLatestCertificates: bool,
    createCertificate: bool,
    getCompanyAddresses: bool,
  }),
  error: shape({
    getCompany: shape(ApiErrorScheme),
    updateCompany: shape(ApiErrorScheme),
    getMyLatestCertificates: shape(ApiErrorScheme),
    createCertificate: shape(ApiErrorScheme),
  }),
  match: shape({
    params: shape({
      companyId: oneOfType([string, number]),
    }),
  }),
  user: shape(UserScheme),
  company: shape(CompanyScheme),
  companyForm: CompanyFormScheme.isRequired,
}
