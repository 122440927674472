import '../style.scss'

import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, StaticContext } from 'react-router'

import { getContainers } from 'actions/container'
import { getOffer } from 'actions/offer'
import { ReactComponent as InformationBlue } from 'assets/svg/detailspage/information-blue.svg'
import { ReactComponent as InformationOrange } from 'assets/svg/detailspage/information-orange.svg'
import { EmptoGroups, MaklerPremiumGroups } from 'constants/user'
import { getName } from 'helper/translations'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'
import { createLoadingSelector } from 'selectors/loading'
import { getOfferSelector } from 'selectors/offer'
import { getCurrentUserSelector } from 'selectors/user'

import { ActivitiesSection } from '../../agreement/AgreementDetailsPage/sections/ActivitiesSection'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { DetailsPage } from '../../common/DetailsPage'
import { CallToActionHeader } from '../../common/DetailsPage/components/CallToActionSection/CallToAction/CallToActionHeader'
import { PageType } from '../../common/DetailsPage/constants'
import { AddressSection } from '../../common/DetailsPage/sections/AddressSection'
import { InquirySection } from '../../common/DetailsPage/sections/InquirySection'
import { PriceSection } from '../../common/DetailsPage/sections/PriceSection'
import { TimeSection } from '../../common/DetailsPage/sections/TimeSection'
import { WasteProducerSection } from '../../common/DetailsPage/sections/WasteProducerSection'
import { HEADLINE_TAG } from '../../common/Headline'
import { LinkButton } from '../../common/LinkButton'
import Paragraph, {
  PARAGRAPH_ALIGN,
  PARAGRAPH_MARGIN,
} from '../../common/Paragraph'
import {
  OFFER_CANCELLATION_REASON,
  OFFER_CANCELLATION_REASON_TEXT,
} from '../../inquiry/constants'
import { OFFER_STATUS } from '../constants'

import { CancelOfferModal } from './CancelOfferModal'
import ResendOfferModal from './ResendOfferModal'

/**
 * description: method to render the offer details page component
 * @param props
 * @constructor
 */
const OfferDetailsPageComponent: FC<
  RouteComponentProps<any, StaticContext, any>
> = ({
  history,
  location,
  match: {
    params: { offerId },
  },
}) => {
  const dispatch = useDispatch()

  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false)
  const [showResendOfferModal, setShowResendOfferModal] = useState(false)
  const [isAgreement, setIsAgreement] = useState(false)

  useEffect(() => {
    dispatch(getOffer(Number(offerId)))
  }, [dispatch, offerId])

  const offer = useSelector(getOfferSelector)

  const containerList = useSelector(getContainersSelector)
  const fractionList = useSelector(getFractionsSelector)
  const indexList = useSelector(getIndexesSelector)

  useEffect(() => {
    if (containerList.length < 1) {
      dispatch(getContainers())
    }
  }, [containerList, dispatch])

  const loadingSelector = createLoadingSelector([
    'GET_OFFER',
    'GET_INQUIRY',
    'GET_CONTAINERS',
    'GET_FRACTIONS',
    'GET_INDEXES',
  ])

  const isLoading = useSelector(loadingSelector)
  const user = useSelector(getCurrentUserSelector)
  const userIsMaklerPremium = user.group_id in MaklerPremiumGroups
  const userIsEmpto = user.group_id in EmptoGroups

  if (isLoading || !offerId || !user || !offer?.id || !offer) return null

  const sections = new Map([
    [
      'wasteProducer',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.wasteProducer.title' />
        ),
        component: (
          <WasteProducerSection
            addScrollElement='wasteProducer'
            offer={offer}
            key='wasteProducerSection'
            hideStatusField
            userIsMaklerPremium={userIsMaklerPremium}
            userIsEmpto={userIsEmpto}
          />
        ),
      },
    ],
    [
      'address',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.address.title' />
        ),
        component: (
          <AddressSection
            addScrollElement='address'
            offer={offer}
            key='addressSection'
          />
        ),
      },
    ],
    [
      'time',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.time.title' />
        ),
        component: (
          <TimeSection
            addScrollElement='time'
            containerList={containerList}
            offer={offer}
            key='timeSection'
          />
        ),
      },
    ],
    [
      'inquiry',
      {
        offset: -230,
        title: (
          <Translate value='inquiryDetailsTranslations.section.inquiry.title' />
        ),
        component: (
          <InquirySection
            addScrollElement='inquiry'
            containerList={containerList}
            fractionList={fractionList}
            offer={offer}
            key='inquirySection'
          />
        ),
      },
    ],
    [
      'price',
      {
        offset: -240,
        title: (
          <Translate value='agreementDetailsTranslations.section.price.title' />
        ),
        component: (
          <PriceSection
            addScrollElement='price'
            indexList={indexList}
            offer={offer}
            key='priceSection'
          />
        ),
      },
    ],
    [
      'activities',
      {
        offset: -240,
        title: (
          <Translate value='agreementDetailsTranslations.section.activities.title' />
        ),
        component: (
          <ActivitiesSection
            addScrollElement='activities'
            offer={offer}
            key='activitiesSection'
          />
        ),
      },
    ],
  ])

  const breadcrumb = {
    breadcrumbTitle: (
      <Translate
        value='offerDetailsTranslations.breadcrumbTitle'
        // always show the company of your partner inside the breadcrumb
        company={offer!.company_object.name}
      />
    ),
    prevLinkTitle:
      (location.state && location.state.backLinkTitle) ||
      I18n.t('myOffers.heading'),
    prevLinkTo: (location.state && location.state.backLinkTo) || '/offer',
    teaserText: <Translate value='offerDetailsTranslations.teaser.text' />,
    teaserTitle: (
      <Translate
        value='offerDetailsTranslations.teaser.title'
        offerId={offer!.id}
        inquiryId={offer!.id}
        fraction={getName(offer!.fraction, fractionList)}
      />
    ),
  }

  let callToAction
  let ctaTitle

  // Receive the cancellation reason as text
  const cancellation_reason = OFFER_CANCELLATION_REASON_TEXT.find(
    ({ id }) => id === Number(offer.cancellation_reason),
  )

  switch (offer.status) {
    // HEADER FOR OFFER WITH STATUS OPEN
    case OFFER_STATUS.STATUS_OPEN:
      callToAction = {
        header: (
          <CallToActionHeader
            title={
              <Translate value='offerDetailsTranslations.cta.cancelOffer.title' />
            }
            key='offer-details-cancel-offer-header'
          />
        ),
        content: (
          <Paragraph
            align={PARAGRAPH_ALIGN.LEFT}
            margin={PARAGRAPH_MARGIN.TOP_NONE}
          >
            <Translate value='offerDetailsTranslations.cta.cancelOffer.text' />
          </Paragraph>
        ),
        buttonsOrFields: (
          <>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.DANGER}
              onClick={() => {
                setShowCancelOfferModal(true)
                setIsAgreement(false)
              }}
              key='offer-details-cancel-button'
              fullWidth
              dataTestId='offer-details-cancel-button'
            >
              <Translate value='offerDetailsTranslations.cta.buttons.cancel' />
            </Button>
            {userIsMaklerPremium && (
              <>
                <hr />
                <CallToActionHeader
                  title={
                    <Translate value='offerDetailsTranslations.cta.resendOffer.title' />
                  }
                  key='offer-details-cancel-offer-header'
                />
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  onClick={() => setShowResendOfferModal(true)}
                  key='offer-details-resend-button'
                  fullWidth
                  dataTestId='offer-details-resend-button'
                >
                  <Translate value='offerDetailsTranslations.cta.buttons.resend' />
                </Button>
              </>
            )}
          </>
        ),
      }
      break

    case OFFER_STATUS.STATUS_AGREEMENT_CANCELLED_EPD:
    case OFFER_STATUS.STATUS_CANCELLED:
      callToAction = {
        // HEADER FOR OFFER WITH STATUS CANCELLED
        header: (
          <CallToActionHeader
            title={
              offer.status === OFFER_STATUS.STATUS_CANCELLED &&
              offer.cancellation_reason ===
                OFFER_CANCELLATION_REASON.REASON_EXPIRED ? (
                <Translate
                  value='offerDetailsTranslations.cta.expired.title'
                  date={moment(offer.status_changed_at).format('L')}
                />
              ) : (
                <>
                  <Translate
                    value='offerDetailsTranslations.cta.newOffer.title'
                    name={`${offer.status_changed_by_object.first_name} ${offer.status_changed_by_object.last_name}`}
                    date={moment(offer.status_changed_at).format('L')}
                  />
                  <p className='cancellation_reason_text'>
                    Begründung: {cancellation_reason?.description}
                  </p>
                  {offer.cancellation_reason ===
                    OFFER_CANCELLATION_REASON.REASON_OTHER && (
                    <p className='cancellation_reason_text'>
                      {offer.cancellation_reason_text.trim().length === 0
                        ? 'keine Angaben'
                        : offer.cancellation_reason_text}
                    </p>
                  )}
                </>
              )
            }
            icon={
              <InformationOrange className='inquiry-details-page__cta-icon' />
            }
            key='offer-details-cancel-offer-header'
          />
        ),
      }

      if (
        offer.status === OFFER_STATUS.STATUS_OPEN &&
        offer.status !== OFFER_STATUS.STATUS_AGREEMENT_CANCELLED_EPD
      ) {
        callToAction.buttonsOrFields = (
          <LinkButton
            className='compare-offers-button'
            fullWidth
            target={`/inquiry/open/${offer!.id}/offer/create?showCreateForm=1`}
            key='offer-details-new-offer-link'
          >
            <Translate value='offerDetailsTranslations.cta.buttons.newOffer' />
          </LinkButton>
        )
      }
      break

    case OFFER_STATUS.STATUS_ACCEPTED:
      callToAction = {
        // HEADER FOR OFFER WITH STATUS ACCEPTED (AGREEMENT)
        header: (
          <CallToActionHeader
            title={
              <Translate value='offerDetailsTranslations.cta.accepted.title' />
            }
            key='offer-details-accepted-header'
          />
        ),
        content: (
          <Paragraph
            align={PARAGRAPH_ALIGN.LEFT}
            margin={PARAGRAPH_MARGIN.TOP_NONE}
          >
            <Translate value='offerDetailsTranslations.cta.accepted.text' />
          </Paragraph>
        ),
        buttonsOrFields: (
          <>
            <LinkButton
              className='compare-offers-button'
              fullWidth
              target={`/agreement/${offer!.id}/`}
              key='inquiry-details-agreement-link'
            >
              <Translate value='offerDetailsTranslations.cta.buttons.agreement' />
            </LinkButton>
            {userIsMaklerPremium && offer.mp_can_cancel && (
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.DANGER}
                onClick={() => {
                  setShowCancelOfferModal(true)
                  setIsAgreement(true)
                }}
                key='offer-details-cancel-button'
                fullWidth
                dataTestId='offer-details-cancel-button'
              >
                <Translate value='offerDetailsTranslations.cta.buttons.cancelAgreement' />
              </Button>
            )}
          </>
        ),
      }
      break

    case OFFER_STATUS.STATUS_REJECTED:
      switch (Number(offer.status)) {
        default:
          ctaTitle = (
            <Translate value='offerDetailsTranslations.cta.closed.inquiry.closed' />
          )
          break
      }
      callToAction = {
        header: (
          <div
            className='inquiry-details-page__cta-info'
            key='inquiry-details-closed-header'
          >
            <CallToActionHeader
              headlineTag={HEADLINE_TAG.H3}
              title={ctaTitle}
              icon={
                <InformationBlue className='inquiry-details-page__cta-icon' />
              }
            />
          </div>
        ),
      }
      break

    // DEFAULT - OFFER DECLINED
    default:
      callToAction = {
        // HEADER FOR OFFER WITH STATUS DECLINED
        header: (
          <div
            className='inquiry-details-page__cta-info'
            key='inquiry-details-closed-header'
          >
            <CallToActionHeader
              headlineTag={HEADLINE_TAG.H3}
              title={
                <Translate
                  value='offerDetailsTranslations.cta.closed.inquiry.closed'
                  expired={moment(offer!.status_changed_at).format('L')}
                />
              }
              icon={
                <InformationBlue className='inquiry-details-page__cta-icon' />
              }
            />
          </div>
        ),
      }
      break
  }

  return (
    <>
      <DetailsPage
        breadcrumb={breadcrumb}
        callToAction={callToAction}
        offer={offer}
        pageTitle={I18n.t('offerDetailsTranslations.pageTitle', {
          offerId: offer!.id,
        })}
        sections={sections}
        user={user}
        pageType={PageType.OFFER}
      />
      <CancelOfferModal
        callback={() => setShowCancelOfferModal(false)}
        offer={offer}
        state={showCancelOfferModal}
        user={user}
        isAgreement={isAgreement}
      />
      <ResendOfferModal
        callback={() => setShowResendOfferModal(false)}
        offer={offer}
        state={showResendOfferModal}
        user={user}
        history={history}
      />
    </>
  )
}

export const OfferDetailsPage = withApiErrorHandling(
  OfferDetailsPageComponent,
  ['GET_OFFER'],
  ['GET_OFFER'],
)
