import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getCollectiveInvoiceCompanies, getTaskLogs } from 'actions/company'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { COLLECTIVE_INVOICING_STATUS_TYPES } from 'components/invoice/constants'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import { getCurrentValueObject } from '../../helpers'
import { CommonFilterProps } from '../../types'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface CollectiveInvoicingFilterProps extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  setCurrentFilters: (any) => void
  currentFilters: any
  currentActivityFilters: any
  setCurrentActivityFilters: (any) => void
}

export const CollectiveInvoicingFilter: FC<CollectiveInvoicingFilterProps> = ({
  setCurrentFilters,
  currentFilters,
  setCurrentActivityFilters,
  currentActivityFilters,
  resetForm,
  setFieldValue,
  isLoading,
  length,
  values,
  handleChange,
}) => {
  const dispatch = useDispatch()
  const [filterWasReset, setFilterWasReset] = useState(false)
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<Option>()

  const statusChoices = [
    { label: I18n.t('general.placeholder.all'), value: '' },
    ...[...COLLECTIVE_INVOICING_STATUS_TYPES].map(item => ({
      label: `${I18n.t(item.name)}`,
      value: `${item.id}`,
    })),
  ]

  useEffect(
    () => {
      setCurrentSelectedStatus(
        getCurrentValueObject(statusChoices, values.status),
      )
    },
    // ESLint: The 'statusChoices' array makes the dependencies of useEffect Hook (at line 52) change on every render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.status],
  )

  useEffect(() => {
    if (values.company || values.status || filterWasReset) {
      const requestValues = {
        id: values.company,
        tasklog_status: values.status,
        order_by: currentFilters.order_by,
      }
      const activityRequestValues = {
        object_id: values.company,
        status: values.status,
        order_by: currentActivityFilters.order_by,
      }
      setCurrentFilters(requestValues)
      setCurrentActivityFilters(activityRequestValues)
      dispatch(getCollectiveInvoiceCompanies(null, requestValues))
      dispatch(getTaskLogs(null, activityRequestValues))

      if (filterWasReset) {
        setFilterWasReset(false)
      }
    }
    // Ignore react-hooks/exhaustive-deps to prevent infinite loops
    // Ignored deps: currentFilters and currentActivityFilters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    setCurrentFilters,
    values,
    filterWasReset,
    setCurrentActivityFilters,
  ])

  return (
    <>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        {/* prettier-ignore */}
        <Select
          urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
          urlPart2='&onlyActive=false&billing_interval=1&maklerpremium_and_role=1'
          getValue={(e) => e.id}
          getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`}
          label={I18n.t('filterTranslations.companyAndIdAndStatusAndOrderNumberFilter.companySelection.label') }
          setValue={e => {setFieldValue('company', e?.value ? e.value : ''); if (!e) {setFilterWasReset(true)}}}
          selectedValue={values.company}
          selecting={'company'}
        />

        <StaticCombobox
          isLoading={isLoading}
          label={I18n.t('filterTranslations.collectiveInvoicingFilter.status')}
          name='status'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={statusChoices}
          onSelectionChange={e => {
            handleChange(e)
            if (!e.target.value && !values.document_number && !values.company) {
              resetForm()
              setFilterWasReset(true)
            }
          }}
          selectedOption={currentSelectedStatus}
          placeholder={I18n.t('general.placeholder.all')}
        />
        <FilterReset
          onResetFilter={() => {
            resetForm()
            setCurrentFilters({})
            setCurrentActivityFilters({})
            setFieldValue('company', '')
            setFilterWasReset(true)
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilters) > 0
          }
        />
      </div>
      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
