const orderApiTranslations = {
  handleGetOrder: {
    error: 'Auftrag konnte leider nicht geladen werden.',
  },
  handleGetOrders: {
    error: 'Aufträge konnten leider nicht geladen werden.',
  },
  handleCreateOrder: {
    success: 'Auftrag wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen des Auftrages leider fehlgeschlagen.',
    errorBadDeliveryDate:
      'Leistungsdatum liegt außerhalb des Verinbarungszeitraums.',
  },
  updateOrderInternalComment: {
    success: 'Der Kommentar wurde erfolgreich aktualisiert.',
    error: 'Die Aktualisierung des Kommentars ist leider fehlgeschlagen.',
  },
  updateOrderAttachments: {
    success: 'Die Dokumente wurde erfolgreich aktualisiert.',
    error: 'Die Aktualisierung der Dokumente ist leider fehlgeschlagen.',
  },
  handleCreateInvoicingDocuments: {
    success: 'Dokumente wurden erfolgreich erstellt.',
    error: 'Erstellen der Dokumente leider fehlgeschlagen.',
  },
  handleDisposeOrder: {
    success: 'Auftrag wurde erfolgreich disponiert.',
    error: 'Disposition des Auftrages leider fehlgeschlagen.',
  },
  handlePostponeOrder: {
    success: 'Auftrag wurde erfolgreich umdisponiert.',
    error: 'Umdisposition des Auftrages leider fehlgeschlagen.',
    errorCollectionDate:
      'Das Gestelldatum kann nicht nach dem Abholdatum liegen.',
  },
  handleCancelOrder: {
    success: 'Auftrag wurde erfolgreich storniert.',
    error: 'Stornierung des Auftrages leider fehlgeschlagen.',
  },
  handleSetETA: {
    success: 'ETA wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen von ETA leider fehlgeschlagen.',
  },
  handleConfirmExecution: {
    success: 'Durchführung des Auftrags wurde bestätigt.',
    error: 'Bestätigung der Durchführung leider fehlgeschlagen.',
  },
  handleCreateOrderEvaluations: {
    success: 'Bewertung wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen der Bewertung leider fehlgeschlagen.',
  },
  handleExportOrders: {
    success: 'Export wurde erfolgreich erstellt.',
    error: 'Exportieren der Aufträge leider fehlgeschlagen.',
  },
  handleGetMaklerPremiumUpcomingOrders: {
    error:
      'Die geplanten Aufträge der nächsten zwei Werktage konnten leider nicht geladen werden.',
  },
  handleSetDisposerManagementOrderToConfirmed: {
    error: 'Bestätigen des Auftrags leider fehlgeschlagen.',
  },
}

export default orderApiTranslations
