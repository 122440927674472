import '../../style.scss'

import { Form, useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { resetApiFetchLoading } from 'actions/app'
import { resetMaklerPremiumCompanies } from 'actions/company'
import { resetInvoiceCheckError } from 'actions/invoicecheck'
import { getCustomTax } from 'actions/maklerpremium'
import { ApiErrorMessages } from 'components/common/ApiErrorMessages'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate/index'
import { InputDecimal } from 'components/common/InputDecimal'
import { InputText } from 'components/common/InputText'
import { ProgressButton } from 'components/common/ProgressButton'
import { RadioButton } from 'components/common/RadioButton'
import { Textarea } from 'components/common/Textarea'
import { UniversalFileUpload } from 'components/common/UniversalFileUpload'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  getInvoiceCheckErrorSelector,
  getInvoiceCheckSelector,
} from 'selectors/invoicecheck'
import { getCustomTaxSelector } from 'selectors/pricecalc'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import {
  InvoiceCheckAcquisitionFormValues,
  InvoiceCheckDocumentType,
} from './index'

export const InvoiceCheckAcquisitionForm: FC<{
  handleCloseModal: () => void
}> = ({ handleCloseModal }) => {
  const dispatch = useDispatch()

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitCount,
    touched,
    validateForm,
    values,
  } = useFormikContext<InvoiceCheckAcquisitionFormValues>()

  const history = useHistory()

  const invoiceCheck = useSelector(getInvoiceCheckSelector)
  const invoiceCheckError = useSelector(getInvoiceCheckErrorSelector)

  const createRequestStatus = useSelector(
    createRequestStatusSelector(['CREATE_INVOICE_CHECK']),
  )
  const updateRequestStatus = useSelector(
    createRequestStatusSelector(['UPDATE_INVOICE_CHECK']),
  )
  const apiErrors = useSelector(
    createErrorSelector(['UPDATE_INVOICE_CHECK', 'CREATE_INVOICE_CHECK']),
  )

  const isLoading = {
    customTax: useSelector(createLoadingSelector(['GET_CUSTOM_TAX'])),
    fileupload: useSelector(createLoadingSelector(['UPLOAD_FILES'])),
    createInvoiceCheck: useSelector(
      createLoadingSelector(['CREATE_INVOICE_CHECK']),
    ),
    updateInvoiceCheck: useSelector(
      createLoadingSelector(['UPDATE_INVOICE_CHECK']),
    ),
  }
  const customTaxObject = useSelector(getCustomTaxSelector)

  useEffect(() => {
    if (!isLoading.customTax && !customTaxObject) {
      dispatch(getCustomTax())
    }
  })

  useEffect(() => {
    if (invoiceCheck && createRequestStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(resetApiFetchLoading('CREATE_INVOICE_CHECK'))
      if (values.saveAndCheckWasClicked) {
        history.push(`/invoicecheck/invoice_check/overview/${invoiceCheck.id}`)
      } else {
        resetForm()
        handleCloseModal()
      }
    }
  }, [
    dispatch,
    handleCloseModal,
    history,
    invoiceCheck,
    createRequestStatus,
    resetForm,
    values.saveAndCheckWasClicked,
  ])

  useEffect(() => {
    if (invoiceCheck && updateRequestStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(resetApiFetchLoading('UPDATE_INVOICE_CHECK'))
      resetForm()
      handleCloseModal()
    }
  }, [dispatch, handleCloseModal, invoiceCheck, resetForm, updateRequestStatus])

  useEffect(
    () => () => {
      dispatch(resetInvoiceCheckError())
    },
    [dispatch],
  )

  const onChange = async (e: React.ChangeEvent<any>) => {
    handleChange(e)
    if (submitCount > 0) {
      await validateForm()
    }
  }

  const isFieldValid = name =>
    (values[name] ?? '') && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const searchFields = [
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.acquisitionForm.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.acquisitionForm.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.acquisitionForm.tax_number.label',
      ),
      optionValue: 'tax_number',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.acquisitionForm.tax_id.label',
      ),
      optionValue: 'tax_id',
    },
  ]

  const saveAndCheck = e => {
    values.saveAndCheckWasClicked = true
    handleSubmit(e)
  }

  const formIsLoading =
    isLoading.fileupload ||
    isLoading.createInvoiceCheck ||
    isLoading.updateInvoiceCheck

  const requiredTouched =
    touched.document_date &&
    touched.document_number &&
    touched.attachments &&
    touched.gross_price &&
    touched.company &&
    touched.vat

  return (
    <div className='uk-modal-body'>
      <Form
        className='uk-panel invoice-check-form'
        onSubmit={handleSubmit}
        noValidate // Disable browser validation
      >
        <div className='uk-width-1-1 uk-margin-bottom'>
          <p>
            {I18n.t('invoiceCheckTranslations.acquisitionForm.description')}
          </p>
          <DropDownInput
            choices={searchFields}
            label={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.search_field.label',
            )}
            name='search_field'
            onChange={async e => {
              dispatch(resetMaklerPremiumCompanies()) // Reset Filtered results
              setFieldValue('company', '', true)
              await onChange(e)
            }}
            showCheckmark={!!values.search_field}
            value={values.search_field}
            withCheckmark
          />
          {/*prettier-ignore*/}
          <Select
            urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/active/?${values.search_field}=`}
            urlPart2='&role=2&page_size=1000&has_empto_agreements=true'
            getValue={(e) => e.id}
            getLabel={(e) => e.id === 0 ? e.name : `${e.name}, ${e?.main_address_object?.zipcode}, ${e?.tax_number}, ${e?.tax_id?.toUpperCase()}`}
            label={I18n.t(`invoiceCheckTranslations.acquisitionForm.${values.search_field}.label`) }
            setValue={async e => {
              setFieldValue('company', e?.value ? e.value : '', true);
              setFieldTouched('company')
              if (submitCount > 0) await validateForm()
            }}
            selectedValue={values?.company}
            selecting={'company'}
            error={errors.company}
          />
          <div className='input__label-container'>
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            {/* eslint-disable jsx-a11y/label-has-for */}
            <label className='input__label'>
              {I18n.t(
                'invoiceCheckTranslations.acquisitionForm.document_type.label',
              )}
            </label>
          </div>
          <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
            <RadioButton
              isChecked={
                values.document_type === InvoiceCheckDocumentType.INVOICE
              }
              label={I18n.t(
                'invoiceCheckTranslations.acquisitionForm.document_type.invoice',
              )}
              name='document_type'
              onChange={(event: React.ChangeEvent<any>) => {
                if (event.target.value === 'on') {
                  setFieldValue(
                    'document_type',
                    InvoiceCheckDocumentType.INVOICE,
                  )
                }
              }}
            />
            <RadioButton
              isChecked={
                values.document_type === InvoiceCheckDocumentType.CREDIT_NOTE
              }
              label={I18n.t(
                'invoiceCheckTranslations.acquisitionForm.document_type.credit_note',
              )}
              name='document_type'
              onChange={(event: React.ChangeEvent<any>) => {
                if (event.target.value === 'on') {
                  setFieldValue(
                    'document_type',
                    InvoiceCheckDocumentType.CREDIT_NOTE,
                  )
                }
              }}
            />
          </div>
          <InputText
            error={getFieldError}
            label={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.document_number.label',
            )}
            name='document_number'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('document_number')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.document_number.placeholder',
            )}
            value={values.document_number}
            showCheckmark={isFieldValid}
            withCheckmark
          />
          <InputDate
            error={getFieldError}
            label={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.document_date.label',
            )}
            maxDate={moment()}
            name='document_date'
            onChange={async value => {
              setFieldValue('document_date', value.format('L'), true)
              setFieldTouched('document_date')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.document_date.placeholder',
            )}
            value={values.document_date}
            showCheckmark={isFieldValid}
            withCheckmark
          />
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.gross_price.label',
            )}
            name='gross_price'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('gross_price')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'invoiceCheckTranslations.acquisitionForm.gross_price.placeholder',
            )}
            value={values.gross_price}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
          {customTaxObject && values.vat && (
            <DropDownInput
              choices={
                customTaxObject.length !== 0
                  ? Object.values(customTaxObject).map((choice: any) => ({
                      optionValue: choice.tax_in_percent,
                      optionLabel: `${parseFloat(
                        choice.tax_in_percent,
                      ).toLocaleString('de-DE', {
                        minimumFractionDigits: 0,
                      })} %`,
                    }))
                  : [
                      {
                        optionValue: values.vat,
                        optionLabel: `${values.vat.toLocaleString('de-DE', {
                          minimumFractionDigits: 0,
                        })} %`,
                      },
                    ]
              }
              name='vat'
              onChange={e => {
                setFieldValue('vat', e.target.value)
                setFieldTouched('vat')
              }}
              label={I18n.t(
                'invoiceCheckTranslations.acquisitionForm.vat.label',
              )}
              value={values.vat}
              withCheckmark
              showCheckmark={isFieldValid}
            />
          )}
        </div>
        <UniversalFileUpload
          name='attachments'
          error={getFieldError}
          label={I18n.t(
            'invoiceCheckTranslations.acquisitionForm.attachments.label',
          )}
          allowedFileTypes='image/png, image/jpeg, application/pdf'
          maxFiles={128} // Should be unlimited
          initialValues={values.attachments}
          onChange={async fileList => {
            await setFieldValue('attachments', fileList, true)
            await setFieldTouched('attachments')
            if (submitCount > 0) await validateForm()
          }}
          withCheckmark
          showCheckmark={isFieldValid}
          showFilename
        />
        <Textarea
          error={getFieldError}
          label={I18n.t(
            'invoiceCheckTranslations.acquisitionForm.comment.label',
          )}
          maxLength={1000}
          name='comment'
          onChange={onChange}
          showRemainingCharacters
          value={values.comment}
          withCheckmark
          showCheckmark={isFieldValid}
        />
        {(
          apiErrors?.response?.data as
            | { non_field_errors: string[] }
            | undefined
        )?.non_field_errors && (
          <ApiErrorMessages
            submitCount={submitCount}
            isSubmitting={isSubmitting}
            isValid={isValid}
            errorsArray={
              (
                apiErrors?.response?.data as
                  | { non_field_errors: string[] }
                  | undefined
              )?.non_field_errors
            }
          />
        )}
        <div className='uk-grid uk-margin-bottom uk-margin-top'>
          <div className='uk-width-1-3'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={() => {
                resetForm()
                handleCloseModal()
              }}
              isDisabled={formIsLoading}
            >
              {I18n.t('general.button.cancel')}
            </Button>
          </div>
          <div
            className='uk-width-2-3 universal-file-upload--with-checkmark'
            data-uk-margin=''
          >
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              fullWidth
              isDisabled={
                // in create mode, disable if required fields weren't touched
                (!requiredTouched && !values.editMode) ||
                !isValid ||
                formIsLoading ||
                isSubmitting
              }
              onClick={handleSubmit}
              isLoading={formIsLoading || isSubmitting}
            >
              <Translate value='general.button.save' />
            </ProgressButton>
            {!values.editMode && (
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                fullWidth
                isDisabled={
                  !requiredTouched || !isValid || formIsLoading || isSubmitting
                }
                onClick={saveAndCheck}
                isLoading={formIsLoading || isSubmitting}
              >
                <Translate value='invoiceCheckTranslations.acquisitionForm.buttons.saveAndCheckButton' />
              </ProgressButton>
            )}
            <div className='form-error'>{invoiceCheckError}</div>
          </div>
        </div>
      </Form>
    </div>
  )
}
