import './style.scss'

import React, { ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'

import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import { BreadcrumbTeaser } from 'components/common/Breadcrumb'
import { BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { ProgressButton } from 'components/common/ProgressButton'
import PageHeader from 'components/layout/PageHeader'
import { InvoiceCheckOverviewDocuments } from 'components/invoicecheck/InvoiceCheckOverview/InvoiceCheckOverviewDocuments'
import { BREAKPOINT } from 'constants/design'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'

import { BackButton } from '../../BackButton'

import { OrderDetails } from './OrderDetails'

interface InvoiceCheckPositionReviewProps {
  breadcrumb: {
    breadcrumbTitle: string
    prevLinkTitle: string
    prevLinkTo: string
    teaserText: string
    teaserTitle: string
  }
  pageTitle?: string
  documentTitle?: string
  formSubmissionButton: {
    buttonText?: string
    action?: () => void
    isLoading?: boolean
    isDisabled?: boolean
  }
  showFormSubmitButton?: boolean
  showOrderDetails?: boolean
  reviewDocument: any
  children: ReactNode
  isRelabeled?: boolean
  isContainerChanged?: boolean
  isOtherService?: boolean
  isAdditionalRent?: boolean
}

export const InvoiceCheckPositionReview = ({
  children,
  breadcrumb = {
    breadcrumbTitle: '',
    prevLinkTitle: '',
    prevLinkTo: '',
    teaserText: '',
    teaserTitle: '',
  },
  pageTitle = '',
  documentTitle = '',
  formSubmissionButton = {
    buttonText: '',
    action: () => undefined,
    isLoading: false,
    isDisabled: false,
  },
  showFormSubmitButton = false,
  showOrderDetails = false,
  reviewDocument = {
    attachments: [],
    company_object: null,
    order_object: null,
  },
  isRelabeled = false,
  isContainerChanged = false,
  isOtherService = false,
  isAdditionalRent = false,
}: InvoiceCheckPositionReviewProps) => {
  const dispatch = useDispatch()
  const fractionList = useSelector(getFractionsSelector)
  const containerList = useSelector(getContainersSelector)

  useEffect(() => {
    if (fractionList.length < 1) dispatch(getFractions())
  }, [dispatch, fractionList.length])

  useEffect(() => {
    if (containerList.length < 1) dispatch(getContainers())
  }, [containerList.length, dispatch])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className='document-review-page'>
        <BreadcrumbTeaser
          breadcrumbTitle={breadcrumb.breadcrumbTitle}
          prevLinkTitle={breadcrumb.prevLinkTitle}
          prevLinkTo={breadcrumb.prevLinkTo}
          teaserText={breadcrumb.teaserText}
          teaserTitle={breadcrumb.teaserTitle}
        />

        <Media query={{ maxWidth: BREAKPOINT.SMALL - 1 }}>
          {isSmallScreen => (
            <PageHeader sticky title={documentTitle}>
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                {showFormSubmitButton && (
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='invite-user-form-submit'
                    isDisabled={formSubmissionButton.isDisabled}
                    isLoading={formSubmissionButton.isLoading}
                    onClick={formSubmissionButton.action as () => void}
                    type={BUTTON_TYPE.SUBMIT}
                  >
                    {formSubmissionButton.buttonText}
                  </ProgressButton>
                )}
                {!isSmallScreen && <BackButton />}
              </ButtonBar>
            </PageHeader>
          )}
        </Media>

        <div className='document-review-page__details document-review-page__details--no-document'>
          <div>
            {/* ORDER DETAILS */}
            {showOrderDetails && reviewDocument.order_object && (
              <OrderDetails
                order={reviewDocument.order_object}
                invoiceCheckPosition={reviewDocument}
                fractionList={fractionList}
                containerList={containerList}
                isRelabeled={isRelabeled}
                isContainerChanged={isContainerChanged}
                isOtherService={isOtherService}
                isAdditionalRent={isAdditionalRent}
              />
            )}
            {/* DOCUMENTS */}
            {showOrderDetails && reviewDocument.invoice_check && (
              <InvoiceCheckOverviewDocuments
                invoiceCheck={reviewDocument.invoice_check}
              />
            )}
            {/* DOCUMENT DETAILS */}
            {children}
          </div>
        </div>
      </div>
    </>
  )
}
