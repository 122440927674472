const discountApiTranslations = {
  handleGetDiscounts: {
    error: 'Rabatte konnten leider nicht geladen werden.',
  },
  handleGetDiscount: {
    error: 'Ihr Rabatt konnten leider nicht geladen werden.',
  },
  handleCreateDiscount: {
    success: 'Rabatt wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen des Rabattes leider fehlgeschlagen.',
  },
  handleUpdateDiscountStatus: {
    success: 'Rabattstatus wurde erfolgreich verändert.',
    error: 'Ändern des Rabattstatus leider fehlgeschlagen.',
  },
  handleRedeemDiscount: {
    success: 'Rabatt wurde erfolgreich eingelöst.',
    error: 'Einlösen des Rabattes leider fehlgeschlagen.',
  },
  handleExportDiscounts: {
    success: 'Der Export der Rabatte wurde erfolgreich gestartet',
    error: 'Der Export der Rabatte konnte leider nicht gestartet werden',
  },
}

export default discountApiTranslations
