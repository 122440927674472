import './style.scss'

import uniqueId from 'lodash.uniqueid'
import { arrayOf, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import Icon from '../../common/Fontello'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import FileComment from '../SingleFileUpload/components/FileComment'
import Image from '../Image/index'

/**
 * Displays a slider with the ui-kit classes.
 * @param props
 * @returns {*}
 */
export class Slider extends Component {
  static propTypes = {
    images: arrayOf(shape({})), // An array of images to be displayed as slider.
  }

  static defaultProps = {
    images: [],
  }

  state = {
    selectedFile: null, // The file currently previewed in the modal
  }

  /**
   * Is called when a preview is clicked. We then display a modal with the preview.
   * @param event
   * @param clickedFile
   */
  handlePreviewClick = (event, clickedFile) => {
    this.setState({
      selectedFile: clickedFile,
    })
  }

  render() {
    const idFilePreviewModalHeadline = uniqueId()

    return (
      <div className='slider-component'>
        <div
          data-uk-slider
          className='uk-position-relative uk-visible-toggle uk-light'
        >
          <div className='uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@l slider-content'>
            {this.props.images.map(image => (
              <div key={String(image.id)}>
                <Image
                  file={image}
                  onClick={event => this.handlePreviewClick(event, image)}
                />
                {image.text && (
                  <div className='actions'>
                    <span
                      className='has-text-icon'
                      title={I18n.t('fileUpload.textIconTitle')}
                    >
                      <Icon name='message' />
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='uk-visible'>
            <button
              className='uk-position-center-left'
              data-uk-slidenav-previous
              data-uk-slider-item='previous'
              type='button'
            >
              <Icon name='chevron-left' />
            </button>
            <button
              className='uk-position-center-right'
              data-uk-slidenav-next
              data-uk-slider-item='next'
              type='button'
            >
              <Icon name='chevron-right' />
            </button>
          </div>
        </div>

        {this.state.selectedFile && (
          <Modal
            ariaDescribedBy={idFilePreviewModalHeadline}
            isOpen={!!this.state.selectedFile}
            onClose={this.handlePreviewClick}
          >
            <>
              <ModalHeader
                onClose={this.handlePreviewClick}
                title={this.state.selectedFile.name}
                titleId={idFilePreviewModalHeadline}
              />

              <FileComment
                file={this.state.selectedFile}
                onFormSubmit={this.handlePreviewClick}
                allowComment={false}
              />
            </>
          </Modal>
        )}
      </div>
    )
  }
}
