import { I18n } from 'react-i18nify'

import { translationsObject } from 'assets/i18n/translations'

// @TODO: This is a workaround - shouldn't be needed to set it in this file (again)
I18n.setTranslations(translationsObject)
I18n.setLocale('de-DE')

export const MAX_LENGTH = {
  COMPANY_NAME: 200,
  FIRST_NAME: 50,
  LAST_NAME: 50,
  PASSWORD: 50,
  MAIL_ADDRESS: 50,
  PHONE_NUMBER: 20,
  POSTAL_CODE: 5,
}

export default {}
