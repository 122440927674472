import './style.scss'

import React, { createContext, FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { isUserCompanyInactive } from 'helper/company'
import { UserPermission } from 'constants/user'
import { exportAutoOffers, getMyAutoOffers } from 'actions/autooffer'
import { getCurrentUserSelector } from 'selectors/user'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { Filter } from '../../common/Filter'
import { MyAutoOfferFilter } from '../../common/Filter/components/MyAutoOfferFilter'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { COMPANY_ROLE } from '../../company/constants'
import PageHeader from '../../layout/PageHeader'
import { AUTO_OFFER_STATUS } from '../constants'

import { AutoOfferModal } from './AutoOfferFormModal'
import { LockUnlockAutoOffersWarningModal } from './LockUnlockAutoOffersWarningModal'
import { ManageAutoOffersModal } from './ManageAutoOffersModal'
import { MyAutoOffersActionButtons } from './MyAutoOffersActionButtons'
import { MyAutoOffersIndicationMessages } from './MyAutoOffersIndicationMessages'
import { MyAutoOffersList } from './MyAutoOffersList'
import { UploadAutoOffersModal } from './UploadAutoOffersModal'

export interface MyAutoOfferListFilters {
  coarse_fraction?: string
  container?: string
  company?: string
  community?: string
  zipcode?: string
  avv__number?: string
  order_by?: string
  status?: number
  role?: COMPANY_ROLE
  page?: number
}

export type MyAutoOffersListContext = {
  currentFilters?: MyAutoOfferListFilters
  currentSorted: { id: string; desc: boolean }[]
  setCurrentFilters: (MyAutoOfferListFilters) => void
  setCurrentSorted: ([{ id: string, desc: boolean }]) => void
}
// eslint-disable-next-line no-redeclare,@typescript-eslint/no-redeclare
export const MyAutoOffersListContext = createContext<MyAutoOffersListContext>({
  currentFilters: undefined,
  currentSorted: [{ id: 'id', desc: false }],
  setCurrentFilters: () => undefined,
  setCurrentSorted: () => undefined,
})

/**
 * @description This component displays a list of the auto offer (bid templates to automatically place offers) for the
 * current user's company.
 * @function
 * @param {Object} props the component props
 */
export const MyAutoOffersPage: FC = () => {
  const user = useSelector(getCurrentUserSelector)
  const match = useRouteMatch()

  const defaultSorted = [{ id: 'id', desc: true }]
  const defaultFilters: MyAutoOfferListFilters = {
    order_by: JSON.stringify(defaultSorted),
    status: AUTO_OFFER_STATUS.STATUS_ACTIVE,
    page: 1,
  }
  const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters)
  const [selectedFilters, setSelectedFilters] = useState<any>(currentFilters)
  const [currentSorted, setCurrentSorted] = useState<any>(defaultSorted)

  const [isAutoOfferModalOpen, setIsAutoOfferModalOpen] = useState(false)
  const [isExportAutoOfferModalOpen, setIsExportAutoOfferModalOpen] =
    useState(false)
  const [isLockUnlockAutoOfferModalOpen, setIsLockUnlockAutoOfferModalOpen] =
    useState(false)
  const [isManageAutoOfferModalOpen, setIsManageAutoOfferModalOpen] =
    useState(false)
  const [isUploadAutoOfferModalOpen, setIsUploadAutoOfferModalOpen] =
    useState(false)
  const [isEditingAutoOffer, setIsEditingAutoOffer] = useState(false)
  const [isArchiveModeEnabled, setIsArchiveModeEnabled] = useState(false)
  const [isLockingAutoOffers, setIsLockingAutoOffers] = useState(false)
  const [areAutoOffersLocked, setAreAutoOffersLocked] = useState(false)
  const [autoOfferToEditId, setAutoOfferToEditId] = useState<number | string>(
    '',
  )

  useEffect(() => {
    setSelectedFilters(currentFilters)
    const auto_offer_status = isArchiveModeEnabled
      ? AUTO_OFFER_STATUS.STATUS_INACTIVE
      : AUTO_OFFER_STATUS.STATUS_ACTIVE
    // Remove unnecessary property
    delete selectedFilters.order_by
    delete selectedFilters.page
    // Updating status depending on isArchiveModeEnabled
    selectedFilters.status = auto_offer_status
  }, [currentFilters, isArchiveModeEnabled, selectedFilters, setCurrentFilters])

  useEffect(() => {
    const archiveModeShouldBeEnabled: boolean =
      match!.path.indexOf('archive') >= 0
    if (isArchiveModeEnabled !== archiveModeShouldBeEnabled) {
      setIsArchiveModeEnabled(archiveModeShouldBeEnabled)
    }
  }, [currentFilters, isArchiveModeEnabled, match])

  useEffect(() => {
    const lockedAutoOffers =
      user.company_object && user.company_object.block_auto_offers
    setAreAutoOffersLocked(lockedAutoOffers)
  }, [user.company_object])

  const companyInactive = isUserCompanyInactive(user)

  const onEditClick = autoOfferData => {
    setIsEditingAutoOffer(true)
    setIsAutoOfferModalOpen(true)
    setAutoOfferToEditId(autoOfferData.id)
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.autoOffers')}</title>
      </Helmet>
      <RequiredPermissions
        requiredPermissions={[
          UserPermission.VIEW_MY_AUTO_OFFER,
          UserPermission.VIEW_AUTOOFFER,
        ]}
        showErrorPage
      >
        <MyAutoOffersListContext.Provider
          value={{
            currentFilters,
            currentSorted,
            setCurrentFilters,
            setCurrentSorted,
          }}
        >
          <PageHeader
            title={
              isArchiveModeEnabled
                ? I18n.t('myAutoOffersPageTranslations.archive.heading')
                : I18n.t('myAutoOffersPageTranslations.heading')
            }
          >
            <MyAutoOffersActionButtons
              isArchiveModeEnabled={isArchiveModeEnabled}
              isCompanyInactive={companyInactive}
              areAutoOffersLocked={areAutoOffersLocked}
              openExportAutoOffersModal={() => {
                setIsExportAutoOfferModalOpen(true)
              }}
              openUploadAutoOfferModal={() =>
                setIsUploadAutoOfferModalOpen(true)
              }
              openLockUnlockAutoOfferModal={() => {
                setIsLockingAutoOffers(true)
                setIsLockUnlockAutoOfferModalOpen(true)
              }}
              openManageAutoOfferModal={() => {
                setIsManageAutoOfferModalOpen(true)
              }}
              openAutoOfferFormModal={() => {
                setIsEditingAutoOffer(false)
                setIsAutoOfferModalOpen(true)
              }}
            />
          </PageHeader>

          <MyAutoOffersIndicationMessages
            isCompanyInactive={companyInactive}
            areAutoOffersLocked={areAutoOffersLocked}
            lockUnlockAutoOffersAction={() => {
              setIsLockingAutoOffers(false)
              setIsLockUnlockAutoOfferModalOpen(true)
            }}
          />

          <Filter>
            <MyAutoOfferFilter
              action={getMyAutoOffers}
              currentFilters={currentFilters}
              placeholder={I18n.t('general.placeholder.all')}
              noResultsText={I18n.t('general.emptyFilterResultMessage')}
              setCurrentFilterValues={setCurrentFilters}
            />
          </Filter>
          <MyAutoOffersList
            archiveMode={isArchiveModeEnabled}
            onEditClick={data => onEditClick(data)}
          />

          <AutoOfferModal
            isOpen={isAutoOfferModalOpen}
            onClose={() => {
              setIsAutoOfferModalOpen(false)
              setIsEditingAutoOffer(false) // resets edit state
              setAutoOfferToEditId('') // resets editing auto offer, to load again even if the same is opened
            }}
            isEditing={isEditingAutoOffer}
            autoOfferToEditId={autoOfferToEditId}
          />
          <LockUnlockAutoOffersWarningModal
            isOpen={isLockUnlockAutoOfferModalOpen}
            onClose={() => setIsLockUnlockAutoOfferModalOpen(false)}
            isLocking={!areAutoOffersLocked && isLockingAutoOffers}
          />
          <RequiredPermissions
            requiredPermissions={[UserPermission.CREATE_PHONE_AUTO_OFFERS]}
          >
            <ManageAutoOffersModal
              isOpen={isManageAutoOfferModalOpen}
              onClose={() => setIsManageAutoOfferModalOpen(false)}
            />
          </RequiredPermissions>
          <UploadAutoOffersModal
            isOpen={isUploadAutoOfferModalOpen}
            onClose={() => setIsUploadAutoOfferModalOpen(false)}
          />
          <RequiredPermissions
            requiredPermissions={[
              UserPermission.ADD_AUTOOFFER,
              UserPermission.VIEW_AUTOOFFER,
            ]}
          >
            <AsyncExportModal
              isOpen={isExportAutoOfferModalOpen}
              onClose={() => setIsExportAutoOfferModalOpen(false)}
              reduxSelector='EXPORT_AUTO_OFFERS'
              title={I18n.t(
                'myAutoOffersPageTranslations.exportAutoOffers.title',
              )}
              logic={exportAutoOffers(selectedFilters)}
            />
          </RequiredPermissions>
        </MyAutoOffersListContext.Provider>
      </RequiredPermissions>
    </>
  )
}
