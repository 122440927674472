import React, { FC, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import Spinner from 'react-spinkit'

import { getMyAgreementInquiredCollectionAddresses } from 'actions/address'
import { getAllCompaniesForFilter } from 'actions/company'
import {
  exportAcceptedOffers,
  exportRent,
  getAcceptedOffers,
  resetAcceptedOffers,
} from 'actions/offer'
import { CARD_LIST_PAGE_SIZE } from 'constants/app'
import { UserPermission } from 'constants/user'
import { localizeDateForBackend } from 'helper/general'
import withErrorBoundary from 'helper/withErrorBoundary'
import { getAgreementCollectionAddressesSelector } from 'selectors/address'
import { getAllCompaniesForFilterSelector } from 'selectors/company'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import {
  getMyOffersSelector,
  getOffersPaginationSelector,
} from 'selectors/offer'
import { useArchive } from 'effects'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { AgreementFilter } from '../../common/Filter/components/AgreementFilter'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { AgreementCard, CardList } from '../../common/NewCards'
import PaginatedContent from '../../common/PaginatedContent'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { COMPANY_ROLE } from '../../company/constants'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import {
  getCoarseFractionItems,
  getFractionsFromItem,
} from '../../inquiry/helpers'
import PageHeader from '../../layout/PageHeader'
import { ArchiveButton } from '../../common/ArchiveButton'

/**
 * @description This component displays a page that shows all offers that were created for a inquiry
 */
export const AgreementPageComponent: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const containerList = useSelector(getContainersSelector)
  const fractionList = useSelector(getFractionsSelector)
  const agreementInquiredCollectionAddressList = useSelector(
    getAgreementCollectionAddressesSelector,
  )
  const companiesList = useSelector(getAllCompaniesForFilterSelector)
  const offerList = useSelector(getMyOffersSelector)
  const offersPagination = useSelector(getOffersPaginationSelector)
  const agreementsLoading = useSelector(
    createLoadingSelector(['GET_ACCEPTED_OFFERS']),
  )
  const addressesLoading = useSelector(
    createLoadingSelector(['GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES']),
  )
  const exportAgreementsLoading = useSelector(
    createLoadingSelector(['EXPORT_ACCEPTED_OFFERS']),
  )
  const companiesLoading = useSelector(
    createLoadingSelector(['GET_ALL_COMPANIES_FOR_FILTER']),
  )
  const exportRentLoading = useSelector(createLoadingSelector(['EXPORT_RENT']))

  const isLoading = useMemo(
    () => ({
      agreements: agreementsLoading,
      agreementsInquiredCollectionAddresses: addressesLoading,
      exportAgreements: exportAgreementsLoading,
      allCompanies: companiesLoading,
      exportRent: exportRentLoading,
    }),
    [
      addressesLoading,
      agreementsLoading,
      exportAgreementsLoading,
      companiesLoading,
      exportRentLoading,
    ],
  )
  const errors = useSelector(createErrorSelector(['GET_ACCEPTED_OFFERS']))

  const location = useLocation<{
    company?: string
    role?: COMPANY_ROLE
    archive?: boolean
  }>()
  const defaultFilters: any = { page_size: CARD_LIST_PAGE_SIZE, archive: false }
  if (location?.state?.company) {
    defaultFilters.company = location.state.company
  }

  /**
   * Use GET-Params to set defaultFilters and pre filter agreements
   * Example: /agreement?company=186&address=744&status=3
   *
   * company = company
   * address = collection_address
   * status = agreement_status
   */
  if (location?.search) {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('company')) {
      defaultFilters.company = searchParams.get('company')
    }
    if (searchParams.has('address')) {
      defaultFilters.collection_address = searchParams.get('address')
    }
    if (searchParams.has('status')) {
      defaultFilters.agreement_status = searchParams.get('status')
    }
  }

  const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters)
  useArchive({ currentFilters, setCurrentFilters })
  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth > INITIAL_FILTER_OPEN_WIDTH,
  )
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [isExpiringExportModalOpen, setIsExpiringExportModalOpen] =
    useState(false)
  const [isRentExportModalOpen, setIsRentExportModalOpen] = useState(false)
  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = () => {
    let filters = {
      ...currentFilters,
    }

    // When sending these filters the backend is trying to apply them but crashes because there are no fields named
    // like this. Should be fixed with #3385
    delete filters.direction
    delete filters.price

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
      if (filter === 'id' && typeof currentFilters[filter] !== 'number') {
        delete filters.id
      }
      if (filter === 'order_type') {
        filters['order_type'] = currentFilters[filter]
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        created_at__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        created_at__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.created_at__gte
    delete filters.created_at__lte
    delete filters.archive

    return filters
  }

  useEffect(() => {
    if (!isLoading.agreements && !offersPagination.loaded && !errors) {
      dispatch(getAcceptedOffers(null, currentFilters))
    }
  }, [currentFilters, dispatch, errors, isLoading, offersPagination])

  useEffect(() => {
    dispatch(getMyAgreementInquiredCollectionAddresses())
    dispatch(getAllCompaniesForFilter())
  }, [dispatch])

  useEffect(() => {
    dispatch(resetAcceptedOffers())
  }, [dispatch, location.state])

  return (
    <>
      <Helmet>
        <title>
          {I18n.t(
            `pageTitles.agreements${location.state?.archive ? 'Archive' : ''}`,
          )}
        </title>
      </Helmet>

      <div className='accepted-offers-page'>
        <PageHeader
          title={I18n.t(
            `acceptedOffers.heading${location.state?.archive ? 'Archive' : ''}`,
          )}
          subtitle={I18n.t(
            `acceptedOffers.subtitle${
              location.state?.archive ? 'Archive' : ''
            }`,
          )}
        >
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <ArchiveButton />
            <RequiredPermissions
              requiredPermissions={[UserPermission.ADD_CUSTOM_AGREEMENT]}
            >
              <IconButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                iconName={ICON_NAME.PLUS}
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => history.push('/agreement/add-agreement')}
              >
                <Translate value='acceptedOffers.addAgreement' />
              </IconButton>
            </RequiredPermissions>

            <IconButton
              iconName='experiment'
              iconPosition={ICON_POSITION.RIGHT}
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
            >
              <Translate
                value={filterOpen ? 'general.hideFilter' : 'general.showFilter'}
              />
            </IconButton>

            <RequiredPermissions
              requiredPermissions={[UserPermission.EXPORT_ACCEPTED_OFFERS]}
            >
              <IconButton
                iconName='export'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => setIsExportModalOpen(true)}
                isDisabled={isLoading.exportAgreements}
                isLoading={isLoading.exportAgreements}
              >
                {I18n.t('general.export')}
              </IconButton>
            </RequiredPermissions>

            <RequiredPermissions
              requiredPermissions={[UserPermission.EXPORT_RENT]}
            >
              <IconButton
                iconName='export'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => setIsRentExportModalOpen(true)}
                isDisabled={isLoading.exportRent}
                isLoading={isLoading.exportRent}
              >
                {I18n.t('acceptedOffers.rentExportModal.buttonText')}
              </IconButton>
            </RequiredPermissions>

            <RequiredPermissions
              requiredPermissions={[UserPermission.EXPORT_ACCEPTED_OFFERS]}
            >
              <IconButton
                iconName='export'
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => setIsExpiringExportModalOpen(true)}
                isDisabled={isLoading.exportAgreements}
                isLoading={isLoading.exportAgreements}
              >
                {I18n.t('acceptedOffers.expiringExportModal.buttonText')}
              </IconButton>
            </RequiredPermissions>
          </ButtonBar>
        </PageHeader>

        <div>
          <Collapse isOpened={filterOpen}>
            <Filter
              addressList={agreementInquiredCollectionAddressList}
              fractionList={getCoarseFractionItems(fractionList)}
              companyList={companiesList}
              isLoading={
                isLoading.agreements ||
                isLoading.agreementsInquiredCollectionAddresses ||
                isLoading.allCompanies
              }
              length={offerList.length}
            >
              {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
              {/* @ts-ignore */}
              <AgreementFilter
                action={getAcceptedOffers}
                setCurrentFilterValues={setCurrentFilters}
                currentFilters={currentFilters}
              />
            </Filter>
          </Collapse>

          <PaginatedContent
            page={offersPagination.current}
            pages={offersPagination.count}
            onPreviousPageClick={() =>
              dispatch(
                getAcceptedOffers(offersPagination.previous, currentFilters),
              )
            }
            onNextPageClick={() =>
              dispatch(getAcceptedOffers(offersPagination.next, currentFilters))
            }
          >
            {/* Loading Indicator */}
            {isLoading.agreements && (
              <div className='uk-flex uk-flex-center uk-margin-large-top'>
                <Spinner name='circle' />
              </div>
            )}

            {/* List of orders */}
            {!isLoading.agreements && (
              <CardList
                component={AgreementCard}
                items={offerList}
                addFunc={item => getFractionsFromItem(item, fractionList)}
                addProps={{ containerList }}
              />
            )}
          </PaginatedContent>
          <AsyncExportModal
            isOpen={isExportModalOpen}
            onClose={() => setIsExportModalOpen(false)}
            reduxSelector='EXPORT_ACCEPTED_OFFERS'
            title={I18n.t('acceptedOffers.exportModal.title')}
            description_translation_key={
              'asyncExportModalTranslations.descriptionWithFilter'
            }
            notice_translation_key={
              Object.values(getCleanedExportFilters()).every(
                x => x === null || x === '' || x === 16,
              )
                ? ''
                : 'asyncExportModalTranslations.dateForm.filterNotice'
            }
            logic={exportAcceptedOffers(getExportFilters())}
            resetOnDispatch={() => {
              setExportStartDate('')
              setExportEndDate('')
            }}
          >
            <DateForm
              setStartDate={setExportStartDate}
              setEndDate={setExportEndDate}
            />
          </AsyncExportModal>
          <AsyncExportModal
            isOpen={isExpiringExportModalOpen}
            onClose={() => setIsExpiringExportModalOpen(false)}
            reduxSelector='EXPORT_ACCEPTED_OFFERS'
            title={I18n.t('acceptedOffers.expiringExportModal.title')}
            description_translation_key={
              'asyncExportModalTranslations.descriptionWithFilter'
            }
            notice_translation_key={
              Object.values(getCleanedExportFilters()).every(
                x => x === null || x === '' || x === 16,
              )
                ? ''
                : 'asyncExportModalTranslations.dateForm.filterNotice'
            }
            logic={exportAcceptedOffers({
              ...getExportFilters(),
              is_expiring: true,
            })}
          />
          <AsyncExportModal
            isOpen={isRentExportModalOpen}
            onClose={() => setIsRentExportModalOpen(false)}
            reduxSelector='EXPORT_RENT'
            title={I18n.t('acceptedOffers.rentExportModal.title')}
            description_translation_key={
              'asyncExportModalTranslations.descriptionWithFilter'
            }
            notice_translation_key={
              Object.values(getCleanedExportFilters()).every(
                x => x === null || x === '' || x === 16,
              )
                ? ''
                : 'asyncExportModalTranslations.dateForm.filterNotice'
            }
            logic={exportRent({
              ...getExportFilters(),
            })}
          >
            <DateForm
              setStartDate={setExportStartDate}
              setEndDate={setExportEndDate}
            />
          </AsyncExportModal>
        </div>
      </div>
    </>
  )
}

export const AgreementPage = withErrorBoundary(AgreementPageComponent)
