export const MAX_LENGTH = {
  COMPANY_NAME: 200,
  FIRST_NAME: 50,
  LAST_NAME: 50,
  POSITION: 50,
  PASSWORD: 50,
  MAIL_ADDRESS: 50,
  PHONE_NUMBER: 20,
  POSTAL_CODE: 5,
  STREET: 100,
  HOUSE_NUMBER: 10,
  CITY: 100,
}
export enum ADD_DOCUMENT_POSITION_UNITS {
  // these numbers need to correspond to the constants in backend/empto/invoicing/models/invoicing_documents.py > InvoiceDocumentItem
  PIECE = 1,
  TON = 2,
  CBM = 8,
  HOUR = 10,
}

export const ADD_DOCUMENT_POSITION_UNIT_CHOICES = [
  {
    id: ADD_DOCUMENT_POSITION_UNITS.CBM,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.cbm',
  },
  {
    id: ADD_DOCUMENT_POSITION_UNITS.PIECE,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.piece',
  },
  {
    id: ADD_DOCUMENT_POSITION_UNITS.TON,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.ton',
  },
  {
    id: ADD_DOCUMENT_POSITION_UNITS.HOUR,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.hour',
  },
]

export enum DSZ_ADD_DOCUMENT_POSITION_UNITS {
  KG = 9,
}

export const DSZ_ADD_DOCUMENT_POSITION_UNIT_CHOICES = [
  {
    id: DSZ_ADD_DOCUMENT_POSITION_UNITS.KG,
    name: 'addInvoicePageTranslations.dsz.steps.3.fields.unit.kg',
  },
]
