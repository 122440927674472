const phoneString = '0800&nbsp00&nbsp56356'
const phoneValue = '0800-00-56356'
const email = 'service@empto.de'

export const createInquiryTranslations = {
  header: {
    title: 'Anfrage erstellen',
    description:
      'Stellen Sie hier Ihre Anfrage zur Abholung Ihres Abfalls - füllen Sie dafür nur kurz das ' +
      'Formular aus. Anschließend erhalten Sie Angebote von Entsorgungsunternehmen und können nach Wunsch den ' +
      'Auftrag erteilen.',
  },
  steps: {
    one: {
      title: 'Leistungsadresse & Lieferzeit',
    },
    two: {
      title: 'Abfallart & Behälter',
    },
    three: {
      title: 'Absenden',
    },
  },
  sidebar: {
    help: {
      title: 'Sie sind sich unsicher?',
      description:
        'Entsorger haben bei der Abgabe eines Angebots die Möglichkeit, ihrer Anfrage ' +
        'Verbesserungsvorschläge hinzuzufügen.',
    },
    customerService: {
      title: 'Exzellenter Kundenservice',
      description: `Der empto Kundenservice ist Mo-Fr zwischen 8-18 Uhr erreichbar. Kostenlose
        Telefonnummer: <a href='tel:${phoneValue}'>${phoneString}</a>`,
    },
  },
  form: {
    addressAndDeliveryFormGroup: {
      noInputResults: {
        company: 'Zu Ihrer Suchanfrage wurde kein Unternehmen gefunden',
        phoneUser: 'Zu Ihrer Suchanfrage wurde kein Benutzer gefunden',
        collectionAddress:
          'Zu Ihrer Suchanfrage wurde keine Leistungsadresse gefunden',
      },
      label: {
        allTheSame: 'egal',
        company: 'Unternehmen',
        phoneUser: 'Benutzer',
        collectionAddress: 'Leistungsadresse',
        specialNote: 'Optional: Besondere Hinweise',
        positionOnPublicGround: 'Stellung auf öffentlichem Grund',
        privateGround: 'Privatgelände',
        timeOfDayDelivery: 'Zeitraum',
        withETA: 'Mit ETA-Service (Estimated Time of Arrival)',
        withETAsmall: 'Mit Estimated-Time-of-Arrival-Service',
      },
      subLabel: {
        collectionAddress: '(Wo soll der Abfall abgeholt werden?)',
        specialNote: '(Ansprechpartner, Anfahrt, Stellplatz, etc.)',
      },
      tooltip: {
        positionOnPublicGround:
          '<p>Wenn der Behälter auf einer öffentlichen Fläche abgestellt werden soll, ' +
          'muss vorher eine entsprechende Stellplatzgenehmigung eingeholt werden. ' +
          'Diesen Service kann das Entsorgungsunternehmen übernehmen und direkt in der Angebotsgestaltung ' +
          'berücksichtigen. Bitte bedenken Sie bei der Wahl des Leistungsbeginns, ' +
          'dass die Einholung der Stellplatzgenehmigung etwas Zeit beansprucht.</p>' +
          '<p><b>Eine Gestellung auf privater Fläche ist selbstverständlich jederzeit möglich ' +
          'und nicht mit zusätzlichen Kosten verbunden.<b></p>',
        withETA:
          '<p>Eine steigende Anzahl an Entsorgungsunternehmen ist in der Lage, ' +
          'die exakte Ankunftszeit ihrer Entsorgungsfahrzeuge an der ' +
          'Leistungsadresse automatisiert und elektronisch zu übermitteln. ' +
          'empto unterstützt die Anbindung dieser Systeme. ' +
          'An dieser Stelle können Sie Ihren Wunsch dokumentieren, ' +
          'dass Sie einen Dienstleister mit ETA-Service eventuell bevorzugen würden.</p>',
        specialNote:
          'Bitte verwenden Sie dieses Textfeld für nützliche Informationen, die das ' +
          'Entsorgungsunternehmen dabei unterstützen, die von Ihnen gewünschte operative Abwicklung ohne ' +
          'Komplikationen durchführen zu können.',
      },
      placeholder: {
        company: 'Unternehmen',
        phoneUser: 'Benutzer',
        collectionAddress: 'Leistungsadresse',
        specialNote: 'Besondere Hinweise',
      },
      validation: {
        companyError: 'Bitte wählen Sie ein Unternehmen aus',
        phoneUserError: 'Bitte wählen Sie einen Kunden aus',
        orderTypeError: 'Bitte wählen Sie eine Auftragsart aus.',
        addressError: 'Bitte wählen Sie eine Adresse aus.',
        deliveryDateError: 'Bitte geben Sie das Lieferdatum an.',
        collectionDateError: 'Bitte geben Sie das Abholdatum an.',
        agreementBeginError: 'Bitte geben Sie den Vereinbarungsbeginn an.',
        agreementEndError: 'Bitte geben Sie das Vereinbarungsende an.',
        turnBeginError: 'Bitte geben Sie den Turnusbeginn an.',
        turnEndError: 'Bitte geben Sie das Turnusende an.',
        intervalError: 'Bitte wählen Sie ein Intervall aus.',
        agreementStartAfterEndDateError:
          'Vereinbarungsbeginn kann nicht nach Vereinbarungsende sein.',
        agreementEndBeforeStartDateError:
          'Vereinbarungsende kann nicht vor Vereinbarungsbeginn sein.',
        turnStartAfterEndDateError:
          'Turnusbeginn kann nicht nach Turnusende sein.',
        turnEndBeforeStartDateError:
          'Turnusende kann nicht vor Turnusbeginn sein.',
      },
      summary: {
        company: 'Unternehmen',
        phoneUser: 'Benutzer',
        address: 'Leistungsadresse',
        positionOnPublicGround: 'Stellplatz',
        orderType: 'Auftragsart',
        serviceDatesOneTime: 'Lieferung / Abholung',
        serviceDatesRecurring: 'Turnusbeginn / Turnusende',
        serviceDatesOnDemand: 'Vereinbarungsbeginn / Vereinbarungsende',
        serviceDatesQuick: 'Lieferung',
        specialNote: 'Besondere Hinweise',
      },
    },
    fractionAndContainerFormGroup: {
      noInputResults: {
        noProtectionClasses: 'Die Schutzklassen konnte nicht geladen werden.',
        noSecurityLevels: 'Die Sicherheitsstufen konnten nicht geladen werden.',
      },
      label: {
        fraction: 'Abfallart',
        fineFraction: 'Optional: Feinfraktion',
        securityGroup: 'Schutzklasse und Sicherheitsstufe',
        container: 'Behälter',
        numberOfContainers: 'Anzahl',
        quantityInCubicMeters: 'Menge in m\u00B3',
        withTop: 'Deckelausführung gewünscht',
        attachments: 'Optional: Bilder / Dokumente',
      },
      subLabel: {
        fraction: '(Informationen zur Abfallart erhalten Sie bei der Auswahl)',
        fineFraction: '(Wir empfehlen das Hochladen von Fotos Ihrer Abfälle)',
        container: '(Informationen zur Behälter erhalten Sie bei der Auswahl)',
        attachments: '(Hier können Sie bis zu drei Dateien hochladen)',
      },
      summary: {
        withTop: ', mit Deckel',
        quantityInCubicMeters: 'Menge in m\u00B3',
        numberOfContainers: 'Anzahl',
      },
      tooltip: {
        fraction:
          '<p> Bitte wählen Sie eine der angezeigten Abfallarten unter Berücksichtigung der Sortierkriterien' +
          'Ist Ihre Abfallart nicht dabei? Bitte rufen Sie uns einfach an unter ' +
          `<a href="tel:${phoneValue}">${phoneString}</a> oder ` +
          `schicken Sie eine kurze Mail an <a href="mailto:${email}">${email}</a></p>`,
        fineFraction:
          '<p> Mit der Bestimmung der Feinfraktion können Sie die Angebotserstellung für die ' +
          'Entsorgungsunternehmen vereinfachen, Rückfragen reduzieren und Ihren Preis optimieren. ' +
          'Unsicher mit der exakten Auswahl der Feinfraktion? ' +
          'Wir empfehlen Ihnen das Hochladen von Fotos Ihrer Abfälle.',
        container:
          '<p> Bitte wählen Sie den von Ihnen gewünschten Behälter. ' +
          'Unsicher bei der Auswahl des geeigneten Behälters? Bitte rufen Sie uns einfach an unter ' +
          `<a href="tel:${phoneValue}">${phoneString}</a> oder ` +
          `schicken Sie eine kurze Mail an <a href="mailto:${email}">${email}</a></p>`,
        numberOfContainer:
          '<p> Bitte wählen Sie die von Ihnen benötigte Stückzahl des Behälters. ' +
          'Unsicher bei der Auswahl der Stückzahl? Bitte rufen Sie uns einfach an unter ' +
          `<a href="tel:${phoneValue}">${phoneString}</a> oder ` +
          `schicken Sie eine kurze Mail an <a href="mailto:${email}">${email}</a></p>`,
      },
      placeholder: {
        fraction: 'Abfallart',
        fineFraction: 'Feinfraktion',
        securityGroup: 'Schutzklasse und Sicherheitsstufe',
        container: 'Behälter',
      },
      validation: {
        fractionError: 'Bitte wählen Sie eine Abfallart aus.',
        securityGroupError:
          'Bitte wählen Sie eine Schutzklasse und Sicherheitsstufe aus.',
        containerError: 'Bitte wählen Sie einen Behälter aus.',
      },
    },
    sendInquiryFormGroup: {
      label: {
        runtimeOfInquiry: 'Laufzeit der Anfrage',
      },
      placeholder: {
        runtimeOfInquiry: 'Laufzeit der Anfrage',
      },
      validation: {
        runtimeOfInquiry: 'Bitte wählen Sie die Laufzeit der Anfrage aus.',
      },
      information:
        'Nach dem Veröffentlichen Ihrer Anfrage erhalten Sie innerhalb der gewählten Laufzeit ' +
        '<b> kostenlos </b> Angebote von Entsorgungsunternehmen.',
    },
    error: {
      containerError: 'Kein Preis für Behälter gefunden',
      containerErrorText:
        'Der ausgewählte Behälter und der Behälter in der Angebotsauswahl stimmen nicht überein.',
    },
    button: {
      nextStep: 'Nächster Schritt',
      submitForm: 'Anfrage veröffentlichen',
      editStep: 'Bearbeiten',
    },
  },
}
