import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'

import {
  exportExecutionProofs,
  getExecutionProofs,
} from 'actions/executionproof'
import { TABLE_PAGE_SIZE_10 } from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { UserPermission } from 'constants/user'
import { get } from 'helper/general'
import { checkRequiredPermissions } from 'helper/permissions'
import { createErrorSelector } from 'selectors/error'
import {
  getExecutionProofPaginationSelector,
  getExecutionProofsSelector,
} from 'selectors/executionproof'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import ExecutionProofFilter from '../../common/Filter/components/ExecutionProofFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import PageHeader from '../../layout/PageHeader'
import { PROOF_TYPE } from '../../proofs/constants'
import { ProofsList } from '../../proofs/ProofsList'

/**
 * @description This component displays a list of execution proofs including the filter, loading indicator etc.
 * @function
 */
export const ExecutionProofsPage = () => {
  const dispatch = useDispatch()
  const executionsProofs = useSelector(getExecutionProofsSelector)
  const user = useSelector(getCurrentUserSelector)
  const pagination = useSelector(getExecutionProofPaginationSelector)

  const [currentFilters, setCurrentFilters] = useState({})

  const isLoading = {
    getExecutionProofs: useSelector(
      createLoadingSelector(['GET_EXECUTION_PROOFS']),
    ),
    updateExecutionProof: useSelector(
      createLoadingSelector(['UPDATE_EXECUTION_PROOFS']),
    ),
    exportExecutionProofs: useSelector(
      createLoadingSelector(['EXPORT_EXECUTION_PROOFS']),
    ),
  }

  const apiError = {
    getExecutionProofs: useSelector(
      createErrorSelector(['GET_EXECUTION_PROOFS']),
    ),
    updateExecutionProof: useSelector(
      createErrorSelector(['UPDATE_EXECUTION_PROOFS']),
    ),
    exportExecutionProofs: useSelector(
      createErrorSelector(['EXPORT_EXECUTION_PROOFS']),
    ),
  }

  useEffect(() => {
    dispatch(getExecutionProofs())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.executionProofDetails')}</title>
      </Helmet>
      <div className='execution-proof-listing-page'>
        <PageHeader title={I18n.t('executionProof.completion')}>
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <Media
              key='media-extag'
              query={{ minWidth: BREAKPOINT.XLARGE }}
              render={() => (
                <RequiredPermissions
                  requiredPermissions={[UserPermission.EXPORT_EXECUTION_PROOFS]}
                >
                  <IconButton
                    iconName='export'
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={() => dispatch(exportExecutionProofs())}
                    isDisabled={isLoading.exportExecutionProofs}
                    isLoading={isLoading.exportExecutionProofs}
                  >
                    <Translate value='general.export' />
                  </IconButton>
                </RequiredPermissions>
              )}
            />
          </ButtonBar>
        </PageHeader>
        <Filter
          isLoading={isLoading.getExecutionProofs}
          length={executionsProofs.length}
        >
          <ExecutionProofFilter
            getCurrentFilterValues={values => setCurrentFilters(values)}
            currentFilterValues={currentFilters}
          />
        </Filter>

        <ProofsList
          proofsType={PROOF_TYPE.EXECUTION_PROOF}
          proofsList={executionsProofs}
          isLoading={isLoading.getExecutionProofs}
          apiError={apiError.getExecutionProofs}
          isReviewButtonVisible={checkRequiredPermissions(
            get(() => user.permission_codenames),
            [UserPermission.VIEW_EXECUTIONPROOF],
          )}
          onNextPageClick={() =>
            dispatch(
              getExecutionProofs(
                pagination.next,
                currentFilters,
                TABLE_PAGE_SIZE_10,
              ),
            )
          }
          onPreviousPageClick={() =>
            dispatch(
              getExecutionProofs(
                pagination.previous,
                currentFilters,
                TABLE_PAGE_SIZE_10,
              ),
            )
          }
          page={pagination.current}
          pages={pagination.count}
          onFetchData={({ sorted }) => {
            if (sorted?.length > 0) {
              const newFilters = {
                ...currentFilters,
                order_by: JSON.stringify(sorted),
              }
              if (
                JSON.stringify(newFilters) !== JSON.stringify(currentFilters) ||
                !pagination.loaded
              ) {
                setCurrentFilters(newFilters)
                dispatch(
                  getExecutionProofs(undefined, newFilters, TABLE_PAGE_SIZE_10),
                )
              }
            }
          }}
        />
      </div>
    </>
  )
}
