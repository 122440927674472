import MaklerPremiumActionTypes from '../actiontypes/maklerpremium'

const itemInitialState = {
  is_company: true,
  // Geschäftskunde
  company_name: '',
  tax_number: '',
  tax_id: '',
  // Privatkunde
  is_male: true,
  first_name: '',
  last_name: '',
  // Geschäftskunde + Privatkunde
  street: '',
  house_number: '',
  postal_code: '',
  city: '',
  payment_on_account: false,
  permanent_discount: 0,
  // Central Contact Data for EPD
  has_central_contact: false,
  central_contact_email: '',
  central_contact_phone: '',
  // Contact Person
  contact_person_is_male: true,
  contact_person_first_name: '',
  contact_person_last_name: '',
  contact_person_position: '',
  contact_person_phone: '',
  contact_person_email: '',
}

export type OfferPipelinePagination = Pagination & {
  total_offers_count: number
  total_retail_price_forecast: number
}

type MaklerPremiumStateType = {
  item: Partial<Company>
  frameworkContractPosition: {
    positions: [FrameworkContractPosition] | []
    loaded: boolean
  }
  frameworkContractPositionsCount?: number
  cancelledPaidDocument: undefined | CancelledPaidDocument
  offerPipeline: {
    pagination: OfferPipelinePagination
    list: CustomOffer[]
  }
}

export const initialState: MaklerPremiumStateType = {
  item: itemInitialState,
  frameworkContractPosition: { positions: [], loaded: false },
  frameworkContractPositionsCount: undefined,
  cancelledPaidDocument: undefined,
  offerPipeline: {
    list: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
      total_offers_count: 0,
      total_retail_price_forecast: 0,
    },
  },
}

/**
 * @description A reducer for createCustomer.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const maklerpremiumReducer = (state = initialState, action) => {
  switch (action.type) {
    case MaklerPremiumActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
      }
    case MaklerPremiumActionTypes.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
      }
    case MaklerPremiumActionTypes.GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_SUCCESS:
      return {
        ...state,
        customInvoiceNetGrossPricesAndVat: action.payload,
      }
    case MaklerPremiumActionTypes.VALIDATE_IBAN_RESET:
    case MaklerPremiumActionTypes.VALIDATE_IBAN_SUCCESS:
      return {
        ...state,
        customInvoiceValidatedIBANErrorMessage: undefined,
      }
    case MaklerPremiumActionTypes.VALIDATE_IBAN_ERROR:
      return {
        ...state,
        customInvoiceValidatedIBANErrorMessage:
          action.payload.response.data.iban[0],
      }
    case MaklerPremiumActionTypes.CREATE_CUSTOM_INVOICE_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case MaklerPremiumActionTypes.GET_FRAMEWORKCONTRACT_SUCCESS:
      return {
        ...state,
        frameworkContractPosition: { positions: action.payload, loaded: true },
      }
    case MaklerPremiumActionTypes.GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_SUCCESS:
      return {
        ...state,
        frameworkContractPositionsCount: action.payload.count,
      }
    case MaklerPremiumActionTypes.RESET_FRAMEWORKCONTRACT:
      return {
        ...state,
        frameworkContractPosition: initialState.frameworkContractPosition,
      }
    case MaklerPremiumActionTypes.GET_CANCELLEDPAIDDOCUMENT_SUCCESS:
    case MaklerPremiumActionTypes.UPDATE_CANCELLEDPAIDDOCUMENT_SUCCESS:
      return {
        ...state,
        cancelledPaidDocument: action.payload,
      }
    case MaklerPremiumActionTypes.UPDATE_CANCELLEDPAIDDOCUMENT_RESET:
      return {
        ...state,
        cancelledPaidDocument: null,
      }
    case MaklerPremiumActionTypes.GET_OFFER_PIPELINE_SUCCESS:
      return {
        ...state,
        offerPipeline: {
          list: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case MaklerPremiumActionTypes.GET_OFFER_PIPELINE_ERROR:
      return {
        ...state,
        offerPipeline: {
          ...state.offerPipeline,
          pagination: {
            ...state.offerPipeline.pagination,
            loaded: true,
          },
        },
      }

    default:
      return {
        ...state,
      }
  }
}
