import './style.scss'

import React, { FC, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { CommunityControl } from 'components/common/CommunityControl'
import { FindAvvByFilterInput } from 'components/common/FindByFilterInput/FindAvvByFilterInput'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { ZipcodeControl } from 'components/common/ZipcodeControl'
import { COMPANY_ROLE } from 'components/company/constants'
import { MyAutoOfferListFilters } from 'components/offer/MyAutoOffersPage/MyAutoOffersPage'
import { UserPermission } from 'constants/user'
import { getAutoOffersPaginationSelector } from 'selectors/autooffer'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'

import { CommonFilterProps } from '../../types'
import { ContainerSearchFilter } from '../ContainerSearchFilter'
import { FractionSearchFilter } from '../FractionSearchFilter'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface MyAutoOfferFilterProps extends Partial<CommonFilterProps> {
  action?: (page: number | null, currentFilters: MyAutoOfferListFilters) => any
  currentFilters: MyAutoOfferListFilters
  noResultsText: string
  placeholder: string
  setCurrentFilterValues: (currentFilters: MyAutoOfferListFilters) => void
}

export type FilterOption = {
  property: string
  value: React.ReactText
}

export const MyAutoOfferFilter: FC<MyAutoOfferFilterProps> = ({
  action,
  currentFilters,
  noResultsText,
  placeholder,
  setCurrentFilterValues,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUserSelector)
  const pagination = useSelector(getAutoOffersPaginationSelector)

  const isLoading = {
    getMyAutoOffers: useSelector(createLoadingSelector(['GET_MY_AUTO_OFFERS'])), // for filter
  }

  const [communityValue, setCommunityValue] = useState<[]>([])
  const [zipcodeValue, setZipcodeValue] = useState<[]>([])

  const handleSelectionChange = (filters: FilterOption[]) => {
    const newFilters = { ...currentFilters, page: 1 }
    filters.forEach(filter => {
      newFilters[filter.property] = filter.value
    })
    setCurrentFilterValues(newFilters)

    if (action) {
      dispatch(action(null, newFilters))
    }
  }

  const mapToOption = (
    item:
      | Fraction
      | Container
      | BasicFilterResponse
      | Community
      | ZipCode
      | Avv
      | FractionFilterResponse,
    propertyLabelField: string,
    propertyValueField: string,
  ) =>
    ({
      label: item[propertyLabelField],
      value: item[propertyValueField],
    } as Option)

  const mapAvvsToOptions = (avvArray: Avv[]) =>
    avvArray.map(({ number }) => ({
      label: number,
      value: number,
    }))

  return (
    <>
      <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
        <FilterReset
          onResetFilter={() => {
            window.location.reload()
          }}
          showResetFilterButton={
            // we do > 1 because the filter always has a status key
            cleanUpAndCountFiltersForReset(currentFilters) > 1
          }
        />
      </ButtonBar>
      <div className='uk-grid uk-child-width-1-1 uk-child-width-1-5@m'>
        <FractionSearchFilter
          currentFilters={currentFilters}
          setCurrentFilterValues={values => {
            handleSelectionChange([
              {
                property: 'coarse_fraction',
                value: values.id,
              } as FilterOption,
            ])
          }}
          noResultsText={noResultsText}
          placeholder={placeholder}
          label={I18n.t('filterTranslations.myAutoOfferFilter.fraction')}
          name='coarse_fraction'
          isClearable
        />
        <ContainerSearchFilter
          currentFilters={currentFilters}
          setCurrentFilterValues={values => {
            handleSelectionChange([
              {
                property: 'container',
                value: values.id,
              } as FilterOption,
            ])
          }}
          noResultsText={noResultsText}
          placeholder={placeholder}
          label={I18n.t('filterTranslations.myAutoOfferFilter.container')}
          name='container'
          mapToOption={mapToOption}
          minCharacters={2}
          isClearable
        />
        {!user.company && (
          <RequiredPermissions
            requiredPermissions={[UserPermission.VIEW_AUTOOFFER]}
          >
            {/* prettier-ignore */}
            <Select
              urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
              urlPart2='&onlyActive=false&role=2'
              getValue={(e) => e.id}
              getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`}
              label={I18n.t('filterTranslations.myAutoOfferFilter.company')}
              setValue={e => {handleSelectionChange([
                {property: 'company',       value: e?.value ? e.value : ''} as FilterOption,
                {property: 'company__role', value: COMPANY_ROLE.WASTE_COMPANY}])}}
              selectedValue={currentFilters.company}
              selecting={'company'}
            />
          </RequiredPermissions>
        )}
        <div className='filter__communities-select'>
          <CommunityControl
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            name='communities'
            onBlur={() => undefined}
            onChange={(property, communities) => {
              handleSelectionChange([
                {
                  property,
                  value: communities.map(community => community.id).join(','),
                } as FilterOption,
              ])
              setCommunityValue(communities)
            }}
            value={communityValue}
            isClearable
          />
        </div>
        <div className='filter__communities-select'>
          <ZipcodeControl
            getOptionLabel={option => option.code}
            getOptionValue={option => option.id}
            name='zipcodes'
            onBlur={() => undefined}
            onChange={(property, zipcodes) => {
              handleSelectionChange([
                {
                  property,
                  value: zipcodes.map(zipcode => zipcode.id).join(','),
                } as FilterOption,
              ])
              setZipcodeValue(zipcodes)
            }}
            value={zipcodeValue}
            isClearable
          />
        </div>
        <FindAvvByFilterInput
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
          )}
          mapOptionLabel={mapAvvsToOptions}
          name='find_avv_by_filter_input'
          noOptionsMessage={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.noOptionsMessage',
          )}
          placeholder={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
          )}
          handleSelectionChange={(value: string | undefined) => {
            handleSelectionChange([
              {
                property: 'avv__number',
                value:
                  typeof value === 'string' ? value?.replace(/\W/g, '') : '',
              } as FilterOption,
            ])
          }}
          value=''
          noResultsText={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.noOptionsMessage',
          )}
          isClearable
        />
      </div>
      <div className='filter__empty-message'>
        {!isLoading.getMyAutoOffers && pagination.total_results < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
