import uniqueId from 'lodash.uniqueid'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { exportUsers } from 'actions/user'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import UserListFilter from '../../common/Filter/components/UserFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import PageHeader from '../../layout/PageHeader'
import { USER_MODALS } from '../constants'
import { UserForm } from '../UserForm'
import { UserList } from '../UserList'

import connector from './connector'
import UserOverviewPageProps from './schemes'

/**
 * @description This component lists the company users editing features.
 * @function
 * @param {Object} props the component props
 */
export class UserOverviewPage extends Component {
  static propTypes = UserOverviewPageProps

  static defaultProps = {
    user: null,
    userList: [],
    pagination: {},
    isLoading: {
      exportUsers: false,
      userList: false,
    },
  }

  state = {
    currentFilterValues: {},
    openModal: false,
    sortedBy: [],
    startDate: '',
    endDate: '',
  }

  /**
   * Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    const match = this.props.match
    if (match?.params?.companyId) {
      this.setState(
        {
          currentFilterValues: { company: match.params.companyId },
          maklerPremiumContactPage: true,
          isExportModalOpen: false,
        },
        () => {
          this.props.getMyUsers(this.state.pagination, this.state.sortedBy, {
            ...this.state.currentFilterValues,
            company: match.params.companyId,
          })
        },
      )
    } else {
      this.props.getMyUsers()
      this.setState({ maklerPremiumContactPage: false })
    }
  }

  /**
   * Component “lifecycle method” componentDidMount
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.userIsContactPerson !== prevProps.userIsContactPerson &&
      this.props.userIsContactPerson === true
    ) {
      this.setState({
        openModal: USER_MODALS.CONTACT_PERSON_INFO_MODAL,
      })
    }
  }

  /**
   * @description call getMyUsers and save sorting values in state
   */
  getSortingValues = state => {
    if (state.sorted.length) {
      const match = this.props.match
      if (match && match.params && match.params.companyId) {
        this.props.getMyUsers(null, state.sorted, {
          company: match.params.companyId,
        })
      } else {
        this.props.getMyUsers(null, state.sorted)
      }
      this.setState({ sortedBy: state.sorted })
    }
  }

  /**
   * @description Toggle a modal
   * @param modal
   * @param event
   */
  handleModalToggle = (modal = null, event = null) => {
    this.props.resetUser()
    if (event) event.preventDefault()
    this.setState({
      openModal: modal,
    })
  }

  /**
   * @description Changes the status of the user. This does a partial update call that changes only the status.
   * @param user object
   * @param status
   */
  handleChangeStatus = (user, status) => {
    this.props.updateUser({
      id: user.id,
      status,
    })
  }

  /**
   * @description Open modal to add a user
   */
  handleAddUser = () => {
    this.props.resetUser()
    this.setState({
      openModal: USER_MODALS.USER_FORM_MODAL,
    })
  }

  /**
   * @description Open modal to edit a user
   */
  handleEditUser = user => {
    this.props.getUser(user.id)
    this.setState({
      openModal: USER_MODALS.USER_FORM_MODAL,
    })
  }

  /**
   * @description Renders the user form modal to add or edit users
   * @return {*}
   */
  renderUserFormModal() {
    const { user, userItem } = this.props
    const isEditing = user && user.id && user.id !== userItem.id
    const isCurrentUser = user.id === userItem.id
    const title = userItem.id
      ? I18n.t('userForm.header.titleEdit')
      : I18n.t('userForm.header.title')
    const idUserFormModalHeadline = uniqueId()
    const isMaklerPremiumContactPerson = this.state.maklerPremiumContactPage

    if (isEditing && this.props.isLoading.getUserToEdit) return null
    return (
      <Modal
        ariaDescribedBy={idUserFormModalHeadline}
        isOpen={this.state.openModal === USER_MODALS.USER_FORM_MODAL}
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={title}
          titleId={idUserFormModalHeadline}
        />

        <UserForm
          onCancel={this.handleModalToggle}
          onSuccess={this.handleModalToggle}
          isCurrentUser={isCurrentUser}
          filters={this.state.currentFilterValues}
          maklerPremiumContactPage={isMaklerPremiumContactPerson}
        />
      </Modal>
    )
  }

  /**
   * @description Renders the contact person info modal
   * @return {*}
   */
  renderContactPersonInfoModal() {
    const title = I18n.t('general.information')
    const idContactPersonInfoHeadline = uniqueId()
    const onClose = () => {
      this.handleModalToggle()
      this.props.resetUserIsContactPerson()
    }

    return (
      <Modal
        ariaDescribedBy={idContactPersonInfoHeadline}
        isOpen={this.state.openModal === USER_MODALS.CONTACT_PERSON_INFO_MODAL}
        onClose={onClose}
      >
        <ModalHeader
          onClose={onClose}
          title={title}
          titleId={idContactPersonInfoHeadline}
        />
        <div className='uk-modal-body'>
          <p>
            <Translate value='userOverview.contactPersonInfoModal.message.start' />
            <Link to='/company/profile' onClick={onClose}>
              <Translate value='userOverview.contactPersonInfoModal.message.link' />
            </Link>
            <Translate value='userOverview.contactPersonInfoModal.message.end' />
          </p>
        </div>
        <div className='uk-modal-footer uk-text-right'>
          <span className='uk-margin-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={onClose}
            >
              <Translate value='general.button.submit' />
            </Button>
          </span>
        </div>
      </Modal>
    )
  }

  /**
   * @description Renders the export modal
   * @return {*}
   */
  renderExportModal() {
    const { currentFilterValues, exportStartDate, exportEndDate } = this.state

    const getExportFilters = () => {
      let filters = {
        ...currentFilterValues,
      }
      // remove `page`, because the export can not handle this property
      delete filters['page']
      Object.keys(filters).forEach(filter => {
        //remove empty filter
        if (currentFilterValues[filter] === '') {
          delete filters[filter]
          return
        }
      })

      if (exportStartDate) {
        filters = {
          ...filters,
          created_at__gte: localizeDateForBackend(exportStartDate),
        }
      }

      if (exportEndDate) {
        filters = {
          ...filters,
          created_at__lte: localizeDateForBackend(exportEndDate),
        }
      }

      return filters
    }

    const getCleanedExportFilters = () => {
      const filters = getExportFilters()
      delete filters.created_at__gte
      delete filters.created_at__lte

      return filters
    }

    const setExportStartDate = exportStartDate => {
      this.setState({ exportStartDate: exportStartDate })
    }

    const setExportEndDate = exportEndDate => {
      this.setState({ exportEndDate: exportEndDate })
    }

    return (
      <AsyncExportModal
        isOpen={this.state.isExportModalOpen}
        onClose={() => this.setState({ isExportModalOpen: false })}
        reduxSelector='EXPORT_USERS'
        title={I18n.t('userOverview.userExportModalTitle')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportUsers(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
    )
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return (
      <>
        <Helmet>
          <title>
            {this.state.maklerPremiumContactPage
              ? I18n.t('pageTitles.contactPerson')
              : I18n.t('pageTitles.myUser')}
          </title>
        </Helmet>
        <div className='user-overview-page'>
          <PageHeader
            title={
              this.state.maklerPremiumContactPage
                ? I18n.t('contactPersonOverview.heading')
                : I18n.t('userOverview.heading')
            }
          >
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              {!this.state.maklerPremiumContactPage && (
                <>
                  <Media
                    key='media-extag'
                    query={{ minWidth: BREAKPOINT.XLARGE }}
                  >
                    <RequiredPermissions
                      requiredPermissions={[UserPermission.EXPORT_USERS]}
                    >
                      <IconButton
                        iconName='export'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() =>
                          this.setState({ isExportModalOpen: true })
                        }
                        isDisabled={this.props.isLoading.exportUsers}
                        isLoading={this.props.isLoading.exportUsers}
                      >
                        <Translate value='general.export' />
                      </IconButton>
                    </RequiredPermissions>
                  </Media>
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.ADD_EMAILUSER]}
                  >
                    <IconButton
                      backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                      iconName='plus'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => this.handleAddUser()}
                    >
                      <Translate value='userOverview.inviteButton' />
                    </IconButton>
                  </RequiredPermissions>
                </>
              )}
              {this.state.maklerPremiumContactPage && (
                <RequiredPermissions
                  requiredPermissions={[
                    UserPermission.CHANGE_MAKLER_PREMIUM_USER,
                  ]}
                >
                  <IconButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    iconName='plus'
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={() => this.handleAddUser()}
                  >
                    <Translate value='userOverview.addButton' />
                  </IconButton>
                </RequiredPermissions>
              )}
            </ButtonBar>
          </PageHeader>
          {!this.state.maklerPremiumContactPage && (
            <Filter
              isLoading={this.props.isLoading.userList}
              length={this.props.userList.length}
            >
              <UserListFilter
                currentFilterValues={this.state.currentFilterValues}
                setCurrentFilterValues={values =>
                  this.setState({ currentFilterValues: values })
                }
                sortedBy={this.state.sortedBy}
                onSelectionChange={currentFilterValues => {
                  this.props.getMyUsers(
                    this.state.pagination,
                    this.state.sortedBy,
                    currentFilterValues,
                  )
                }}
                isClearable
              />
            </Filter>
          )}
          <UserList
            pages={this.props.pagination.count}
            page={this.props.pagination.current}
            onPreviousPageClick={() =>
              this.props.getMyUsers(
                this.props.pagination.previous,
                this.state.sortedBy,
                this.state.currentFilterValues,
              )
            }
            onNextPageClick={() =>
              this.props.getMyUsers(
                this.props.pagination.next,
                this.state.sortedBy,
                this.state.currentFilterValues,
              )
            }
            user={this.props.user}
            userList={this.props.userList}
            onFetchData={this.getSortingValues}
            isLoading={this.props.isLoading.userList}
            onStatusChange={this.handleChangeStatus}
            onModalToggle={this.handleModalToggle}
            onEditUser={this.handleEditUser}
          />

          {/* Modals */}
          {this.renderUserFormModal()}
          {this.renderContactPersonInfoModal()}
          {this.renderExportModal()}
        </div>
      </>
    )
  }
}

export default compose(withErrorBoundary, connector)(UserOverviewPage)
