import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import React, { useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import ReactModal from 'react-modal'
import { BrowserRouter, matchPath } from 'react-router-dom'

import { translationsObject } from '../assets/i18n/translations'
import { ScrollToTop } from '../config/scrollToTop'
import { APP_CONSTANTS } from '../constants/app'
import { routes } from '../routes/root'
import { storedLoggedIn } from '../services/http'

import LegacyBrowserNotification from './common/LegacyBrowserNotification'
import { PageDimensionsProvider } from './common/PageDimensions'
import { RoutesHandler } from './common/RoutesHandler'
import Toastr from './common/Toastr'
import connector from './connector'
import { CookieConsentBanner } from './general/CookieConsentBanner'

ReactModal.setAppElement('body')

/**
 * @description This component renders and controls a app component.
 * @function
 * @param {Object} props the component props
 */
const App = ({ actions, loggedIn }) => {
  const REACT_APP_SENTRY_KEY = APP_CONSTANTS.REACT_APP_SENTRY_KEY

  if (REACT_APP_SENTRY_KEY) {
    const history = createBrowserHistory()

    Sentry.init({
      dsn: REACT_APP_SENTRY_KEY,
      environment: APP_CONSTANTS.REACT_APP_ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            routes,
            matchPath,
          ),
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    })
  }

  // This useEffect does not seem to be effective. There is a workaround in UserForm/constants.js
  useEffect(() => {
    I18n.setTranslations(translationsObject)
    I18n.setLocale('de-DE')
    I18n.setHandleMissingTranslation(key => {
      if (APP_CONSTANTS.REACT_APP_ENVIRONMENT === 'local') {
        //eslint-disable-next-line no-throw-literal
        throw `Translation for ${key} missing`
      }
      return key.split('.').pop()
    })
  }, [])

  useEffect(() => {
    if (loggedIn && !storedLoggedIn()) {
      actions.user.logout()
    }
  }, [actions.user, loggedIn])

  return (
    <>
      <LegacyBrowserNotification />
      <BrowserRouter>
        <PageDimensionsProvider>
          <ScrollToTop>
            <HelmetProvider>
              <RoutesHandler />
            </HelmetProvider>
          </ScrollToTop>
        </PageDimensionsProvider>
      </BrowserRouter>
      <Toastr />
      <CookieConsentBanner />
    </>
  )
}

export default connector(App)
