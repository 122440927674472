export const asyncExportModalTranslations = {
  description: `Export anfordern und an die Mailadresse %{userEmail} zustellen?<br /><br />
  Die Zustellung kann bis zu einer Stunde dauern!`,
  descriptionWithFilter: `Export gemäß Filterung anfordern und an die Mailadresse %{userEmail} zustellen?<br /><br />
  Die Zustellung kann bis zu einer Stunde dauern!`,
  dsz: {
    licensingYearTitle: 'Lizenzierungsjahr',
    licensingYearPlaceholder: 'Lizenzierungsjahr das exportiert werden soll',
    licensingYearError:
      'Bitte geben Sie ein beliebiges Lizenzierungsjahr ab 2015 an',
  },
  dateForm: {
    explanation:
      'Mit den obigen Feldern kann der Zeitraum des Exports eingegrenzt' +
      ' werden. Soll die Zeit nicht eingegrenzt werden, können die Felder einfach leer gelassen werden.',
    startDateLabel: 'Frühestes %{type}',
    endDateLabel: 'Spätestes %{type}',
    fillOrOptional: 'Auswählen oder leer lassen',
    labelTypeCreateDate: 'Erstelldatum',
    labelTypeDocumentDate: 'Belegdatum',
    labelTypeDocumentReviewDate: 'Freigabedatum',
    labelTypeOrderExecutionDate: 'Leistungsdatum',
    labelTypeInvoicingDate: 'Abrechnungsdatum',
    filterNotice: 'Achtung gesetzte Filter',
  },
}
