import { isString, useFormikContext } from 'formik'
import moment from 'moment/moment'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getIndexValues } from 'actions/indexes'
import { getIndexes } from 'components/common/DetailsPage/helper'
import { DropDownInput } from 'components/common/DropDownInput'
import { InputDecimal } from 'components/common/InputDecimal'
import { RadioButton } from 'components/common/RadioButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { MONTH_PREVIOUS_CHOICES } from 'components/company/constants'
import { OFFER_ORDER_TYPE } from 'components/inquiry/constants'
import { inputIsValid } from 'components/maklerpremium/epd/AddAgreementPage/AddAgreementFormSteps/Step4/components/helpers'
import { InvoiceCheckPositionsFormStepsValues } from 'components/invoicecheck/InvoiceCheckOverview/InvoiceCheckPositionsForm/InvoiceCheckPositionsFormSteps'
import { PRICE_MODELS } from 'components/offer/constants'
import { DATE_FORMATS, PARTNER_PRICE_MODEL_CHOICES } from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector, getIndexValuesSelector } from 'selectors/indexes'

import { get_index_month_number } from '../../helper'

interface CompensationPriceFieldsProps {
  selectedOrder: OrderListItem
  isEditing?: boolean
  showRequiredDot?: boolean
  withCheckmarks?: boolean
  showPriceModelField?: boolean
}

export const SURCHARGE_REDUCTION = {
  SURCHARGE: 'surcharge',
  REDUCTION: 'reduction',
}

export const CompensationPriceFields: FC<CompensationPriceFieldsProps> = ({
  selectedOrder,
  isEditing = true,
  showRequiredDot = false,
  withCheckmarks = false,
  showPriceModelField = false,
}) => {
  const dispatch = useDispatch()
  const { errors, handleChange, submitCount, values, setFieldValue } =
    useFormikContext<InvoiceCheckPositionsFormStepsValues>()

  const fractionList = useSelector(getFractionsSelector)
  const indexList = useSelector(getIndexesSelector)
  const indexValues = useSelector(getIndexValuesSelector)

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < BREAKPOINT.XLARGE - 1,
  )

  const indexListNames = useMemo(
    () =>
      getIndexes(
        values.fraction,
        values.fine_fraction,
        fractionList,
        indexList,
      ),
    [values.fraction, values.fine_fraction, fractionList, indexList],
  )

  const surcharge_reduction = useMemo(
    () =>
      values.index_surcharge_reduction === SURCHARGE_REDUCTION.SURCHARGE
        ? values.surcharge ?? ''
        : values.reduction ?? '',
    [values.index_surcharge_reduction, values.surcharge, values.reduction],
  )

  const indexMonths = [
    {
      id: MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS,
      label: I18n.t('compensationPriceFieldsTranslations.indexMonth.options.0'), // Vormonat
    },
    {
      id: MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS_BEFORE,
      label: I18n.t('compensationPriceFieldsTranslations.indexMonth.options.1'), // Vor-Vormonat
    },
    {
      id: MONTH_PREVIOUS_CHOICES.MONTH_DELIVERY,
      label: I18n.t('compensationPriceFieldsTranslations.indexMonth.options.2'), // Leistungsmonat
    },
  ]

  const showErrorMsg = fieldErr => {
    if (submitCount > 0 && isSmallScreen && fieldErr) {
      return isString(fieldErr)
        ? fieldErr
        : `${I18n.t('disposalPriceFieldsTranslations.validation.required')}`
    }
    return ''
  }

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false

  /**
   * Get index values by service date
   */
  useEffect(() => {
    let date
    if (selectedOrder.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME) {
      if (values.collection_date) {
        date = values.collection_date
      }
    } else {
      if (values.delivery_date) {
        date = values.delivery_date
      }
    }

    let difference: number | undefined = undefined
    switch (Number(values.index_month)) {
      case MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS:
        difference = 1
        break
      case MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS_BEFORE:
        difference = 2
        break
      case MONTH_PREVIOUS_CHOICES.MONTH_DELIVERY:
        difference = 0
    }

    if (date && moment(date).isValid() && difference !== undefined) {
      const dateForIndex = moment(date, DATE_FORMATS).subtract(
        difference,
        'month',
      )
      dispatch(getIndexValues([dateForIndex.format('L')]))
    }
  }, [
    dispatch,
    values.collection_date,
    values.delivery_date,
    values.index_month,
    selectedOrder,
  ])

  /**
   * useEffect to set index value as compensation ton depending on selected index
   */
  useEffect(() => {
    const month = get_index_month_number(
      values.index_month,
      values.delivery_date,
    )

    if (indexValues.length > 0 && values.index && month) {
      const indexValue = indexValues.find(
        _indexValue =>
          _indexValue.index_object.id === Number(values.index) &&
          _indexValue.month === month + 1,
      )
      if (indexValue) {
        setFieldValue(
          'compensation_ton',
          Number(indexValue?.value ?? 0).toLocaleString('de-DE', {
            minimumFractionDigits: 2,
          }),
        )
      } else {
        setFieldValue('compensation_ton', '')
      }
    }
  }, [
    setFieldValue,
    indexValues,
    values.index,
    values.index_month,
    values.delivery_date,
    dispatch,
  ])

  useEffect(() => {
    /**
     * @description method to get window width
     */
    const handleWindowResize = () => {
      setIsSmallScreen(window.innerWidth < BREAKPOINT.XLARGE - 1)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <div className='price-detail-fields'>
      {showPriceModelField && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.priceModel')}
          value={values.price_model}
        >
          <DropDownInput
            choices={PARTNER_PRICE_MODEL_CHOICES.map(item => ({
              optionValue: item.optionValue,
              optionLabel: `${I18n.t(item.optionLabel)}`,
            }))}
            error={showErrorMsg(errors.price_model)}
            label={I18n.t(`priceDetailFieldsTranslations.priceModel`)}
            name='price_model'
            onChange={handleChange}
            value={values.price_model}
            isRequired={showRequiredDot && !values.price_model}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {values.price_model === PRICE_MODELS.COMPENSATION_SINGLE && (
        <>
          {indexListNames.length > 0 ? (
            /* INDEX */
            <DropDownInput
              dataTestId='index-input'
              choices={indexListNames.map(indexOption => ({
                optionValue: indexOption.id,
                optionLabel: indexOption.name,
              }))}
              isPlaceholderClickable
              label={I18n.t(`compensationPriceFieldsTranslations.index.label`)}
              name={'index'}
              onChange={e => {
                setFieldValue('compensation_ton', '')
                if (Number(e.target.value) === 0) {
                  setFieldValue('index_month', '')
                  setFieldValue('surcharge', '')
                  setFieldValue('reduction', '')
                } else {
                  setFieldValue('index_month', '0')
                }
                handleChange(e)
              }}
              placeholder={I18n.t(
                `compensationPriceFieldsTranslations.index.noIndex`,
              )}
              value={values.index}
              withCheckmark={withCheckmarks}
              showCheckmark={!errors.index ?? false}
            />
          ) : (
            <ReadOnlyField
              label={I18n.t(`compensationPriceFieldsTranslations.index.label`)}
              value={I18n.t(
                `compensationPriceFieldsTranslations.index.noValues`,
              )}
            />
          )}
        </>
      )}
      {values.index && values.index > 0 && (
        <DropDownInput
          choices={indexMonths.map(indexOption => ({
            optionValue: indexOption.id,
            optionLabel: indexOption.label,
          }))}
          isPlaceholderClickable
          label={I18n.t(`compensationPriceFieldsTranslations.indexMonth.label`)}
          name={`index_month`}
          onChange={e => {
            setFieldValue('compensation_ton', '')
            handleChange(e)
          }}
          value={values.index_month}
          withCheckmark={withCheckmarks}
          showCheckmark={isFieldValid}
        />
      )}
      {values?.price_model === PRICE_MODELS.COMPENSATION_CONTAINER && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t(
            'compensationPriceFieldsTranslations.compensationContainer',
          )}
          value={values.compensation_container}
        >
          <InputDecimal
            dataTestId='compensation-container-input'
            error={showErrorMsg(errors.compensation_container)}
            label={I18n.t(
              'compensationPriceFieldsTranslations.compensationContainer',
            )}
            maxValue={5000}
            name='compensation_container'
            onChange={handleChange}
            placeholder={I18n.t(
              'compensationPriceFieldsTranslations.pricePlaceholder',
            )}
            value={values.compensation_container}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.compensation_container}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {values?.price_model === PRICE_MODELS.COMPENSATION_SINGLE && (
        /* COMPENSATION PER TON */
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('compensationPriceFieldsTranslations.compensationTon')}
          value={values.compensation_ton}
        >
          <InputDecimal
            dataTestId='compensation-ton-input'
            error={showErrorMsg(errors.compensation_ton)}
            label={I18n.t(
              'compensationPriceFieldsTranslations.compensationTon',
            )}
            maxValue={5000}
            name='compensation_ton'
            onChange={handleChange}
            placeholder={I18n.t(
              'compensationPriceFieldsTranslations.compensationTon',
            )}
            value={values.compensation_ton}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.compensation_ton}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}

      {values.index && values.index > 0 && (
        <>
          {/* SURCHARGE / REDUCTION */}
          <InputDecimal
            name={`${values.index_surcharge_reduction}`}
            placeholder={I18n.t(
              `compensationPriceFieldsTranslations.${values.index_surcharge_reduction}.placeholder`,
            )}
            value={`${surcharge_reduction}`}
            maxValue={999.99}
            onChange={e => inputIsValid(e.target.value) && handleChange(e)}
            label={
              <div>
                {/* Zuschlag */}
                <RadioButton
                  isChecked={
                    values.index_surcharge_reduction ===
                    SURCHARGE_REDUCTION.SURCHARGE
                  }
                  label={I18n.t(
                    `compensationPriceFieldsTranslations.surcharge.label`,
                  )}
                  name={`compensation-index-surcharge`}
                  onChange={(event: React.ChangeEvent<any>) => {
                    if (event.target.value === 'on') {
                      setFieldValue(
                        `index_surcharge_reduction`,
                        SURCHARGE_REDUCTION.SURCHARGE,
                      )
                      setFieldValue(`surcharge`, '')
                      setFieldValue(`reduction`, '')
                    }
                  }}
                />
                {/* Abschlag */}
                <RadioButton
                  isChecked={
                    values.index_surcharge_reduction ===
                    SURCHARGE_REDUCTION.REDUCTION
                  }
                  label={I18n.t(
                    `compensationPriceFieldsTranslations.reduction.label`,
                  )}
                  name={`compensation-index-reduction`}
                  onChange={(event: React.ChangeEvent<any>) => {
                    if (event.target.value === 'on') {
                      setFieldValue(
                        `index_surcharge_reduction`,
                        SURCHARGE_REDUCTION.REDUCTION,
                      )
                      setFieldValue(`reduction`, '')
                      setFieldValue(`surcharge`, '')
                    }
                  }}
                />
              </div>
            }
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </>
      )}
    </div>
  )
}
