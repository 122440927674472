import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import { getAddressListForCompanySelector } from 'selectors/address'
import { getCompanyFormSelector, getCompanySelector } from 'selectors/company'
import { getCountriesSelector } from 'selectors/country'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  countries: getCountriesSelector(state),
  company: getCompanySelector(state),
  companyForm: getCompanyFormSelector(state),
  companyAddresses: getAddressListForCompanySelector(state),
  reset: false,
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => {
  return {
    changeFormValue: (model, value) => dispatch(actions.change(model, value)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)
