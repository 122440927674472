import React, { FC } from 'react'

import PageHeader from '../../layout/PageHeader'
import Paragraph, { PARAGRAPH_MARGIN } from '../Paragraph'

import {
  BreadcrumbNavigation,
  BreadcrumbNavigationProps,
} from './BreadcrumbNavigation'

interface BreadcrumbTeaserProps extends BreadcrumbNavigationProps {
  teaserText: React.ReactNode
  teaserTitle: React.ReactNode
}

export const BreadcrumbTeaser: FC<BreadcrumbTeaserProps> = ({
  breadcrumbTitle,
  prevLinkTitle,
  prevLinkTo,
  teaserText,
  teaserTitle,
}) => (
  <>
    <BreadcrumbNavigation
      breadcrumbTitle={breadcrumbTitle}
      prevLinkTitle={prevLinkTitle}
      prevLinkTo={prevLinkTo}
    />
    <PageHeader title={teaserTitle} />
    <Paragraph margin={PARAGRAPH_MARGIN.TOP_NONE}>{teaserText}</Paragraph>
  </>
)
