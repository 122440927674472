/**
 * @description selector to get invoiceCheck list.
 * @param state
 * @return {*}
 */

export const getInvoiceChecksSelector: (
  state: any,
) => InvoiceCheck[] | InvoiceCheckTableListItem[] = state =>
  state.invoiceCheck.invoiceChecks.list

export const getInvoiceCheckPaginationSelector: (
  state: any,
) => Pagination = state => state.invoiceCheck.invoiceChecks.pagination

export const getInvoiceCheckSelector: (state: any) => InvoiceCheck = state =>
  state.invoiceCheck.invoiceCheck

export const getInvoiceCheckErrorSelector: (state: any) => string = state =>
  state.invoiceCheck.invoiceCheckError

export const getInvoiceCheckPositionSelector: (
  state: any,
) => InvoiceCheckPosition = state => state.invoiceCheck.invoiceCheckPosition

export const getInvoiceCheckPositionsSelector: (
  state: any,
) => InvoiceCheckPosition[] = state =>
  state.invoiceCheck.invoiceCheckPositions.list

export const getInvoiceCheckPositionPaginationSelector: (
  state: any,
) => Pagination = state => state.invoiceCheck.invoiceCheckPositions.pagination

export const getInvoiceCheckPositionReviewSelector: (state: any) => {
  loaded: boolean
  item: InvoiceCheckPositionReview
} = state => state.invoiceCheck.invoiceCheckPosition

export const getInvoiceCheckPositionNetPriceSelector: (
  state: any,
) => number | undefined = state =>
  state.invoiceCheck.invoiceCheckPositionNetPrice
