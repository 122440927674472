import { useFormikContext } from 'formik'
import React, { useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getMaklerPremiumCompanies } from 'actions/company'
import { getFieldError } from 'components/maklerpremium/CommunicationCenterPage/helper'
import { NewEntryValues } from 'components/maklerpremium/CommunicationCenterPage/types'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import { ComCenterContactFormContext } from '../index'

/**
 * @description This component displays the stage 3 part of the operations within the main modal form of the communication center page
 */
export const CategoryOperationsStage3Form = () => {
  const {
    values,
    submitCount,
    setFieldValue,
    errors,
    validateForm,
    handleChange,
  } = useFormikContext<NewEntryValues>()

  const formikContext = {
    values,
    errors,
    submitCount,
    validateForm,
    handleChange,
    setFieldValue,
  }

  const { comCenterContact } = useContext(ComCenterContactFormContext)

  const dispatch = useDispatch()

  useEffect(() => {
    if (comCenterContact && values.customer) {
      dispatch(
        getMaklerPremiumCompanies(null, {
          id_slug: `${values.customer},${comCenterContact.customer_slug}`,
        }),
      )
    }
  }, [dispatch, comCenterContact, values.customer])

  return (
    <>
      {/*prettier-ignore*/}
      <Select
        urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/makler-premium-companies/?company_name=`}
        urlPart2='&maklerpremium_role=1&page_size=25&block_auto_offers=false&is_active=true&page=1'
        getValue={(e) => e.id}
        getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number}`}
        label={I18n.t('communicationCenterPageTranslations.fields.customer.label')}
        setValue={e => {setFieldValue('customer', e?.value ? e.value : '')}}
        selectedValue={values?.customer}
        error={getFieldError('customer', formikContext)}
        selecting={'company'}
        withCheckmark
      />
    </>
  )
}
