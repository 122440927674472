import './style.scss'

import { Form, Formik, FormikErrors } from 'formik'
import React, { createContext, FC, ReactNode, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as yup from 'yup'

import { uploadFiles } from 'actions/attachment'
import { createCustomAgreement } from 'actions/maklerpremium'
import { FormStep } from 'components/common/FormSteps'
import { COMPANY_STATUS } from 'components/company/constants'
import {
  progressStepStatus,
  STEP_STATUS,
} from 'components/common/FormSteps/helper'
import { UploadItem } from 'components/common/UniversalFileUpload/types'
import {
  OFFER_ORDER_TYPE,
  OFFER_SERVICE_INTERVAL,
} from 'components/inquiry/constants'
import { WEEKDAY } from 'constants/app'
import { getAddAgreementCustomerCompanySelector } from 'selectors/company'
import { getFractionsSelector } from 'selectors/fraction'

import { SelectCustomerAndPartnerForm } from './Step1/SelectCustomerAndPartnerForm'
import { SelectCustomerAndPartnerSummary } from './Step1/SelectCustomerAndPartnerSummary'
import { SelectAddressContainerFractionAvvForm } from './Step2/SelectAddressContainerFractionAvvForm'
import { SelectAddressContainerFractionAvvSummary } from './Step2/SelectAddressContainerFractionAvvSummary'
import { SelectAgreementDetailsForm } from './Step3/SelectAgreementDetailsForm'
import { SelectAgreementDetailsSummary } from './Step3/SelectAgreementDetailsSummary'
import { SURCHARGE_REDUCTION } from './Step4/components/SinglePriceFormFields'
import {
  BILLING_MODEL,
  DefineNetPriceForm,
  PRICING,
} from './Step4/DefineNetPriceForm'
import { DefineNetPriceSummary } from './Step4/DefineNetPriceSummary'
import { CommentAndImagesForm } from './Step5/CommentAndImagesForm'

interface AddAgreementContextType {
  stepStatus: STEP_STATUS
  saveStep: () => void
  editStep: () => void
}

export const AddAgreementFormContext = createContext<AddAgreementContextType>({
  stepStatus: STEP_STATUS.INITIAL,
  saveStep: () => undefined,
  editStep: () => undefined,
})

export type AddAgreementFormValues = {
  // Step 1
  customer: {
    // customer search fields
    search_field: string
    selected_company?: Company
    company_name?: string
    empto_external_number?: number
    zipcode?: number
    only_active?: boolean
  }
  // partner search fields
  partner: {
    search_field: string
    selected_company?: Company
    company_name?: string
    empto_external_number?: number
    zipcode?: number
    only_active?: boolean
  }

  // Step 2
  collection_address?: number
  fraction?: number
  fine_fraction?: number
  fraction_object?: Fraction
  avv?: number
  avv_object?: Avv
  security_group?: number
  container?: number
  number_of_containers?: number
  position_on_public_ground?: boolean

  // Step 3
  order_type?: OFFER_ORDER_TYPE
  delivery_date?: string
  collection_date?: string
  time_of_day_delivery?: number
  time_of_day_collection?: number
  rythm_begin?: string
  turn_begin?: string
  turn_end?: string
  interval?: OFFER_SERVICE_INTERVAL
  interval_weekday_first?: WEEKDAY
  interval_weekday_second?: WEEKDAY

  // Step 4
  customer_pricing: string // only required for form validation
  customer_billing_model: string
  customer_rent_price?: string
  customer_transport_price?: string
  customer_disposal_cost_ton?: string
  customer_disposal_cost_container?: string
  customer_index?: string
  customer_index_surcharge_reduction: string // only required for form validation
  customer_index_month?: number
  customer_surcharge?: string
  customer_reduction?: string
  customer_compensation_ton?: string
  customer_handle_cost_ton?: string
  customer_cost_container?: string
  customer_compensation_container?: string
  customer_package_price?: string
  partner_pricing: string // only required for form validation
  partner_billing_model: string
  partner_rent_price?: string
  partner_transport_price?: string
  partner_disposal_cost_ton?: string
  partner_disposal_cost_container?: string
  partner_index?: string
  partner_index_month?: number
  partner_index_surcharge_reduction: string
  partner_surcharge?: string
  partner_reduction?: string
  partner_compensation_ton?: string
  partner_handle_cost_ton?: string
  partner_cost_container?: string
  partner_compensation_container?: string

  // Step 5
  comment: string
  attachments: UploadItem[]
}

export const step3validationSchema = yup.object().shape({
  order_type: yup.string().required(),
  delivery_date: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  collection_date: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  time_of_day_delivery: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  time_of_day_collection: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  rythm_begin: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  turn_begin: yup.string().when('order_type', {
    is: order_type =>
      Number(order_type) === OFFER_ORDER_TYPE.TYPE_ON_DEMAND ||
      Number(order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  turn_end: yup.string().when('order_type', {
    is: order_type =>
      Number(order_type) === OFFER_ORDER_TYPE.TYPE_ON_DEMAND ||
      Number(order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  interval: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    then: yup
      .string()
      .oneOf(Object.values(OFFER_SERVICE_INTERVAL).map(String))
      .required(),
    otherwise: yup.string(),
  }),
  interval_weekday_first: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    then: yup.string().oneOf(Object.values(WEEKDAY).map(String)).required(),
    otherwise: yup.string(),
  }),
  interval_weekday_second: yup.string().when('order_type', {
    is: order_type => Number(order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    then: yup.string().when('interval', {
      is: interval =>
        Number(interval) === OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK,
      then: yup.string().oneOf(Object.values(WEEKDAY).map(String)).required(),
      otherwise: yup.string(),
    }),
    otherwise: yup.string(),
  }),
})

export const step4validationSchema = fractionList =>
  yup.object().shape({
    // CustomerPrices
    customer_billing_model: yup.string().required(),
    // customer_pricing: yup.string().required(),
    customer_pricing: yup.string().when(['fraction', 'fine_fraction'], {
      is: (fraction, fine_fraction) => {
        const selectedFraction = fractionList.find(
          item => item.id === Number(fine_fraction ? fine_fraction : fraction),
        )

        return !selectedFraction?.allow_price_model_compensation
      },
      then: yup
        .string()
        .required()
        .matches(/^disposal/),
      otherwise: yup.string().required(),
    }),
    customer_rent_price: yup.string().when('customer_billing_model', {
      is: customer_billing_model =>
        customer_billing_model === BILLING_MODEL.SINGLE_PRICE ||
        customer_billing_model === BILLING_MODEL.CONTAINER_PRICE,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    customer_transport_price: yup.string().when('customer_billing_model', {
      is: customer_billing_model =>
        customer_billing_model === BILLING_MODEL.SINGLE_PRICE,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    customer_disposal_cost_ton: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing'], {
        is: (customer_billing_model, customer_pricing) =>
          customer_billing_model === BILLING_MODEL.SINGLE_PRICE &&
          customer_pricing === PRICING.DISPOSAL,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    customer_disposal_cost_container: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing'], {
        is: (customer_billing_model, customer_pricing) =>
          customer_billing_model === BILLING_MODEL.CONTAINER_PRICE &&
          customer_pricing === PRICING.DISPOSAL,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    customer_package_price: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing'], {
        is: (customer_billing_model, customer_pricing) =>
          customer_billing_model === BILLING_MODEL.PACKAGE_PRICE &&
          customer_pricing === PRICING.DISPOSAL,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    customer_cost_container: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing'], {
        is: (customer_billing_model, customer_pricing) =>
          customer_billing_model === BILLING_MODEL.PACKAGE_PRICE &&
          customer_pricing === PRICING.COMPENSATION,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    customer_compensation_container: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing'], {
        is: (customer_billing_model, customer_pricing) =>
          customer_billing_model === BILLING_MODEL.PACKAGE_PRICE &&
          customer_pricing === PRICING.COMPENSATION,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    customer_index: yup.string(),
    customer_index_month: yup.number().min(0).max(2),
    customer_surcharge: yup
      .string()
      .when(
        [
          'customer_billing_model',
          'customer_pricing',
          'customer_index',
          'customer_index_surcharge_reduction',
        ],
        {
          is: (
            customer_billing_model,
            customer_pricing,
            customer_index,
            customer_index_surcharge_reduction,
          ) =>
            customer_billing_model === BILLING_MODEL.SINGLE_PRICE &&
            customer_pricing === PRICING.COMPENSATION &&
            Number(customer_index) > 0 &&
            customer_index_surcharge_reduction ===
              SURCHARGE_REDUCTION.SURCHARGE,
          then: yup.string().required(),
          otherwise: yup.string(),
        },
      ),
    customer_reduction: yup
      .string()
      .when(
        [
          'customer_billing_model',
          'customer_pricing',
          'customer_index',
          'customer_index_surcharge_reduction',
        ],
        {
          is: (
            customer_billing_model,
            customer_pricing,
            customer_index,
            customer_index_surcharge_reduction,
          ) =>
            customer_billing_model === BILLING_MODEL.SINGLE_PRICE &&
            customer_pricing === PRICING.COMPENSATION &&
            Number(customer_index) > 0 &&
            customer_index_surcharge_reduction ===
              SURCHARGE_REDUCTION.REDUCTION,
          then: yup.string().required(),
          otherwise: yup.string(),
        },
      ),
    customer_compensation_ton: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing', 'customer_index'], {
        is: (customer_billing_model, customer_pricing, customer_index) =>
          customer_billing_model === BILLING_MODEL.SINGLE_PRICE &&
          customer_pricing === PRICING.COMPENSATION &&
          (isNaN(Number(customer_index)) || Number(customer_index) === 0),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    customer_handle_cost_ton: yup
      .string()
      .when(['customer_billing_model', 'customer_pricing'], {
        is: (customer_billing_model, customer_pricing) =>
          customer_billing_model === BILLING_MODEL.SINGLE_PRICE &&
          customer_pricing === PRICING.COMPENSATION,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),

    // PartnerPrices
    partner_billing_model: yup.string().required(),
    partner_pricing: yup.string().required(),
    partner_rent_price: yup.string().when('partner_billing_model', {
      is: partner_billing_model =>
        partner_billing_model === BILLING_MODEL.SINGLE_PRICE,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    partner_transport_price: yup.string().when('partner_billing_model', {
      is: partner_billing_model =>
        partner_billing_model === BILLING_MODEL.SINGLE_PRICE,
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    partner_disposal_cost_ton: yup
      .string()
      .when(['partner_billing_model', 'partner_pricing'], {
        is: (partner_billing_model, partner_pricing) =>
          partner_billing_model === BILLING_MODEL.SINGLE_PRICE &&
          partner_pricing === PRICING.DISPOSAL,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    partner_disposal_cost_container: yup
      .string()
      .when(['partner_billing_model', 'partner_pricing'], {
        is: (partner_billing_model, partner_pricing) =>
          partner_billing_model === BILLING_MODEL.CONTAINER_PRICE &&
          partner_pricing === PRICING.DISPOSAL,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    partner_cost_container: yup
      .string()
      .when(['partner_billing_model', 'partner_pricing'], {
        is: (partner_billing_model, partner_pricing) =>
          partner_billing_model === BILLING_MODEL.CONTAINER_PRICE &&
          partner_pricing === PRICING.COMPENSATION,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    partner_compensation_container: yup
      .string()
      .when(['partner_billing_model', 'partner_pricing'], {
        is: (partner_billing_model, partner_pricing) =>
          partner_billing_model === BILLING_MODEL.CONTAINER_PRICE &&
          partner_pricing === PRICING.COMPENSATION,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    partner_index: yup.string(),
    partner_index_month: yup.number().min(0).max(2),
    partner_surcharge: yup
      .string()
      .when(
        [
          'partner_billing_model',
          'partner_pricing',
          'partner_index',
          'partner_index_surcharge_reduction',
        ],
        {
          is: (
            partner_billing_model,
            partner_pricing,
            partner_index,
            partner_index_surcharge_reduction,
          ) =>
            partner_billing_model === BILLING_MODEL.SINGLE_PRICE &&
            partner_pricing === PRICING.COMPENSATION &&
            Number(partner_index) > 0 &&
            partner_index_surcharge_reduction === SURCHARGE_REDUCTION.SURCHARGE,
          then: yup.string().required(),
          otherwise: yup.string(),
        },
      ),
    partner_reduction: yup
      .string()
      .when(
        [
          'partner_billing_model',
          'partner_pricing',
          'partner_index',
          'partner_index_surcharge_reduction',
        ],
        {
          is: (
            partner_billing_model,
            partner_pricing,
            partner_index,
            partner_index_surcharge_reduction,
          ) =>
            partner_billing_model === BILLING_MODEL.SINGLE_PRICE &&
            partner_pricing === PRICING.COMPENSATION &&
            Number(partner_index) > 0 &&
            partner_index_surcharge_reduction === SURCHARGE_REDUCTION.REDUCTION,
          then: yup.string().required(),
          otherwise: yup.string(),
        },
      ),
    partner_compensation_ton: yup
      .string()
      .when(['partner_billing_model', 'partner_pricing', 'partner_index'], {
        is: (partner_billing_model, partner_pricing, partner_index) =>
          partner_billing_model === BILLING_MODEL.SINGLE_PRICE &&
          partner_pricing === PRICING.COMPENSATION &&
          (isNaN(Number(partner_index)) || Number(partner_index) === 0),
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    partner_handle_cost_ton: yup
      .string()
      .when(['partner_billing_model', 'partner_pricing'], {
        is: (partner_billing_model, partner_pricing) =>
          partner_billing_model === BILLING_MODEL.SINGLE_PRICE &&
          partner_pricing === PRICING.COMPENSATION,
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
  })

export const AddAgreementFormSteps: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const company = useSelector(getAddAgreementCustomerCompanySelector)
  const fractionList = useSelector(getFractionsSelector)

  const initialValues = {
    customer: {
      search_field: 'company_name',
      only_active: false,
      company_name: '',
      empto_external_number: undefined,
      zipcode: undefined,
      selected_company: undefined,
    },
    partner: {
      search_field: 'company_name',
      only_active: true,
      company_name: '',
      empto_external_number: undefined,
      zipcode: undefined,
      selected_company: undefined,
    },

    // Step 2
    collection_address: undefined,
    fraction: undefined,
    fine_fraction: undefined,
    avv: undefined,
    security_group: undefined,
    container: undefined,
    number_of_containers: 1,
    position_on_public_ground: false,

    // Step 3
    order_type: undefined,
    delivery_date: undefined,
    collection_date: undefined,
    time_of_day_delivery: undefined,
    time_of_day_collection: undefined,
    rythm_begin: undefined,
    turn_begin: undefined,
    turn_end: undefined,
    interval: undefined,
    interval_weekday_first: undefined,
    interval_weekday_second: undefined,

    // Step 4
    customer_pricing: PRICING.DISPOSAL,
    customer_billing_model: BILLING_MODEL.PACKAGE_PRICE,
    customer_rent_price: undefined,
    customer_transport_price: undefined,
    customer_disposal_cost_ton: undefined,
    customer_disposal_cost_container: undefined,
    customer_cost_container: undefined,
    customer_index: undefined,
    customer_index_month: undefined,
    customer_index_surcharge_reduction: SURCHARGE_REDUCTION.SURCHARGE,
    customer_surcharge: undefined,
    customer_reduction: undefined,
    customer_compensation_ton: undefined,
    customer_handle_cost_ton: undefined,
    customer_compensation_container: undefined,
    customer_package_price: undefined,
    partner_pricing: PRICING.DISPOSAL,
    partner_billing_model: BILLING_MODEL.CONTAINER_PRICE,
    partner_rent_price: undefined,
    partner_transport_price: undefined,
    partner_disposal_cost_ton: undefined,
    partner_disposal_cost_container: undefined,
    partner_index: undefined,
    partner_index_month: undefined,
    partner_index_surcharge_reduction: SURCHARGE_REDUCTION.SURCHARGE,
    partner_surcharge: undefined,
    partner_reduction: undefined,
    partner_compensation_ton: undefined,
    partner_handle_cost_ton: undefined,
    partner_cost_container: undefined,
    partner_compensation_container: undefined,

    // Step 5
    comment: '',
    attachments: [],
  }

  const validationSchema = yup
    .object()
    .shape({
      // Step 1
      // CustomerInfo
      customer: yup.object().shape({
        search_field: yup.string(),
        company_name: yup.string(),
        empto_external_number: yup.number(),
        zipcode: yup.string(),
      }),

      // PartnerInfo
      partner: yup.object().shape({
        search_field: yup.string(),
        company_name: yup.string(),
        empto_external_number: yup.number(),
        zipcode: yup.string(),
      }),

      // Step 2
      collection_address: yup.number().required(),
      fraction: yup.string().required(),
      fine_fraction: yup.number(),
      avv: yup.number().required(),
      security_group: yup.number(),
      container: yup.number().required(),
      number_of_containers: yup.number().required(),
      position_on_public_ground: yup.string().required(),

      // Step 3 is concatenated below

      // Step 4 is concatenated below

      //Step 5
      comment: yup.string(),
      attachments: company?.custom_agreement_optional_certificate
        ? yup.array().min(0).max(3)
        : yup.array().min(1).max(3).required(),
    })
    .concat(step3validationSchema)
    .concat(step4validationSchema(fractionList))

  const [stepsStatus, setStepsStatus] = useState<STEP_STATUS[]>([
    STEP_STATUS.COMPLETED,
    STEP_STATUS.CREATING,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
  ])

  const formSteps = {
    // Step 1: SelectCustomerAndPartner
    1: {
      form: <SelectCustomerAndPartnerForm />,
      summary: <SelectCustomerAndPartnerSummary />,
    },
    // Step 2: SelectAddressContainerFractionAvv
    2: {
      form: <SelectAddressContainerFractionAvvForm />,
      summary: <SelectAddressContainerFractionAvvSummary />,
    },
    // Step 3: SelectAgreementDetails
    3: {
      form: <SelectAgreementDetailsForm />,
      summary: <SelectAgreementDetailsSummary />,
    },
    // Step 4: DefineNetPrice
    4: {
      form: <DefineNetPriceForm />,
      summary: <DefineNetPriceSummary />,
    },
    // Step 5: CommentAndImages
    5: {
      form: <CommentAndImagesForm />,
      summary: <></>,
    },
  }

  function renderStep(
    index: string,
    step: { summary: ReactNode; form: ReactNode },
  ) {
    return stepsStatus[Number(index)] > STEP_STATUS.INITIAL &&
      stepsStatus[Number(index)] === STEP_STATUS.COMPLETED
      ? step.summary
      : step.form
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: AddAgreementFormValues) => {
        const uploadCallbacks = {
          onDocumentSubmit: attachments => {
            dispatch(createCustomAgreement({ ...values, attachments }, history))
          },
        }
        if (values.attachments) {
          dispatch(
            uploadFiles(
              values.attachments.map(attachment => {
                return {
                  file: attachment.data,
                  text: attachment.text,
                }
              }),
              uploadCallbacks,
            ),
          )
        }
      }}
      validate={(values: AddAgreementFormValues) => {
        const errors: FormikErrors<AddAgreementFormValues> = {}

        if (
          values.customer.selected_company?.status &&
          values.customer.selected_company?.status !==
            COMPANY_STATUS.STATUS_ACTIVE
        ) {
          errors.customer = {
            ...errors.customer,
            selected_company: I18n.t(
              'addAgreementPageTranslations.steps.1.fields.customer.blocked_customer',
            ),
          }
        }
        return errors
      }}
    >
      <Form>
        {Object.entries(formSteps).map(([index, step]) => (
          <FormStep
            key={`step_${index}`}
            isDisabled={stepsStatus[Number(index) - 1] < STEP_STATUS.COMPLETED}
            showDivider
            showProgressLine
            stepNumber={
              stepsStatus[Number(index)] < STEP_STATUS.COMPLETED &&
              Number(index)
            }
            title={`addAgreementPageTranslations.steps.${index}.title`}
          >
            <AddAgreementFormContext.Provider
              value={{
                stepStatus: stepsStatus[Number(index)],
                saveStep: () => {
                  progressStepStatus(stepsStatus, setStepsStatus, Number(index))
                },
                editStep: () => {
                  progressStepStatus(stepsStatus, setStepsStatus, Number(index))
                },
              }}
            >
              {renderStep(index, step)}
            </AddAgreementFormContext.Provider>
          </FormStep>
        ))}
      </Form>
    </Formik>
  )
}
