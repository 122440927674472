export const addAgreementPageTranslations = {
  header: {
    title: 'Vereinbarung erstellen',
    description:
      'Hier können Sie manuell eine Vereinbarung zwischen einem Kunden und ' +
      'einem Partner erstellen. Bitte füllen Sie alle erforderlichen Felder ' +
      'aus.',
  },
  steps: {
    1: {
      title: 'Kunde und Partner auswählen',
      fields: {
        // customer search fields
        customer_title: 'Kunde',
        customer_search_field: {
          label: 'Suchfeld auswählen',
          placeholder: 'Suchfeld',
        },
        customer: {
          company_name: {
            label: 'Name des Kunden',
            noOptionsMessage: 'Kein Kunde mit diesem Namen gefunden.',
            placeholder: 'Name',
            error: 'Ungültiger oder leerer Name',
          },
          empto_external_number: {
            label: 'Kundennummer',
            noOptionsMessage: 'Kein Kunde mit dieser Kundennummer gefunden.',
            placeholder: 'Kundennummer',
            error: 'Ungültige oder leere Kundennummer',
          },
          zipcode: {
            label: 'Postleitzahl',
            noOptionsMessage: 'Kein Kunde mit dieser Postleitzahl gefunden.',
            placeholder: 'Postleitzahl',
            error: 'Ungültige oder leere Postleitzahl',
          },
          blocked_customer: {
            error: 'Kunde ist gesperrt.',
          },
        },
        // partner search fields
        partner_title: 'Partner',
        partner_search_field: {
          label: 'Suchfeld auswählen',
          placeholder: 'Suchfeld',
        },
        partner: {
          company_name: {
            label: 'Name des Partners',
            noOptionsMessage: 'Kein Partner mit diesem Namen gefunden.',
            placeholder: 'Name',
            error: 'Ungültiger oder leerer Name',
          },
          empto_external_number: {
            label: 'Kundennummer',
            noOptionsMessage: 'Kein Partner mit dieser Kundennummer gefunden.',
            placeholder: 'Kundennummer',
            error: 'Ungültige oder leere Kundennummer',
          },
          zipcode: {
            label: 'Postleitzahl',
            noOptionsMessage: 'Kein Partner mit dieser Postleitzahl gefunden.',
            placeholder: 'Postleitzahl',
            error: 'Ungültige oder leere Postleitzahl',
          },
        },
      },
    },
    2: {
      title: 'Leistungsadresse, Abfallart & Behälter auswählen',
      fields: {
        address: {
          label: 'Leistungsaddresse',
          subLabel: 'Wo soll der Abfall abgeholt werden?',
          noInputResults:
            'Zu Ihrer Suchanfrage wurde keine Leistungsadresse gefunden',
          placeholder: 'Leistungsadresse',
        },
      },
    },
    3: {
      title: 'Auftragsart und Termine festlegen',
      dismissableInfo:
        'Der Entsorger wird direkt beauftragt. Der Kunde zahlt diesen Auftrag auf Rechnung.',
    },
    4: {
      title: 'Nettopreise festlegen',
      fields: {
        pricing: {
          label: 'Preismodell',
          disposal: 'Entsorgungskosten',
          compensation: 'Verwertungspreis',
        },
        billingModel: {
          label: 'Abrechnungsmodell',
          packagePrice: 'Pauschalpreis',
          singlePrice: 'Einzelpreis',
          containerPrice: 'Behälterpreis',
        },
        rentPrice: {
          label: 'Mietpreis (€ pro Behälter und Monat)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
        transportPrice: {
          label: 'Transportpreis (€ pro Stück)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
        disposalPrice: {
          label: 'Entsorgungskosten (€ pro Tonne)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        containerDisposalPrice: {
          label: 'Transport- und Entsorgungspreis (€ pro Behälter)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        packagePrice: {
          // only used for customer!
          label: 'Pauschalpreis (€ pro Behälter)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        disposalCostContainer: {
          label: 'Kosten (€ pro Behälter)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        costContainer: {
          label: 'Kosten (€ pro Behälter)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        compensationContainer: {
          label: 'Vergütung (€ pro Behälter)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        rentPriceContainerMonth: {
          // only used for partner!
          label: 'Mietpreis (€ pro Behälter und Monat)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
        packageRentPrice: {
          // only used for partner!
          label: 'Mietpreis (€ pro Behälter und Monat)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
        packageTransportAndDisposalPrice: {
          // only used for partner!
          label: 'Transport- und Entsorgungspreis (€ pro Behälter)',
          placeholder: 'Wert zwischen 0 € und 4999,99 €',
        },
        index: {
          label: 'Index',
          noValues: 'Kein Index',
        },
        indexMonth: {
          label: 'relevanter Monat für Indexwert',
          placeholder: 'Vormonat',
        },
        surcharge: {
          label: 'Zuschlag (€ pro Tonne)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
        reduction: {
          label: 'Abschlag (€ pro Tonne)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
        compensationTon: {
          label: 'Vergütung (€ pro Tonne)',
          placeholder: 'Wert zwischen 0 € und 1999,99 €',
        },
        handleCostTon: {
          label: 'Handlingkosten (€ pro Tonne)',
          placeholder: 'Wert zwischen 0 € und 999,99 €',
        },
      },
      summary_customer_title: 'Preise des Kunden ',
      summary_partner_title: 'Preise des Partners ',
    },
    5: {
      title: 'Schriftverkehr und sonstige Absprachen protokollieren',
      fields: {
        comment: {
          label:
            'Optional: Inhaltliche Ergänzung (wird in E-Mails an Kunde und Partner angezeigt!)',
          placeholder: 'Besondere Hinweise',
        },
        attachments: {
          label: 'Schriftverkehr mit Kunde & Entsorger',
          sublabel: '(Hier können Sie bis zu drei Dateien hochladen)',
        },
      },
    },
  },
  saveAgreement: 'Vereinbarung erstellen',
}
