import './style.scss'

import moment from 'moment'
import React from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import { getName } from '../../helper/translations'
import { CERTIFICATE_STATUS, CERTIFICATE_TYPES } from '../certificate/constants'
import Icon from '../common/Fontello'
import { PaginatedTable } from '../common/PaginatedTable'
import { UpDownArrow } from '../common/UpDownArrow'
import UserLink from '../common/UserLink'
import { EXECUTION_PROOF_STATES } from '../executionproof/constants'
import { INVOICE_CHECK_STATES } from '../invoicecheck/constants'
import {
  WORK_LOAD_CATEGORIES,
  WORK_LOAD_CATEGORY,
} from '../workload/WorkloadPage/constants'

import { PROOF_TYPE } from './constants'

export const ProofsList = ({
  apiError,
  isLoading,
  isReviewButtonVisible,
  onFetchData,
  onNextPageClick,
  onPreviousPageClick,
  page,
  pages,
  proofsList,
  proofsType,
}) => {
  const orderIdColumn =
    proofsType === PROOF_TYPE.CLAIM_PROOF ||
    proofsType === PROOF_TYPE.EXECUTION_PROOF ||
    proofsType === PROOF_TYPE.WORKLOAD
      ? [
          {
            id: proofsType === PROOF_TYPE.WORKLOAD ? 'order__id' : 'order_id',
            Header: (
              <span>
                <Translate value='certificateArchive.table.order' />
                <UpDownArrow />
              </span>
            ),
            accessor: function (data) {
              if (
                data.workload_type &&
                data.workload_type.includes(WORK_LOAD_CATEGORY.EPD_ROTATION)
              ) {
                return (
                  <Link to={`/agreement/${data.id}`}>{`V: ${data.id}`}</Link>
                )
              }
              if (data.order && Number(data.order) !== 0) {
                return <Link to={`/order/${data.order}`}>{data.order}</Link>
              }
            },
            width: 100,
          },
        ]
      : []

  const documentNumberColumns =
    proofsType === PROOF_TYPE.INVOICE_CHECK
      ? [
          {
            id: 'document_number',
            Header: (
              <span>
                <Translate value='invoiceCheckTranslations.table.documentNumber' />
                <UpDownArrow />
              </span>
            ),
            width: 150,
            accessor: data => data.document_number,
          },
        ]
      : []

  const partnerCompanyColumn =
    proofsType === PROOF_TYPE.INVOICE_CHECK
      ? [
          {
            id: 'company',
            Header: (
              <span>
                <Translate value='invoiceCheckTranslations.table.partnerCompany' />
                <UpDownArrow />
              </span>
            ),
            accessor: data =>
              data.company_object && (
                <Link to={`/company/${data.company_object.id}`}>
                  {data.company_object.name}
                </Link>
              ),
          },
        ]
      : []

  const grossPriceColumn =
    proofsType === PROOF_TYPE.INVOICE_CHECK
      ? [
          {
            id: 'gross_price',
            Header: (
              <span>
                <Translate value='invoiceCheckTranslations.table.grossPrice' />
                <UpDownArrow />
              </span>
            ),
            width: 130,
            accessor: data =>
              `${new Intl.NumberFormat('de-DE').format(data.gross_price)} €`,
          },
        ]
      : []

  const companyColumns =
    proofsType === PROOF_TYPE.CLAIM_PROOF ||
    proofsType === PROOF_TYPE.EXECUTION_PROOF ||
    proofsType === PROOF_TYPE.CERTIFICATE
      ? [
          {
            id: 'created_by__company__empto_external_number',
            Header: (
              <span>
                <Translate value='certificateArchive.table.customerNo' />
                <UpDownArrow />
              </span>
            ),
            accessor: data =>
              data.company_object && data.company_object.empto_external_number,
          },
          {
            id: 'created_by__company__name',
            Header: (
              <span>
                <Translate value='certificateArchive.table.companyName' />
                <UpDownArrow />
              </span>
            ),
            accessor: data =>
              data.company_object && (
                <Link to={`/company/${data.company_object.id}`}>
                  {data.company_object.name}
                </Link>
              ),
          },
        ]
      : []

  const workloadTypeAndPartnerColumns =
    proofsType === PROOF_TYPE.WORKLOAD
      ? [
          {
            id: 'customer__empto_external_number',
            Header: (
              <span>
                <Translate value='certificateArchive.table.customerNo' />
                <UpDownArrow />
              </span>
            ),
            accessor: data => data.customer_empto_external_number,
          },
          {
            id: 'customer__name',
            Header: (
              <span>
                <Translate value='workload.customerName' />
                <UpDownArrow />
              </span>
            ),
            accessor: data => (
              <Link to={`/company/${data.customer_id}`}>
                {data.customer_name}
              </Link>
            ),
          },
          {
            id: 'partner__name',
            Header: (
              <span>
                <Translate value='workload.partnerName' />
                <UpDownArrow />
              </span>
            ),
            accessor: data => (
              <Link to={`/company/${data.partner_id}`}>
                {data.partner_name}
              </Link>
            ),
          },
          {
            id: 'workload_type',
            Header: (
              <span>
                <Translate value='certificateArchive.table.category' />
                <UpDownArrow />
              </span>
            ),
            accessor: data => {
              return data.workload_type
                .map(item => {
                  return I18n.t(getName(item, WORK_LOAD_CATEGORIES))
                })
                .join(', ')
            },
          },
        ]
      : []

  const certificateTypesColumn =
    proofsType === PROOF_TYPE.CERTIFICATE ||
    proofsType === PROOF_TYPE.MY_CERTIFICATE
      ? [
          {
            Header: (
              <span>
                <Translate value='certificateArchive.table.docType' />
                <UpDownArrow />
              </span>
            ),
            accessor: 'type',
            Cell: data => I18n.t(getName(data.value, CERTIFICATE_TYPES)),
          },
        ]
      : []

  const attachmentsCountColumn =
    proofsType !== PROOF_TYPE.INVOICE_CHECK &&
    proofsType !== PROOF_TYPE.WORKLOAD
      ? [
          {
            id: 'attachments_count',
            Header: (
              <span>
                <Translate value='certificateArchive.table.number' />
                <UpDownArrow />
              </span>
            ),
            accessor: data =>
              (data.attachments && data.attachments.length) ||
              data.attachments_count,
            width: 100,
          },
        ]
      : []

  const allColumns = [
    {
      id: 'created_by__last_name',
      Header: (
        <span>
          <Translate value='certificateArchive.table.uploadUser' />
          <UpDownArrow />
        </span>
      ),
      accessor: data => {
        if (data.claim_user_id) {
          return (
            <UserLink
              userName={`${data.claim_user_first_name} ${data.claim_user_last_name}`}
              userStatus={Number(data.claim_user_status)}
              userId={Number(data.claim_user_id)}
            />
          )
        } else if (data.created_by_object) {
          return (
            <UserLink
              userName={data.created_by_object.full_name}
              userStatus={data.created_by_object.status}
              userId={data.created_by_object.id}
            />
          )
        }
        return (
          <span>
            <Translate value='certificateArchive.uploadedBySystemAdmin' />
          </span>
        )
      },
    },
    {
      id: 'created_at',
      Header: (
        <span>
          <Translate value='certificateArchive.table.uploadDate' />
          <UpDownArrow />
        </span>
      ),
      accessor: data =>
        data.created_at && moment(data.created_at).format('L LT'),
    },
    {
      id: 'status',
      Header: (
        <span>
          <Translate value='general.status' />
          <UpDownArrow />
        </span>
      ),
      accessor: data =>
        data.status &&
        I18n.t(
          getName(
            data.status,
            proofsType === PROOF_TYPE.INVOICE_CHECK
              ? INVOICE_CHECK_STATES
              : EXECUTION_PROOF_STATES,
          ),
        ),
    },
    {
      id: 'reviewed_at',
      Header: (
        <span>
          <Translate value='certificateArchive.table.statusDate' />
          <UpDownArrow />
        </span>
      ),
      accessor: data =>
        data.reviewed_at && moment(data.reviewed_at).format('L LT'),
    },
  ]

  // Column with the name of the reviewer
  const reviewerColumn = [
    PROOF_TYPE.CERTIFICATE,
    PROOF_TYPE.INVOICE_CHECK,
  ].includes(proofsType)
    ? [
        {
          id: 'reviewed_by__last_name',
          Header: (
            <span>
              <Translate value='certificateArchive.table.statusUser' />
              <UpDownArrow />
            </span>
          ),
          accessor: data => data.reviewed_by_object?.full_name,
        },
      ]
    : []

  const attachmentColumn =
    proofsType !== PROOF_TYPE.INVOICE_CHECK &&
    proofsType !== PROOF_TYPE.WORKLOAD
      ? [
          {
            id: 'proofAttachments',
            Header: I18n.t('general.attachments'),
            sortable: false,
            accessor: 'attachments',
            Cell: data => (
              <div className='uk-flex'>
                {data.value &&
                  data.value.map(file => (
                    <div
                      className='attachment uk-margin-auto-left'
                      key={file.id}
                    >
                      <Link
                        to={file.file}
                        onClick={event => {
                          event.preventDefault()
                          window.open(file.file)
                        }}
                        data-uk-tooltip={file.name}
                      >
                        <Icon name='document-new' />
                      </Link>
                    </div>
                  ))}
              </div>
            ),
          },
        ]
      : []

  const getActionLinks = data => {
    let url
    switch (proofsType) {
      case PROOF_TYPE.EXECUTION_PROOF:
        url = 'execution-proof'
        break
      case PROOF_TYPE.CERTIFICATE:
        url = 'certificate'
        break
      case PROOF_TYPE.CLAIM_PROOF:
        url = 'claim-proof'
        break
      case PROOF_TYPE.INVOICE_CHECK:
        url = 'invoicecheck/invoice_check/overview'
        break
      case PROOF_TYPE.WORKLOAD:
        switch (true) {
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.PRICEAGREEMENT,
          ):
            url = 'certificate'
            break
          case data.original.workload_type.includes(WORK_LOAD_CATEGORY.CLAIM):
            url = 'claim-proof'
            break
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.OTHER_SERVICE,
          ):
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.RELABELING,
          ):
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.CONTAINER_CHANGED,
          ):
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.ADDITIONAL_RENT,
          ):
            url = 'workload/invoice_check_position'
            break
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.EPD_ROTATION,
          ):
            url = 'workload/offer'
            break
          case data.original.workload_type.includes(
            WORK_LOAD_CATEGORY.CANCELLED_PAID_DOCUMENT,
          ):
            url = 'workload/cancel_paid_document'
            break
          default:
            url = ''
        }
        break
      default:
        url = ''
    }

    let iconAction
    let tooltipAction
    switch (data.original.status) {
      case CERTIFICATE_STATUS.STATUS_PENDING:
        if (isReviewButtonVisible) {
          iconAction = 'clipboard'
          tooltipAction = 'review'
        } else {
          iconAction = 'search'
          tooltipAction = 'view'
        }
        break
      default:
        iconAction = 'search'
        tooltipAction = 'view'
        break
    }

    const translationKey = I18n.t(`certificateArchive.table.${tooltipAction}`)

    return (
      <Link
        className={`icon-${iconAction}`}
        to={`/${url}/${data.original.id}`}
        data-uk-tooltip={`title: ${translationKey}`}
      />
    )
  }

  const actionColumns =
    proofsType !== PROOF_TYPE.MY_CERTIFICATE
      ? [
          {
            id: 'actions',
            Header: I18n.t('general.actions'),
            width: 100,
            sortable: false,
            filterable: false,
            Cell: data => (
              <span
                className='action-buttons'
                data-testid='orderproof-row'
                data-orderproof-id={data.original.id}
              >
                {getActionLinks(data)}
              </span>
            ),
          },
        ]
      : []

  const columns = [
    ...documentNumberColumns,
    ...orderIdColumn,
    ...partnerCompanyColumn,
    ...grossPriceColumn,
    ...companyColumns,
    ...workloadTypeAndPartnerColumns,
    ...attachmentsCountColumn,
    ...certificateTypesColumn,
    ...allColumns,
    ...reviewerColumn,
    ...attachmentColumn,
    ...actionColumns,
  ]

  if (apiError) return null

  return (
    <div className='proof-list'>
      <PaginatedTable
        serverSidePagination
        page={page}
        pages={pages}
        loading={isLoading}
        handleShowPreviousPage={onPreviousPageClick}
        handleShowNextPage={onNextPageClick}
        table={{
          columns,
          data: proofsList,
          onFetchData,
        }}
      />
    </div>
  )
}
