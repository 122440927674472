import keyMirror from 'keymirror'

const CREATE_INVOICE_CHECK = keyMirror({
  CREATE_INVOICE_CHECK_REQUEST: null,
  CREATE_INVOICE_CHECK_SUCCESS: null,
  CREATE_INVOICE_CHECK_ERROR: null,
})

const UPDATE_INVOICE_CHECK = keyMirror({
  UPDATE_INVOICE_CHECK_REQUEST: null,
  UPDATE_INVOICE_CHECK_SUCCESS: null,
  UPDATE_INVOICE_CHECK_ERROR: null,
})

const GET_INVOICE_CHECKS = keyMirror({
  GET_INVOICE_CHECKS_REQUEST: null,
  GET_INVOICE_CHECKS_SUCCESS: null,
  GET_INVOICE_CHECKS_ERROR: null,
})

const GET_INVOICE_CHECK = keyMirror({
  GET_INVOICE_CHECK_REQUEST: null,
  GET_INVOICE_CHECK_SUCCESS: null,
  GET_INVOICE_CHECK_ERROR: null,
  RESET_INVOICE_CHECK_ERROR: null,
})

const GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER = keyMirror({
  GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_REQUEST: null,
  GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_SUCCESS: null,
  GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_ERROR: null,
  GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_ORDER: null,
})

const CREATE_INVOICE_CHECK_POSITION = keyMirror({
  CREATE_INVOICE_CHECK_POSITION_REQUEST: null,
  CREATE_INVOICE_CHECK_POSITION_SUCCESS: null,
  CREATE_INVOICE_CHECK_POSITION_ERROR: null,
})

const UPDATE_INVOICE_CHECK_POSITION = keyMirror({
  UPDATE_INVOICE_CHECK_POSITION_REQUEST: null,
  UPDATE_INVOICE_CHECK_POSITION_SUCCESS: null,
  UPDATE_INVOICE_CHECK_POSITION_ERROR: null,
})

const DELETE_INVOICE_CHECK_POSITION = keyMirror({
  DELETE_INVOICE_CHECK_POSITION_REQUEST: null,
  DELETE_INVOICE_CHECK_POSITION_SUCCESS: null,
  DELETE_INVOICE_CHECK_POSITION_ERROR: null,
})

const GET_INVOICE_CHECK_POSITIONS = keyMirror({
  GET_INVOICE_CHECK_POSITIONS_REQUEST: null,
  GET_INVOICE_CHECK_POSITIONS_SUCCESS: null,
  GET_INVOICE_CHECK_POSITIONS_ERROR: null,
})

const UPDATE_REVIEWED_INVOICE_CHECK_POSITION = keyMirror({
  UPDATE_REVIEWED_INVOICE_CHECK_POSITION_REQUEST: null,
  UPDATE_REVIEWED_INVOICE_CHECK_POSITION_SUCCESS: null,
  UPDATE_REVIEWED_INVOICE_CHECK_POSITION_ERROR: null,
})

const GET_INVOICE_CHECK_POSITION_PRICE = keyMirror({
  GET_INVOICE_CHECK_POSITION_PRICE_REQUEST: null,
  GET_INVOICE_CHECK_POSITION_PRICE_SUCCESS: null,
  GET_INVOICE_CHECK_POSITION_PRICE_ERROR: null,
})

const GET_INVOICE_CHECK_POSITION = keyMirror({
  GET_INVOICE_CHECK_POSITION_REQUEST: null,
  GET_INVOICE_CHECK_POSITION_SUCCESS: null,
  GET_INVOICE_CHECK_POSITION_ERROR: null,
})

const EXPORT_INVOICE_CHECK_BATCH_FILE = keyMirror({
  EXPORT_INVOICE_CHECK_BATCH_FILE_REQUEST: null,
  EXPORT_INVOICE_CHECK_BATCH_FILE_SUCCESS: null,
  EXPORT_INVOICE_CHECK_BATCH_FILE_ERROR: null,
})

const EXPORT_INVOICE_CHECK = keyMirror({
  EXPORT_INVOICE_CHECK_REQUEST: null,
  EXPORT_INVOICE_CHECK_SUCCESS: null,
  EXPORT_INVOICE_CHECK_ERROR: null,
})

const REOPEN_INVOICE_CHECK = keyMirror({
  REOPEN_INVOICE_CHECK_REQUEST: null,
  REOPEN_INVOICE_CHECK_SUCCESS: null,
  REOPEN_INVOICE_CHECK_ERROR: null,
})

export default {
  ...CREATE_INVOICE_CHECK,
  ...UPDATE_INVOICE_CHECK,
  ...GET_INVOICE_CHECKS,
  ...GET_INVOICE_CHECK,
  ...GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER,
  ...CREATE_INVOICE_CHECK_POSITION,
  ...UPDATE_INVOICE_CHECK_POSITION,
  ...DELETE_INVOICE_CHECK_POSITION,
  ...GET_INVOICE_CHECK_POSITION,
  ...GET_INVOICE_CHECK_POSITIONS,
  ...UPDATE_REVIEWED_INVOICE_CHECK_POSITION,
  ...GET_INVOICE_CHECK_POSITION_PRICE,
  ...EXPORT_INVOICE_CHECK_BATCH_FILE,
  ...EXPORT_INVOICE_CHECK,
  ...REOPEN_INVOICE_CHECK,
}
