import moment from 'moment'
import React from 'react'
import { I18n } from 'react-i18nify'
import { bool, shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { InternalCommentTextArea } from 'components/order/OrderDetailsPage/components/OrderSwitcherContent/components/Order/components/InternalComment'
import { UserPermission } from 'constants/user'
import { localizeDate } from 'helper/general'
import { getName } from 'helper/translations'
import { OrderScheme } from 'schemes/order'
import { OFFER_ORDER_TYPES } from 'components/inquiry/constants'

/**
 * @description This component renders the order component.
 */
export const OrderComponent = ({ order, showAccordion }) => (
  <>
    <DetailSection
      title={I18n.t('order.title')}
      icon={`${showAccordion ? 'article' : ''}`}
      showAccordion={showAccordion}
    >
      <DetailRow
        label={I18n.t('inquiry.orderType')}
        value={I18n.t(
          getName(order.offer_object.order_type, OFFER_ORDER_TYPES),
        )}
      />
      <DetailRow label={I18n.t('order.number')} value={order.id} />
      <DetailRow
        label={I18n.t('orderDetails.orderDate')}
        value={moment(order.created_at).format('L LT')}
      />
      <DetailRow
        label={I18n.t('orderDetails.additionalInformation')}
        value={order.additional_information}
      />
    </DetailSection>

    <div
      className={`uk-accordion-content ${!showAccordion ? 'uk-hidden' : ''}`}
    >
      <DetailSection>
        <DetailRow
          label={I18n.t('inquiry.orderType')}
          value={I18n.t(
            getName(order.offer_object.order_type, OFFER_ORDER_TYPES),
          )}
        />
        <DetailRow label={I18n.t('order.number')} value={order.id} />
        <DetailRow
          label={I18n.t('orderDetails.orderDate')}
          value={moment(order.created_at).format('L LT')}
        />
        <DetailRow
          label={I18n.t('orderDetails.deliveryDate')}
          value={localizeDate(order.delivery_date)}
        />
        <DetailRow
          label={I18n.t('general.createdBy')}
          value={`${order.created_by_object.first_name} ${order.created_by_object.last_name}`}
        />
        <DetailRow
          label={I18n.t('inquiry.withEstimatedTimeOfArrival')}
          value={I18n.t(
            `general.${
              order.offer_object.with_estimated_time_of_arrival ? 'yes' : 'no'
            }`,
          )}
        />
        <DetailRow
          label={I18n.t('inquiry.positionOnPublicGround.short')}
          value={I18n.t(
            `general.${
              order.offer_object.position_on_public_ground ? 'yes' : 'no'
            }`,
          )}
        />
        {order.offer_object.special_note && (
          <DetailRow
            label={I18n.t('inquiryForm.placeholder.specialNote')}
            value={order.offer_object.special_note}
          />
        )}
        <DetailRow
          label={I18n.t('acceptedOffers.number')}
          value={order.offer}
          path={`/offer/${order.offer}`}
        />
        <InternalCommentTextArea
          requiresPermissions={[UserPermission.SET_INTERNAL_COMMENT]}
          order={order}
          label={I18n.t('orderDetails.internalComment')}
          value={order.internal_comment}
          buttonValue={I18n.t('general.button.save')}
        />
      </DetailSection>
    </div>
  </>
)

OrderComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
  showAccordion: bool.isRequired,
}

export default OrderComponent
