import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { InputText } from 'components/common/InputText'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { COMPANY_ROLE } from 'components/company/constants'
import { getCurrentUserSelector } from 'selectors/user'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import { CommonFilterProps } from '../../types'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface CompanyAndIdFilterProps extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  idField?: string
  idTranslation: string
  idFilterActive: boolean
  companyTranslation: string
  companyFilterActive: boolean
  setCurrentFilterValues: (currentFilters: any) => void
  currentFilterValues: any
  resultType?: COMPANY_ROLE
}

export const CompanyAndIdFilter: FC<CompanyAndIdFilterProps> = ({
  action,
  handleBlur,
  handleChange,
  idField = 'id',
  idTranslation,
  idFilterActive = true,
  companyFilterActive = true,
  isLoading,
  length,
  resetForm,
  resultType,
  setCurrentFilterValues,
  currentFilterValues,
  setFieldValue,
  values,
}) => {
  const dispatch = useDispatch()
  const [filterWasReset, setFilterWasReset] = useState(false)

  const user = useSelector(getCurrentUserSelector)

  useEffect(() => {
    const requestValues = {
      ...currentFilterValues,
      company: values.company,
      [idField]: values.id,
      makler_premium_company: user.company,
    }
    if (
      filterWasReset ||
      JSON.stringify(currentFilterValues) !== JSON.stringify(requestValues)
    ) {
      setCurrentFilterValues(requestValues)
      dispatch(action(0, requestValues))
      if (filterWasReset) {
        setFilterWasReset(false)
      }
    }
  }, [
    action,
    currentFilterValues,
    dispatch,
    filterWasReset,
    idField,
    resultType,
    setCurrentFilterValues,
    user.company,
    values,
  ])

  return (
    <>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        {companyFilterActive && (
          <Select
            urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
            urlPart2='&onlyActive=false&maklerpremium_and_role=1'
            getValue={e => e.id}
            getLabel={e =>
              e.id === 0
                ? e.name
                : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`
            }
            label={I18n.t(
              'filterTranslations.userListFilter.companySelection.label',
            )}
            setValue={e => {
              setFieldValue('company', e?.value ? e.value : '')
            }}
            selectedValue={values?.company}
            selecting={'company'}
          />
        )}
        {idFilterActive && (
          <InputText
            label={idTranslation}
            maxLength={15}
            name='id'
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e)
            }}
            value={values.id}
          />
        )}
        <FilterReset
          onResetFilter={() => {
            setFieldValue('company', '')
            resetForm()
            setFilterWasReset(true)
            setCurrentFilterValues({})
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilterValues) > 0
          }
        />
      </div>
      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
