import moment from 'moment'
import React from 'react'
import { I18n, Translate } from 'react-i18nify'

import { COLLECTION_CONTAINER_IDS } from '../../constants/app'
import { getName } from '../../helper/translations'
import Icon, { ICON_NAME } from '../common/Fontello'
import { CardDetailsListItem } from '../common/NewCards/Card'
import { OFFER_ORDER_TYPES } from '../inquiry/constants'
import { getFractionOrContainerDetailsById } from '../inquiry/helpers'

import { OFFER_CARD_TRANSLATION_CATEGORY, OFFER_STATUS } from './constants'

// This decides which text should be shown, notice that there are texts that don't need a date cause there is no
// needed in the translations
const getInformationText = (
  offerItem: OfferSmall,
  translationCategory = 'details',
) => {
  if (offerItem.status === OFFER_STATUS.STATUS_ACCEPTED) {
    return offerItem.status_changed_at
      ? {
          informationText: 'newOfferCard.details.informationStatusAccepted',
          informationDate: moment(offerItem.status_changed_at).format('L'),
        }
      : {
          informationText:
            'newOfferCard.details.informationStatusAcceptedWithoutDate',
          informationDate: '',
        }
  }
  if (offerItem.status === OFFER_STATUS.STATUS_REJECTED) {
    return {
      informationText:
        'newOfferCard.details.informationStatusRejectedWithoutDate',
      informationDate: '',
    }
  }
  if (
    offerItem.status === OFFER_STATUS.STATUS_CANCELLED ||
    offerItem.status === OFFER_STATUS.STATUS_AGREEMENT_CANCELLED_EMPTO
  ) {
    return {
      informationText:
        'newOfferCard.details.informationStatusCancelledWithoutDate',
      informationDate: '',
    }
  }
  if (offerItem.status === OFFER_STATUS.STATUS_OPEN) {
    // Here is no fallback text if the date is missing so if it is missing the date is replaced with a translation
    // like: 'There is no date'
    return {
      informationText: `newOfferCard.${translationCategory}.informationStatusOpen`,
      informationDate: offerItem.runtime_of_inquiry
        ? moment(offerItem.runtime_of_inquiry).format('L')
        : I18n.t('newOfferCard.details.noDate'),
    }
  }
  // This is shown as a fallback
  return {
    informationText: 'newOfferCard.details.noText',
    informationDate: '',
  }
}

const getServiceBeginItem = item => (
  <CardDetailsListItem>
    <Icon name={ICON_NAME.TRUCK} />
    <Translate
      value='newOfferCard.details.serviceBegin'
      date={moment(item.service_start_date).format('L')}
    />
  </CardDetailsListItem>
)

const getServiceEndItem = item =>
  item.service_end_date && (
    <CardDetailsListItem>
      <Icon name={ICON_NAME.CALENDAR} />
      <Translate
        value='newAgreementCard.endDate'
        date={moment(item.service_end_date).format('L')}
        dangerousHTML
      />
    </CardDetailsListItem>
  )

const getLocationItem = item => (
  <CardDetailsListItem>
    <Icon name={ICON_NAME.LOCATION} />
    {item.collection_address_object.display_name}
  </CardDetailsListItem>
)

const getStoreItem = item => (
  <CardDetailsListItem>
    <Icon name={ICON_NAME.STORE} />
    <span className='two-lines break-all'>
      {item.customer_company_object.name}
    </span>
  </CardDetailsListItem>
)

const getContainerItem = (item, containerList) => {
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(item.container),
  )

  const { capacity: containerCapacity, name: containerName } =
    getFractionOrContainerDetailsById(
      item.container,
      containerList,
    ) as Container

  return (
    <CardDetailsListItem>
      <Icon name={ICON_NAME.CONTAINER_SKIP} />
      <Translate
        value={
          showQuantityInCubicMeters
            ? 'newOfferCard.details.containerCubicMeters'
            : 'newOfferCard.details.container'
        }
        capacity={containerCapacity}
        name={containerName}
        amount={
          showQuantityInCubicMeters
            ? item.quantity_in_cubic_meters
            : item.number_of_containers
        }
        dangerousHTML
      />
    </CardDetailsListItem>
  )
}

const getArrowRightItem = item => (
  <CardDetailsListItem>
    <Icon name={ICON_NAME.ARROW_RIGHT} />
    {I18n.t(getName(item.order_type, OFFER_ORDER_TYPES))}
  </CardDetailsListItem>
)

const getHeadlineItem = item => (
  <CardDetailsListItem withoutIcon>
    <Translate
      value='newOfferCard.details.headline'
      id={item.id}
      date={moment(item.created_at).format('L')}
      dangerousHTML
    />
  </CardDetailsListItem>
)

export const getStateText = (item, translationCategory) => {
  const { informationText, informationDate } = getInformationText(
    item,
    translationCategory,
  )

  return <Translate value={informationText} date={informationDate} />
}

const getInformationItem = (item, translationCategory) => (
  <CardDetailsListItem>
    <Icon name={ICON_NAME.INFORMATION} />
    {getStateText(item, translationCategory)}
  </CardDetailsListItem>
)

export const getDashboardCardListItems = (item, containerList, hasEndDate) => [
  getLocationItem(item),
  getServiceBeginItem(item),
  hasEndDate ? getServiceEndItem(item) : null,
  getStoreItem(item),
  getContainerItem(item, containerList),
  getArrowRightItem(item),
]

export const getOfferPageCardListItems = (item, containerList) => [
  getHeadlineItem(item),
  getInformationItem(item, OFFER_CARD_TRANSLATION_CATEGORY.DETAILS),
  getServiceBeginItem(item),
  getLocationItem(item),
  getStoreItem(item),
  getContainerItem(item, containerList),
  getArrowRightItem(item),
]
