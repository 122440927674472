import 'react-table/react-table.css'
import './style.scss'

import uniqueId from 'lodash.uniqueid'
import moment from 'moment/moment'
import { arrayOf, bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import Spinner from 'react-spinkit'
import ReactTable from 'react-table'
import { compose } from 'recompose'

import { exportIndexValues } from '../../actions/indexes'
import { BREAKPOINT } from '../../constants/design'
import { UserPermission } from '../../constants/user'
import withErrorBoundary from '../../helper/withErrorBoundary'
import {
  OfferIndexAddScheme,
  OfferIndexScheme,
  OfferIndexValuesScheme,
} from '../../schemes/offer'
import { AsyncExportModal } from '../common/AsyncExportModal'
import { Button, BUTTON_BACKGROUND_COLOR } from '../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../common/ButtonBar/index'
import { ICON_POSITION, IconButton } from '../common/IconButton'
import { Modal } from '../common/Modal/index'
import ModalHeader from '../common/ModalHeader/index'
import Paragraph from '../common/Paragraph/index'
import { RequiredPermissions } from '../common/RequiredPermissions/index'
import PageHeader from '../layout/PageHeader'

import AddMonthValuesModalComponent from './components/AddNextMonthValuesModal'
import ChangeIndexValueFormModalComponent from './components/ChangeIndexValueForm/index'
import connector from './connector'
import { INDEX_VALUES_MODALS } from './constants'
import { getLatestChange, getMonthSum, getTableData } from './helpers'

/**
 * @description This component lists the company addresses and offers editing features.
 * @function
 * @param {Object} props the component props
 */
export class IndexValuesPageComponent extends Component {
  static propTypes = {
    actions: shape({
      form: shape({
        change: func.isRequired,
        reset: func.isRequired,
      }).isRequired,
      indexes: shape({
        getIndexes: func.isRequired,
        getIndexValues: func.isRequired,
        exportIndexValues: func.isRequired,
      }).isRequired,
    }).isRequired,
    indexList: arrayOf(shape(OfferIndexScheme)),
    indexValues: arrayOf(shape(OfferIndexValuesScheme)),
    nextMonthValues: arrayOf(shape(OfferIndexAddScheme)),
    isLoading: shape({
      exportIndexValues: bool,
      indexList: bool,
    }),
  }

  static defaultProps = {
    isLoading: {
      exportIndexValues: false,
      indexList: false,
    },
    indexValues: [],
    indexList: [],
    nextMonthValues: [],
  }

  state = {
    dates: [
      moment(),
      moment().subtract(1, 'month'),
      moment().subtract(2, 'month'),
      moment().subtract(3, 'month'),
      moment().subtract(4, 'month'),
      moment().subtract(5, 'month'),
      moment().subtract(6, 'month'),
      moment().subtract(7, 'month'),
      moment().subtract(8, 'month'),
      moment().subtract(9, 'month'),
      moment().subtract(10, 'month'),
      moment().subtract(11, 'month'),
    ],
    openModal: false,
    modalData: null,
    nextMonthAddable: false,
    isExportModalOpen: false,
  }

  /**
   * Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    const { actions, indexList, indexValues } = this.props

    if (!indexList.length) actions.indexes.getIndexes()
    if (!indexValues.length)
      actions.indexes.getIndexValues(
        this.state.dates.map(date => date.format('L')),
      )
  }

  /**
   * Component “lifecycle method” UNSAFE_componentWillReceiveProps
   */
  UNSAFE_componentWillReceiveProps({
    actions,
    indexList,
    indexValues,
    nextMonthValues,
  }) {
    const nextMonth = this.state.dates[this.state.dates.length - 1]
    const nextMonthAddable = !indexValues.find(
      indexValue => indexValue.month === Number(nextMonth.format('M')),
    )

    this.setState({ nextMonthAddable }, () => {
      if (nextMonthAddable && indexList.length && !nextMonthValues.length) {
        const nextMonthIndexList = indexList.map(index => ({
          index: index.id,
          value: '',
          month: Number(nextMonth.format('M')),
          year: Number(nextMonth.format('YYYY')),
        }))

        actions.form.change('indexes.nextMonthIndexList', nextMonthIndexList)
      }
    })
  }

  /**
   * @description Toggle a modal
   * @param modal
   * @param modalData
   */
  handleModalToggle = (modal = null, modalData = null) => {
    this.setState({
      modalData: modal && modalData ? modalData : null,
      openModal: modal,
    })
  }

  /**
   * @description renders the table cell
   * @param data
   * @param cellIndex
   * @param isDisabled
   * @return {*}
   */
  renderCell = (data, cellIndex, isDisabled) => {
    const value =
      data.original.data[cellIndex].value !== null
        ? data.original.data[cellIndex].value
        : '---'

    if (isDisabled) {
      return <div className='table-cell'>{value}</div>
    }

    return (
      <Button
        backgroundColor={BUTTON_BACKGROUND_COLOR.TRANSPARENT}
        className='table-cell'
        onClick={() =>
          this.handleModalToggle(
            INDEX_VALUES_MODALS.CHANGE_VALUE_MODAL,
            data.original.data[cellIndex],
          )
        }
      >
        <span>{value}</span>
      </Button>
    )
  }

  /**
   * @description renders the table header
   * @param text
   */
  renderHeader = text => <div className='table-header'>{text}</div>

  /**
   * @description renders the table column footer
   * @param value
   * @param lastModifiedAt
   * @param lastModifiedBy
   * @return {*}
   */
  renderColumnFooter = (value, lastModifiedAt, lastModifiedBy) => (
    <div className='index-value-table-footer uk-margin-medium-top'>
      <Paragraph>{value}</Paragraph>

      <Paragraph>{lastModifiedAt}</Paragraph>

      <Paragraph>{lastModifiedBy}</Paragraph>
    </div>
  )

  /**
   * @description renders table columns
   */
  renderColumns = () => {
    const columns = [
      {
        Header: this.renderHeader(I18n.t('indexValuesPage.table.header.index')),
        columns: [
          {
            Cell: data => (
              <div className='table-cell'>{data.original.indexName}</div>
            ),
            Footer: this.renderColumnFooter(
              I18n.t('indexValuesPage.table.footer.total'),
              I18n.t('indexValuesPage.table.footer.lastChange'),
              I18n.t('indexValuesPage.table.footer.changedBy'),
            ),
          },
        ],
      },
    ]

    this.state.dates.forEach((date, i) => {
      const isLast = i === 12
      const isDisabled = i > 1
      const monthSum = getMonthSum(this.props.indexValues, date)
      const latestChange = getLatestChange(this.props.indexValues, date)

      columns.push({
        Header: this.renderHeader(
          `${date.format('MMMM')} ${date.format('YYYY')}`,
        ),
        columns: [
          {
            Cell: data => this.renderCell(data, i, isDisabled),
            Footer: this.renderColumnFooter(
              monthSum,
              latestChange.date,
              latestChange.user,
            ),
            show: !isLast,
          },
        ],
      })
    })

    return columns
  }

  /**
   * @description Renders the index values table
   * @return {*}
   */
  renderTable = () => {
    // data conveniently structured to use on the react table
    const tableData = getTableData(this.props.indexValues, this.state.dates)

    return (
      <>
        <ReactTable
          columns={this.renderColumns()}
          data={tableData}
          sortable={false}
          resizable={false}
          showPageSizeOptions={false}
          showPagination={false}
          showPageJump={false}
          defaultPageSize={tableData.length}
          className='table -highlight'
        />
      </>
    )
  }

  /**
   * @description Renders the add/change values modal.
   * @return {*}
   */
  renderModal() {
    const idModalHeadline = uniqueId()

    return (
      <Modal
        ariaDescribedBy={idModalHeadline}
        isOpen={
          this.state.openModal === INDEX_VALUES_MODALS.CHANGE_VALUE_MODAL ||
          this.state.openModal ===
            INDEX_VALUES_MODALS.ADD_NEXT_MONTH_VALUES_MODAL
        }
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={
            this.state.openModal === INDEX_VALUES_MODALS.CHANGE_VALUE_MODAL
              ? I18n.t('indexValuesPage.changeIndexValue')
              : I18n.t('indexValuesPage.modalHeader')
          }
          titleId={idModalHeadline}
        />
        {this.state.openModal === INDEX_VALUES_MODALS.CHANGE_VALUE_MODAL && (
          <ChangeIndexValueFormModalComponent
            handleModalToggle={this.handleModalToggle}
            indexList={this.props.indexList}
            indexValues={this.props.indexValues}
            modalData={this.state.modalData}
          />
        )}
        {this.state.openModal ===
          INDEX_VALUES_MODALS.ADD_NEXT_MONTH_VALUES_MODAL && (
          <AddMonthValuesModalComponent
            indexList={this.props.indexList}
            indexValues={this.props.indexValues}
            handleModal={this.handleModalToggle}
          />
        )}
      </Modal>
    )
  }

  render() {
    const { indexValues, isLoading } = this.props
    const nextMonth = this.state.dates[this.state.dates.length - 1]

    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.indexValues')}</title>
        </Helmet>

        <div className='index-values-page'>
          <PageHeader title={I18n.t('indexValuesPage.heading')}>
            {!isLoading.indexList && (
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                {
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.ADD_INDEXVALUES]}
                  >
                    <IconButton
                      backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                      iconPosition={ICON_POSITION.RIGHT}
                      iconName='plus'
                      onClick={() =>
                        this.handleModalToggle(
                          INDEX_VALUES_MODALS.ADD_NEXT_MONTH_VALUES_MODAL,
                        )
                      }
                    >
                      <Translate
                        value='indexValuesPage.buttonText'
                        month={nextMonth.format('MMMM')}
                      />
                    </IconButton>
                  </RequiredPermissions>
                }
                <Media
                  key='media-extag'
                  query={{ minWidth: BREAKPOINT.XLARGE }}
                  render={() => (
                    <RequiredPermissions
                      requiredPermissions={[UserPermission.EXPORT_INDEX_VALUES]}
                    >
                      <IconButton
                        iconName='export'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() =>
                          this.setState({ isExportModalOpen: true })
                        }
                        isDisabled={isLoading.exportIndexValues}
                        isLoading={isLoading.exportIndexValues}
                      >
                        <Translate value='general.export' />
                      </IconButton>
                    </RequiredPermissions>
                  )}
                />
              </ButtonBar>
            )}
          </PageHeader>

          {/* Loading Indicator */}
          {isLoading.indexList && (
            <div className='uk-flex uk-flex-center uk-margin-large-top'>
              <Spinner name='circle' />
            </div>
          )}

          {/* Table */}
          {!isLoading.indexList && indexValues.length && (
            <div className='uk-flex uk-flex-center uk-margin-large-top'>
              {this.renderTable()}
            </div>
          )}

          {/* Empty message */}
          {!isLoading.indexList && !indexValues.length && (
            <div className='uk-margin-top uk-text-center'>
              <Translate value='indexValuesPage.noIndexValues' />
            </div>
          )}

          {/* Modal */}
          {this.renderModal()}

          <AsyncExportModal
            isOpen={this.state.isExportModalOpen}
            onClose={() => this.setState({ isExportModalOpen: false })}
            reduxSelector='EXPORT_INDEX_VALUES'
            title={I18n.t('indexValuesPage.exportModalTitle')}
            logic={exportIndexValues()}
          />
        </div>
      </>
    )
  }
}

export default compose(withErrorBoundary, connector)(IndexValuesPageComponent)
