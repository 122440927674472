import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getActiveAddresses } from 'actions/address'
import {
  getCompany,
  resetCustomInvoiceCompanies,
  resetMaklerPremiumCompanies,
} from 'actions/company'
import { Checkbox } from 'components/common/Checkbox'
import { DropDownInput } from 'components/common/DropDownInput'
import {
  FormStepSummaryRow,
  GoToNextFormStepButton,
} from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { getAddressesSelector } from 'selectors/address'
import { getCompanySelector } from 'selectors/company'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import {
  EPDAddInvoiceFormContext,
  EPDAddInvoiceFormValues,
} from '../EPDAddInvoiceFormSteps'

export const InvoiceReceiverForm: FC = () => {
  const { errors, handleChange, setValues, setFieldValue, values } =
    useFormikContext<EPDAddInvoiceFormValues>()

  const dispatch = useDispatch()
  const companyActiveAddressList = useSelector(getAddressesSelector)
  const company = useSelector(getCompanySelector)

  const { stepStatus, saveStep, isInvoice, toggleIsInvoice } = useContext(
    EPDAddInvoiceFormContext,
  )

  const [serviceAddressDiffers, setServiceAddressDiffers] =
    useState<boolean>(false)
  const [selectedCompanyHasChanged, setSelectedCompanyHasChanged] =
    useState<boolean>(false)

  const toggleDifferingServiceAddress = () => {
    setServiceAddressDiffers(!serviceAddressDiffers)
    if (!serviceAddressDiffers) {
      setFieldValue('different_service_address', undefined, false)
    }
  }

  useEffect(() => {
    if (
      serviceAddressDiffers &&
      (!companyActiveAddressList.length || selectedCompanyHasChanged)
    ) {
      dispatch(getActiveAddresses({ id: Number(values.selected_company?.id) }))
      setSelectedCompanyHasChanged(false)
    }
  }, [
    companyActiveAddressList.length,
    dispatch,
    selectedCompanyHasChanged,
    serviceAddressDiffers,
    values.selected_company,
  ])

  useEffect(() => {
    if (company.id) {
      setFieldValue('selected_company', company)
      setFieldValue('company_name', company?.name)
      setFieldValue('empto_external_number', company?.empto_external_number)
      setFieldValue('invoice_address', company?.main_address_object)

      dispatch(getActiveAddresses({ id: company.id }))
    }
  }, [company, dispatch, setFieldValue])

  const companyActiveAddressChoices = companyActiveAddressList.flatMap(item =>
    item.id === values.invoice_address?.id
      ? []
      : {
          label: `${item.display_name}`,
          value: item.id,
        },
  )

  const searchFields = [
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.epd.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.epd.steps.1.fields.empto_external_number.label',
      ),
      optionValue: 'empto_external_number',
    },
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.epd.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  return (
    <>
      {/*SELECT SEARCHFIELD*/}
      <DropDownInput
        choices={searchFields}
        label={I18n.t(
          'addInvoicePageTranslations.epd.steps.1.fields.search_field.label',
        )}
        name='search_field'
        onChange={e => {
          setValues(
            {
              ...values,
              company_name: '',
              empto_external_number: undefined,
              zipcode: undefined,
              selected_company: undefined,
            },
            false,
          )
          dispatch(resetMaklerPremiumCompanies()) // Reset filtered results
          dispatch(resetCustomInvoiceCompanies()) // Reset filtered results
          handleChange(e)
        }}
        showCheckmark
        value={values.search_field}
        withCheckmark
      />

      {/*SEARCH*/}
      {/*prettier-ignore*/}
      <Select
        urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/custom-invoice-companies/?${values.search_field}=`}
        urlPart2='&page_size=1000&has_maklerpremium_agreements=true&has_empto_agreements=true'
        getValue={(e) => e.id}
        getLabel={(e) => e.id === 0 ? e.name : `${e.empto_external_number}, ${e.name}, ${e.zipcode} ${e.location}`}
        label={I18n.t(`addInvoicePageTranslations.epd.steps.1.fields.${values.search_field}.label`)}
        setValue={e => {
          if (e?.value) {
            setSelectedCompanyHasChanged(true)
            dispatch(getCompany({ id: e.value }))
            setFieldValue('assigned_order', undefined)
            if (!isInvoice) {toggleIsInvoice()}}
          else {setFieldValue('selected_company', undefined);}
        }}
        selectedValue={values.selected_company?.id}
        selecting={'company'}
        error={errors.selected_company}
      />

      {/*SUMMARY FIELDS*/}
      <div className='add-invoice-step-summary'>
        {values.selected_company && (
          <>
            <div className='add-invoice-step-summary_grid-body'>
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.epd.steps.1.fields.empto_external_number.label',
                )}
                value={values.empto_external_number}
              />
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.epd.steps.1.fields.company_name.label',
                )}
                value={values.company_name}
              />
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.epd.steps.1.fields.invoice_address.label',
                )}
                value={`${values.invoice_address?.display_name}`}
              />
              {values.selected_company.tax_id && (
                <FormStepSummaryRow
                  label={I18n.t(
                    'addInvoicePageTranslations.epd.steps.1.fields.tax_id.label',
                  )}
                  value={values.selected_company.tax_id}
                />
              )}
            </div>
            <Checkbox
              isChecked={serviceAddressDiffers}
              name='different_service_address'
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.1.fields.different_service_address.label',
              )}
              onChange={toggleDifferingServiceAddress}
            />
            {serviceAddressDiffers && !errors.different_service_address && (
              <StaticCombobox
                label={I18n.t(
                  'addInvoicePageTranslations.epd.steps.1.fields.service_address.label',
                )}
                name='different_service_address'
                error={errors.different_service_address}
                isDisabled={companyActiveAddressChoices.length === 0}
                noResultsText={I18n.t(
                  'addInvoicePageTranslations.epd.steps.1.fields.service_address.noOptionsMessage',
                )}
                options={companyActiveAddressChoices}
                onSelectionChange={e => {
                  const selectedAddress = companyActiveAddressList.find(
                    item => item.id === Number(e.target.value),
                  )
                  setFieldValue('different_service_address', selectedAddress)
                }}
                placeholder={
                  companyActiveAddressChoices.length !== 0
                    ? I18n.t(
                        'addInvoicePageTranslations.epd.steps.1.fields.service_address.placeholder',
                      )
                    : I18n.t(
                        'addInvoicePageTranslations.epd.steps.1.fields.service_address.errorOneOption',
                      )
                }
                selectedOption={
                  values.different_service_address
                    ? {
                        label: values.different_service_address?.display_name,
                        value: values.different_service_address?.id,
                      }
                    : undefined
                }
                withCheckmark
                showCheckmark={!!values.different_service_address}
              />
            )}
            {serviceAddressDiffers && errors.different_service_address && (
              <div className='combobox__error'>
                {errors.different_service_address}
              </div>
            )}
          </>
        )}
      </div>
      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={
          !values?.selected_company ||
          !!values?.different_service_address !== serviceAddressDiffers
        }
      />
    </>
  )
}
