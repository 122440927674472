import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'

import { Filter as FilterComponent } from 'components/common/Filter'
import { APP_CONSTANTS } from 'constants/app'

import { StaticCombobox } from '../../common/StaticCombobox'
import { Select } from '../../Select/Select'
import { InputText } from '../../common/InputText'

import { MarkupPageContext } from './MarkupPage'

export const Filter: FC = () => {
  const {
    pageCount,
    codeFilter,
    setCodeFilter,
    cityFilter,
    communityFilter,
    setCityFilter,
    setCommunityFilter,
    stateFilter,
    setStateFilter,
    markupFilter,
    setMarkupFilter,
    communityOptions,
    stateOptions,
    markupOptions,
    setShouldUpdate,
    setPage,
  } = useContext(MarkupPageContext)

  function appendAllOption(options) {
    return [{ label: I18n.t('general.placeholder.all'), value: '' }, ...options]
  }

  function update() {
    setPage(1)
    setShouldUpdate(true)
  }

  return (
    <MarkupPageContext.Consumer>
      {() => (
        <FilterComponent length={pageCount}>
          <>
            <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
              <InputText
                maxLength={5}
                label={'PLZ'}
                name='code'
                onChange={e => {
                  if (e?.target?.value) {
                    setCodeFilter(e.target.value)
                    if (e.target.value.length > 1) {
                      update()
                    }
                  } else {
                    setCodeFilter('')
                    update()
                  }
                }}
                value={codeFilter}
              />

              <Select
                urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/city/?name=`}
                urlPart2=''
                getValue={e => e.name}
                getLabel={e => e.name}
                label={'Ort'}
                setValue={e => {
                  if (e?.value) {
                    setCityFilter(e.value)
                  } else {
                    setCityFilter('')
                  }
                  update()
                }}
                selectedValue={cityFilter}
                selecting={'city'}
              />

              <StaticCombobox
                label='Landkreis'
                name='community'
                noResultsText={I18n.t('filterTranslations.noInputResults')}
                options={appendAllOption(
                  communityOptions.map(community => ({
                    label: community,
                    value: community,
                  })),
                )}
                onSelectionChange={e => {
                  if (e?.target?.value) {
                    setCommunityFilter(e.target.value)
                  } else {
                    setCommunityFilter('')
                  }
                  update()
                }}
                selectedOption={{
                  label: communityFilter,
                  value: communityFilter,
                }}
                placeholder={I18n.t('general.placeholder.all')}
              />

              <StaticCombobox
                label='Land'
                name='state'
                noResultsText={I18n.t('filterTranslations.noInputResults')}
                options={appendAllOption(
                  stateOptions.map(state => ({ label: state, value: state })),
                )}
                onSelectionChange={e => {
                  if (e?.target?.value) {
                    setStateFilter(e.target.value)
                  } else {
                    setStateFilter('')
                  }
                  update()
                }}
                selectedOption={{ label: stateFilter, value: stateFilter }}
                placeholder={I18n.t('general.placeholder.all')}
              />

              <StaticCombobox
                label='Marge'
                name='markup'
                noResultsText={I18n.t('filterTranslations.noInputResults')}
                options={appendAllOption(
                  markupOptions?.map(markup => ({
                    label: markup,
                    value: markup,
                  })),
                )}
                onSelectionChange={e => {
                  if (e?.target?.value) {
                    setMarkupFilter(e.target.value)
                  } else {
                    setMarkupFilter('')
                  }
                  update()
                }}
                selectedOption={{ label: markupFilter, value: markupFilter }}
                placeholder={I18n.t('general.placeholder.all')}
              />
            </div>
          </>
        </FilterComponent>
      )}
    </MarkupPageContext.Consumer>
  )
}
