import { createForms } from 'react-redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { combineReducers } from 'redux'

import addressReducer from './address'
import appReducer from './app'
import { autoOfferReducer } from './autooffer'
import certificateReducer from './certificate'
import communityReducer from './community'
import companyReducer from './company'
import containerReducer from './container'
import { instructionReducer } from './core'
import { countryReducer } from './country'
import { dashboardReducer } from './dashboard'
import discountReducer from './discount'
import { dszReducer } from './dsz'
import emptoDownloadsReducer from './emptodownloads'
import emptoInternalDownloadsReducer from './emptointernaldownloads'
import errorReducer from './error'
import executionProofReducer from './executionproof'
import fractionReducer from './fraction'
import { holidayReducer } from './holiday'
import indexesReducer from './indexes'
import avvReducer from './avv'
import invoiceReducer from './invoice'
import { invoiceCheckReducer } from './invoicecheck'
import loadingReducer from './loading'
import { maklerpremiumReducer } from './maklerpremium'
import offerReducer from './offer'
import { orderReducer } from './order'
import orderClaimReducer from './orderclaim'
import { priceCalcReducer } from './pricecalc'
import { requestStatusReducer } from './requestStatus'
import securityGroupReducer from './securitygroup'
import userReducer from './user'
import { workloadReducer } from './workload'
import zipcodeReducer from './zipcode'

/**
 * @description A const array of all existing reducers.
 */
const combinedReducers = combineReducers({
  app: appReducer,
  address: addressReducer,
  autoOffer: autoOfferReducer,
  certificate: certificateReducer,
  community: communityReducer,
  country: countryReducer,
  company: companyReducer,
  container: containerReducer,
  core: combineReducers({
    instruction: instructionReducer,
  }),
  dashboard: dashboardReducer,
  discount: discountReducer,
  dsz: dszReducer,
  emptoDownloads: emptoDownloadsReducer,
  emptoInternalDownloads: emptoInternalDownloadsReducer,
  executionProof: executionProofReducer,
  fraction: fractionReducer,
  holiday: holidayReducer,
  indexes: indexesReducer,
  avv: avvReducer,
  invoice: invoiceReducer,
  invoiceCheck: invoiceCheckReducer,
  maklerpremium: maklerpremiumReducer,
  offer: offerReducer,
  order: orderReducer,
  orderClaim: orderClaimReducer,
  pricecalc: priceCalcReducer,
  requestStatus: requestStatusReducer,
  securityGroup: securityGroupReducer,
  toastr: toastrReducer,
  user: userReducer,
  workload: workloadReducer,
  zipcode: zipcodeReducer,
  ...createForms({
    company: companyReducer,
    indexes: indexesReducer,
    invoice: invoiceReducer,
    zipcode: zipcodeReducer,
  }),
  error: errorReducer, // errorReducer and loadingReducer have to be the last items
  loading: loadingReducer, // otherwise they finish before other reducers are done actually placing the values in state
})

/**
 * @description The root reducer.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const rootReducer = (state, action) => combinedReducers(state, action)

export default rootReducer
