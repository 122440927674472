import { CancelledPaidDocumentReviewPageComponent } from '../components/maklerpremium/CancelPaidDocumentReview/CancelledPaidDocumentReviewPage'
import { WorkloadPage } from '../components/workload/WorkloadPage'
import { UserPermission } from '../constants/user'
import { OfferReviewPageComponent } from '../components/workload/OfferReviewPage'
import { InvoiceCheckPositionReviewPageComponent } from '../components/invoicecheck/InvoiceCheckPositionReviewPage'

/**
 * @constant
 * @description A constant to define workload routes.
 */
export const workloadRoutes = [
  {
    path: '/workload',
    component: WorkloadPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_WORKLOAD],
    showErrorPage: true,
  },
  {
    path: '/workload/cancel_paid_document/:cancelPaidDocumentID',
    component: CancelledPaidDocumentReviewPageComponent,
    requiredPermissions: [UserPermission.VIEW_CANCELLEDPAIDDOCUMENT],
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/workload/invoice_check_position/:invoiceCheckPositionId',
    component: InvoiceCheckPositionReviewPageComponent,
    requiredPermissions: [UserPermission.VIEW_INVOICECHECKPOSITION],
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/workload/offer/:offerId',
    component: OfferReviewPageComponent,
    requiredPermissions: [UserPermission.VIEW_ACCEPTED_OFFER],
    exact: true,
    showErrorPage: true,
  },
]
