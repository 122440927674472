/**
 * @description The Dropzone component delivers files with a "blob:" url. We need to read this and convert to
 * base64, then we can display the preview, even before uploading the file.
 * @param file
 * @returns {Promise<any>}
 */
export const filePreviewAsUrl = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result) resolve(reader.result)
      else reject(Error('Failed converting to base64'))
    }
  })

export default {}
