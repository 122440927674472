import ActionTypes from '../actiontypes/address'

export const itemInitialState = {
  id: -1,
  description: '',
  house_number: '',
  location: '',
  country: undefined,
  street: '',
  zipcode: '',
  contact_person: '',
  company: '',
  company_name: '',
  place_id: '',
  created_at: '',
  lastmodified_at: '',
  status_lastmodified_at: '',
  display_name: '',
  latitude: '',
  longitude: '',
  status: -1,
  contact_person_on_site: '',
  telephone_number_on_site: '',
  email_on_site: '',
}

export const initialState = {
  isEditing: false,
  item: itemInitialState,
  itemList: [],
  itemListForCompany: [],
  itemListForCompanyId: null,
  itemListForCompanyError: null,
  itemListWithOrders: [],
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
  updatedItem: null,
  inquiryCollectionAddressList: [],
  agreementInquiredCollectionAddressList: [],
  orderInquiredCollectionAddressList: [],
}

/**
 * @description A reducer for address.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ADDRESSES_SUCCESS: {
      return {
        ...state,
        itemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    }
    case ActionTypes.GET_ACTIVE_ADDRESSES_SUCCESS:
    case ActionTypes.GET_WASTEMANAGER_ADDRESSES_SUCCESS: {
      return {
        ...state,
        itemList: action.payload,
      }
    }
    case ActionTypes.GET_ADDRESSES_FOR_COMPANY_REQUEST:
      return {
        ...state,
        itemListForCompany: [],
        itemListForCompanyId: action.payload.companyId,
      }
    case ActionTypes.GET_ADDRESSES_FOR_COMPANY_SUCCESS:
      if (
        action.payload.length &&
        // payload in the item list is coming with the company IDs as numbers, but it's a string in the state
        (action.payload[0].company === state.itemListForCompanyId ||
          action.payload[0].company.toString() === state.itemListForCompanyId)
      ) {
        return {
          ...state,
          itemListForCompany: action.payload,
        }
      }
      return state
    case ActionTypes.ADD_ADDRESS_SUCCESS: {
      const itemList = [action.payload, ...state.itemList] // for a regular user
      const itemListForCompany = [action.payload, ...state.itemListForCompany] // for the empto user
      return {
        ...state,
        item: itemInitialState,
        itemListForCompany,
        itemList,
      }
    }
    case ActionTypes.UPDATE_ADDRESS_SUCCESS: {
      const itemList = state.itemList.map(address =>
        address.id === action.payload.id ? action.payload : address,
      )
      return {
        ...state,
        item: itemInitialState,
        itemList,
        isEditing: false,
      }
    }
    case ActionTypes.BLOCK_ADDRESS_SUCCESS: {
      const itemList = state.itemList.map(address =>
        address.id === action.payload.id
          ? { ...address, status: action.payload.status }
          : address,
      )
      return {
        ...state,
        item: action.payload,
        itemList,
      }
    }
    case ActionTypes.GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_SUCCESS: {
      return {
        ...state,
        agreementInquiredCollectionAddressList: action.payload,
      }
    }
    case ActionTypes.GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_SUCCESS: {
      return {
        ...state,
        orderInquiredCollectionAddressList: action.payload,
      }
    }
    case ActionTypes.GET_ADDRESSES_WITH_ORDERS_SUCCESS: {
      return {
        ...state,
        itemListWithOrders: action.payload,
      }
    }
    default:
      return state
  }
}

export default addressReducer
