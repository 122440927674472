import { Form, Formik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import { createLoadingSelector } from 'selectors/loading'
import { getCancelledPaidDocumentSelector } from 'selectors/maklerpremium'
import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import {
  getCancelledPaidDocument,
  resetCancelledPaidDocument,
  updateCancelledPaidDocument,
} from 'actions/maklerpremium'

import { InvoiceCheckPositionReview } from '../../common/DocumentReview/InvoiceCheckPositionReview'
import SaveInternalCommentModal from '../../invoicecheck/InvoiceCheckPositionReviewPage/components/SaveInternalCommentModal'
import { INVOICE_CHECK_POSITION_STATUS } from '../../invoicecheck/InvoiceCheckPositionReviewPage/constants'

import { CancelPaidDocumentReviewForm } from './CancelPaidDocumentReviewForm'

export type CancelledPaidDocumentReviewFormValues = Partial<{
  id: React.ReactText
  internal_note: string
  reject_reason: string
  review_message: string
  status: React.ReactText
}>

export const CancelledPaidDocumentReviewPage: FC = () => {
  const { cancelPaidDocumentID } = useParams<{ cancelPaidDocumentID: string }>()
  const dispatch = useDispatch()
  const history = useHistory()

  const document = useSelector(getCancelledPaidDocumentSelector)

  const [displayModal, setDisplayModal] = useState(false)
  const [isReview, setIsReview] = useState(false)

  const isLoading = {
    getCancelledPaidDocument: useSelector(
      createLoadingSelector(['GET_CANCELLEDPAIDDOCUMENT']),
    ),
    updateCancelledPaidDocument: useSelector(
      createLoadingSelector(['SAVE_CANCELLEDPAIDDOCUMENT']),
    ),
  }

  useEffect(() => {
    dispatch(getCancelledPaidDocument(cancelPaidDocumentID))
    return () => {
      dispatch(resetCancelledPaidDocument())
    }
  }, [cancelPaidDocumentID, dispatch])

  useEffect(() => {
    if (document) {
      setIsReview(
        document.status === INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING ||
          !document.reviewed_at,
      )
    }
  }, [document])

  const getPageInformation = (): {
    breadcrumb: string
    pageTitle: string
    documentTitle: string
  } => ({
    breadcrumb: I18n.t(
      'CancelledPaidDocumentReviewPageTranslations.breadcrumb.title',
    ),
    pageTitle: I18n.t('CancelledPaidDocumentReviewPageTranslations.pageTitle'),
    documentTitle: I18n.t(
      'CancelledPaidDocumentReviewPageTranslations.documentTitle',
    ),
  })

  const handleSaveInternalNote = (internalNoteValue: string) => {
    dispatch(
      updateCancelledPaidDocument(
        {
          id: document.id,
          internal_note: internalNoteValue,
        },
        document.id,
        history,
      ),
    )
    setDisplayModal(!displayModal)
  }

  if (!document) {
    return (
      <div className='uk-flex uk-flex-center uk-margin-large-top'>
        <Spinner name='circle' />
      </div>
    )
  }

  return (
    <>
      <div className='invoice_check_position_redeclarion-review-page'>
        <Formik
          initialValues={{
            internal_note: document.internal_note ?? '',
            status: String(INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING),
            review_message: '',
            reject_reason: '',
          }}
          validationSchema={() =>
            yup.object().shape({
              internal_note: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
              status: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
              review_message: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
              reject_reason: yup
                .string()
                // eslint-disable-next-line no-template-curly-in-string
                .typeError('${value} ist nicht vom Typ ${type}'),
            })
          }
          validate={(values: CancelledPaidDocumentReviewFormValues) => {
            const errors: {
              internal_note?: string
              status?: string
              review_message?: string
              reject_reason?: string
            } = {}

            if (
              Number(values.status) ===
              INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
            ) {
              errors.status = I18n.t(
                'CancelledPaidDocumentReviewPageTranslations.validation.status',
              )
            }

            if (
              Number(values.status) ===
              INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED
            ) {
              if (values.reject_reason === '') {
                errors.reject_reason = I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.validation.rejectReason',
                )
              }
            }

            return errors
          }}
          onSubmit={values => {
            const valuesToSend = {
              ...values,
            }
            if (
              Number(valuesToSend.status) !==
              INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED
            ) {
              valuesToSend.reject_reason = ''
            }
            dispatch(
              updateCancelledPaidDocument(valuesToSend, document.id, history),
            )
          }}
        >
          {({ submitCount, handleSubmit, isSubmitting, values, isValid }) => (
            <>
              <InvoiceCheckPositionReview
                breadcrumb={{
                  breadcrumbTitle: getPageInformation().breadcrumb,
                  prevLinkTitle: I18n.t(
                    'CancelledPaidDocumentReviewPageTranslations.breadcrumb.prevLinkTitle',
                  ),
                  prevLinkTo: '/workload',
                  teaserText: '',
                  teaserTitle: '',
                }}
                pageTitle={getPageInformation().pageTitle}
                documentTitle={getPageInformation().documentTitle}
                formSubmissionButton={{
                  buttonText:
                    isReview &&
                    Number(values.status) ===
                      INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
                      ? I18n.t(
                          'InvoiceCheckPositionReviewPageTranslations.submitButtonText.isSaving',
                        )
                      : I18n.t(
                          'InvoiceCheckPositionReviewPageTranslations.submitButtonText.isReviewing',
                        ),
                  action:
                    isReview &&
                    Number(values.status) ===
                      INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
                      ? () => setDisplayModal(!displayModal)
                      : handleSubmit,
                  isLoading:
                    isSubmitting && isLoading.updateCancelledPaidDocument,
                  isDisabled:
                    (submitCount > 0 && !isValid) ||
                    (isSubmitting && isLoading.updateCancelledPaidDocument),
                }}
                showFormSubmitButton={isReview}
                showOrderDetails
                reviewDocument={document}
              >
                <Form
                  className='uk-panel order-claim-review-form'
                  data-testid='order-claim-review-form'
                  noValidate
                >
                  <CancelPaidDocumentReviewForm
                    isReview={isReview}
                    reviewObject={document}
                  />
                </Form>
              </InvoiceCheckPositionReview>

              <SaveInternalCommentModal
                openModal={displayModal}
                handleSaveInternalNote={() =>
                  handleSaveInternalNote(values.internal_note ?? '')
                }
                handleModalToggle={() => setDisplayModal(!displayModal)}
                isCancelledPaidDocument
                isRelabeled={false}
              />
            </>
          )}
        </Formik>
      </div>
    </>
  )
}

export const CancelledPaidDocumentReviewPageComponent = withErrorBoundary(
  withApiErrorHandling(
    CancelledPaidDocumentReviewPage,
    ['GET_CANCELLEDPAIDDOCUMENT'],
    ['GET_CANCELLEDPAIDDOCUMENT'],
  ),
)
