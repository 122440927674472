import ActionTypes from '../actiontypes/user'

/** @function
 * @name registerUser
 * @description An action method to create a user.
 * @return {Object}
 */
export const registerUser = (userInfo, history, callback) => ({
  type: ActionTypes.REGISTER_USER_REQUEST,
  payload: {
    userInfo,
    history,
    callback,
  },
})

/** @function
 * @name inviteUser
 * @description An action method to invite a user.
 * @return {Object}
 */
export const inviteUser = (userInfo, callback) => ({
  type: ActionTypes.INVITE_USER_REQUEST,
  payload: {
    userInfo,
    callback,
  },
})

/** @function
 * @name activateCurrentUser
 * @description An action method to activate a user.
 * @param uid
 * @param token
 * @return {Object}
 */
export const activateCurrentUser = (uid, token) => ({
  type: ActionTypes.ACTIVATE_CURRENT_USER_REQUEST,
  payload: {
    uid,
    token,
  },
})

/** @function
 * @name login
 * @description An action method to login a user.
 * @param username
 * @param password
 * @param stayLogged
 * @return {Object}
 */
export const login = (username, password, stayLogged = false) => ({
  type: ActionTypes.LOGIN_REQUEST,
  payload: {
    username,
    password,
    stayLogged,
  },
})

/** @function
 * @name getCurrentUser
 * @description An action method to get the current logged in user.
 * @return {Object}
 */
export const getCurrentUser = () => ({
  type: ActionTypes.GET_CURRENT_USER_REQUEST,
})

/** @function
 * @name getUser
 * @description An action method to get a user by its id.
 * @return {Object}
 */
export const getUser = id => ({
  type: ActionTypes.GET_USER_REQUEST,
  payload: id,
})

/** @function
 * @name getMyUsers
 * @description An action method to get a list of users from my company.
 * @return {Object}
 */
export const getMyUsers = (page, sortedBy, filters) => ({
  type: ActionTypes.GET_MY_USERS_REQUEST,
  payload: {
    page,
    sortedBy,
    filters,
  },
})

/** @function
 * @name getMyUsers
 * @description An action method to get a list of users from a specified company.
 * @return {Object}
 */
// the reason we are sending "avoid_pagination" is because we might want to get all the users without a paginated
// response, to be used in a select dropdown.
// We could rely only on company since GET_MY_USERS_REQUEST does not send a company, but in case we are an empto user
// filtering for other empto users, we are not sending a company (its undefined), which will give us a paginated
// response again. Using a condition only to verify if user is a platform admin is not enough.
export const getCompanyUsers = (
  company,
  avoid_pagination = true,
  name = '',
) => ({
  type: ActionTypes.GET_COMPANY_USERS_REQUEST,
  payload: {
    company,
    avoid_pagination,
    name,
  },
})

/** @function
 * @name requestPasswordReset
 * @description An action method to send reset password link to a user.
 * @return {Object}
 */
export const requestPasswordReset = (email, history) => ({
  type: ActionTypes.PASSWORD_RESET_REQUEST,
  payload: {
    email,
    history,
  },
})

/** @function
 * @name confirmNewPassword
 * @description An action method to set new password to a user.
 * @return {Object}
 */
export const confirmNewPassword = (
  uid,
  token,
  password,
  passwordRepeat,
  history,
  callback,
) => ({
  type: ActionTypes.PASSWORD_RESET_CONFIRM_REQUEST,
  payload: {
    uid,
    token,
    new_password1: password,
    new_password2: passwordRepeat,
    history,
    callback,
  },
})

/** @function
 * @name setPassword
 * @description An action method to set password to a user.
 * @param uid
 * @param token
 * @param password
 * @param callback
 * @return {Object}
 */
export const setPassword = (uid, token, password, callback) => ({
  type: ActionTypes.SET_PASSWORD_REQUEST,
  payload: {
    uid,
    token,
    new_password1: password,
    new_password2: password,
    callback,
  },
})

/** @function
 * @name logout
 * @description An action method to logout a user.
 * @return {Object}
 */
export const logout = history => ({
  type: ActionTypes.LOGOUT_REQUEST,
  payload: {
    history,
  },
})

/** @function
 * @name updateAccount
 * @description An action method to update the currently logged in user's account.
 * @return {Object}
 */
export const updateAccount = user => ({
  type: ActionTypes.UPDATE_CURRENT_USER_REQUEST,
  payload: user,
})

/** @function
 * @name updateUser
 * @description An action method to update a user (e.g. in my company).
 * @return {Object}
 */
export const updateUser = (user, callback) => ({
  type: ActionTypes.UPDATE_USER_REQUEST,
  payload: {
    user,
    callback,
  },
})

/** @function
 * @name changePassword
 * @description An action method to change user password.
 * @return {Object}
 */
export const changePassword = (oldPassword, newPassword, callback) => ({
  type: ActionTypes.CHANGE_PASSWORD_REQUEST,
  payload: {
    old_password: oldPassword,
    new_password: newPassword,
    callback,
  },
})

/**
 * @function
 * @name exportUsers
 * @return {Object}
 */
export const exportUsers = filters => ({
  type: ActionTypes.EXPORT_USERS_REQUEST,
  payload: {
    filters,
  },
})

/** @function
 * @name getUsersByCompany
 * @description An action method to get a users by company ID.
 * @return {Object}
 */
export const getUsersByCompany = companyId => ({
  type: ActionTypes.GET_USERS_BY_COMPANY_REQUEST,
  payload: companyId,
})

/** @function
 * @name resetUserIsContactPerson.
 * @returns {object}
 */
export const resetUserIsContactPerson = () => ({
  type: ActionTypes.RESET_USER_IS_CONTACT_PERSON,
})

/** @function
 * @name resetUser.
 * @returns {object}
 */
export const resetUser = () => ({
  type: ActionTypes.RESET_USER,
})

export default {}
