import 'react-table/react-table.css'

import moment from 'moment/moment'
import { arrayOf, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Form } from 'react-redux-form'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import TextboxTypes from 'components/common/Form/components/TextboxControl/constants'
import { smallerThenThousand } from 'components/common/Form/components/TextboxControl/helpers'
import TextboxControl from 'components/common/Form/components/TextboxControl'
import { ProgressButton } from 'components/common/ProgressButton'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { UserPermission } from 'constants/user'
import {
  OfferIndexAddScheme,
  OfferIndexChangeFormScheme,
  OfferIndexChangeScheme,
  OfferIndexScheme,
} from 'schemes/offer'

import { ChangeIndexValueFormValidators } from '../../constants'

import connector from './connector'

class ChangeIndexValueFormModalComponent extends Component {
  static propTypes = {
    actions: shape({
      indexes: shape({
        changeIndexValues: func.isRequired,
        addIndexValue: func.isRequired,
      }).isRequired,
      form: shape({
        change: func.isRequired,
      }).isRequired,
    }).isRequired,
    indexChange: shape(OfferIndexChangeScheme),
    indexChangeForm: shape(OfferIndexChangeFormScheme),
    indexList: arrayOf(shape(OfferIndexScheme)),
    handleModalToggle: func.isRequired,
    modalData: shape(OfferIndexAddScheme),
  }

  static defaultProps = {
    indexList: [],
    indexChange: {},
    indexChangeForm: {},
    modalData: {},
  }

  UNSAFE_componentWillMount() {
    this.props.actions.form.change('indexes.item', this.props.modalData)
  }

  /**
   * Handles clicking the change button.
   */
  onChange = () => {
    // TODO @Tim: Replace React-Redux-Form's magic submit button handling
  }

  /**
   * @description Submit the value change (change index value).
   */
  handleSubmitChange = () => {
    if (!this.props.modalData.id) {
      this.props.actions.indexes.addIndexValue([
        {
          index: this.props.indexList.find(
            object => object.name === this.props.indexChange.index,
          ).id,
          value: this.props.indexChange.value,
          month: this.props.indexChange.month,
          year: this.props.indexChange.year,
        },
      ])
    } else {
      this.props.actions.indexes.changeIndexValues([
        {
          id: this.props.indexChange.id,
          value: this.props.indexChange.value,
        },
      ])
    }
    this.props.handleModalToggle()
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return (
      <div className='index-values-change-form-page uk-align-center'>
        <Form
          className='uk-panel'
          model={this.props.indexChangeForm.$form.model}
          onSubmit={this.handleSubmitChange}
          validators={ChangeIndexValueFormValidators}
          validateOn={
            this.props.indexChangeForm.$form.submitFailed ? 'change' : 'submit'
          }
          data-pending={this.props.indexChangeForm.$form.pending}
        >
          <div className='index-value-change-form-page uk-modal-body'>
            <div className='uk-width-1-1 uk-child-width-1-2 uk-grid uk-text-center'>
              <div className='uk-text-bold'>{this.props.modalData.index}</div>
              <div className='uk-text-bold'>
                {/* momentjs works with months from 0 to 11 and we have from 1 to 12 in the DB.
                That is why i am subtracting 1 */}
                {I18n.t(
                  `constant.date.months.${moment({
                    month: this.props.modalData.month - 1,
                  })
                    // lang need to be set to english so the translation key in the translation file work properly.
                    .lang('en')
                    .format('MMMM')}`,
                  { year: this.props.modalData.year },
                )}
              </div>
            </div>
            <hr className='uk-divider-icon' />
            {/* Value */}
            <TextboxControl
              className='uk-input'
              field={this.props.indexChangeForm.value}
              type={TextboxTypes.NUMBER}
              addMapProps={{
                isAllowed: () => values => smallerThenThousand(values),
              }}
              placeholder={I18n.t('indexValuesPage.addValueForm.value')}
              validators={ChangeIndexValueFormValidators.value}
              errorMessages={{
                isRequired: I18n.t('message.validation.required'),
                isValidFloat: I18n.t('message.validation.wrongRegex'),
              }}
              allowNegative
              maxLength={6}
            />

            <div className='uk-modal-footer uk-text-right'>
              <RequiredPermissions
                requiredPermissions={[UserPermission.CHANGE_INDEXVALUES]}
              >
                <ProgressButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  isDisabled={
                    !this.props.indexChangeForm.$form.valid ||
                    this.props.indexChangeForm.$form.pending
                  }
                  isLoading={this.props.indexChangeForm.$form.pending}
                  onClick={this.onChange}
                  type='submit'
                >
                  <Translate value='indexValuesPage.addValueForm.changeButton' />
                </ProgressButton>
              </RequiredPermissions>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default connector(ChangeIndexValueFormModalComponent)
