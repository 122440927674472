import React, { FC, useContext } from 'react'

import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import PageHeader from 'components/layout/PageHeader'
import { UserPermission } from 'constants/user'

import { MarkupPageContext } from './MarkupPage'

export const Header: FC = () => {
  const { setExportModalOpen, setImportModalOpen } =
    useContext(MarkupPageContext)

  return (
    <MarkupPageContext.Consumer>
      {() => (
        <div className='page-header'>
          <PageHeader title={'Postleitzahlen und ihre Aufschläge'}>
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <RequiredPermissions
                requiredPermissions={[UserPermission.VIEW_MARKUP]}
              >
                <IconButton
                  iconName='download'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    setImportModalOpen(true)
                  }}
                >
                  Import
                </IconButton>
              </RequiredPermissions>

              <RequiredPermissions
                requiredPermissions={[UserPermission.EDIT_MARKUP]}
              >
                <IconButton
                  iconName='export'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    setExportModalOpen(true)
                  }}
                >
                  Export
                </IconButton>
              </RequiredPermissions>
            </ButtonBar>
          </PageHeader>
        </div>
      )}
    </MarkupPageContext.Consumer>
  )
}
