import { agreementRoute } from './agreement'
import { autoOfferRoute } from './autooffer'
import { certificateRoutes } from './certificate'
import { claimProofRoutes } from './claimproof'
import { companyRoutes } from './company'
import { dashboardRoutes } from './dashboard'
import { discountRoute } from './discount'
import { dsztRoute } from './dsz'
import { executionProofRoutes } from './executionproof'
import { generalRoutes } from './general'
import { invoiceCheckRoutes } from './invoicecheck'
import { maklerpremiumRoutes } from './maklerpremium'
import { offerRoute } from './offer'
import { orderRoutes } from './order'
import { placeRoutes } from './place'
import { userRoute } from './user'
import { workloadRoutes } from './workload'

/**
 * @constant
 * @description A constant to define all routes.
 */
export const routes = [
  ...agreementRoute,
  ...autoOfferRoute,
  ...certificateRoutes,
  ...claimProofRoutes,
  ...companyRoutes,
  ...dashboardRoutes,
  ...discountRoute,
  ...dsztRoute,
  ...executionProofRoutes,
  ...generalRoutes,
  ...invoiceCheckRoutes,
  ...maklerpremiumRoutes,
  ...offerRoute,
  ...orderRoutes,
  ...placeRoutes,
  ...userRoute,
  ...workloadRoutes,
]
