import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'

import { AttachmentScheme } from '../components/common/SingleFileUpload/schemes'
import { FieldScheme } from '../components/common/Form/schemes'

import { AddressSmallScheme } from './address'
import { CompanySmallScheme } from './company'
import { UserSmallScheme } from './user'

/**
 * This scheme is never returned by the api but contains the data that
 * inquiry, offer and auto offer share.
 * @type {shim}
 */

export const AbstractInquiryScheme = {
  container: number,
  fine_fraction: oneOfType([number, string]),
  fraction: oneOfType([number, string]),
  id: number,
  number_of_containers: oneOfType([number, string]),
  with_estimated_time_of_arrival: bool,
  with_top: bool,
  security_group: number,
}

export const AbstractInquiryFormScheme = {
  $form: shape({
    submitFailed: bool,
  }).isRequired,
  id: FieldScheme,
  container: FieldScheme,
  fine_fraction: FieldScheme,
  fraction: FieldScheme,
  number_of_containers: FieldScheme,
  security_group: FieldScheme,
  with_estimated_time_of_arrival: FieldScheme,
  with_top: FieldScheme,
}

export const InquirySmallScheme = {
  active_offer_id: number,
  canceled_by_empto: number,
  collection_address_object: shape(AddressSmallScheme), // This is the full object (read only)
  company_object: shape(CompanySmallScheme), // This is the full object (read only)
  created_at: string,
  created_by_empto: number, // The ID of the empto user who created this item.
  fraction: oneOfType([number, string]),
  id: number,
  my_offers_count: number, // The number of offers created by my company.
  offers_count: number, // The number of offers for this inquiry.
  open_offers_count: number, // The number of open offers for this inquiry.
  order_type: oneOfType([number, string]),
  service_start_date: string, // The date of the actual start of the service depending on the order type.
  time_of_day_delivery: oneOfType([number, string]),
  uncancelled_offers_count: number, // The number of uncancelled offers for this inquiry.
}

export const InquiryScheme = {
  ...AbstractInquiryScheme,
  accepted_offer_id: number,
  active_offer_id: number,
  additional_contact_person: string,
  attachments: arrayOf(shape(AttachmentScheme)),
  canceled_by_empto: bool,
  cancel_reason: string,
  collection_address: oneOfType([number, string]), // This is only the ID (write only)
  collection_address_object: shape(AddressSmallScheme), // This is the full object (read only)
  collection_date: string,
  company: oneOfType([number, string]), // This is only the ID (write only)
  company_object: shape(CompanySmallScheme),
  created_at: string,
  created_by_empto: number, // The ID of the empto user who created this item.
  created_by_object: shape(UserSmallScheme),
  delivery_date: string,
  interval: oneOfType([number, string]),
  my_offers_count: number, // The number of offers created by my company.
  offers_count: number, // The number of offers for this inquiry.
  open_offers_count: number, // The number of open offers for this inquiry.
  order_type: oneOfType([number, string]),
  phone_user: oneOfType([number, string]),
  position_on_public_ground: bool,
  runtime_of_inquiry: string,
  service_start_date: string, // The date of the actual start of the service depending on the order type.
  special_note: string,
  status: oneOfType([number, string]),
  status_changed_at: string,
  status_changed_by_object: shape(UserSmallScheme),
  time_of_day_collection: oneOfType([number, string]),
  time_of_day_delivery: oneOfType([number, string]),
  turn_begin: string,
  turn_end: string,
  uncancelled_offers_count: number, // The number of open offers for this inquiry.
}

export const InquiryFilterScheme = {
  radius__lte: number,
}

export const InquiryFormScheme = {
  ...AbstractInquiryFormScheme,
  additional_contact_person: FieldScheme,
  attachments: FieldScheme,
  cancel_reason: FieldScheme,
  collection_address: FieldScheme,
  collection_date: FieldScheme,
  delivery_date: FieldScheme,
  interval: FieldScheme,
  order_type: FieldScheme,
  position_on_public_ground: FieldScheme,
  runtime_of_inquiry: FieldScheme,
  special_note: FieldScheme,
  time_of_day_collection: FieldScheme,
  time_of_day_delivery: FieldScheme,
  turn_begin: FieldScheme,
  turn_end: FieldScheme,
}
