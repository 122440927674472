import ActionTypes from '../actiontypes/address'

/** @function
 * @name getAddresses
 * @description An action method to get a list of collection addresses.
 * @return {Object}
 */
export const getAddresses = (page, sortedBy, filters) => ({
  type: ActionTypes.GET_ADDRESSES_REQUEST,
  payload: {
    page,
    sortedBy,
    filters,
  },
})

/** @function
 * @name getActiveAddresses
 * @description An action method to get a list of active collection addresses.
 * @return {Object}
 */
export const getActiveAddresses = filters => ({
  type: ActionTypes.GET_ACTIVE_ADDRESSES_REQUEST,
  payload: filters,
})

/** @function
 * @name getWastemanagerAddresses
 * @description An action method to get a list of collection addresses with active and inactive zip code.
 * @return {Object}
 */
export const getWastemanagerAddresses = company => ({
  type: ActionTypes.GET_WASTEMANAGER_ADDRESSES_REQUEST,
  payload: {
    company,
  },
})

/** @function
 * @name getAddressListForCompany
 * @description An action method to get the list of collection addresses for a company.
 * @return {Object}
 */
export const getAddressListForCompany = (companyId, filters) => ({
  type: ActionTypes.GET_ADDRESSES_FOR_COMPANY_REQUEST,
  payload: {
    companyId,
    filters,
  },
})

/** @function
 * @name addAddress
 * @description An action method to add a collection address.
 * @return {Object}
 */
export const addAddress = (address, callbacks) => ({
  type: ActionTypes.ADD_ADDRESS_REQUEST,
  payload: {
    address,
    callbacks,
  },
})

/** @function
 * @name updateAddress
 * @description An action method to update a collection address.
 * @return {Object}
 */
export const updateAddress = (address, callbacks) => ({
  type: ActionTypes.UPDATE_ADDRESS_REQUEST,
  payload: {
    address,
    callbacks,
  },
})

/** @function
 * @name blockAddress
 * @description An action method to cancel an address.
 * @return {Object}
 */
export const blockAddress = address => ({
  type: ActionTypes.BLOCK_ADDRESS_REQUEST,
  payload: address,
})

/** @function
 * @name getMyAgreementInquiredCollectionAddresses
 * @description An action method to get the addresses of my agreements.
 * @return {Object}
 */
export const getMyAgreementInquiredCollectionAddresses = () => ({
  type: ActionTypes.GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_REQUEST,
})

/** @function
 * @name getAddressesWithOrders
 * @description An action method to get a list of collection addresses with a list of order numbers.
 * @return {Object}
 */
export const getAddressesWithOrders = filters => ({
  type: ActionTypes.GET_ADDRESSES_WITH_ORDERS_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name exportAddresses
 * @return {Object}
 */
export const exportAddresses = (filters, sortedBy) => ({
  type: ActionTypes.EXPORT_ADDRESSES_REQUEST,
  payload: {
    filters,
    sortedBy,
  },
})
