import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import InvoiceCheckTypes from '../actiontypes/invoicecheck'
import { INVOICE_CHECK_STATUSES } from '../components/invoicecheck/constants'
import { OrderType } from '../components/invoicecheck/InvoiceCheckOverview/InvoiceCheckPositionsForm/Step2'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

// Send avv as raw number ("15 01 10*" -> "150110")
const cleanAvvBeforeSend = data => {
  const newData = { ...data }
  if (typeof data.avv !== 'undefined' && data.avv !== null) {
    newData.avv = data.avv.replace(/[ *]/g, '')
  }
  return newData
}

// net_price is a string, we need to convert it to number for the backend
const convertOtherServiceNetPriceToNumber = data => {
  const newData = { ...data }
  if ([OrderType.OTHER_SERVICE, OrderType.RENT].includes(data.order_type)) {
    newData.net_price = Number(data.net_price.replace(',', '.'))
  }
  return newData
}

/**
 * @function
 * @name handleCreateInvoiceCheck
 * @description Creates a new InvoiceCheck Record
 */
export const handleCreateInvoiceCheck = createLogic({
  type: InvoiceCheckTypes.CREATE_INVOICE_CHECK_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { createInvoiceCheckData } = action.payload
    createInvoiceCheckData.gross_price = parseFloat(
      createInvoiceCheckData.gross_price.replace(',', '.'),
    )

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/`,
        createInvoiceCheckData,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.CREATE_INVOICE_CHECK_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateInvoiceCheckRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError
        const duplicateErrors = [
          'The fields document_number, document_date, company must make a unique set.',
          'Die Felder document_number, document_date, company müssen eine eindeutige Menge bilden.',
        ]

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckRequest.forbidden',
          )
        } else if (
          err.response.data !== undefined &&
          err.response.data.non_field_errors !== undefined &&
          err.response.data.non_field_errors.some(item =>
            duplicateErrors.includes(item),
          )
        ) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckRequest.duplicate',
          )
          err.hideToastr = true
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckRequest.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.CREATE_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateInvoiceCheck
 * @description Creates a new InvoiceCheck Record
 */
export const handleUpdateInvoiceCheck = createLogic({
  type: InvoiceCheckTypes.UPDATE_INVOICE_CHECK_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { updateInvoiceCheckData } = action.payload
    updateInvoiceCheckData.gross_price = parseFloat(
      updateInvoiceCheckData.gross_price.replace(',', '.'),
    )

    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/${updateInvoiceCheckData.id}/`,
        updateInvoiceCheckData,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.UPDATE_INVOICE_CHECK_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleUpdateInvoiceCheckRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckRequest.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.UPDATE_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceChecks
 * @description gets list of invoicechecks
 */
export const handleGetInvoiceChecks = createLogic({
  type: InvoiceCheckTypes.GET_INVOICE_CHECKS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        order_by: JSON.stringify([
          { id: 'status', desc: false },
          { id: 'created_at', desc: false },
        ]),
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECKS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceChecks.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECKS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheck
 * @description gets invoicecheck by id
 */
export const handleGetInvoiceCheck = createLogic({
  type: InvoiceCheckTypes.GET_INVOICE_CHECK_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    let expand = ''
    if (action.payload) {
      data = { invoiceCheckId: action.payload.invoiceCheckId }
      expand = action.payload.expand.join()
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/${data.invoiceCheckId}/?expand=${expand}`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceChecks.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckForCoveredOrder
 * @description gets invoicecheck by id
 */
export const handleGetInvoiceCheckForAlreadyCoveredOrder = createLogic({
  type: InvoiceCheckTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: 1,
        order_and_not_rejected_invoice_checks: action.payload.orderId,
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceChecks.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckPosition
 * @description gets a single invoiceCheckPosition by ID
 */
export const handleGetInvoiceCheckPosition = createLogic({
  type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    let data = {
      ...action.payload,
    }

    if (action.payload?.expand) {
      data = { ...data, expand: action.payload.expand.join(',') }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/${action.payload.id}/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITION_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckPositions
 * @description gets list of invoicecheckpositions
 */
export const handleGetInvoiceCheckPositions = createLogic({
  type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITIONS_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITIONS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITIONS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateInvoiceCheckPosition
 * @description Creates a new InvoiceCheckPosition Record
 */
export const handleCreateInvoiceCheckPosition = createLogic({
  type: InvoiceCheckTypes.CREATE_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    // eslint-disable-next-line no-param-reassign
    action.payload.data = cleanAvvBeforeSend(action.payload.data)
    // eslint-disable-next-line no-param-reassign
    action.payload.data = convertOtherServiceNetPriceToNumber(
      action.payload.data,
    )

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.CREATE_INVOICE_CHECK_POSITION_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckPositionRequest.success',
          ),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckPositionRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckPositionRequest.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.CREATE_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateInvoiceCheckPosition
 * @description Updates a new InvoiceCheckPosition Record
 */
export const handleUpdateInvoiceCheckPosition = createLogic({
  type: InvoiceCheckTypes.UPDATE_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    // eslint-disable-next-line no-param-reassign
    action.payload.data = cleanAvvBeforeSend(action.payload.data)
    // eslint-disable-next-line no-param-reassign
    action.payload.data = convertOtherServiceNetPriceToNumber(
      action.payload.data,
    )
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/${action.payload.id}/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.UPDATE_INVOICE_CHECK_POSITION_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckPositionRequest.success',
          ),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckPositionRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckPositionRequest.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.UPDATE_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleDeleteInvoiceCheckPosition
 * @description Deletes a new InvoiceCheckPosition Record
 */
export const handleDeleteInvoiceCheckPosition = createLogic({
  type: InvoiceCheckTypes.DELETE_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .delete(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/${action.payload.id}/`,
      )
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: InvoiceCheckTypes.DELETE_INVOICE_CHECK_POSITION_SUCCESS,
          payload: {
            id: action.payload.id,
          },
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleDeleteInvoiceCheckPositionRequest.success',
          ),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleDeleteInvoiceCheckPositionRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleDeleteInvoiceCheckPositionRequest.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.DELETE_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckPositionPrice
 * @description Gets a new InvoiceCheckPosition Price
 */
export const handleGetInvoiceCheckPositionPrice = createLogic({
  type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITION_PRICE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    // eslint-disable-next-line no-param-reassign
    action.payload.data = cleanAvvBeforeSend(action.payload.data)
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/calculate-net-price/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITION_PRICE_SUCCESS,
          payload: {
            ...res,
          },
        })
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleGetInvoiceCheckPositionPrice.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleGetInvoiceCheckPositionPrice.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.GET_INVOICE_CHECK_POSITION_PRICE_ERROR,
          payload,
        })

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateReviewedInvoiceCheckPosition
 * @description Updates a reviewed invoice check position
 */
export const handleUpdateReviewedInvoiceCheckPosition = createLogic({
  type: InvoiceCheckTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check-position/${action.payload.id}/review/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_SUCCESS,
          payload: res,
        })

        let message = I18n.t(
          'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.relabeled.success',
        )
        if (res.container_changed) {
          message = I18n.t(
            'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.containerChanged.success',
          )
        } else if (res.additional_rent) {
          message = I18n.t(
            'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.additionalRent.success',
          )
        }
        toastr.success('', message)
      })
      .then(() => action.payload.history.goBack())
      .then(done)
      .catch(err => {
        dispatch(
          actions.setPending('maklerpremium.invoiceCheckPosition.item', false),
        )
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleEPDExportBatchFile
 * @description triggers the invoice export for epd.
 */
export const handleExportInvoiceCheckBatchFile = createLogic({
  type: InvoiceCheckTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload.filters) {
      data = { ...data, filters: JSON.stringify(action.payload.filters) }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/export-batch-file/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceCheckTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_SUCCESS,
        })

        toastr.success(
          '',
          I18n.t('api.invoice.handleExportInvoiceCheckBatchFile.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleExportInvoiceCheckBatchFile.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportInvoiceCheck
 * @description triggers the invoice check export.
 */
export const handleExportInvoiceCheck = createLogic({
  type: InvoiceCheckTypes.EXPORT_INVOICE_CHECK_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/export/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceCheckTypes.EXPORT_INVOICE_CHECK_SUCCESS,
        })
        toastr.success(
          '',
          I18n.t('api.invoice.handleExportInvoiceCheck.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleExportInvoiceCheck.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.EXPORT_INVOICE_CHECK_ERROR,
          payload,
        })
        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleReopenInvoiceCheck
 * @description reopens an InvoiceCheck.
 */
export const handleReopenInvoiceCheck = createLogic({
  type: InvoiceCheckTypes.REOPEN_INVOICE_CHECK_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {
      status: INVOICE_CHECK_STATUSES.STATUS_REOPENED,
    }
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoicecheck/invoice-check/${action.payload.invoiceCheckId}/`,
        data,
      )
      .then(res => {
        dispatch({
          type: InvoiceCheckTypes.REOPEN_INVOICE_CHECK_SUCCESS,
          payload: {
            ...res.data,
          },
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleReopenInvoiceCheckRequest.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceCheckTypes.REOPEN_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleCreateInvoiceCheck,
  handleCreateInvoiceCheckPosition,
  handleDeleteInvoiceCheckPosition,
  handleExportInvoiceCheck,
  handleExportInvoiceCheckBatchFile,
  handleGetInvoiceCheck,
  handleGetInvoiceCheckForAlreadyCoveredOrder,
  handleGetInvoiceCheckPosition,
  handleGetInvoiceCheckPositionPrice,
  handleGetInvoiceCheckPositions,
  handleGetInvoiceChecks,
  handleReopenInvoiceCheck,
  handleUpdateInvoiceCheck,
  handleUpdateInvoiceCheckPosition,
  handleUpdateReviewedInvoiceCheckPosition,
]
