import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import uniqueId from 'lodash.uniqueid'
import { bool, func, node, number, oneOf, shape, string } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { I18n } from 'react-i18nify'

import { TooltipIconProps } from '../TooltipIcon/schemes'
import Icon from '../Fontello'
import { IconButton } from '../IconButton'
import { Input, INPUT_BORDER_COLOR, INPUT_TYPE, KEY_ENTER } from '../Input'
import { Tooltip, TOOLTIP_TRIGGER, TOOLTIP_ZINDEX } from '../Tooltip'

const CAPS_LOCK = 'CapsLock'
const KEY_UP = 'keyup'

export const InputPassword = ({
  borderColor = INPUT_BORDER_COLOR.DEFAULT,
  dataTestId = '',
  dataTestIdError = '',
  error = '',
  hideLabel = false,
  isRequired = false,
  label,
  maxLength = 255,
  name,
  onBlur,
  onChange,
  onSubmit,
  placeholder = '',
  tooltipZIndex = TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL,
  tooltip = null,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isCapsLockActive, setIsCapsLockActive] = useState(false)

  useEffect(() => {
    const checkCapsLockActive = event => {
      if (event.getModifierState) {
        setIsCapsLockActive(event.getModifierState(CAPS_LOCK))
      }
    }

    document.addEventListener(KEY_UP, checkCapsLockActive)
    return () => {
      document.removeEventListener(KEY_UP, checkCapsLockActive)
    }
  }, [])

  const onValueChange = event => {
    if (event.target.value.length <= maxLength) onChange(event)
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const allowSubmissionByEnter = event => {
    if (onSubmit && event && event.key === KEY_ENTER) onSubmit(event)
  }

  const keyPressEvents = event => {
    allowSubmissionByEnter(event)
  }

  const tooltipId = uniqueId() // necessary when there are more than 1 password input field

  return (
    <div className='input-password'>
      <div className='input-password__show-password'>
        <Tooltip
          duration={0}
          hideOnClick={false}
          title={
            showPassword
              ? I18n.t('message.validation.password.hidePassword')
              : I18n.t('message.validation.password.showPassword')
          }
          trigger={TOOLTIP_TRIGGER.MOUSEENTER}
          tooltipId={`password_${tooltipId}`}
          zIndex={tooltipZIndex}
        >
          <IconButton
            iconName='preview'
            onClick={toggleShowPassword}
            tabIndex={-1} // Prevent from being keyboard-accessible
          />
        </Tooltip>
      </div>
      <Input
        borderColor={borderColor}
        dataTestId={dataTestId}
        dataTestIdError={dataTestIdError}
        error={error}
        hideLabel={hideLabel}
        isRequired={isRequired}
        label={label}
        name={name}
        onBlur={onBlur}
        onChange={onValueChange}
        onKeyPress={keyPressEvents}
        placeholder={placeholder}
        type={showPassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD}
        tooltip={tooltip}
        value={value}
      />
      {isCapsLockActive && (
        <div className='input-password__caps-lock-info'>
          <Icon name='warning' />
          {I18n.t('general.capsLockOn')}
        </div>
      )}
    </div>
  )
}

InputPassword.propTypes = forbidExtraProps({
  borderColor: oneOf(Object.values(INPUT_BORDER_COLOR)),
  dataTestId: string,
  dataTestIdError: string,
  error: string,
  hideLabel: bool,
  isRequired: bool,
  label: node.isRequired,
  maxLength: number,
  name: string.isRequired,
  onBlur: func,
  onChange: func.isRequired,
  onSubmit: func,
  placeholder: string,
  tooltipZIndex: number,
  tooltip: shape(TooltipIconProps),
  value: string.isRequired,
})

export default InputPassword
