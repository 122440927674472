import keyMirror from 'keymirror'

import { OFFER_ORDER_TYPE } from '../inquiry/constants'

/**
 * These are the times of day (day slots) for quick orders.
 */
export const OFFER_STATUS = {
  STATUS_OPEN: 1,
  STATUS_CANCELLED: 2,
  STATUS_ACCEPTED: 3,
  STATUS_REJECTED: 4,
  STATUS_AGREEMENT_CANCELLED_EMPTO: 5, // Storniert durch empto
  STATUS_AGREEMENT_CANCELLED_EPD: 6, // Storniert durch EPD
}

export const OFFER_STATES = [
  {
    id: OFFER_STATUS.STATUS_OPEN,
    name: 'constant.status.valid',
  },
  {
    id: OFFER_STATUS.STATUS_CANCELLED,
    name: 'constant.status.cancelled',
  },
  {
    id: OFFER_STATUS.STATUS_ACCEPTED,
    name: 'constant.status.commitment',
  },
  {
    id: OFFER_STATUS.STATUS_REJECTED,
    name: 'constant.status.refusal',
  },
  {
    id: OFFER_STATUS.STATUS_AGREEMENT_CANCELLED_EMPTO,
    name: 'constant.status.cancelledByEmpto',
  },
  {
    id: OFFER_STATUS.STATUS_AGREEMENT_CANCELLED_EPD,
    name: 'constant.status.cancelledByEPD',
  },
]

/**
 * Auto offer status options
 */
export const AUTO_OFFER_STATUS = {
  STATUS_ACTIVE: 1, // The auto offer is active and will be applied
  STATUS_INACTIVE: 2, // The auto offer is inactive and will not be applied
}

/**
 * auto offer status settings
 */
export const AUTO_OFFER_STATES = [
  {
    id: AUTO_OFFER_STATUS.STATUS_ACTIVE,
    name: 'constant.status.active',
    colorClass: 'green',
  },
  {
    id: AUTO_OFFER_STATUS.STATUS_INACTIVE,
    name: 'constant.status.inactive',
    colorClass: 'red',
  },
]

/**
 * These are the types of orders that auto offers can have.
 */
export const AUTO_OFFER_ORDER_TYPES = [
  {
    id: OFFER_ORDER_TYPE.TYPE_ONE_TIME, // order just happens one time
    name: 'orderType.oneTime',
  },
  {
    id: OFFER_ORDER_TYPE.TYPE_QUICK, // Container is delivered, directly filled and collected.
    name: 'orderType.quick',
  },
]

/**
 * Auto offer status options
 */
export const AGREEMENT_ACTIVATION_STATUS = {
  STATUS_ACTIVE: 3, // The agreement(accepted offer) is active
  STATUS_INACTIVE: 5, // The agreement(accepted offer) inactive (canceled by empto)
}

/**
 * status of an agreement(accepted offer) state
 */
export const AGREEMENT_ACTIVATION_STATES = [
  {
    id: AGREEMENT_ACTIVATION_STATUS.STATUS_ACTIVE,
    name: 'constant.status.active',
    colorClass: 'green',
  },
  {
    id: AGREEMENT_ACTIVATION_STATUS.STATUS_INACTIVE,
    name: 'constant.status.inactive',
    colorClass: 'red',
  },
]

export const OFFER_DETAILS_MODALS = keyMirror({
  CANCEL_OFFER_MODAL: null,
  RESEND_OFFER_MODAL: null,
  ACCEPT_OFFER_MODAL: null,
})

export const OFFER_CARD_TRANSLATION_CATEGORY = {
  DETAILS: 'details',
  DASHBOARD: 'dashboard',
}

export const PRICE_MODELS = {
  DISPOSAL_FLATRATE: 'disposalFlatrate', // Entsorgung Pauschalpreis
  DISPOSAL_SINGLE: 'disposalSingle', // Entsorgung Einzelpreis
  DISPOSAL_CONTAINER: 'disposalContainer', // Entsorgung Behälterpreis
  COMPENSATION_CONTAINER: 'compensationContainer', // Vergütung Behälterpreis
  COMPENSATION_SINGLE: 'compensationSingle', // Vergütung Einzelpreis
}
