import { arrayOf, number, oneOfType, shape, string } from 'prop-types'

import { AttachmentScheme } from '../components/common/SingleFileUpload/schemes'

import { AddressSmallScheme } from './address'
import { CompanySmallScheme } from './company'

export const UserSmallScheme = {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  gender: oneOfType([number, string]),
  preferred_address_object: shape(AddressSmallScheme),
  status: number,
  date_of_birth: string,
}

export const UserLoginScheme = {
  email: string,
  password: string,
}

export const UserChangePasswordScheme = {
  email: string,
  password: string,
}

export const UserSetPasswordScheme = {
  email: string,
  password: string,
}

export const UserScheme = {
  id: number,
  gender: oneOfType([number, string]),
  first_name: string,
  last_name: string,
  preferred_address: number,
  preferred_address_object: shape(AddressSmallScheme),
  email: string,
  company: oneOfType([number, string]),
  street: string,
  house_number: string,
  zipcode: string,
  location: string,
  permission_codenames: arrayOf(string),
  position: string,
  business_phone_number: string,
  profile_image_object: shape(AttachmentScheme),
  profile_image: number,
  member_since: string,
  group_name: string,
  status: number,
  group_id: oneOfType([number, string]),
  company_id: number,
  company_object: shape(CompanySmallScheme),
  company_groups: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ),
  date_of_birth: string,
}
