import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import { APP_CONSTANTS } from 'constants/app'
import { FractionScheme } from 'schemes/fraction'
import { UserScheme } from 'schemes/user'
import { InputNumber } from 'components/common/InputNumber'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { OFFER_STATES } from 'components/offer/constants'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { NoContentMessage } from 'components/common/NoContentMessage/index'
import { OFFER_ORDER_TYPES } from 'components/inquiry/constants'
import { Select } from 'components/Select/Select'

import {
  filterMapFractionList,
  getCurrentValueObject,
  resetInputOption,
} from '../../helpers'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

import connector from './connector'

class MyOfferFilter extends Component {
  static propTypes = {
    fractionList: arrayOf(shape(FractionScheme)),
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
    isLoading: bool,
    length: number,
    resetForm: func.isRequired,
    user: shape(UserScheme).isRequired,
    values: shape({
      coarse_fraction: string,
      company: string,
      id: oneOfType([number, string]),
      order_type: string,
      status: string,
      old_runtime_of_inquiry: bool,
    }).isRequired,
  }

  static defaultProps = {
    fractionList: [],
    isLoading: false,
    length: 0,
  }

  state = {
    currentSelectedFraction: {},
    currentSelectedOrderType: {},
    currentSelectedStatus: {},
  }

  componentDidUpdate({ values: prevValues }) {
    const { values } = this.props

    if (values !== prevValues) this.handleValueChange(this.props)

    if (values.coarse_fraction !== prevValues.coarse_fraction) {
      this.setState({
        currentSelectedFraction: getCurrentValueObject(
          this.getSelectionItems(this.props).COARSE_FRACTION,
          values.coarse_fraction,
        ),
      })
    }

    if (values.order_type !== prevValues.order_type) {
      this.setState({
        currentSelectedOrderType: getCurrentValueObject(
          this.getSelectionItems(this.props).ORDER_TYPE,
          values.order_type,
        ),
      })
    }

    if (values.status !== prevValues.status) {
      this.setState({
        currentSelectedStatus: getCurrentValueObject(
          this.getSelectionItems(this.props).STATUS,
          values.status,
        ),
      })
    }
  }

  getSelectionItems = props => ({
    COARSE_FRACTION: [
      ...resetInputOption,
      ...filterMapFractionList(props.fractionList, this.props.user),
    ],
    COMPANY: [
      ...resetInputOption,
      ...props.companyList.map(item => ({
        label: item.name,
        value: `${item.id}`,
      })),
    ],
    ORDER_TYPE: [
      ...resetInputOption,
      ...OFFER_ORDER_TYPES.map(item => ({
        label: `${I18n.t(item.name)}`,
        value: `${item.id}`,
      })),
    ],
    STATUS: [
      ...resetInputOption,
      ...OFFER_STATES.map(item => ({
        label: `${I18n.t(item.name)}`,
        value: `${item.id}`,
      })),
    ],
  })

  handleValueChange = props => {
    if (props.values) {
      const requestValues = {
        coarse_fraction: props.values.coarse_fraction,
        company: props.values.company,
        id: props.values.id,
        order_type: props.values.order_type,
        status: props.values.status,
        old_runtime_of_inquiry: props.values.old_runtime_of_inquiry,
      }
      props.getCurrentFilterValues(requestValues)
    }
  }

  render() {
    const {
      fractionList,
      handleBlur,
      handleChange,
      setFieldValue,
      isLoading,
      length,
      resetForm,
      values,
    } = this.props

    return (
      <>
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          <FilterReset
            onResetFilter={() => {
              resetForm()
            }}
            showResetFilterButton={cleanUpAndCountFiltersForReset(values) > 0}
          />
        </ButtonBar>
        <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
          <StaticCombobox
            isLoading={fractionList.length < 1}
            label={I18n.t('filterTranslations.myOfferFilter.fraction')}
            name='coarse_fraction'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems(this.props).COARSE_FRACTION}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedFraction}
          />

          <StaticCombobox
            label={I18n.t('filterTranslations.myOfferFilter.orderType')}
            name='order_type'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems(this.props).ORDER_TYPE}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedOrderType}
          />

          <StaticCombobox
            label={I18n.t('filterTranslations.myOfferFilter.status')}
            name='status'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems(this.props).STATUS}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedStatus}
          />

          {/* prettier-ignore */}
          <Select
            urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/my-offer/inquired-companies/?search=`}
            urlPart2=''
            getValue={(e) => e.id}
            getLabel={(e) => e.id === 0 ? e.name : `${e.name}`}
            label={I18n.t('filterTranslations.myOfferFilter.company')}
            setValue={e => {
              if   (!e?.value) {setFieldValue('company', undefined);}
              else {setFieldValue('company', e.value);}
            }}
            selectedValue={values.company}
            selecting={'company'}
          />

          <InputNumber
            label={I18n.t('filterTranslations.myOfferFilter.offerNumber')}
            maxLength={10}
            name='id'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.id}
          />
        </div>

        <div className='filter__empty-message'>
          {!isLoading && length < 1 && (
            <NoContentMessage
              message={I18n.t('general.emptyFilterResultMessage')}
              showResetFilterButton={false}
            />
          )}
        </div>
      </>
    )
  }
}

export default connector(MyOfferFilter)
