// Environment specific settings are handled in a config.js file that you find in the public folder and which is
// included directly in index.html. This lets us replace the values by changing the file per environment
// (e.g. using a Kubernetes config map).
import { isSnapshot } from '../helper/snapshots'

/**
 * DO ONLY USE THIS FOR SNAPSHOTTING
 The correct way to define variables per environment is by entering them im public/config.js (e.g. by volume mounting
 them in docker/kubernetes). But we do the snapshotting in the CI pipeline, where the environment is not clear yet,
 since we want to have the same docker image independent of the environment. We simply always use the production
 API for snapshotting. In this case the jobs, downloads etc. will be wrong in the static pages on the test server
 but this is not so important, since Google only indexes the production server anyways.
 */
const PRODUCTION_API = 'https://api.empto.de/api/v1'

export const APP_CONSTANTS = {
  REACT_APP_API_BASE_URL: isSnapshot()
    ? PRODUCTION_API
    : // @ts-ignore
      (window.REACT_APP_API_BASE_URL as string),
  // @ts-ignore
  REACT_APP_CLIENT_BASE_URL: window.REACT_APP_CLIENT_BASE_URL as string,
  // @ts-ignore
  REACT_APP_SENTRY_KEY: window.REACT_APP_SENTRY_KEY as string,
  // @ts-ignore
  REACT_APP_ENVIRONMENT: window.REACT_APP_ENVIRONMENT as string,
}

/**
 * Defines how many weeks the collection date has to be after the delivery date.
 * @type {number}
 */
export const COLLECTION_DATE_AHEAD_WEEKS = 6

/**
 * Defines maximum number years the turn end date has to be after the turn begin data.
 * @type {number}
 */
export const TURN_END_MAXIMUM_YEARS = 1

/**
 * Weekdays
 */
export enum WEEKDAY {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export const WEEKDAYS = [
  {
    id: WEEKDAY.MONDAY,
    name: 'constant.date.weekdays.monday',
  },
  {
    id: WEEKDAY.TUESDAY,
    name: 'constant.date.weekdays.tuesday',
  },
  {
    id: WEEKDAY.WEDNESDAY,
    name: 'constant.date.weekdays.wednesday',
  },
  {
    id: WEEKDAY.THURSDAY,
    name: 'constant.date.weekdays.thursday',
  },
  {
    id: WEEKDAY.FRIDAY,
    name: 'constant.date.weekdays.friday',
  },
  {
    id: WEEKDAY.SATURDAY,
    name: 'constant.date.weekdays.saturday',
  },
  {
    id: WEEKDAY.SUNDAY,
    name: 'constant.date.weekdays.sunday',
  },
]

/**
 * Date formats
 */

export const DATE_FORMATS = ['YYYY-MM-DD', 'DD.MM.YYYY']

/**
 * Lists
 */

export const CARD_LIST_PAGE_SIZE = 16

/**
 * Tables
 */

export const TABLE_PAGE_SIZE = 5
export const TABLE_PAGE_SIZE_10 = 10

/**
 * HTTP status codes
 */

export enum STATUS_CODE {
  HTTP_200_OK = 200,
  HTTP_201_CREATED = 201,
}

/**
 * ZENTEK HEADQUARTERS COORDINATES IN KÖLN
 */
export enum ZENTEK_COORDINATES {
  lat = 50.9092672000000022,
  lng = 7.0550666999999994,
}

/**
 * ID for footer element
 * @type {string}
 */

export const COLLECTION_CONTAINER_IDS = [18, 19]

export enum BUSINESS_SEGMENT {
  BUSINESS_ALL = 0,
  BUSINESS_EMPTO = 1,
  BUSINESS_EPD = 2,
  // 3 was TVP and is currently not used
  BUSINESS_DSZ = 4,
}

export const MAKLER_PREMIUM_BUSINESS_TYPES = [
  BUSINESS_SEGMENT.BUSINESS_EPD,
  BUSINESS_SEGMENT.BUSINESS_DSZ,
]

export const BUSINESS_SEGMENT_CHOICES = [
  {
    id: BUSINESS_SEGMENT.BUSINESS_EMPTO,
    name: 'myAutoOffersPageTranslations.form.fields.businessSegment.options.empto',
  },
  {
    id: BUSINESS_SEGMENT.BUSINESS_EPD,
    name: 'myAutoOffersPageTranslations.form.fields.businessSegment.options.epd',
  },
]

export const EPD_USER_POSITION_IDENTIFIER = 'EPD-API-Account'

export const REGEX_PATTERN_TWO_DIGITS_AFTER_COMMA = /^-?\d+(\.\d{0,2})?$/

export const INDEX_MONTHS_CHOICES = [
  {
    optionLabel: 'compensationPriceFieldsTranslations.indexMonth.options.0',

    optionValue: 0,
  }, // Vormonat
  {
    optionLabel: 'compensationPriceFieldsTranslations.indexMonth.options.1',

    optionValue: 1,
  }, // Vor-Vormonat
  {
    optionLabel: 'compensationPriceFieldsTranslations.indexMonth.options.2',

    optionValue: 2,
  }, // Leistungsmonat
]

export const PARTNER_PRICE_MODEL_CHOICES = [
  {
    optionLabel: 'agreementDetailsTranslations.priceModels.disposalContainer',
    optionValue: 'disposalContainer',
  },
  {
    optionLabel: 'agreementDetailsTranslations.priceModels.disposalSingle',
    optionValue: 'disposalSingle',
  },
  {
    optionLabel:
      'agreementDetailsTranslations.priceModels.compensationContainer',
    optionValue: 'compensationContainer',
  },
  {
    optionLabel: 'agreementDetailsTranslations.priceModels.compensationSingle',
    optionValue: 'compensationSingle',
  },
]
