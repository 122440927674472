import { arrayOf, number, shape, string, func } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Errors } from 'react-redux-form'

import Icon from 'components/common/Fontello'
import ControlEditToggle from 'components/common/Form/components/ControlEditToggle'
import TextboxTypes from 'components/common/Form/components/TextboxControl/constants'
import SelectControl from 'components/common/Form/components/SelectControl'
import TextboxControl from 'components/common/Form/components/TextboxControl'
import { SectionHeader } from 'components/common/SectionHeader'
import { CompanyFormValidators } from 'components/company/CompanyProfilePage/constants'
import { countryIsGermany } from 'components/company/CompanyProfilePage/helpers'
import { CompanyFormScheme } from 'components/company/CompanyProfilePage/schemes'
import { AddressScheme } from 'schemes/address'

import connector from '../connector'
import {
  getListLabelForCountry,
  getListLabelForCompanyAddress,
} from '../helper'

export class MainAddressDetailsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reset: false, // Flag to control reset of the fields if needed
    }
  }
  render() {
    if (!this.props.company.main_address_object) return null

    const selectedCountryIsGermany = countryIsGermany(
      this.props.company.main_address_object.country,
    )
    const onChangeSelectID = event => {
      const { changeFormValue } = this.props
      const foundAddress = this.props.companyAddresses.find(
        address => address.id === Number(event.target.value),
      )
      if (foundAddress) {
        changeFormValue('company.item.main_address_object', foundAddress)
        this.setState({ ...this.state, reset: !this.state.reset })
      }
    }

    return (
      <section className='uk-card uk-padding-remove address-details'>
        <SectionHeader>
          <Icon name='map' />
          <Translate value='companyProfilePage.mainAddressDetails' />
        </SectionHeader>

        <div className='uk-padding details-container'>
          <div className='uk-grid uk-child-width-1-1 uk-child-width-1-4@m'>
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.street')}
              value={this.props.company.main_address_object.street}
              triggerEdit={
                !this.props.companyForm.main_address_object.street.valid
              }
              errorClass={
                !this.props.companyForm.main_address_object.street.valid
                  ? 'uk-text-danger'
                  : null
              }
              reset={this.state.reset}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.main_address_object.street}
                validators={CompanyFormValidators['main_address_object.street']}
                placeholder={I18n.t('general.placeholder.street')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.street'),
                }}
                maxLength={200}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.houseNumber')}
              value={this.props.company.main_address_object.house_number}
              triggerEdit={
                !this.props.companyForm.main_address_object.house_number.valid
              }
              errorClass={
                !this.props.companyForm.main_address_object.house_number.valid
                  ? 'uk-text-danger'
                  : null
              }
              reset={this.state.reset}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.main_address_object.house_number}
                validators={
                  CompanyFormValidators['main_address_object.house_number']
                }
                placeholder={I18n.t('general.placeholder.houseNumber')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.houseNumber'),
                }}
                maxLength={10}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.zipcode')}
              value={this.props.company.main_address_object.zipcode}
              triggerEdit={
                !this.props.companyForm.main_address_object.zipcode.valid
              }
              errorClass={
                !this.props.companyForm.main_address_object.zipcode.valid
                  ? 'uk-text-danger'
                  : null
              }
              reset={this.state.reset}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.main_address_object.zipcode}
                placeholder={I18n.t('general.placeholder.zipcode')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.zipcode'),
                  isValidZipcode: I18n.t('addressForm.validation.validZipcode'),
                }}
                maxLength={selectedCountryIsGermany ? 5 : 10}
                type={
                  selectedCountryIsGermany
                    ? TextboxTypes.NUMBER
                    : TextboxTypes.TEXT
                }
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.location')}
              value={this.props.company.main_address_object.location}
              triggerEdit={
                !this.props.companyForm.main_address_object.location.valid
              }
              errorClass={
                !this.props.companyForm.main_address_object.location.valid
                  ? 'uk-text-danger'
                  : null
              }
              reset={this.state.reset}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.main_address_object.location}
                validators={
                  CompanyFormValidators['main_address_object.location']
                }
                placeholder={I18n.t('general.placeholder.location')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.location'),
                }}
                maxLength={100}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-small-bottom'
              label={I18n.t('companyProfilePage.label.country')}
              value={getListLabelForCountry(
                this.props.countries.find(
                  country =>
                    country.id ===
                    Number(this.props.company.main_address_object.country),
                ),
              )}
              triggerEdit={
                this.props.companyForm.main_address_object.country &&
                !this.props.companyForm.main_address_object.country.valid
              }
              reset={this.state.reset}
              editable
            >
              <SelectControl
                className='uk-select'
                field={this.props.companyForm.main_address_object.country}
                validators={
                  CompanyFormValidators['main_address_object.country']
                }
                options={this.props.countries.map(item => ({
                  id: item.id,
                  label: getListLabelForCountry(item),
                }))}
                errorMessages={{
                  isRequired: I18n.t('companyProfilePage.validation.country'),
                }}
                placeholder={I18n.t('companyProfilePage.label.country')}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-small-bottom'
              label={I18n.t('companyProfilePage.label.id')}
              value={this.props.company.main_address_object.id}
              triggerEdit={
                this.props.companyForm.main_address_object.id &&
                !this.props.companyForm.main_address_object.id.valid
              }
              editable
            >
              <SelectControl
                className='uk-select'
                field={this.props.companyForm.main_address_object.id}
                validators={CompanyFormValidators['main_address_object.id']}
                options={this.props.companyAddresses.map(item => ({
                  id: item.id,
                  label: getListLabelForCompanyAddress(item),
                }))}
                errorMessages={{
                  isRequired: I18n.t('companyProfilePage.validation.id'),
                }}
                placeholder={I18n.t('companyProfilePage.label.id')}
                onChange={onChangeSelectID}
              />
            </ControlEditToggle>
          </div>

          <Errors
            model='company.item'
            className='form-error'
            messages={{
              mainAddressZipcode: I18n.t(
                'companyProfilePage.validation.mainAddressZipcode',
              ),
            }}
          />
        </div>
      </section>
    )
  }
}

MainAddressDetailsComponent.propTypes = {
  countries: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ).isRequired,
  company: shape({
    main_address_object: shape(AddressScheme),
  }).isRequired,
  companyForm: CompanyFormScheme.isRequired,
  companyAddresses: arrayOf(shape(AddressScheme)).isRequired,
  changeFormValue: func.isRequired,
}

export default connector(MainAddressDetailsComponent)
