import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext, useMemo } from 'react'
import { I18n } from 'react-i18nify'

import { DismissableInfo } from 'components/common/DismissableInfo'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { OrderTypeDetailsGroup } from 'components/common/GenericInquiryFields'
import { BILLING_INTERVAL } from 'components/company/constants'
import { OFFER_ORDER_TYPE } from 'components/inquiry/constants'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
  step3validationSchema,
} from '../AddAgreementFormSteps'

export const SelectAgreementDetailsForm: FC = () => {
  const { values } = useFormikContext<AddAgreementFormValues>()
  const { stepStatus, saveStep } = useContext(AddAgreementFormContext)

  const showDismissableInfoBox = useMemo(
    () =>
      values.customer.selected_company?.billing_interval ===
        BILLING_INTERVAL.ORDER &&
      values.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME &&
      moment(values.delivery_date).isAfter(),
    [
      values.customer.selected_company?.billing_interval,
      values.delivery_date,
      values.order_type,
    ],
  )

  return (
    <>
      <div className='maklerpremium-agreementform__step3'>
        <OrderTypeDetailsGroup
          deliveryMinDate={moment('2018-01-01')}
          collectionMinDate={moment('2018-01-01')}
          collectionMaxDate={moment('2099-12-31')}
          turnBeginMinDate={moment('2018-01-01')}
          turnEndMinDate={moment('2018-01-01')}
          turnEndMaxDate={moment('2099-12-31')}
          useCollectionDateAheadWeeks={false}
        />
        {showDismissableInfoBox && (
          <div className='uk-margin-top'>
            <DismissableInfo
              title={I18n.t('general.attention')}
              text={I18n.t(
                'addAgreementPageTranslations.steps.3.dismissableInfo',
              )}
              disableOnAnimationEnd
            />
          </div>
        )}
        <GoToNextFormStepButton
          buttonText={
            stepStatus === STEP_STATUS.EDITING
              ? I18n.t('general.button.save')
              : I18n.t('createInquiryTranslations.form.button.nextStep')
          }
          onClick={saveStep}
          isDisabled={!step3validationSchema.isValidSync(values)}
        />
      </div>
    </>
  )
}
