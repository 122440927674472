import './style.scss'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { Textarea } from 'components/common/Textarea'
import { InvoiceCheckPositionReviewFormValues } from 'components/invoicecheck/InvoiceCheckPositionReviewPage'
import {
  INVOICE_CHECK_POSITION_STATES,
  INVOICE_CHECK_POSITION_STATUS,
} from 'components/invoicecheck/InvoiceCheckPositionReviewPage/constants'
import { getName } from 'helper/translations'

import { DetailsSection } from '../../DetailsSection'

interface InvoiceCheckPositionReviewDetailProps {
  invoiceCheckPosition: InvoiceCheckPositionReview
  isReview?: boolean
}

export const InvoiceCheckPositionReviewDetail: FC<
  InvoiceCheckPositionReviewDetailProps
> = ({ invoiceCheckPosition, isReview = false }) => {
  const { errors, handleBlur, handleChange, submitCount, touched, values } =
    useFormikContext<InvoiceCheckPositionReviewFormValues>()
  const renderCriteria = isReview ? values : invoiceCheckPosition

  const InternalNote = (
    <ReadOnlyField
      editable={isReview}
      label={I18n.t(
        'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.internalNote.label',
      )}
      value={invoiceCheckPosition.internal_note}
    >
      <Textarea
        label={I18n.t(
          'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.internalNote.label',
        )}
        maxLength={500}
        name='internal_note'
        onChange={handleChange}
        placeholder={I18n.t(
          'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.internalNote.placeholder',
        )}
        showRemainingCharacters
        value={values.internal_note}
      />
    </ReadOnlyField>
  )

  return (
    <DetailsSection
      title={
        isReview
          ? I18n.t(
              'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.sectionTitle.review',
            )
          : I18n.t(
              'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.sectionTitle.details',
            )
      }
    >
      <div className='invoice_check-review'>
        <div
          className={classNames({
            'order-claim-review__review-group': !isReview,
          })}
        >
          {/* DATE OF REVIEW (DAY.MONTH.YEAR) */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.reviewedAt',
              )}
              value={moment(invoiceCheckPosition.reviewed_at).format('L')}
            />
          )}

          {/* INTERNAL NOTE (position in form must be before status) */}
          {isReview && InternalNote}

          {/* STATUS */}
          <ReadOnlyField
            editable={isReview}
            label={I18n.t(
              'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.status',
            )}
            value={
              !isReview
                ? I18n.t(
                    getName(
                      invoiceCheckPosition.status,
                      INVOICE_CHECK_POSITION_STATES,
                    ),
                  )
                : undefined
            }
          >
            <DropDownInput
              error={
                submitCount > 0 && touched.status
                  ? (errors.status as string)
                  : ''
              }
              dataTestId='status-select'
              choices={INVOICE_CHECK_POSITION_STATES.map(item => ({
                optionValue: item.id,
                optionLabel: I18n.t(item.name),
              }))}
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.status',
              )}
              name='status'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.status',
              )}
              value={values.status || ''}
              isRequired={
                Number(values.status) ===
                INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
              }
            />
          </ReadOnlyField>

          {/* NAME OF USER WHO MADE REVIEW */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.reviewedBy',
              )}
              value={invoiceCheckPosition.reviewed_by_name}
            />
          )}

          {/* TIME OF REVIEW (HOUR:MINUTE) */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.timeOfReview',
              )}
              value={moment(invoiceCheckPosition.reviewed_at).format('LT')}
            />
          )}
        </div>
        {/* INTERNAL NOTE (Position in details should be after reviewer name) */}
        {!isReview && !!renderCriteria.internal_note && InternalNote}

        <div
          className={classNames({
            'order-claim-review__review-message-group': !isReview,
          })}
        >
          {/* REVIEW MESSAGE */}
          {Number(renderCriteria.status) ===
            INVOICE_CHECK_POSITION_STATUS.STATUS_ACCEPTED &&
            !!renderCriteria.review_message && (
              <ReadOnlyField
                editable={isReview}
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.reviewText.label',
                )}
                value={invoiceCheckPosition.review_message}
              >
                <Textarea
                  label={I18n.t(
                    'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.reviewText.placeholder',
                  )}
                  maxLength={500}
                  name='review_message'
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.reviewText.placeholder',
                  )}
                  showRemainingCharacters
                  value={values.review_message}
                />
              </ReadOnlyField>
            )}

          {/* REJECT REASON */}
          {((!isReview && invoiceCheckPosition.reject_reason) ||
            Number(renderCriteria.status) ===
              INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.rejectReason.label.details',
              )}
              value={invoiceCheckPosition.reject_reason}
            >
              <Textarea
                error={
                  submitCount > 0 && touched.reject_reason
                    ? (errors.reject_reason as string)
                    : ''
                }
                isRequired={!values.reject_reason}
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.rejectReason.label.review',
                )}
                placeholder={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.invoiceCheckPositionReview.rejectReason.placeholder',
                )}
                maxLength={500}
                name='reject_reason'
                onChange={handleChange}
                showRemainingCharacters
                value={values.reject_reason}
              />
            </ReadOnlyField>
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
