import React, { FC, useContext, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getName } from 'helper/translations'
import { getMaklerPremiumCompanies } from 'actions/company'
import {
  getMaklerPremiumCompaniesPaginationSelector,
  getMaklerPremiumCompaniesSelector,
} from 'selectors/company'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

import { CERTIFICATE_TYPE } from '../../certificate/constants'
import Icon, { ICON_NAME } from '../../common/Fontello'
import { PaginatedTable } from '../../common/PaginatedTable'
import { UpDownArrow } from '../../common/UpDownArrow'
import { COMPANY_STATES, PAYMENT_METHODS } from '../../company/constants'

import { DisposerProducerListContext } from './DisposerProducerPage'

export const WasteProducersList: FC = () => {
  const dispatch = useDispatch()

  const { currentFilters, defaultSorted, setCurrentFilters } =
    useContext<DisposerProducerListContext>(DisposerProducerListContext)

  const companyList = useSelector(getMaklerPremiumCompaniesSelector)
  const pagination = useSelector(getMaklerPremiumCompaniesPaginationSelector)
  const isLoading = useSelector(
    createLoadingSelector(['GET_MAKLER_PREMIUM_COMPANIES']),
  )
  const apiError = useSelector(
    createErrorSelector(['GET_MAKLER_PREMIUM_COMPANIES']),
  )

  useEffect(() => {
    if (!isLoading && !pagination.loaded && !apiError) {
      dispatch(getMaklerPremiumCompanies(null, currentFilters))
    }
  }, [apiError, currentFilters, dispatch, isLoading, pagination.loaded])

  const empto_external_numberColumn = [
    {
      id: 'empto_external_number',
      accessor: 'empto_external_number',
      Header: (
        <>
          <Translate value='companyProfilePage.label.customerNumber' />
          <UpDownArrow />
        </>
      ),
      width: 150,
    },
  ]

  const companyColumn = [
    {
      id: 'name',
      accessor: data =>
        data && <Link to={`/company/${data.id}`}>{data.name}</Link>,
      Header: (
        <>
          <Translate value='general.name' />
          <UpDownArrow />
        </>
      ),
      width: 280,
    },
  ]

  const streetColumn = [
    {
      id: 'main_address__street',
      accessor: data =>
        data && data.main_address_object && data.main_address_object.street ? (
          <span>
            {`${data.main_address_object.street} ${data.main_address_object.house_number}`}
          </span>
        ) : (
          <span />
        ),
      Header: (
        <>
          <Translate value='companyProfilePage.label.street' />
          <UpDownArrow />
        </>
      ),
      width: 230,
    },
  ]

  const zipcodeColumn = [
    {
      id: 'main_address__zipcode',
      accessor: 'main_address_object.zipcode',
      Header: (
        <>
          <Translate value='companyProfilePage.label.zipcode' />
          <UpDownArrow />
        </>
      ),
      width: 80,
    },
  ]

  const locationColumn = [
    {
      id: 'main_address__location',
      accessor: 'main_address_object.location',
      Header: (
        <>
          <Translate value='companyProfilePage.label.location' />
          <UpDownArrow />
        </>
      ),
      width: 100,
    },
  ]

  const stateColumn = [
    {
      id: 'status',
      accessor: data =>
        data.status && I18n.t(getName(data.status, COMPANY_STATES)),
      Header: (
        <>
          <Translate value='companyProfilePage.label.status' />
          <UpDownArrow />
        </>
      ),
      width: 90,
    },
  ]

  const discountColumn = [
    {
      id: 'permanent_discount',
      accessor: data =>
        data && data.permanent_discount && data.permanent_discount > 0 ? (
          <span>{data.permanent_discount}%</span>
        ) : (
          <span />
        ),
      Header: (
        <>
          <Translate value='disposerProducerPageTranslations.wasteProducersPageTranslations.permanent_discount' />
          <UpDownArrow />
        </>
      ),
      width: 120,
    },
  ]

  const contactPersonColumn = [
    {
      id: 'contact_person__first_name',
      accessor: data =>
        data && data.contact_person && <span>{data.contact_person}</span>,
      Header: (
        <>
          <Translate value='companyProfilePage.label.contactPerson' />
          <UpDownArrow />
        </>
      ),
      width: 150,
    },
  ]

  const paymentMethodColumn = [
    {
      id: 'payment_method',
      accessor: data => {
        if (
          data.payment_method &&
          getName(data.payment_method, PAYMENT_METHODS)
        ) {
          return I18n.t(getName(data.payment_method, PAYMENT_METHODS))
        }
        return null
      },
      Header: (
        <>
          <Translate value='companyProfilePage.label.paymentMethod' />
          <UpDownArrow />
        </>
      ),
      width: 200,
    },
  ]

  const documentColumns = [
    {
      id: 'documents',
      Cell: data => {
        const priceAgreementFile =
          data.original &&
          data.original.certificates &&
          data.original.certificates
            .filter(
              certificate =>
                certificate.type === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT,
            )
            .shift()

        const companyDocumentFile =
          data.original &&
          data.original.certificates &&
          data.original.certificates
            .filter(
              certificate => certificate.type === CERTIFICATE_TYPE.TYPE_COMPANY,
            )
            .shift()

        return (
          <span className='document-buttons' data-testid='orderproof-row'>
            {/* TODO CT: Aktuell kann ein EPD Kunde niemals einen Gewerbenachweis haben,
             trotzdem wollten wir das Dokument-Icon nicht ausbauen, aber standardmäßig auf false haben.
             Sollte der Kunde einmal ein Dokument bekommen (<Ticket NR einfügen>),
             muss die Condition hier angepasst werden.*/}
            {companyDocumentFile && (
              <Link
                to='/'
                data-uk-tooltip={`title: ${I18n.t(
                  'disposerProducerPageTranslations.wasteDisposersPageTranslations.actions.document',
                )}`}
              >
                <Icon name={ICON_NAME.DOCUMENT} />
              </Link>
            )}

            {priceAgreementFile && (
              <Link
                to='/'
                onClick={event => {
                  event.preventDefault()
                  window.open(priceAgreementFile.attachments.file)
                }}
                data-uk-tooltip={`title: ${I18n.t(
                  'disposerProducerPageTranslations.wasteDisposersPageTranslations.actions.priceagreement',
                )}`}
              >
                <Icon name={ICON_NAME.HANDSHAKE_O} />
              </Link>
            )}
          </span>
        )
      },
      filterable: false,
      Header: I18n.t('general.attachments'),
      sortable: false,
      width: 100,
    },
  ]

  const columns = [
    ...empto_external_numberColumn,
    ...companyColumn,
    ...streetColumn,
    ...zipcodeColumn,
    ...locationColumn,
    ...stateColumn,
    ...discountColumn,
    ...contactPersonColumn,
    ...paymentMethodColumn,
    ...documentColumns,
  ]

  if (apiError) return null

  return (
    <div className='waste-producer-page__list uk-margin-large-top'>
      <PaginatedTable
        serverSidePagination
        page={pagination.current}
        pages={pagination.count}
        loading={isLoading}
        handleShowPreviousPage={() => {
          dispatch(
            getMaklerPremiumCompanies(pagination.previous, currentFilters),
          )
        }}
        handleShowNextPage={() => {
          dispatch(getMaklerPremiumCompanies(pagination.next, currentFilters))
        }}
        table={{
          columns,
          data: companyList,
          defaultSorted,
          onFetchData: ({ page, sorted }) => {
            if (sorted && sorted.length > 0) {
              const newFilters = {
                ...currentFilters,
                order_by: JSON.stringify(sorted),
              }
              setCurrentFilters(newFilters)
              if (!isLoading) {
                dispatch(getMaklerPremiumCompanies(page, newFilters))
              }
            }
          },
        }}
      />
    </div>
  )
}
