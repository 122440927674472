import { I18n } from 'react-i18nify'

import {
  addCustomerCompanyToState,
  addPartnerCompanyToState,
} from 'actions/company'
import { COMPANY_ROLE } from 'components/company/constants'

import { AddAgreementFormValues } from '../AddAgreementFormSteps'

export const ROLE_KEYS = {
  CUSTOMER: 'customer',
  PARTNER: 'partner',
}

export const ROLES = [ROLE_KEYS.CUSTOMER, ROLE_KEYS.PARTNER]

export const getValues = (role: string, values: AddAgreementFormValues) => {
  switch (role) {
    case ROLE_KEYS.CUSTOMER:
      return {
        searchField: values.customer.search_field,
        selectedCompany: values.customer.selected_company,
        onlyActiveCompanies: values.customer.only_active,
      }
    case ROLE_KEYS.PARTNER:
      return {
        searchField: values.partner.search_field,
        selectedCompany: values.partner.selected_company,
        onlyActiveCompanies: values.partner.only_active,
      }
    // default case should never happen
    default:
      return {
        searchField: '',
      }
  }
}

export const getErrors = (role: string, errors) => {
  switch (role) {
    case ROLE_KEYS.CUSTOMER:
      return {
        selectedCompanyError: errors.customer?.selected_company.error,
      }
    case ROLE_KEYS.PARTNER:
      return {
        selectedCompanyError: errors.partner?.selected_company.error,
      }
    // default case should never happen
    default:
      return {
        selectedCompanyError: '',
      }
  }
}

export const getChoices = (role: string) => {
  switch (role) {
    case ROLE_KEYS.CUSTOMER:
      return [
        {
          optionLabel: I18n.t(
            'addAgreementPageTranslations.steps.1.fields.customer.company_name.label',
          ),
          optionValue: 'company_name',
        },
        {
          optionLabel: I18n.t(
            'addAgreementPageTranslations.steps.1.fields.customer.empto_external_number.label',
          ),
          optionValue: 'empto_external_number',
        },
        {
          optionLabel: I18n.t(
            'addAgreementPageTranslations.steps.1.fields.customer.zipcode.label',
          ),
          optionValue: 'zipcode',
        },
      ]
    case ROLE_KEYS.PARTNER:
      return [
        {
          optionLabel: I18n.t(
            'addAgreementPageTranslations.steps.1.fields.partner.company_name.label',
          ),
          optionValue: 'company_name',
        },
        {
          optionLabel: I18n.t(
            'addAgreementPageTranslations.steps.1.fields.partner.empto_external_number.label',
          ),
          optionValue: 'empto_external_number',
        },
        {
          optionLabel: I18n.t(
            'addAgreementPageTranslations.steps.1.fields.partner.zipcode.label',
          ),
          optionValue: 'zipcode',
        },
      ]
    // default case should never happen
    default:
      return []
  }
}

export const resetValuesForRole = (
  role: string,
  setValues: (values: AddAgreementFormValues, shouldValidate?: boolean) => void,
  values: AddAgreementFormValues,
) => {
  switch (role) {
    case ROLE_KEYS.CUSTOMER:
      setValues(
        {
          ...values,
          customer: {
            ...values.customer,
            company_name: '',
            empto_external_number: undefined,
            zipcode: undefined,
            selected_company: undefined,
          },
        },
        false,
      )
      break
    case ROLE_KEYS.PARTNER:
      setValues(
        {
          ...values,
          partner: {
            ...values.partner,
            company_name: '',
            empto_external_number: undefined,
            zipcode: undefined,
            selected_company: undefined,
          },
        },
        false,
      )
      break
    // default case should never happen
    default:
      break
  }
}

export const getCompanyRole = (role: string) => {
  switch (role) {
    case ROLE_KEYS.CUSTOMER:
      return COMPANY_ROLE.WASTE_PRODUCER
    case ROLE_KEYS.PARTNER:
      return COMPANY_ROLE.WASTE_COMPANY
    // default case should never happen
    default:
      return COMPANY_ROLE.NONE
  }
}

export const getCompanyRoleKey = (role: number) => {
  switch (role) {
    case COMPANY_ROLE.WASTE_PRODUCER:
      return ROLE_KEYS.CUSTOMER
    case COMPANY_ROLE.WASTE_COMPANY:
      return ROLE_KEYS.PARTNER
    // default case should never happen
    default:
      return undefined
  }
}

export const addCompanyToState = (dispatch: any, company: Company) => {
  switch (company.role) {
    case COMPANY_ROLE.WASTE_PRODUCER:
      dispatch(addCustomerCompanyToState(company))
      break
    case COMPANY_ROLE.WASTE_COMPANY:
      dispatch(addPartnerCompanyToState(company))
      break
    // default case should never happen
    default:
      return
  }
}
