import uniqueId from 'lodash.uniqueid'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Spinner from 'react-spinkit'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { getName } from 'helper/translations'
import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { TABLE_PAGE_SIZE_10 } from 'constants/app'
import {
  getMaklerPremiumUpcomingOrders,
  setMaklerPremiumUpcomingOrderToConfirmed,
} from 'actions/order'
import { exportUpcomingOrders } from 'actions/maklerpremium'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  getMaklerPremiumUpcomingOrdersFilterCompanies,
  getMaklerPremiumUpcomingOrdersFilterContainers,
  getMaklerPremiumUpcomingOrdersFilterTimestamp,
  getMaklerPremiumUpcomingOrdersPaginationSelector,
  getMaklerPremiumUpcomingOrdersSelector,
} from 'selectors/order'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { CompanyAndStatusFilter } from '../../common/Filter/components/CompanyAndStatusFilter'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { PaginatedTable } from '../../common/PaginatedTable'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UpDownArrow } from '../../common/UpDownArrow'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import PageHeader from '../../layout/PageHeader'
import { OFFER_STATUS } from '../../offer/constants'
import {
  UPCOMING_ORDER_STATES,
  UPCOMING_ORDER_STATUS,
} from '../../order/constants'

export enum UpcomingOrderType {
  COLLECTION = 1,
  DELIVERY,
}

const UpcomingOrdersListComponent: FC = () => {
  const dispatch = useDispatch()

  const upcomingOrders = useSelector(getMaklerPremiumUpcomingOrdersSelector)
  const pagination = useSelector(
    getMaklerPremiumUpcomingOrdersPaginationSelector,
  )
  const filterCompanyList = useSelector(
    getMaklerPremiumUpcomingOrdersFilterCompanies,
  )
  const filterContainerList = useSelector(
    getMaklerPremiumUpcomingOrdersFilterContainers,
  )
  const filterTimestamp = useSelector(
    getMaklerPremiumUpcomingOrdersFilterTimestamp,
  )
  const isLoading = useSelector(
    createLoadingSelector(['GET_MAKLER_PREMIUM_UPCOMING_ORDERS']),
  )
  const errors = useSelector(
    createErrorSelector(['GET_MAKLER_PREMIUM_UPCOMING_ORDERS']),
  )

  const defaultSorted = [
    { id: 'order_date', desc: false },
    { id: 'id', desc: false },
  ]
  const defaultFilters: any = {
    order_by: JSON.stringify(defaultSorted),
  }
  const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters)
  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth >= INITIAL_FILTER_OPEN_WIDTH,
  )

  const [order, setOrder] = useState<Order>()
  const [upcomingOrderType, setUpcomingOrderType] =
    useState<UpcomingOrderType>()

  const [isExportUpcomingOrdersModalOpen, setIsExportUpcomingOrdersModalOpen] =
    useState(false)

  // ChangeStatus Modal
  const idChangeStatusModalHeadline = uniqueId()
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false)
  const handleOpenCloseChangeStatusModal = () => {
    if (isChangeStatusModalOpen) {
      setOrder(undefined)
    }
    setIsChangeStatusModalOpen(!isChangeStatusModalOpen)
  }

  const columns = [
    /* Order Number */
    {
      id: 'id',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.orderNumber' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 125,
      Cell: data => {
        if (!data.original.is_order) {
          return (
            <Link to={`/agreement/${data.original.id}`}>
              {`V: ${data.original.id}`}
            </Link>
          )
        }
        return (
          <Link to={`/order/${data.original.id}`}>{`${data.original.id}`}</Link>
        )
      },
      filterable: false,
    },
    /* Date of Order */
    {
      id: 'order_date',
      accessor: data => data.order_date && moment(data.order_date).format('L'),
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.date' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 120,
      filterable: false,
    },
    /* Disposer */
    {
      id: 'disposer_company_name',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.disposer' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 200,
      Cell: data => (
        <Link to={`/company/${data.original.disposer_company_id}`}>
          {data.original.disposer_company_name}
        </Link>
      ),
    },
    /* Producer */
    {
      id: 'producer_company_name',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.producer' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 200,
      Cell: data => (
        <Link to={`/company/${data.original.producer_company_id}`}>
          {data.original.producer_company_name}
        </Link>
      ),
      filterable: false,
    },
    /* Zipcode of collection location */
    {
      id: 'zipcode',
      accessor: data => data.zipcode,
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.zip' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 125,
      filterable: false,
    },
    /* Location of collection location */
    {
      id: 'location',
      accessor: data => data.location,
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.location' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 100,
      filterable: false,
    },
    /* Fraction */
    {
      id: 'fraction_name',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.fraction' />
          <UpDownArrow />
        </span>
      ),
      Cell: data => data.original.fraction,
      minWidth: 135,
      filterable: false,
    },
    /* Container */
    {
      id: 'container',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.container' />
          <UpDownArrow />
        </span>
      ),
      Cell: data =>
        `${data.original.container_capacity} ${data.original.container}`,
      minWidth: 150,
      filterable: false,
    },
    /* Type */
    {
      id: 'type',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.type' />
          <UpDownArrow />
        </span>
      ),
      Cell: data => data.original.type,
      minWidth: 150,
      filterable: false,
    },
    /* Status */
    {
      id: 'upcoming_order_status',
      Header: (
        <span>
          <Translate value='upcomingOrdersTranslations.columns.upcomingOrderStatus' />
        </span>
      ),
      Cell: data => {
        let localUpcomingOrderType
        let upcomingOrderStatus

        if (
          data.original.type === 'Sofortbefüllung' ||
          data.original.type === 'Stellung' ||
          data.original.type === 'Tausch/Leerung' ||
          data.original.type === 'Abzug'
        ) {
          localUpcomingOrderType = UpcomingOrderType.DELIVERY
          upcomingOrderStatus =
            data.original.upcoming_order_status?.upcoming_delivery_status
        } else if (data.original.type === 'Abholung') {
          localUpcomingOrderType = UpcomingOrderType.COLLECTION
          upcomingOrderStatus =
            data.original.upcoming_order_status?.upcoming_collection_status
        }

        return (
          <>
            {upcomingOrderStatus === OFFER_STATUS.STATUS_OPEN && (
              <>
                {!data.original.is_order ? (
                  <>
                    {getName(upcomingOrderStatus, UPCOMING_ORDER_STATES, true)}
                  </>
                ) : (
                  <Link
                    to='/'
                    onClick={event => {
                      setOrder(data.original)
                      event.preventDefault()
                      setUpcomingOrderType(localUpcomingOrderType)
                      handleOpenCloseChangeStatusModal()
                    }}
                  >
                    {getName(upcomingOrderStatus, UPCOMING_ORDER_STATES, true)}
                  </Link>
                )}
              </>
            )}

            {upcomingOrderStatus !== UPCOMING_ORDER_STATUS.STATUS_OPEN && (
              <>{getName(upcomingOrderStatus, UPCOMING_ORDER_STATES, true)}</>
            )}
          </>
        )
      },
      minWidth: 165,
      sortable: false,
    },
  ]

  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = () => {
    let filters = {
      ...currentFilters,
    }

    delete filters.order_by

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
      if (filter === 'id' && typeof currentFilters[filter] !== 'number') {
        delete filters.id
      }
      if (filter === 'order_type') {
        filters['order_type'] = currentFilters[filter]
        delete filters.order_type
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        created_at__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        created_at__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.created_at__gte
    delete filters.created_at__lte

    return filters
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('upcomingOrdersTranslations.headline')}</title>
      </Helmet>

      <div className='offer-upcoming-orders'>
        {/* Buttons */}
        <Media query={{ maxWidth: BREAKPOINT.MEDIUM }}>
          {isSmallScreen => (
            <PageHeader
              title={I18n.t('upcomingOrdersTranslations.headline')}
              subtitle={
                <>
                  <div className='uk-margin-small-top uk-margin-medium-bottom'>
                    {I18n.t('upcomingOrdersTranslations.description')}
                  </div>
                  {pagination.total_results && (
                    <div className='uk-margin-small-top'>
                      {I18n.t('upcomingOrdersTranslations.totalOrders')}
                      {pagination.total_results}
                      {I18n.t('upcomingOrdersTranslations.totalOrdersPostfix')}
                    </div>
                  )}
                  {filterTimestamp && (
                    <div>
                      {I18n.t('upcomingOrdersTranslations.filter.lastUpdate')}:{' '}
                      {new Date(filterTimestamp ?? 0).toLocaleTimeString()}
                    </div>
                  )}
                </>
              }
              noMargin={!isSmallScreen}
            >
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <Media
                  key='media-extag'
                  query={{ minWidth: BREAKPOINT.XLARGE }}
                >
                  <RequiredPermissions
                    requiredPermissions={[
                      UserPermission.EXPORT_UPCOMING_ORDERS,
                    ]}
                  >
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => setIsExportUpcomingOrdersModalOpen(true)}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  </RequiredPermissions>
                </Media>
                <IconButton
                  iconName={ICON_NAME.EXPERIMENT}
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    setFilterOpen(!filterOpen)
                  }}
                >
                  <Translate
                    value={
                      filterOpen ? 'general.hideFilter' : 'general.showFilter'
                    }
                  />
                </IconButton>
              </ButtonBar>
            </PageHeader>
          )}
        </Media>

        {/* Filter */}
        <Collapse isOpened={filterOpen}>
          <Filter
            companyList={filterCompanyList}
            containerList={filterContainerList}
            isLoading={isLoading}
            length={upcomingOrders.length}
          >
            {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
            {/* @ts-ignore */}
            <CompanyAndStatusFilter
              action={getMaklerPremiumUpcomingOrders}
              statusField='upcoming_order_status'
              statusTranslation={I18n.t(
                'upcomingOrdersTranslations.filter.upcomingOrderStatus',
              )}
              companyTranslation={I18n.t(
                'upcomingOrdersTranslations.filter.partner',
              )}
              typeTranslation={I18n.t('upcomingOrdersTranslations.filter.type')}
              setCurrentFilterValues={setCurrentFilters}
              currentFilterValues={currentFilters}
            />
          </Filter>
        </Collapse>

        {/* Page Content */}
        {(isLoading && !upcomingOrders.length) || !!errors ? (
          <div className='uk-flex uk-flex-center'>
            <Spinner name='circle' />
          </div>
        ) : (
          // Paginated Table
          <PaginatedTable
            serverSidePagination
            page={pagination.current}
            pages={pagination.count}
            loading={isLoading}
            handleShowPreviousPage={() => {
              dispatch(
                getMaklerPremiumUpcomingOrders(
                  pagination.previous,
                  currentFilters,
                  TABLE_PAGE_SIZE_10,
                ),
              )
            }}
            handleShowNextPage={() => {
              dispatch(
                getMaklerPremiumUpcomingOrders(
                  pagination.next,
                  currentFilters,
                  TABLE_PAGE_SIZE_10,
                ),
              )
            }}
            table={{
              columns,
              data: upcomingOrders,
              defaultSorted,
              onFetchData: ({ page, sorted }) => {
                if (sorted && sorted.length > 0) {
                  const newFilters = {
                    ...currentFilters,
                    order_by: JSON.stringify(sorted),
                  }
                  if (
                    JSON.stringify(newFilters) !==
                      JSON.stringify(currentFilters) ||
                    !pagination.loaded
                  ) {
                    setCurrentFilters(newFilters)
                    dispatch(getMaklerPremiumUpcomingOrders(page, newFilters))
                  }
                }
              },
            }}
          />
        )}
      </div>

      {/* Geplante Aufträge Status ändern */}
      <Modal
        ariaDescribedBy={idChangeStatusModalHeadline}
        isOpen={isChangeStatusModalOpen}
        onClose={handleOpenCloseChangeStatusModal}
      >
        <ModalHeader
          onClose={handleOpenCloseChangeStatusModal}
          title={I18n.t('upcomingOrdersTranslations.modal.header')}
          titleId={idChangeStatusModalHeadline}
        />
        <div className='uk-modal-body'>
          <p>{I18n.t('upcomingOrdersTranslations.modal.description')}</p>

          <ButtonBar align={BUTTON_BAR_ALIGN.CENTER}>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={handleOpenCloseChangeStatusModal}
            >
              {I18n.t('general.button.cancel')}
            </Button>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={() => {
                if (order && upcomingOrderType) {
                  dispatch(
                    setMaklerPremiumUpcomingOrderToConfirmed(
                      order.id,
                      UPCOMING_ORDER_STATUS.STATUS_CONFIRMED_INQUIRED,
                      upcomingOrderType,
                    ),
                  )
                  handleOpenCloseChangeStatusModal()
                }
              }}
            >
              {I18n.t('upcomingOrdersTranslations.modal.confirm')}
            </Button>
          </ButtonBar>
        </div>
      </Modal>

      {/* Geplante Aufträge exportieren */}
      <AsyncExportModal
        isOpen={isExportUpcomingOrdersModalOpen}
        onClose={() => setIsExportUpcomingOrdersModalOpen(false)}
        reduxSelector='EXPORT_UPCOMING_ORDERS'
        title={I18n.t('upcomingOrdersTranslations.exportModal.title')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportUpcomingOrders(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
    </>
  )
}

export const UpcomingOrdersList = withErrorBoundary(
  withApiErrorHandling(UpcomingOrdersListComponent, [
    'GET_MAKLER_PREMIUM_UPCOMING_ORDERS',
  ]),
)
