import React from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { NotFoundComponent } from '../components/common/RoutesHandler/components/NotFoundComponent'
import { createErrorSelector } from '../selectors/error'
import { createLoadingSelector } from '../selectors/loading'

import { DefaultErrorComponent as DefaultError } from './defaultError'

const withApiErrorHandlingComponent = (
  ErrorComponent,
  WrappedComponent,
  errorSelector,
  loadingSelector,
) => {
  if (!ErrorComponent || !WrappedComponent) {
    throw new Error('Both ErrorComponent and WrappedComponent are required')
  }

  const ApiErrorHandling = props => {
    const { error: propError, isLoading: propIsLoading } = props
    const reduxError = useSelector(createErrorSelector(errorSelector))
    const reduxIsLoading = useSelector(createLoadingSelector(loadingSelector))

    const error = propError || reduxError
    const isLoading = propIsLoading || reduxIsLoading

    // error is false => return component
    if (error === false || typeof error === 'undefined') {
      return <WrappedComponent {...props} />
    }

    // error is an object
    if (typeof error === 'object' && !isLoading) {
      // but every error item inside is falsy
      if (Object.entries(error).every(errorItem => errorItem === false)) {
        // => return component
        return <WrappedComponent {...props} />
      }

      // some error items are truthy, but it's still loading => return component
      if (
        isLoading === true ||
        (typeof isLoading === 'object' &&
          Object.entries(isLoading).some(loadingItem => loadingItem === true))
      ) {
        return <WrappedComponent {...props} />
      }

      // not loading, but some errors
      let errorMessage = ''
      errorMessage =
        error && error.humanReadableError
          ? // if only one error, set human Readable error
            error.humanReadableError
          : // else set generic error message
            `${I18n.t('message.unknownErrorDevelopersNotified')}`

      if (
        // 404
        (error.response && error.response.status === 404) ||
        // or disallowed
        (error.config && error.config.params && error.config.params.id)
      ) {
        // show Not Found Route instead of actual component or error message
        return <NotFoundComponent />
      }

      // give preference to the error being shown on toaster, rather than the Error Component
      if (error && error.humanReadableError && !error.hideToastr)
        return <WrappedComponent {...props} />

      // show error message if no other return path matched
      return <ErrorComponent {...props} errorMessage={errorMessage} />
    }

    return <WrappedComponent {...props} />
  }

  ApiErrorHandling.displayName = `withApiErrorHandling(${
    WrappedComponent.displayName || WrappedComponent.name
  })`

  return ApiErrorHandling
}

export const withApiErrorHandling = (
  WrappedComponent,
  errorSelector = [],
  loadingSelector = [],
) =>
  withApiErrorHandlingComponent(
    DefaultError,
    WrappedComponent,
    errorSelector,
    loadingSelector,
  )
