import { useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { resetMaklerPremiumCompanies } from 'actions/company'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate'
import { InputDecimal } from 'components/common/InputDecimal'
import { InputText } from 'components/common/InputText'
import { ProgressButton } from 'components/common/ProgressButton'
import { UniversalFileUpload } from 'components/common/UniversalFileUpload'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import { CustomOfferCreateFormValues } from './CustomOfferCreateModal'

type CustomOfferCreateFormPropsType = {
  onClose: () => void
}

export const CustomOfferCreateForm = (
  props: CustomOfferCreateFormPropsType,
) => {
  const dispatch = useDispatch()
  const { onClose } = props

  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    setValues,
    submitCount,
    values,
    validateForm,
  } = useFormikContext<CustomOfferCreateFormValues>()

  const searchFields = [
    {
      optionLabel: I18n.t(
        'createMaklerPremiumOfferPageTranslations.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'createMaklerPremiumOfferPageTranslations.steps.1.fields.empto_external_number.label',
      ),
      optionValue: 'empto_external_number',
    },
    {
      optionLabel: I18n.t(
        'createMaklerPremiumOfferPageTranslations.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  const handleResetFormOnCustomerChange = company => ({
    attachments: values.attachments,
    company,
    custom_offer_number: values.custom_offer_number,
    issued_at: values.issued_at,
    net_price: values.net_price,
    runtime_of_custom_offer: values.runtime_of_custom_offer,
    search_field: 'company_name',
    non_field_errors: undefined, // dummy value for non-field-errors, DO NOT USE in Form
  })

  const minRuntimeCustomOfferHour =
    // the default value before entering anything is the current day, so if there is no value, show only
    // the possible hour-options between now and the end of the working day (22:00)

    (!values.runtime_of_custom_offer ||
      // if there is a value and the selected day is today, show only the possible hour-options between
      // now and the end of the working day (22:00)
      moment(values.runtime_of_custom_offer, 'L').isSame(
        moment().format('L'),
      )) &&
    // restrict choices to hours within our defined working day (08:00 - 22:00)
    moment().hours() > 8 &&
    moment().hours() < 22
      ? moment()
      : moment().hours(8).minutes(0)

  const isFieldValid = name =>
    (values[name] ?? '') && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  return (
    <>
      <div
        className='uk-modal-body bookkeeping-list__form_modal-fields'
        data-testid='bookkeeping-list__form_modal-fields'
      >
        <div className='maklerpremium-offerform__column-2'>
          <DropDownInput
            choices={searchFields}
            label={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.1.fields.search_field.label',
            )}
            name='search_field'
            onChange={e => {
              setFieldValue('existing_customer', '')
              setFieldValue('zipcode', '')
              dispatch(resetMaklerPremiumCompanies()) // Reset Filtered results
              handleChange(e)
            }}
            value={values.search_field}
            withCheckmark
          />
        </div>
        <div className='maklerpremium-offerform__column-2'>
          {/*prettier-ignore*/}
          <Select
            urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/makler-premium-companies/?${values.search_field}=`}
            urlPart2='&maklerpremium_role=1&page_size=1000&page=1'
            getValue={(e) => e.id}
            getLabel={(e) => e.id === 0 ? e.name : `${e.main_address_object.zipcode}, ${e.name}, ${e.empto_external_number}`}
            label={I18n.t(`addInvoicePageTranslations.epd.steps.1.fields.${values.search_field}.label`)}
            setValue={e => {
              setValues(handleResetFormOnCustomerChange(e?.value), false)
            }}
            selectedValue={values.company}
            selecting={'company'}
            error={errors.company}
          />
        </div>

        {values.company && (
          <>
            <InputText
              label={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.customOfferNumber.label',
              )}
              placeholder={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.customOfferNumber.label',
              )}
              maxLength={30}
              name='custom_offer_number'
              onChange={handleChange}
              value={values.custom_offer_number}
              error={getFieldError}
              withCheckmark
              showCheckmark={isFieldValid}
            />

            <InputDate
              label={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.issuedAt.label',
              )}
              placeholder={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.issuedAt.label',
              )}
              maxDate={moment()}
              name='issued_at'
              onChange={value => {
                setFieldValue('issued_at', value.format('L'))
                setFieldTouched('issued_at')
              }}
              value={values.issued_at}
              error={getFieldError}
              withCheckmark
              showCheckmark={isFieldValid}
            />

            <InputDecimal
              label={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.net_price.label',
              )}
              placeholder={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.net_price.label',
              )}
              name='net_price'
              value={values.net_price}
              maxValue={99999}
              onChange={handleChange}
              error={getFieldError}
              withCheckmark
              showCheckmark={isFieldValid}
              removeBrowserStyling
            />

            <InputDate
              label={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.runtimeOfCustomOffer.label',
              )}
              placeholder={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.runtimeOfCustomOffer.label',
              )}
              maxTime={moment().hours(22).minutes(0)}
              minTime={minRuntimeCustomOfferHour}
              minDate={moment()}
              showTimeSelect
              name='runtime_of_custom_offer'
              onChange={value =>
                setFieldValue('runtime_of_custom_offer', value)
              }
              value={values.runtime_of_custom_offer}
              error={getFieldError}
              withCheckmark
              showCheckmark={isFieldValid}
            />

            <UniversalFileUpload
              name='attachments'
              label={I18n.t(
                'offerPipelineTranslations.customOfferCreateModal.form.attachments.label',
              )}
              allowedFileTypes='application/pdf'
              maxFiles={1}
              initialValues={values.attachments}
              onChange={fileList => {
                setFieldValue('attachments', fileList)
                if (submitCount > 0) validateForm()
              }}
              showFilename
              error={getFieldError}
              withCheckmark
            />
          </>
        )}
      </div>

      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <Button
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.button.cancel' />
        </Button>

        <ProgressButton
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={submitCount > 0 && !isValid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          <Translate value='general.button.save' />
        </ProgressButton>
      </div>
    </>
  )
}
