import React from 'react'
import { Control } from 'react-redux-form'

import SingleFileUpload from 'components/common/SingleFileUpload'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import FileUploadControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
const FileUploadControl = props => {
  const handleFilesUpload = uploadedFiles => {
    props.actions.form.change(props.field.model, uploadedFiles[0].id)
  }

  const getField = () => {
    if (!props.field) return null
    return (
      <div
        className={`file-upload-control${props.disabled ? ' disabled' : ''}`}
      >
        <Control
          className={props.className}
          model={props.field.model}
          placeholder={props.placeholder || ''}
          component={props.uploadComponent}
          onFilesUploaded={handleFilesUpload}
          onFileRemoved={props.handleFileRemoved}
          onFileEdited={props.handleFileEdit}
          mapProps={{
            ...props.addMapProps,
            initialFile: () => props.addMapProps.initialFile,
            displayEditIcon: () => props.addMapProps.displayEditIcon,
          }}
        />
      </div>
    )
  }

  return <FormField {...props} fieldComponent={getField()} />
}

FileUploadControl.propTypes = FileUploadControlProps

FileUploadControl.defaultProps = {
  ...ControlDefaultProps,
  uploadComponent: SingleFileUpload,
  singleFormField: true,
  addMapProps: {},
}

export default connector(FileUploadControl)
