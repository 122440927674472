import './style.scss'

import classNames from 'classnames'
import React, { useRef } from 'react'
import { Control } from 'react-redux-form'
import Spinner from 'react-spinkit'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import SelectControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
const SelectControl = props => {
  const selectRef = useRef(null)
  const addOptionRef = useRef(null)

  const handleChange = event => {
    if (
      addOptionRef.current &&
      event.target.value === addOptionRef.current.value
    ) {
      props.onAdd(event)
    } else {
      props.onChange(event)
    }
  }

  const getField = () => {
    if (!props.field) return null

    return (
      <div
        className={classNames(
          'select-control',
          { 'uk-disabled': props.disabled },
          { 'button-control': props.isLoading },
          { 'with-caption': props.isLoading },
        )}
      >
        <Control.select // eslint-disable-line react/jsx-pascal-case
          data-testid={props.dataTestId}
          ref={selectRef}
          className={classNames(
            props.className,
            { 'remove-arrow': props.isLoading },
            {
              placeholder:
                props.field.value === undefined ||
                props.field.value === null ||
                props.field.value === '' ||
                props.field.value === 'placeholder',
            },
          )}
          model={props.field.model}
          value={`${props.field.value}`}
          tabIndex={props.tabIndex}
          parser={props.parser ? props.parser : value => value}
          onChange={handleChange}
        >
          {props.placeholder && (
            <option value='' hidden={!props.enablePlaceholder}>
              {props.placeholder}
            </option>
          )}

          {props.onAdd && (
            <option ref={addOptionRef}>{props.addOptionLabel}</option>
          )}

          {props.options.map(option => (
            <option value={option.value || option.id} key={option.id}>
              {option.label}
            </option>
          ))}
        </Control.select>

        {props.isLoading && (
          <div className='caption symbol'>
            <Spinner
              fadeIn='none' // Show immediately
              name='circle'
              color='grey'
            />
          </div>
        )}
      </div>
    )
  }

  return <FormField {...props} fieldComponent={getField()} />
}

SelectControl.propTypes = SelectControlProps

SelectControl.defaultProps = {
  ...ControlDefaultProps,
  options: [],
  addOptionLabel: 'addOption',
  enablePlaceholder: false,
  isBoolean: false,
  parser: null,
  onAdd: null,
  onChange: () => undefined,
  isLoading: false,
  dataTestId: '',
}

export default connector(SelectControl)
