import './style.scss'

import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getSecurityGroups } from 'actions/securitygroup'
import { ReadOnlyField } from 'components/common/ReadOnly'
import {
  getContainerNameForShops,
  getFractionName,
} from 'components/executionproof/helpers'
import {
  OFFER_ORDER_TYPE,
  OFFER_SERVICE_INTERVAL,
  OFFER_SERVICE_INTERVAL_WEEKDAYS,
  OFFER_SERVICE_INTERVALS,
} from 'components/inquiry/constants'
import { COLLECTION_CONTAINER_IDS, DATE_FORMATS } from 'constants/app'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'
import { getName } from 'helper/translations'

import { DetailsSection } from '../../DetailsSection'

interface OfferDetailsComponentProps {
  offer: Offer
  fractionList: Fraction[]
  containerList: Container[]
}

export const OfferDetails: FC<OfferDetailsComponentProps> = ({
  offer,
  fractionList = [],
  containerList = [],
}) => {
  const isOrderType = {
    oneTime:
      offer.order_type &&
      Number(offer.order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME,
    regular:
      offer.order_type &&
      Number(offer.order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING,
    onDemand:
      offer.order_type &&
      Number(offer.order_type) === OFFER_ORDER_TYPE.TYPE_ON_DEMAND,
    quick:
      offer.order_type &&
      Number(offer.order_type) === OFFER_ORDER_TYPE.TYPE_QUICK,
  }

  const dispatch = useDispatch()
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(offer.container),
  )
  const fraction = getFractionName(offer.fraction, fractionList)
  const container = getContainerNameForShops(offer.container, containerList)
  const date = moment(offer.turn_begin, DATE_FORMATS).format('L')
  const securityGroupList = useSelector(getSecurityGroupsSelector)
  useEffect(() => {
    if (securityGroupList.length < 1) dispatch(getSecurityGroups())
  }, [securityGroupList.length, dispatch])

  const getSecurityGroup = security_group => {
    return securityGroupList
      .filter(item => item.id === Number(security_group))
      .map(
        item =>
          `${I18n.t('selectSecurityGroupTranslations.protectionClassNumber', {
            protectionClass: item.protection_class,
          })} / ${I18n.t(
            'selectSecurityGroupTranslations.securityLevelNumber',
            { securityLevel: item.security_level },
          )}`,
      )
      .shift()
  }

  const initialSecurityGroupDisplay = offer.security_group
    ? ' / ' + getSecurityGroup(offer.security_group)
    : ''
  const initialNumberOfContainersDisplay = offer.number_of_containers
    ? `${offer.number_of_containers}x `
    : ''

  let offerDays
  if (isOrderType.regular && offer.interval) {
    if (
      offer &&
      offer.interval === OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
    ) {
      offerDays = [
        offer && offer.interval_weekday_first,
        offer && offer.interval_weekday_second,
      ]
    } else {
      offerDays = [offer && offer.interval_weekday_first]
    }
  }

  return (
    <DetailsSection
      title={I18n.t('OfferReviewTranslations.offerDetails.sectionTitle')}
    >
      <div className='document-review-section-offer-details'>
        {/* OFFER NUMBER */}
        <div className='document-review-section-offer-details__producer-section'>
          {!!offer.id && (
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.sectionTitle',
              )}
              value={`${offer.id} / ${fraction} / ${initialNumberOfContainersDisplay}${container} ${initialSecurityGroupDisplay} / ${date}`}
            />
          )}
        </div>

        {/* Customer */}
        {!!offer.customer_company_object?.name && (
          <div className='document-review-section-offer-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerDetails.customer')}
              value={offer.customer_company_object.name}
            />
          </div>
        )}

        {isOrderType.regular && (
          <div className='document-review-section-offer-details__disposer-section'>
            {/* Rhythm */}
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerDetails.rhythm')}
              value={getName(
                offer && offer.interval,
                OFFER_SERVICE_INTERVALS,
                true,
              )}
            />
            <ReadOnlyField
              label={I18n.t(
                'inquiryDetailsTranslations.section.time.fields.serviceIntervalWeekdays',
                {
                  count:
                    offer.interval ===
                    OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
                      ? 2
                      : 1,
                },
              )}
              value={offerDays
                .map(value =>
                  value != null
                    ? getName(value, OFFER_SERVICE_INTERVAL_WEEKDAYS, true)
                    : I18n.t('general.allTheSame'),
                )
                .join(', ')}
            />
          </div>
        )}

        {/* DISPOSER */}
        {!!offer.company_object?.name && (
          <div className='document-review-section-offer-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerDetails.disposer')}
              value={offer.company_object?.name}
            />
          </div>
        )}

        {/* COLLECTION ADDRESS */}
        {!!offer.collection_address_object?.display_name && (
          <div className='document-review-section-offer-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.collectionAddress',
              )}
              value={offer.collection_address_object.display_name}
            />
          </div>
        )}

        {/* INVOICE CHECK COMPANY */}
        {offer.company_object?.name && (
          <div className='document-review-section-offer-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.invoiceCheckCompany',
              )}
              value={offer.company_object.name}
            />
          </div>
        )}

        <div className='document-review-section-offer-details__waste-section'>
          {!!offer?.fraction && (
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerDetails.fraction')}
              value={getFractionName(offer.fraction, fractionList)}
            />
          )}

          {/* FINE FRACTION */}
          {!!offer.fine_fraction && (
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.fineFraction',
              )}
              value={getFractionName(offer.fine_fraction, fractionList)}
            />
          )}

          {!!offer.security_group && (
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.securityGroup',
              )}
              value={getSecurityGroup(offer.security_group)}
            />
          )}

          {/* CONTAINER */}
          {!!offer.container && (
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerDetails.container')}
              value={getContainerNameForShops(offer.container, containerList)}
            />
          )}

          {/* NUMBER OF CONTAINERS */}
          {!!offer.number_of_containers && !showQuantityInCubicMeters && (
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.numberOfContainers',
              )}
              value={offer.number_of_containers}
            />
          )}

          {/* TURN BEGIN DATE */}
          {(isOrderType.regular || isOrderType.onDemand) &&
            offer.turn_begin && (
              <ReadOnlyField
                label={I18n.t(
                  'OfferReviewTranslations.offerDetails.' +
                    `${isOrderType.regular ? 'turnBegin' : 'agreementBegin'}`,
                )}
                value={offer ? moment(offer.turn_begin).format('L') : ''}
              />
            )}
          {/* TURN END DATE */}
          {(isOrderType.regular || isOrderType.onDemand) && offer.turn_end && (
            <ReadOnlyField
              label={I18n.t(
                'OfferReviewTranslations.offerDetails.' +
                  `${isOrderType.regular ? 'turnEnd' : 'agreementEnd'}`,
              )}
              value={moment(offer && offer.turn_end).format('L')}
            />
          )}

          {/* Abholdatum */}
          {offer.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME &&
            offer.collection_date && (
              <ReadOnlyField
                label={I18n.t(
                  'OfferReviewTranslations.offerDetails.collectionDate',
                )}
                value={moment(offer.collection_date, DATE_FORMATS).format('L')}
              />
            )}
        </div>
      </div>
    </DetailsSection>
  )
}
