import moment from 'moment/moment'
import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import { OFFER_ORDER_TYPES } from 'components/inquiry/constants'
import { getFractionOrContainerDetailsById } from 'components/inquiry/helpers'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { decimalToGermanFormat } from 'helper/general'
import { getName } from 'helper/translations'

import {
  CardContainer,
  CardDetailsList,
  CardDetailsListItem,
  CardDistinctArea,
  CardHeader,
  CardStatusHeader,
  CardSwatch,
} from '../Card'

export interface AgreementCardProps {
  coarseFraction: Fraction
  containerList: Container[]
  fraction: Fraction
  item: AcceptedOffer
}

export const AgreementCard: FC<AgreementCardProps> = ({
  coarseFraction,
  fraction,
  containerList,
  item,
}) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const today = moment().startOf('day')
  const serviceEndDate = moment(item.service_end_date)
  const serviceStartDate = moment(item.service_start_date)
  const fractionFound = getFractionOrContainerDetailsById(
    item.container,
    containerList,
  )
  const containerCapacity = fractionFound ? fractionFound?.capacity : 0
  const containerName = fractionFound ? fractionFound?.name : ''
  const status =
    today.diff(serviceStartDate, 'days') >= 0 && // Not yet started
    today.diff(serviceEndDate, 'days') <= 0 // Already finished
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(item.container),
  )

  return (
    <CardContainer
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Link className='new-card__click-area' to={`/agreement/${item.id}`}>
        <CardHeader
          title={coarseFraction && coarseFraction.name}
          subtitle={fraction && fraction.name}
        />
        <CardSwatch
          blurred={!hovered}
          image={coarseFraction && coarseFraction.image}
          name={coarseFraction && coarseFraction.name}
        />
        <CardDistinctArea>
          <CardStatusHeader
            state={
              status
                ? I18n.t('constant.status.active')
                : I18n.t('constant.status.inactive')
            }
            highlighted={status}
            title={`${I18n.t('newAgreementCard.details.title')} ${item.id}`}
          />
        </CardDistinctArea>
        <CardDetailsList
          items={[
            <CardDetailsListItem key={ICON_NAME.STORE}>
              <Icon name={ICON_NAME.STORE} />
              <span className='two-lines break-all'>
                {item.customer_company_object.name}
              </span>
            </CardDetailsListItem>,
            <CardDetailsListItem key={ICON_NAME.LOCATION}>
              <Icon name={ICON_NAME.LOCATION} />
              {item.collection_address_object.display_name}
            </CardDetailsListItem>,
            <CardDetailsListItem key={ICON_NAME.CALENDAR}>
              <Icon name={ICON_NAME.CALENDAR} />
              <Translate
                value='newAgreementCard.startDate'
                date={serviceStartDate.format('L')}
                dangerousHTML
              />
            </CardDetailsListItem>,
            <CardDetailsListItem key={ICON_NAME.CALENDAR}>
              <Icon name={ICON_NAME.CALENDAR} />
              <Translate
                value='newAgreementCard.endDate'
                date={serviceEndDate.format('L')}
                dangerousHTML
              />
            </CardDetailsListItem>,
            // Disposer Details
            <CardDetailsListItem key={ICON_NAME.TRUCK}>
              <Icon name={ICON_NAME.TRUCK} />
              <span className='two-lines break-all'>{item.disposer_name}</span>
            </CardDetailsListItem>,
            <CardDetailsListItem key={ICON_NAME.CONTAINER_SKIP}>
              <Icon name={ICON_NAME.CONTAINER_SKIP} />
              <Translate
                value={
                  showQuantityInCubicMeters
                    ? 'newAgreementCard.details.containerCubicMeters'
                    : 'newAgreementCard.details.container'
                }
                capacity={containerCapacity}
                name={containerName}
                amount={
                  showQuantityInCubicMeters
                    ? decimalToGermanFormat(item.quantity_in_cubic_meters)
                    : item.number_of_containers
                }
                dangerousHTML
              />
            </CardDetailsListItem>,
            <CardDetailsListItem key={ICON_NAME.ARROW_RIGHT}>
              <Icon name={ICON_NAME.ARROW_RIGHT} />
              {I18n.t(getName(item.order_type, OFFER_ORDER_TYPES))}
            </CardDetailsListItem>,
          ]}
        />
      </Link>
    </CardContainer>
  )
}
