import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'

import FileComment from 'components/common/SingleFileUpload/components/FileComment'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'

interface PreviewAttachmentModalProps {
  callback: (modal: any, clickedFile: any) => void
  file?: Attachment | null
  modalVisible?: boolean
}

export const PreviewAttachmentModal: FC<PreviewAttachmentModalProps> = ({
  callback,
  file = null,
  modalVisible = false,
}) => {
  const selectedFileModalId = uniqueId() // from lodash package. Generate a unique ID

  return (
    <>
      {file && (
        <Modal
          ariaDescribedBy={selectedFileModalId}
          isOpen={modalVisible}
          onClose={callback}
        >
          <div>
            <ModalHeader
              onClose={callback}
              title={file.name}
              titleId={selectedFileModalId}
            />

            <FileComment
              allowComment={false}
              file={file}
              onFormSubmit={callback}
            />
          </div>
        </Modal>
      )}
    </>
  )
}
