import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools'
import keyMirror from 'keymirror'

import { BUSINESS_SEGMENT } from 'constants/app'

import { isValidZipcode } from '../../common/AddressForm/helpers'
import {
  isCommercialRegistryNumber,
  isEmail,
  isGermanTaxId,
  isNotEmpty,
  isRegistrationNumber,
  isRequired,
  isURL,
} from '../../common/Form/validators'
import { COMPANY_ROLE } from '../constants'

import { countryIsGermany, postalAddressFieldsAreValid } from './helpers'

// ID of country germany (set in backend via fixture)
export const COUNTRY_GERMANY = 2

export const CompanyFormValidators = {
  central_contact_email: { isEmail },
  commercial_registry_number: { isCommercialRegistryNumber },
  contact_person: { isRequired },
  name: { isNotEmpty },
  website_url: { isURL },
  registration_number: { isRegistrationNumber },
  'main_address_object.street': { isRequired },
  'main_address_object.house_number': { isRequired },
  'main_address_object.location': { isRequired },
  'main_address_object.country': { isRequired },
  'main_address_object.id': { isRequired },
  '': {
    iban: values =>
      values.iban ? isValidIBAN(electronicFormatIBAN(values.iban)) : true,
    bic: values => (values.bic ? isValidBIC(values.bic) : true),
    taxDataFilled: values => {
      if (values.role === COMPANY_ROLE.WASTE_COMPANY) {
        if (countryIsGermany(values.main_address_object.country)) {
          return !!values.tax_id || !!values.tax_number
        }
      }
      return true
    },
    tax_id: values => {
      if (values.role === COMPANY_ROLE.WASTE_COMPANY) {
        if (
          values.tax_id &&
          countryIsGermany(values.main_address_object.country)
        ) {
          return isGermanTaxId(values.tax_id)
        }
      }
      return true
    },
    postalAddressDataFilled: values =>
      postalAddressFieldsAreValid(values.postal_address_object),
    mainAddressZipcode: values => {
      if (countryIsGermany(values.main_address_object.country)) {
        return isValidZipcode(values.main_address_object.zipcode)
      }
      return true
    },
    postalAddressZipcode: values => {
      if (countryIsGermany(values.postal_address_object.country)) {
        return isValidZipcode(values.postal_address_object.zipcode)
      }
      return true
    },
    registrationNumbersFilled: values => {
      if (
        values.business_segment === BUSINESS_SEGMENT.BUSINESS_DSZ &&
        values.makler_premium_company &&
        values.role === COMPANY_ROLE.WASTE_PRODUCER
      ) {
        return !!values.registration_number
      }
      return true
    },
  },
}

export const COMPANY_MODALS = keyMirror({
  CERTIFICATE_FORM_MODAL: null,
  FILE_PREVIEW_MODAL: null,
  UPLOAD_DOCUMENT_MODAL: null,
})

export default {}
