/**
 * Returns the name
 * @param country
 * @return {string}
 */
export const getListLabelForCountry = country => {
  if (country && country.name) {
    return country.name
  }
  return ''
}

export const getListLabelForCompanyAddress = address => {
  if (address) {
    if (address.display_name)
      return `ID: ${address.id}, ${address.display_name}`
    else
      return `ID: ${address.id}, ${address.street} ${address.house_number}, ${address.zipcode} ${address.location}`
  }
  return ''
}

export default {}
