// Styles
import './style.scss'

import { bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'

import {
  FILE_TYPE_IMAGE,
  FILE_TYPE_PDF,
} from 'components/common/SingleFileUpload/constants'
import { AttachmentScheme } from 'components/common/SingleFileUpload/schemes'
import Icon from 'components/common/Fontello'
import { Textarea } from 'components/common/Textarea'

import FileThumbnail from '../FileThumbnail'

/**
 * @description This component renders a preview of a file with a small form to enter a text description of the file.
 * @param {Object} props the component props
 */
export default class FileComment extends Component {
  static propTypes = {
    onFormSubmit: func, // Is called when the form is submitted
    file: shape(AttachmentScheme).isRequired, // The file to display and to add the comment to
    allowComment: bool, // Whether to display the comment form
    allowDownload: bool, // Show a download button or not
  }

  static defaultProps = {
    allowComment: true,
    allowDownload: false,
    onFormSubmit: () => undefined,
  }

  state = {
    file: this.props.file,
    textareaValue: this.props.file.text || '',
    selectedFileType: null,
  }

  componentDidMount() {
    if (this.props.file.type) {
      this.setState({ selectedFileType: this.props.file.type.split('/')[0] })
    } else {
      if (this.props.file.file.includes('pdf'))
        this.setState({ selectedFileType: FILE_TYPE_PDF })
      if (this.props.file.file.includes('bmp'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      if (this.props.file.file.includes('flif'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      if (this.props.file.file.includes('jpeg'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      if (this.props.file.file.includes('jpg'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      if (this.props.file.file.includes('png'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      if (this.props.file.file.includes('svg+xml'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      if (this.props.file.file.includes('webp'))
        this.setState({ selectedFileType: FILE_TYPE_IMAGE })
    }
  }

  /**
   * @function
   * @description When the form is submitted, the text is added to the file and the onFormSubmit prop is called.
   */
  handleSetComment = () => {
    if (this.state.textareaValue) {
      this.setState(
        { file: { ...this.state.file, text: this.state.textareaValue } },
        () => {
          this.props.onFormSubmit(this.state.file)
        },
      )
    } else {
      this.props.onFormSubmit(this.state.file)
    }
  }

  handleChangeText = ({ target: { value } }) => {
    this.setState({ textareaValue: value })
  }

  render() {
    return (
      <div className='uk-panel'>
        <div className='uk-modal-body attachment-preview-modal'>
          <div className='preview-document uk-margin-small-top uk-margin-small'>
            <FileThumbnail file={this.props.file} />
            {this.state.selectedFileType &&
              this.state.selectedFileType !== FILE_TYPE_IMAGE && (
                <a
                  href={this.props.file.file}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='uk-button uk-button-secondary uk-margin-top'
                >
                  <Translate value='fileUpload.openFileButtonTitle' />
                </a>
              )}
          </div>
          {this.props.allowComment ? (
            <Textarea
              maxLength={200}
              name='file-comment'
              onChange={this.handleChangeText}
              placeholder={I18n.t(
                'fileUpload.fileCommentForm.placeholder.text',
              )}
              showRemainingCharacters
              value={this.state.textareaValue}
            />
          ) : (
            <p>{this.props.file.text}</p>
          )}

          {this.props.allowDownload && (
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={this.props.file.file}
              className='uk-icon-link'
            >
              <Icon name='download' />
              {I18n.t('general.download.doc')}
            </a>
          )}
        </div>

        <div className='uk-modal-footer uk-text-right'>
          <button
            type='button'
            className='uk-button uk-button-primary'
            onClick={this.handleSetComment}
          >
            <Translate value='general.button.submit' />
          </button>
        </div>
      </div>
    )
  }
}
