import keyMirror from 'keymirror'

const GET_AVVS = keyMirror({
  GET_AVVS_REQUEST: null,
  GET_AVVS_SUCCESS: null,
  GET_AVVS_ERROR: null,
})

const SEND_CONVERT_INQUIRY_CONFIRMATION = keyMirror({
  SEND_CONVERT_INQUIRY_CONFIRMATION_REQUEST: null,
  SEND_CONVERT_INQUIRY_CONFIRMATION_SUCCESS: null,
  SEND_CONVERT_INQUIRY_CONFIRMATION_ERROR: null,
})
export default {
  ...SEND_CONVERT_INQUIRY_CONFIRMATION,
  ...GET_AVVS,
}
