import { useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES } from 'components/invoicecheck/constants'

import { InvoiceCheckPositionsFormValues } from '../../InvoiceCheckPositionsFormSteps'
import { getOfferDisposerRentPriceContainerMonth } from '../helper'
import { OrderType } from '../OrderAndServicesForm'

type RentFormPropsType = {
  isFieldValid: (value: string) => boolean
  getFieldError: (name: string | undefined) => string | undefined
  offerOptions: { label: string; value: string }[]
  selectedOfferOption?: { label: string; value: string }
  offers?: Offer[]
}

export const RentForm = (props: RentFormPropsType) => {
  const {
    getFieldError,
    isFieldValid,
    offerOptions,
    selectedOfferOption,
    offers,
  } = props

  // Form State (values, errors, functions)
  const { handleBlur, handleChange, setFieldValue, values } =
    useFormikContext<InvoiceCheckPositionsFormValues>()

  if (values.order_type !== OrderType.RENT) return <></>

  const RENT_DOCUMENT_MIN_DATE = moment('2021-01-01')

  return (
    <>
      {/* Vereinbarungsnummer */}
      <StaticCombobox
        error={getFieldError}
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.offer.label',
        )}
        name='offer'
        noResultsText={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.offer.noResultsText',
        )}
        options={offerOptions}
        selectedOption={selectedOfferOption}
        showCheckmark={isFieldValid}
        withCheckmark
        onSelectionChange={(event: React.ChangeEvent<any>) => {
          handleChange(event)
          setFieldValue(
            'net_price',
            getOfferDisposerRentPriceContainerMonth(offers, event.target.value),
          )
        }}
      />
      {/* Leistungsmonat */}
      <InputDate
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.deliveryMonth.label',
        )}
        placeholder={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.deliveryMonth.label',
        )}
        name='delivery_date'
        filterDate={date => date.date() === 1}
        onChange={value => {
          setFieldValue('delivery_date', value.startOf('month').format('L'))
          setFieldValue('collection_date', undefined)
        }}
        showCheckmark={isFieldValid}
        value={values.delivery_date}
        withCheckmark
        minDate={RENT_DOCUMENT_MIN_DATE}
        maxDate={moment()}
      />
      {/* Kategorie */}
      <DropDownInput
        choices={INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES.map(choice => ({
          optionValue: choice.id,
          optionLabel: I18n.t(choice.name),
        }))}
        name='category'
        onBlur={handleBlur}
        onChange={handleChange}
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.label',
        )}
        placeholder={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.label',
        )}
        showCheckmark={isFieldValid}
        value={values.category}
        error={getFieldError}
        isDisabled
        withCheckmark
      />
    </>
  )
}
