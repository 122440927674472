const invoiceApiTranslations = {
  handleGetDocuments: {
    error: 'Belege konnten leider nicht geladen werden.',
  },
  handleGetDocument: {
    error: 'Beleg konnte leider nicht geladen werden.',
  },
  handleUpdateEPDDocument: {
    success: 'Beleg wurde erfolgreich bearbeitet.',
    error: 'Beleg konnte nicht bearbeitet werden.',
  },
  handleEPDExportBatchfile: {
    success: 'Buchungsstapel Export wurde erfolgreich gestartet.',
    error: 'Buchungsstapel Export konnte nicht erstellt werden',
  },
  handleEPDExportInvoices: {
    success: 'Export wurde erfolgreich erstellt.',
    error: 'Export konnte nicht erstellt werden',
  },
  handleExportInvoiceCheckBatchFile: {
    success: 'Buchungsstapel Export wurde erfolgreich gestartet.',
    error: 'Buchungsstapel Export konnte nicht erstellt werden',
  },
  handleExportInvoiceCheck: {
    success: 'Export wurde erfolgreich erstellt.',
    error: 'Export konnte nicht erstellt werden',
  },
}

export default invoiceApiTranslations
