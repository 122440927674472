import { createSelector } from 'reselect'

import { ADDRESS_STATUS } from '../components/company/constants'

/**
 * @description selector to get address list.
 * @param state
 * @return {*}
 */
export const getAddressesSelector: (state: any) => Address[] = state =>
  state.address.itemList

/**
 * @description selector to get only active address list ordered by street and house number.
 * @param state
 * @return {*}
 */
export const getActiveOrderedAddressesSelector: (state: any) => Address[] =
  createSelector(getAddressesSelector, addresses =>
    addresses.filter(
      address => address.status === ADDRESS_STATUS.STATUS_ACTIVE,
    ),
  )

/**
 * @description selector to get address list for company
 * @param state
 * @return {*}
 */
export const getAddressListForCompanySelector: (
  state: any,
) => Address[] = state => state.address.itemListForCompany

/**
 * @description selector to get an address.
 * @param state
 * @return {*}
 */
export const getAddressSelector: (state: any) => Address = state =>
  state.address.item

/**
 * @description selector to get an user pagination object.
 * @param state
 * @return {*}
 */
export const getAddressPaginationSelector = state => state.address.pagination

/**
 * @description selector to get a list of collection addresses to use with agreements filter.
 * (orders by street name and house number and removes duplicates).
 * @param state
 * @return {*}
 */
export const getAgreementCollectionAddressesSelector: (
  state: any,
) => Address[] = state =>
  state.address.agreementInquiredCollectionAddressList as Address[]

/**
 * @description selector to get a list of collection addresses with order numbers
 * @param state
 * @return {*}
 */
export const getAddressesWithOrdersSelector: (
  state: any,
) => AddressWithOrders[] = state => state.address.itemListWithOrders
