import { Form, Formik } from 'formik'
import moment, { isMoment } from 'moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { changeAgreementPeriodForStandingOffers } from 'actions/offer'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import InputDate from 'components/common/InputDate'
import { ProgressButton } from 'components/common/ProgressButton'
import { OFFER_ORDER_TYPE } from 'components/inquiry/constants'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

interface ChangeAgreementPeriodFormProps {
  offer: Offer
  onCancel?: () => void
  onSuccess?: () => void
}

/**
 * @description This component renders and controls the ChangeAgreementPeriodForm
 * @param offer the agreement to be changed
 * @param onCancel action to be executed with click on cancel
 * @param onSuccess action to be executed when submit successes
 * @constructor
 */
export const ChangeAgreementPeriodForm: FC<ChangeAgreementPeriodFormProps> = ({
  offer,
  onCancel = () => undefined,
  onSuccess = () => undefined,
}) => {
  const dispatch = useDispatch()

  const error = useSelector(
    createErrorSelector(['CHANGE_AGREEMENT_PERIOD_REQUEST']),
  )
  const isLoading = useSelector(
    createLoadingSelector(['CHANGE_AGREEMENT_PERIOD_REQUEST']),
  )

  const offerIsRecurring =
    Number(offer.order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING

  return (
    <div className='change-agreement-period-form-page'>
      <Formik
        initialValues={{
          service_start_date: moment(offer.service_start_date),
          service_end_date: moment(offer.service_end_date),
        }}
        validationSchema={() =>
          yup.object().shape({
            service_start_date: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            service_end_date: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
          })
        }
        validate={() => {
          const errors: {
            service_start_date?: string
            service_end_date?: string
          } = {}

          return errors
        }}
        onSubmit={values => {
          const service_start_date = isMoment(values.service_start_date)
            ? values.service_start_date.format()
            : moment(values.service_start_date, 'DD.MM.YYYY').format()
          const service_end_date = isMoment(values.service_end_date)
            ? values.service_end_date.format()
            : moment(values.service_end_date, 'DD.MM.YYYY').format()

          dispatch(
            changeAgreementPeriodForStandingOffers(
              offer.id,
              service_start_date,
              service_end_date,
              offerIsRecurring,
            ),
          )
          onSuccess()
        }}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldTouched,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => (
          <Form
            className='uk-modal-body change-agreement-period-form'
            data-testid='change-agreement-period-form'
            noValidate // Disable browser validation
          >
            {offerIsRecurring && (
              <Translate value='changeAgreementPeriodFormTranslations.recurringOfferHint' />
            )}
            <ApiValidationMessages error={error} />
            <InputDate
              error={
                submitCount > 0 && touched.service_start_date
                  ? errors.service_start_date
                  : ''
              }
              dataTestId='service-start-date-input'
              label={I18n.t(
                'changeAgreementPeriodFormTranslations.serviceStartDate',
              )}
              minDate={moment()}
              name='service_start_date'
              onChange={value => {
                setFieldValue('service_start_date', value.format('L'))
                setFieldTouched('service_start_date')
                if (moment(value, 'L') > moment(values.service_end_date, 'L')) {
                  setFieldValue('service_end_date', '')
                }
              }}
              placeholder={I18n.t(
                'changeAgreementPeriodFormTranslations.serviceStartDate',
              )}
              value={values.service_start_date}
              validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
              isRequired={!values.service_start_date}
              overwrittenValue={
                moment(offer.service_start_date).isSameOrAfter(moment(), 'days')
                  ? null
                  : offer.service_start_date
              }
            />

            <InputDate
              error={
                submitCount > 0 && touched.service_end_date
                  ? errors.service_end_date
                  : ''
              }
              dataTestId='service-end-date-input'
              label={I18n.t(
                'changeAgreementPeriodFormTranslations.serviceEndDate',
              )}
              // eslint-disable-next-line import/no-named-as-default-member
              minDate={moment.max(
                moment(values.service_start_date, 'L'),
                moment(),
              )}
              name='service_end_date'
              onChange={value => {
                setFieldValue('service_end_date', value.format('L'))
                setFieldTouched('service_end_date')
              }}
              placeholder={I18n.t(
                'changeAgreementPeriodFormTranslations.serviceEndDate',
              )}
              value={values.service_end_date}
              validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
              isRequired={!values.service_end_date}
              overwrittenValue={
                moment(offer.service_end_date).isSameOrAfter(moment(), 'days')
                  ? null
                  : offer.service_end_date
              }
            />

            <div className='uk-modal-footer upload-document-form__buttons'>
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={onCancel}
                  dataTestId='change-agreement-period-form-cancel-button'
                >
                  {I18n.t('general.button.cancel')}
                </Button>

                <ProgressButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  dataTestId='change-agreement-period-form-submit'
                  isDisabled={
                    (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                  }
                  isLoading={isSubmitting && isLoading}
                  onClick={handleSubmit}
                  type={BUTTON_TYPE.BUTTON}
                >
                  {I18n.t('general.button.save')}
                </ProgressButton>
              </ButtonBar>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
