import { UserPermission } from '../constants/user'
import { MarkupPage } from '../components/place/Markup/MarkupPage'

/**
 * @constant
 * @description A constant to define place routes.
 */
export const placeRoutes = [
  {
    path: '/place/markups',
    component: MarkupPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MARKUP],
    showErrorPage: true,
  },
]
