import { I18n } from 'react-i18nify'

import { UserPermission } from 'constants/user'

export enum MenuType {
  Main = 'mainMenu',
  Mobile = 'mobileMenu',
  User = 'userMenu',
}

export interface BaseMenuItemElement {
  dataTestId?: string
  exact?: boolean
  iconName?: string
  name: string
  notEmpto?: boolean
  requiredPermissions?: UserPermission[]
  spaceLeft?: boolean
  showWhen: {
    isLoggedIn: boolean
    menuTypes: MenuType[]
  }
}

export interface MenuItemElement extends BaseMenuItemElement {
  linkTo: string
}

export interface LogOutButtonElement extends BaseMenuItemElement {
  isLogoutButton: true
}
export const menuItems: (MenuItemElement | LogOutButtonElement)[] = [
  {
    linkTo: '/offer',
    iconName: 'document',
    name: I18n.t('menu.myOffers'),
    dataTestId: 'menu-main-item-my-offers',
    requiredPermissions: [UserPermission.VIEW_MY_OFFER],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Main, MenuType.Mobile] },
  },
  {
    linkTo: '/agreement',
    iconName: 'view-list',
    name: I18n.t('menu.agreement'),
    dataTestId: 'menu-main-item-agreement',
    requiredPermissions: [UserPermission.VIEW_ACCEPTED_OFFER],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Main, MenuType.Mobile] },
  },
  {
    linkTo: '/order',
    iconName: 'view-list',
    name: I18n.t('menu.orders'),
    dataTestId: 'menu-main-item-orders',
    requiredPermissions: [UserPermission.VIEW_ORDER],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Main, MenuType.Mobile] },
  },
  {
    iconName: 'user',
    linkTo: '/user/profile/',
    name: I18n.t('general.profile'),
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'user-group',
    linkTo: '/user/mine',
    name: I18n.t('general.members'),
    requiredPermissions: [UserPermission.CAN_MANAGE_USERS],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'briefcase',
    linkTo: '/company/profile',
    name: I18n.t('general.companyProfile'),
    notEmpto: true,
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'copy',
    linkTo: '/certificate/all',
    name: I18n.t('general.certificates'),
    requiredPermissions: [UserPermission.VIEW_CERTIFICATE_USER_MENU],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'to-do',
    linkTo: '/invoicecheck/invoice_check',
    name: I18n.t('general.invoiceCheck'),
    requiredPermissions: [UserPermission.VIEW_INVOICECHECK],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'copy',
    linkTo: '/execution-proof/all',
    name: I18n.t('executionProof.completion'),
    requiredPermissions: [UserPermission.VIEW_EXECUTIONPROOF_USER_MENU],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'copy',
    linkTo: '/workload',
    name: I18n.t('workload.completion'),
    requiredPermissions: [UserPermission.VIEW_WORKLOAD],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'graph-line',
    linkTo: '/index/values',
    name: I18n.t('general.indexValues'),
    requiredPermissions: [UserPermission.VIEW_INDEXVALUES],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'copy',
    linkTo: '/auto-offer',
    name: I18n.t('general.autoOffer'),
    requiredPermissions: [
      UserPermission.VIEW_MY_AUTO_OFFER,
      UserPermission.VIEW_AUTOOFFER,
    ],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'location',
    linkTo: '/address/mine',
    notEmpto: true,
    name: I18n.t('general.locations'),
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'duplicate',
    linkTo: '/discount',
    name: I18n.t('pageTitles.discounts'),
    notEmpto: true,
    requiredPermissions: [UserPermission.VIEW_DISCOUNT_USER_MENU],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'to-do',
    linkTo: '/maklerpremium/bookkeeping',
    name: I18n.t('bookkeepingTranslations.headline'),
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_INVOICE],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'to-do',
    linkTo: '/maklerpremium/collective-invoicing',
    name: I18n.t('pageTitles.collectiveInvoicing'),
    requiredPermissions: [UserPermission.VIEW_COLLECTIVE_INVOICING],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'to-do',
    linkTo: '/place/markups',
    name: I18n.t('pageTitles.markup'),
    requiredPermissions: [UserPermission.VIEW_MARKUP],
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'download',
    linkTo: '/internaldownloads',
    name: I18n.t('pageTitles.internalDownloads'),
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
  {
    iconName: 'enter',
    linkTo: '/anmelden',
    name: I18n.t('menu.login'),
    dataTestId: 'menu-main-item-login',
    spaceLeft: true,
    showWhen: {
      isLoggedIn: false,
      menuTypes: [MenuType.Mobile, MenuType.Main],
    },
  },
  {
    iconName: 'exit',
    name: I18n.t('general.logout'),
    isLogoutButton: true,
    showWhen: { isLoggedIn: true, menuTypes: [MenuType.Mobile, MenuType.User] },
  },
]
