import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import Spinner from 'react-spinkit'

import { getAddressListForCompany } from 'actions/address'
import {
  addCertificateType,
  createCertificate,
  getCompaniesPriceAgreements,
} from 'actions/certificate'
import {
  getCompany,
  pauseUnpauseCompany,
  resetCompany,
  updateCompany,
} from 'actions/company'
import { exportCustomerData } from 'actions/maklerpremium'
import { getAcceptedOffers } from 'actions/offer'
import { getOrdersCount } from 'actions/order'
import { getCompanyUsers } from 'actions/user'
import { ReactComponent as DocumentIcon } from 'assets/svg/detailspage/document.svg'
import { ReactComponent as HandshakeIcon } from 'assets/svg/detailspage/handshake.svg'
import { ReactComponent as MapIcon } from 'assets/svg/detailspage/map.svg'
import { ReactComponent as MoneyGradientIcon } from 'assets/svg/detailspage/money-gradient.svg'
import { ReactComponent as PencilGardientIcon } from 'assets/svg/detailspage/pencil-gradient.svg'
import { ReactComponent as ProcessedIcon } from 'assets/svg/detailspage/processed_gradient.svg'
import { BREAKPOINT } from 'constants/design'
import { UserPermission } from 'constants/user'
import {
  isCompanyActive,
  isCompanyInactive,
  isWasteCompany,
  isWasteProducer,
} from 'helper/company'
import { checkRequiredPermissions } from 'helper/permissions'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import withErrorBoundary from 'helper/withErrorBoundary'
import { getAddressListForCompanySelector } from 'selectors/address'
import {
  getAcceptedEfbCertificateSelector,
  getCertificateSelector,
  getCompaniesPriceAgreementsSelector,
} from 'selectors/certificate'
import { getCompanySelector } from 'selectors/company'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getOffersPaginationSelector } from 'selectors/offer'
import { getOrdersCountSelector } from 'selectors/order'
import { getCurrentUserSelector } from 'selectors/user'

import { CERTIFICATE_TYPE } from '../../certificate/constants'
import Accordion from '../../common/Accordion'
import AccordionItem from '../../common/Accordion/components/AccordionItem'
import { AsyncExportModal } from '../../common/AsyncExportModal'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_COLOR,
} from '../../common/Button'
import { CallToAction } from '../../common/DetailsPage/components/CallToActionSection/CallToAction'
import { CallToActionHeader } from '../../common/DetailsPage/components/CallToActionSection/CallToAction/CallToActionHeader'
import { NavigationBar } from '../../common/DetailsPage/components/NavigationBar'
import FileComment from '../../common/SingleFileUpload/components/FileComment'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { usePageDimensions } from '../../common/PageDimensions'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UploadDocumentForm } from '../../common/UploadDocumentForm'
import { getRoles } from '../../maklerpremium/helpers'
import Certificates from '../CompanyProfilePage/components/Certificates'
import { COMPANY_MODALS } from '../CompanyProfilePage/constants'
import { ADDRESS_STATUS, COMPANY_ROLE, COMPANY_STATUS } from '../constants'

import { AboutUs } from './components/AboutUs'
import { AddressesMap } from './components/AddressesMap'
import { AgreementsAndOrders } from './components/AgreementsAndOrders'
import { BasicInformation } from './components/BasicInformation'
import { BlockCompanyModal } from './components/BlockCompanyModal'
import { CompanyInfoPageWasteCompanyIndicationMessage } from './components/CompanyInfoPageWasteCompanyIndicationMessage'
import { CompanyInfoPageWasteProducerIndicationMessage } from './components/CompanyInfoPageWasteProducerIndicationMessage'
import { CompanyNotes } from './components/CompanyNotes'
import { CompanyProfileImage } from './components/CompanyProfileImage'
import { ContactInformation } from './components/ContactInformation'
import { Documents } from './components/Documents'
import { DocumentUploadModal } from './components/DocumentUploadModal'
import { PauseCompanyModal } from './components/PausingModal'
import { PaymentAndDiscount } from './components/PaymentAndDiscount'

export const CompanyInfoPageComponent: FC<
  RouteComponentProps<{ companyId: string }>
> = ({
  match: {
    params: { companyId },
  },
}) => {
  // State
  const [showCertificateModule, setShowCertificateModule] = useState(false)
  const [openModal, setOpenModal] = useState<string | boolean>(false)
  const [maklerPremium, setMaklerPremium] = useState(false)
  const [selectedFile, setSelectedFile] = useState<Attachment>() // The currently selected file for the preview.
  const [isPausingModalOpen, setIsPausingModalOpen] = useState(false)
  const [isBlockingModalOpen, setIsBlockingModalOpen] = useState(false)
  const [isCustomerExportModalOpen, setIsCustomerExportModalOpen] =
    useState(false)

  // Router
  const history = useHistory()
  // Redux
  const dispatch = useDispatch()

  const addressList = useSelector(getAddressListForCompanySelector)
  const company = useSelector(getCompanySelector)
  const acceptedEfbCertificate = useSelector(getAcceptedEfbCertificateSelector)
  const priceAgreementList = useSelector(getCompaniesPriceAgreementsSelector)
  const certificate = useSelector(getCertificateSelector)
  const currentUser = useSelector(getCurrentUserSelector)
  const createCertificateErrorState = useSelector(
    createErrorSelector(['CREATE_CERTIFICATE']),
  )
  const offersPagination = useSelector(getOffersPaginationSelector)
  const ordersCount = useSelector(getOrdersCountSelector)

  const getAddressListForCompanyLoadingState = useSelector(
    createLoadingSelector(['UPDATE_COMPANY']),
  )
  const createCertificateLoadingState = useSelector(
    createLoadingSelector(['CREATE_CERTIFICATE']),
  )
  const getAcceptedOffersLoadingState = useSelector(
    createLoadingSelector(['GET_ACCEPTED_OFFERS']),
  )
  const getOrdersCountLoadingState = useSelector(
    createLoadingSelector(['GET_ORDERS_COUNT']),
  )
  const getUsersLoadingState = useSelector(
    createLoadingSelector(['GET_COMPANY_USERS']),
  )

  const isLoading = {
    acceptedOffers: useSelector(createLoadingSelector(['GET_ACCEPTED_OFFERS'])),
    getCompanyLoadingState: useSelector(
      createLoadingSelector(['GET_ADDRESSES_FOR_COMPANY']),
    ),
    exportCustomerData: useSelector(
      createLoadingSelector(['EXPORT_CUSTOMER_DATA']),
    ),
  }

  // Check, if company was created by makler premium
  const { isMaklerPremium } = getRoles(currentUser)
  const companyBelongsToCurrentUser =
    isMaklerPremium &&
    currentUser?.company_object?.id === company.makler_premium_company

  const isMaklerPremiumPartner =
    companyBelongsToCurrentUser && isWasteCompany(company)
  const isMaklerPremiumCustomer =
    companyBelongsToCurrentUser && isWasteProducer(company)

  const hasComCenterPermission = checkRequiredPermissions(
    currentUser.permission_codenames,
    [UserPermission.VIEW_COMMUNICATIONCENTER],
  )

  const isCurrentUserCompany = company.id === currentUser.company
  const hasAgreements = offersPagination.total_results > 0
  const [ctaOpen, setCtaOpen] = useState(false)
  const { footerBottomPos } = usePageDimensions()

  const callToAction = useMemo(
    () => ({
      header: (
        <CallToActionHeader
          title={<Translate value='companyInfoPage.actions' />}
          key='company-info-page-cta-header'
          icon={<ProcessedIcon className='inquiry-details-page__cta-icon' />}
        />
      ),
      content: undefined,
      buttonsOrFields: [
        // Kommunikationscenter
        isMaklerPremiumPartner && (
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            isDisabled={!isMaklerPremium || !hasComCenterPermission}
            onClick={() => {
              history.push(`/maklerpremium/communicationcenter/${company.id}`)
            }}
            className='button--full-width'
            key='comCenterButton'
          >
            <Translate value='pageTitles.comCenter' />
          </IconButton>
        ),
        // Angebot erstellen
        isMaklerPremiumCustomer && (
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            iconName={ICON_NAME.PLUS}
            iconPosition={ICON_POSITION.RIGHT}
            isLoading={isLoading.acceptedOffers}
            isDisabled={isLoading.acceptedOffers || !isCompanyActive(company)}
            onClick={() =>
              history.push('/maklerpremium/offer/create', {
                company: company,
              })
            }
            className='button--full-width'
            key='createOfferButton'
          >
            <Translate value='dashboard.MP.EPD.offerCreate.buttonText' />
          </IconButton>
        ),
        // Arbeitsvorrat
        <RequiredPermissions
          requiredPermissions={[UserPermission.VIEW_WORKLOAD]}
          key='workload'
        >
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            onClick={() => {
              const filter =
                company?.role === COMPANY_ROLE.WASTE_PRODUCER
                  ? 'customer'
                  : 'partner'
              window.open(`/workload/?${filter}=${company.id}`, '_blank')
            }}
            className='button--full-width'
            key='workloadButton'
          >
            <Translate value='pageTitles.workload' />
          </IconButton>
        </RequiredPermissions>,
        // Stammdaten bearbeiten
        (isCurrentUserCompany || companyBelongsToCurrentUser) && (
          <RequiredPermissions
            requiredPermissions={
              companyBelongsToCurrentUser
                ? [
                    UserPermission.VIEW_MAKLER_PREMIUM_PARTNER_BASE_DATA,
                    UserPermission.VIEW_MAKLER_PREMIUM_CUSTOMER_BASE_DATA,
                  ]
                : []
            }
          >
            <NavLink
              to={`/company/profile/${
                companyBelongsToCurrentUser ? `${company.id}` : ''
              }`}
              className='uk-button uk-button-primary button--full-width'
              key='companyEditLink'
            >
              <Translate value='companyInfoPage.editButton' />
            </NavLink>
          </RequiredPermissions>
        ),
        // Export only for mp admin
        <>
          {isMaklerPremiumCustomer &&
            isMaklerPremium &&
            companyBelongsToCurrentUser && (
              <RequiredPermissions
                requiredPermissions={[UserPermission.EXPORT_CUSTOMER_DATA]}
                key='exportCustomerData'
              >
                <IconButton
                  iconName='export'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => setIsCustomerExportModalOpen(true)}
                  isLoading={isLoading.exportCustomerData}
                  className='button--full-width'
                  key='exortCustomerDataButton'
                >
                  <Translate value='general.export' />
                </IconButton>
              </RequiredPermissions>
            )}
        </>,
        // Kunde sperren | Entsorger pausieren
        <RequiredPermissions
          requiredPermissions={[UserPermission.CAN_BLOCK_CUSTOMER]}
          key='blockCustomer'
        >
          {/* KUNDE SPERREN*/}
          {isMaklerPremiumCustomer && isCompanyActive(company) && (
            <Button
              className='button_block_customer button--full-width'
              backgroundColor={BUTTON_BACKGROUND_COLOR.WARNING}
              fontColor={BUTTON_FONT_COLOR.WHITE}
              // onClick={blockCompany}
              onClick={() => setIsBlockingModalOpen(true)}
              key='blockCustomerButton'
            >
              <Translate value='companyInfoPage.lockCompanyButtonText' />
            </Button>
          )}

          {/* ENTSORGER PAUSIEREN*/}
          {isMaklerPremiumPartner &&
            !company.block_auto_offers &&
            !company.block_auto_offers_start_date && (
              <Button
                className='button_pause_auto_offers button--full-width'
                backgroundColor={BUTTON_BACKGROUND_COLOR.WARNING}
                fontColor={BUTTON_FONT_COLOR.WHITE}
                onClick={() => setIsPausingModalOpen(true)}
                key='pauseAutoOffersButton'
              >
                <Translate value='companyInfoPage.pauseAutoOffersButtonText' />
              </Button>
            )}
        </RequiredPermissions>,
        <RequiredPermissions
          requiredPermissions={[UserPermission.ADD_CERTIFICATE]}
          key='addCertificates'
        >
          {!currentUser.company && (
            <Button
              className='button--full-width'
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={() => setShowCertificateModule(!showCertificateModule)}
              key='addCertificatesButton'
            >
              <Translate value='companyInfoPage.addCertificates' />
            </Button>
          )}
        </RequiredPermissions>,
      ],
    }),
    [
      isMaklerPremiumPartner,
      isMaklerPremium,
      hasComCenterPermission,
      isMaklerPremiumCustomer,
      isLoading.acceptedOffers,
      isLoading.exportCustomerData,
      company,
      isCurrentUserCompany,
      companyBelongsToCurrentUser,
      currentUser.company,
      history,
      showCertificateModule,
    ],
  )

  const sections = new Map([
    [
      'agreements-orders',
      {
        offset: -180,
        title: <Translate value='agreementsAndOrders.headline' />,
      },
    ],
    [
      'payment-discount',
      {
        offset: -180,
        title: <Translate value='paymentAndDiscount.headline' />,
      },
    ],
    [
      'documents',
      {
        offset: -180,
        title: <Translate value='documents.headline' />,
      },
    ],
    [
      'notes',
      {
        offset: -180,
        title: <Translate value='notes.headline' />,
      },
    ],
  ])

  const showLoadingSpinner =
    (isLoading.getCompanyLoadingState ||
      getAddressListForCompanyLoadingState) &&
    companyBelongsToCurrentUser &&
    getAcceptedOffersLoadingState &&
    getOrdersCountLoadingState &&
    getUsersLoadingState

  // uniqueId
  const idAddCertificateModalHeadline = uniqueId()
  const idFilePreviewModalHeadline = uniqueId()

  useEffect(() => {
    if (priceAgreementList.initial && company.id) {
      const companyIDList = [company.id]
      dispatch(getCompaniesPriceAgreements(companyIDList))
    }
  }, [dispatch, priceAgreementList, company.id])

  useEffect(() => {
    const companyIsNotSetOrChanged =
      !company || company.id !== Number(companyId)

    if (companyId) {
      if (!company || company.id !== Number(companyId)) {
        dispatch(
          getCompany({
            id: Number(companyId),
            expand:
              'evaluation_rate,number_of_evaluations,number_of_invoice_checks',
          }),
        )
      }
      setMaklerPremium(company.makler_premium_company !== null)
    }

    if (companyId && companyIsNotSetOrChanged) {
      dispatch(
        getCompany({
          id: Number(companyId),
          expand:
            'evaluation_rate,number_of_evaluations,number_of_invoice_checks',
        }),
      )
    }

    if (companyId && companyIsNotSetOrChanged && addressList.length === 0) {
      dispatch(getAddressListForCompany(Number(companyId), {}))
    }
  }, [company, companyId, dispatch, addressList.length])

  // On unmount, if a company is stored in the current state,
  // reset the company so that we receive a fresh instance of the company
  useEffect(
    () => () => {
      dispatch(resetCompany())
    },
    [dispatch],
  )

  const handleModalClose = (event?: React.SyntheticEvent) => {
    if (event) event.preventDefault()
    setOpenModal(false)
  }

  /**
   * @description Open modal to add a certificate
   */
  const handleAddCertificate = type => {
    dispatch(addCertificateType(type))
    setOpenModal(COMPANY_MODALS.CERTIFICATE_FORM_MODAL)
  }

  /**
   * @description Open modal to upload a document
   */
  const handleUploadDocumentClick = () => {
    setOpenModal(COMPANY_MODALS.UPLOAD_DOCUMENT_MODAL)
  }

  const unlockAutoOffers = () => {
    dispatch(pauseUnpauseCompany(company.id))
  }

  const unblockCompany = () => {
    // Sometimes companies have an empty postal_address_object stored (id = -1), if so, delete it
    if (company.postal_address_object?.id === -1) {
      delete company.postal_address_object
    }

    dispatch(
      updateCompany({
        ...company,
        status: COMPANY_STATUS.STATUS_ACTIVE,
        status_reason: '',
        isUpdatingCompanyStatus: true,
      }),
    )
  }

  /**
   * Is called when a preview is clicked. We then display a modal with the preview.
   * @param event
   * @param clickedFile
   */
  const handlePreviewClick = (event, clickedFile) => {
    setSelectedFile(clickedFile)
    setOpenModal(COMPANY_MODALS.FILE_PREVIEW_MODAL)
  }

  const upLoadLabel = certificateType => {
    switch (Number(certificateType)) {
      case CERTIFICATE_TYPE.TYPE_EFB:
        return I18n.t(
          'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.efb',
        )
      case CERTIFICATE_TYPE.TYPE_OTHER:
        return I18n.t(
          'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.other',
        )
      case CERTIFICATE_TYPE.TYPE_COMPANY:
        return I18n.t(
          'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.company',
        )
      case CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT:
        return I18n.t(
          'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.priceAgreement',
        )
      default:
        return ''
    }
  }

  useEffect(() => {
    if (company && company?.role) {
      if (company.role === COMPANY_ROLE.WASTE_PRODUCER) {
        dispatch(getAcceptedOffers(null, { customer_company: company.id }))
        dispatch(getOrdersCount({ customer_company: company.id }))
      } else {
        dispatch(getAcceptedOffers(null, { offer_company: company.id }))
        dispatch(getOrdersCount({ offer_company: company.id }))
      }
      dispatch(getCompanyUsers(company.id))
    }
  }, [company, dispatch])

  // render
  if (!company.id) return null

  if (showLoadingSpinner)
    return (
      <div className='uk-flex uk-flex-center uk-margin-large-top'>
        <Spinner name='circle' />
      </div>
    )

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.companyProfileDetails')}</title>
      </Helmet>

      <div className='company-info-page uk-margin-small-top'>
        {/* Indication if paused or locked */}
        <>
          {/* Unlock customer */}
          {isMaklerPremiumCustomer && isCompanyInactive(company) && (
            <CompanyInfoPageWasteProducerIndicationMessage
              blockUnblockCompanyAction={unblockCompany}
              statusReason={company.status_reason}
            />
          )}

          {/* unpause partner */}
          {isMaklerPremiumPartner &&
            (company.block_auto_offers ||
              company.block_auto_offers_start_date) && (
              <CompanyInfoPageWasteCompanyIndicationMessage
                blockUnblockAutoOffersAction={unlockAutoOffers}
                blockAutoOffersStartDate={company.block_auto_offers_start_date}
                blockAutoOffersEndDate={company.block_auto_offers_end_date}
                blockAutoOffersReason={company.block_auto_offers_reason}
              />
            )}
          <div className='uk-clearfix' />
        </>

        <div className='uk-grid'>
          <div className='uk-width-1-1 uk-width-2-3@l'>
            <div className='uk-grid-medium' data-uk-grid=''>
              <div className='uk-width-1-1 uk-width-2-5@m uk-text-center uk-text-left@m'>
                <CompanyProfileImage company={company} />
              </div>
              <div className='uk-width-1-1 uk-width-3-5@m uk-text-center uk-text-left@m'>
                <BasicInformation
                  company={company}
                  companyValidEfbCertificate={acceptedEfbCertificate}
                  isMaklerPremium={companyBelongsToCurrentUser}
                  hasAgreements={hasAgreements}
                />
                <AboutUs />
              </div>
            </div>
          </div>
          <div className='uk-width-1-1 uk-width-1-3@l uk-margin-small-top'>
            <ContactInformation company={company} />
          </div>
        </div>

        <div className='uk-grid'>
          <div className='company-info-page--extended--company uk-width-1-1 uk-width-2-3@l uk-margin-small-top'>
            {maklerPremium && (
              <>
                {/* Navigation bar (Desktop only) */}
                <Media query={{ minWidth: BREAKPOINT.XLARGE }}>
                  <NavigationBar sections={sections} sticky />
                </Media>
                <Media query={{ maxWidth: BREAKPOINT.MEDIUM - 1 }}>
                  {matches => (
                    <Accordion collapsible={matches} multiple={matches}>
                      {(companyBelongsToCurrentUser || hasAgreements) && (
                        <AccordionItem
                          key='agreements-orders'
                          title={
                            <>
                              <HandshakeIcon className='icon icon--color-default icon--background-color-default' />
                              <Translate
                                value='agreementsAndOrders.headline'
                                className='company-info-page__header'
                              />
                            </>
                          }
                        >
                          <AgreementsAndOrders
                            agreementsCount={offersPagination.total_results}
                            company={company}
                            ordersCount={ordersCount}
                          />
                        </AccordionItem>
                      )}
                      {(companyBelongsToCurrentUser || hasAgreements) && (
                        <AccordionItem
                          key='payment-discount'
                          title={
                            <>
                              <MoneyGradientIcon className='icon icon--color-default icon--background-color-default' />
                              <Translate
                                value='paymentAndDiscount.headline'
                                className='company-info-page__header'
                              />
                            </>
                          }
                        >
                          <PaymentAndDiscount company={company} />
                        </AccordionItem>
                      )}
                      {(companyBelongsToCurrentUser || hasAgreements) && (
                        <AccordionItem
                          key='documents'
                          title={
                            <div className='uk-grid'>
                              <div className='uk-width-1-1 uk-width-2-3@m'>
                                <DocumentIcon className='icon icon--color-default icon--background-color-default' />
                                <Translate
                                  value='documents.headline'
                                  className='company-info-page__header'
                                />
                              </div>
                              {!matches && (
                                <div className='uk-width-1-1 uk-width-1-3@m'>
                                  <IconButton
                                    backgroundColor={
                                      BUTTON_BACKGROUND_COLOR.PRIMARY
                                    }
                                    iconName={ICON_NAME.PLUS}
                                    iconPosition={ICON_POSITION.RIGHT}
                                    onClick={handleUploadDocumentClick}
                                    fullWidth
                                  >
                                    <Translate value='companyInfoPageTranslations.uploadDocumentButtonLabel' />
                                  </IconButton>
                                </div>
                              )}
                            </div>
                          }
                        >
                          {matches && (
                            <div className='uk-width-1-1'>
                              <IconButton
                                className='accordion-item__title__button'
                                backgroundColor={
                                  BUTTON_BACKGROUND_COLOR.PRIMARY
                                }
                                iconName={ICON_NAME.PLUS}
                                iconPosition={ICON_POSITION.RIGHT}
                                onClick={() => undefined}
                                fullWidth
                              >
                                <Translate value='documents.uploadButton' />
                              </IconButton>
                            </div>
                          )}
                          <Documents
                            companyId={company.id}
                            archiveLink={`/company/${company.id}/archive`}
                          />
                        </AccordionItem>
                      )}
                      {(companyBelongsToCurrentUser || hasAgreements) && (
                        <AccordionItem
                          key='notes'
                          title={
                            <>
                              <PencilGardientIcon className='icon icon--color-default icon--background-color-default' />
                              <Translate
                                value='notes.headline'
                                className='company-info-page__header'
                              />
                            </>
                          }
                        >
                          <CompanyNotes className='notes' company={company} />
                        </AccordionItem>
                      )}
                    </Accordion>
                  )}
                </Media>
              </>
            )}
          </div>
          <div className='company-info-page--extended--location uk-width-1-1 uk-width-1-3@l uk-margin-small-top'>
            <Media query={{ maxWidth: BREAKPOINT.XLARGE - 1 }}>
              {isSmallScreen =>
                isSmallScreen && maklerPremium ? (
                  // create Form visible = either show Agreement/New Order Form or New Offer Form
                  <div
                    style={{
                      bottom: footerBottomPos,
                    }}
                    className='inquiry-details-page__cta--sticky-bottom uk-width-1-1'
                  >
                    {ctaOpen ? (
                      <CallToAction
                        callToAction={callToAction}
                        handleCtaToggle={() => {
                          setCtaOpen(false)
                        }}
                        isSmallScreen
                        closeButtonTranslationKey={
                          'companyInfoPage.ctaActionButtonShowLess'
                        }
                      />
                    ) : (
                      <Button
                        backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                        onClick={() => setCtaOpen(true)}
                        fullWidth
                        dataTestId='inquiry-details-show-more-button'
                      >
                        <Translate value={'companyInfoPage.ctaActionButton'} />
                      </Button>
                    )}
                  </div>
                ) : (
                  <div data-uk-sticky='top: 10; offset: 100'>
                    {maklerPremium && (
                      <div className='company-info-page__cta uk-margin-bottom'>
                        <CallToAction callToAction={callToAction} />
                      </div>
                    )}
                    <div className='company-info-page__cta'>
                      <CallToActionHeader
                        title={
                          <Translate
                            value='allAddresses.headline'
                            className='company-info-page__header'
                          />
                        }
                        key='company-info-page-cta-header'
                        icon={
                          <MapIcon className='inquiry-details-page__cta-icon' />
                        }
                      />
                      <AddressesMap
                        addressList={addressList.filter(
                          address =>
                            address.status === ADDRESS_STATUS.STATUS_ACTIVE,
                        )}
                        company={company}
                        addressCount={addressList.length}
                      />
                    </div>
                  </div>
                )
              }
            </Media>
          </div>
        </div>
      </div>

      <Collapse isOpened={showCertificateModule}>
        <RequiredPermissions
          requiredPermissions={[UserPermission.ADD_CERTIFICATE]}
        >
          <div className='uk-margin-medium-bottom'>
            <Certificates
              company={company}
              user={currentUser}
              onCertificateAdd={handleAddCertificate}
              onPreviewClick={handlePreviewClick}
            />
          </div>
        </RequiredPermissions>
      </Collapse>

      <Modal
        ariaDescribedBy={idAddCertificateModalHeadline}
        isOpen={openModal === COMPANY_MODALS.CERTIFICATE_FORM_MODAL}
        onClose={handleModalClose}
      >
        <ModalHeader
          onClose={handleModalClose}
          title={I18n.t('companyProfilePage.certificates.modalTitle')}
          titleId={idAddCertificateModalHeadline}
        />

        <UploadDocumentForm
          preSelectedCompanyId={Number(companyId)}
          document={certificate}
          documentType={certificate.type}
          onCloseForm={handleModalClose}
          onDocumentSubmit={values =>
            dispatch(
              createCertificate({
                ...values,
                min_load: 0,
                type: certificate.type,
                company: company.id,
              }),
            )
          }
          apiError={createCertificateErrorState}
          permissions={[UserPermission.ADD_CERTIFICATE]}
          isLoading={createCertificateLoadingState}
          maxFiles={
            certificate.type && certificate.type === CERTIFICATE_TYPE.TYPE_EFB
              ? 3
              : 1
          }
          uploadLabel={upLoadLabel(certificate.type)}
          showApiValidationMessage={!!createCertificateErrorState}
          aditionalApiFetchError='CREATE_CERTIFICATE'
        />
      </Modal>

      {selectedFile && (
        <Modal
          ariaDescribedBy={idFilePreviewModalHeadline}
          isOpen={openModal === COMPANY_MODALS.FILE_PREVIEW_MODAL}
          onClose={handleModalClose}
        >
          <>
            <ModalHeader
              onClose={handleModalClose}
              title={selectedFile.name}
              titleId={idFilePreviewModalHeadline}
            />

            <FileComment
              file={selectedFile}
              onFormSubmit={handleModalClose}
              allowComment={false}
            />
          </>
        </Modal>
      )}

      <PauseCompanyModal
        callback={() => {
          setIsPausingModalOpen(false)
        }}
        company={company}
        showPausingModal={isPausingModalOpen}
      />

      <BlockCompanyModal
        callback={() => {
          setIsBlockingModalOpen(false)
        }}
        company={company}
        showModal={isBlockingModalOpen}
      />

      <AsyncExportModal
        isOpen={isCustomerExportModalOpen}
        onClose={() => setIsCustomerExportModalOpen(false)}
        reduxSelector='EXPORT_CUSTOMER_DATA'
        title={I18n.t('companyInfoPage.exportModal.title')}
        logic={exportCustomerData(companyId)}
      />

      <DocumentUploadModal
        isOpen={openModal === COMPANY_MODALS.UPLOAD_DOCUMENT_MODAL}
        onClose={handleModalClose}
      />
    </>
  )
}

export const CompanyInfoPage = withErrorBoundary(
  withApiErrorHandling(CompanyInfoPageComponent, [
    'GET_ADDRESSES_FOR_COMPANY',
    'GET_COMPANY',
    'UPDATE_COMPANY',
  ]),
)
