import classNames from 'classnames'
import { FieldArray, useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getOrdersForInvoiceCheck } from 'actions/order'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FractionAndContainerFormFields } from 'components/common/FractionAndContainerFormFields'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import InputDate from 'components/common/InputDate'
import { InputDecimal } from 'components/common/InputDecimal'
import { InputNumber } from 'components/common/InputNumber'
import { InputText } from 'components/common/InputText'
import { RadioButton } from 'components/common/RadioButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { OFFER_ORDER_TYPE } from 'components/inquiry/constants'
import { InvoiceCheckDocumentType } from 'components/invoicecheck/InvoiceCheckPage/InvoiceCheckAcquisition'
import { COLLECTION_CONTAINER_IDS, DATE_FORMATS } from 'constants/app'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../../InvoiceCheckPositionsFormSteps'
import { getFirstDayofThreeYearsAgoAsFormattedString } from '../helper'
import { OrderType, WeightOrNumber } from '../OrderAndServicesForm'

import { CompensationPriceFields } from './CompensationPriceFields/CompensationPriceFields'
import { DisposalPriceFields } from './DisposalPriceFields/DisposalPriceFields'

type OpenOrderFormPropsType = {
  selectedOrder?: OrderListItem
  isLoading: { [key: string]: boolean }
  isFieldValid: (value: string) => boolean
  getFieldError: (name: string | undefined) => string | undefined
  orderOptions: { label: string; value: string }[]
  selectedOrderOption?: { label: string; value: string }
  dismissibleInfoForAlreadyCapturedOrder: JSX.Element
  dismissibleInfoForCancelledOrder: JSX.Element
  dismissibleInfoForOrderPictures: JSX.Element
}

export const OpenOrderForm: FC<OpenOrderFormPropsType> = ({
  selectedOrder,
  isLoading,
  isFieldValid,
  getFieldError,
  orderOptions,
  selectedOrderOption,
  dismissibleInfoForAlreadyCapturedOrder,
  dismissibleInfoForCancelledOrder,
  dismissibleInfoForOrderPictures,
}) => {
  // Form State (values, errors, functions)
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<InvoiceCheckPositionsFormValues>()

  const dispatch = useDispatch()
  const [showPriceModelField, setShowPriceModelField] = useState(false)

  // FormStep Context (invoiceCheck object)
  const { invoiceCheck, invoiceCheckPosition } = useContext(
    InvoiceCheckPositionsFormContext,
  )
  const [orderHistoricDataLinkDisabled, setOrderHistoricDataLinkDisabled] =
    useState(false)

  const securityGroupFilterOptions = [4, 8]
  const showRemoveWeightFieldButton =
    values.weight_object_list && values.weight_object_list.length > 1
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(selectedOrder?.container),
  )

  // useEffect to show price model field when needed
  useEffect(() => {
    if (!selectedOrder) return
    if (
      (Number(values.fraction) === Number(selectedOrder.fraction) ||
        Number(values.fine_fraction) === Number(selectedOrder.fraction)) &&
      Number(values.container) === Number(selectedOrder.container)
    ) {
      setShowPriceModelField(false)
    } else {
      setShowPriceModelField(true)
    }
  }, [values.container, values.fraction, values.fine_fraction, selectedOrder])

  if (values.order_type !== OrderType.OPEN_ORDER) return <></>

  const handleOnHistoricDataLinkClick = () => {
    if (!values.collection_address) {
      return
    }

    dispatch(
      getOrdersForInvoiceCheck(
        // page is undefined
        undefined,
        {
          address: values.collection_address,
          // Loads historic order data into the order field and gets triggered when the user
          // clicks the corresponding link.
          delivery_date__gte: getFirstDayofThreeYearsAgoAsFormattedString(),
        },
        Number.MAX_SAFE_INTEGER,
        ['order_statuses'],
      ),
    )

    setOrderHistoricDataLinkDisabled(true)
  }

  return (
    <>
      {/* Auftragsnummer */}
      <StaticCombobox
        error={getFieldError}
        label={
          <div className='open-order-form-combobox-label-with-link'>
            <Translate value='invoiceCheckTranslations.positionsForm.steps.2.fields.order.label' />
            <ButtonLink
              onClick={
                orderHistoricDataLinkDisabled
                  ? () => undefined
                  : handleOnHistoricDataLinkClick
              }
              linkColor={
                orderHistoricDataLinkDisabled
                  ? BUTTON_LINK_COLOR.MUTED
                  : BUTTON_LINK_COLOR.PRIMARY
              }
            >
              {I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.order.actionLinkLabel',
              )}
            </ButtonLink>
          </div>
        }
        name='order'
        noResultsText={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.order.noResultsText',
        )}
        options={orderOptions}
        onSelectionChange={handleChange}
        selectedOption={selectedOrderOption}
        showCheckmark={isFieldValid}
        withCheckmark
        isDisabled={!!invoiceCheckPosition?.id}
        isLoading={isLoading.orderOptions}
      />
      {dismissibleInfoForAlreadyCapturedOrder}
      {dismissibleInfoForCancelledOrder}
      {dismissibleInfoForOrderPictures}

      {/* Abfallart und Behälter */}
      <FractionAndContainerFormFields
        hideDismissableInfos
        hidePublicPlacement
        invoiceCheckPositionForm
        showAvvField
        showSecurityGroupField
        securityGroupFilterOptions={securityGroupFilterOptions}
        defaultSelectedSecurityGroupId={values.security_group}
        showSubLabel={false}
      />

      <div
        className={classNames({
          'execution-proof-review--side-by-side':
            selectedOrder?.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME,
        })}
      >
        {/* Leistungs-/Stelldatum */}
        <InputDate
          label={
            selectedOrder?.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME
              ? I18n.t('inquiry.date.deliveryDate')
              : I18n.t('orderDetails.deliveryDate')
          }
          name='delivery_date'
          onChange={value => {
            setFieldValue('delivery_date', value.format('L'))
            setFieldValue('collection_date', undefined)
          }}
          showCheckmark={
            selectedOrder?.order_type !== OFFER_ORDER_TYPE.TYPE_ONE_TIME
              ? isFieldValid
              : false
          }
          value={values.delivery_date}
          withCheckmark={
            selectedOrder?.order_type !== OFFER_ORDER_TYPE.TYPE_ONE_TIME
          }
          maxDate={moment()}
        />
        {/* Nur bei Einmalauftrag: Abholdatum */}
        {selectedOrder?.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME && (
          <InputDate
            label={I18n.t('inquiry.date.collectionDate')}
            minDate={moment(values.delivery_date, DATE_FORMATS)}
            name='collection_date'
            onChange={value => {
              setFieldValue('collection_date', value.format('L'))
            }}
            showCheckmark={
              isFieldValid('delivery_date') && isFieldValid('collection_date')
            }
            value={values.collection_date}
            withCheckmark
            maxDate={moment()}
          />
        )}
      </div>

      {/* Disposal Price Fields */}
      {invoiceCheck?.document_type === InvoiceCheckDocumentType.INVOICE && (
        <DisposalPriceFields
          isEditing
          withCheckmarks
          showPriceModelField={showPriceModelField}
        />
      )}

      {/* Compensation Price Fields */}
      {selectedOrder &&
        invoiceCheck?.document_type ===
          InvoiceCheckDocumentType.CREDIT_NOTE && (
          <CompensationPriceFields
            selectedOrder={selectedOrder}
            isEditing
            withCheckmarks
            showPriceModelField={showPriceModelField}
          />
        )}

      <div className='execution-proof-review__radio-choices margin-regular-top'>
        {/* Gewicht (in kg) */}
        <RadioButton
          dataTestId='weight-or-number-weight'
          isChecked={values.weight_or_number === WeightOrNumber.INPUT_WEIGHT}
          label={I18n.t(
            'documentReviewPageTranslations.executionProofReview.chooseWeight',
          )}
          name='weight_or_number'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue(
                'weight_object_list',
                [{ weight_receipt: '', weight: '' }],
                true,
              )
              setFieldValue(
                'weight_or_number',
                WeightOrNumber.INPUT_WEIGHT,
                true,
              )
              setFieldValue('quantity_in_cubic_meters', undefined, true)
            }
          }}
        />
        {/* Behälteranzahl (in Stück) */}
        <RadioButton
          dataTestId='weight-or-number-number'
          isChecked={
            values.weight_or_number ===
            WeightOrNumber.INPUT_NUMBER_OF_CONTAINERS
          }
          label={I18n.t(
            'documentReviewPageTranslations.executionProofReview.chooseNumber',
          )}
          name='weight_or_number'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue(
                'weight_or_number',
                WeightOrNumber.INPUT_NUMBER_OF_CONTAINERS,
                true,
              )
              setFieldValue(
                'weight_object_list',
                [{ weight_receipt: '', weight: '' }],
                true,
              )
              setFieldValue('quantity_in_cubic_meters', undefined, true)
            }
          }}
        />
      </div>
      {values.weight_or_number === WeightOrNumber.INPUT_WEIGHT ? (
        // Wiegescheine
        <FieldArray name='weight_object_list'>
          {({ push, pop }) => (
            <>
              {values.weight_object_list?.map((weight, index) => (
                <div
                  className='execution-proof-review--side-by-side'
                  key={`${index}`}
                >
                  {/* Wiegescheinnummer */}
                  <InputText
                    dataTestId={`weight-receipt-input-${index + 1}`}
                    label={I18n.t(
                      'documentReviewPageTranslations.executionProofReview.weightReceipt',
                      { number: index + 1 },
                    )}
                    maxLength={20}
                    name={`weight_object_list.${index}.weight_receipt`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'documentReviewPageTranslations.executionProofReview.weightReceipt',
                      { number: index + 1 },
                    )}
                    value={
                      values.weight_object_list
                        ? values.weight_object_list[index].weight_receipt
                        : ''
                    }
                    error={
                      submitCount > 0 &&
                      values.weight_object_list &&
                      values.weight_object_list[index].weight_receipt === '' &&
                      !!errors.weight_receipt
                        ? (errors.weight_receipt as string)
                        : ''
                    }
                  />
                  {/* Gewicht des Behälter (in Kg) */}
                  <InputNumber
                    dataTestId={`weight-input-${index + 1}`}
                    label={I18n.t(
                      'documentReviewPageTranslations.executionProofReview.weight',
                      { number: index + 1 },
                    )}
                    placeholder={I18n.t(
                      'documentReviewPageTranslations.executionProofReview.weight',
                      { number: index + 1 },
                    )}
                    name={`weight_object_list.${index}.weight`}
                    maxLength={5}
                    onChange={handleChange}
                    value={
                      values.weight_object_list
                        ? values.weight_object_list[index].weight
                        : undefined
                    }
                    removeBrowserStyling
                    error={
                      submitCount > 0 &&
                      values.weight_object_list &&
                      values.weight_object_list[index].weight === '' &&
                      !!errors.weight
                        ? (errors.weight as string)
                        : ''
                    }
                    showCheckmark={
                      values.weight_object_list &&
                      values.weight_object_list[index] &&
                      !!values.weight_object_list[index].weight_receipt &&
                      !!values.weight_object_list[index].weight
                    }
                    withCheckmark
                  />
                </div>
              ))}
              <div
                className={classNames(
                  'execution-proof-review--side-by-side-buttons',
                  {
                    'execution-proof-review--side-by-side-buttons-single-button':
                      !showRemoveWeightFieldButton,
                  },
                )}
              >
                <IconButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  fullWidth
                  iconName='plus'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => push({ weight_receipt: '', weight: '' })}
                >
                  {I18n.t(
                    'invoiceCheckTranslations.positionsForm.steps.2.buttons.addRemoveContainer',
                  )}
                </IconButton>
                {showRemoveWeightFieldButton && (
                  <IconButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    fullWidth
                    iconName='trash'
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={pop}
                  >
                    {I18n.t(
                      'invoiceCheckTranslations.positionsForm.steps.2.buttons.addRemoveContainer',
                    )}
                  </IconButton>
                )}
              </div>
            </>
          )}
        </FieldArray>
      ) : (
        // Pauschalpreis
        <>
          {/* Anzahl Container */}
          {!showQuantityInCubicMeters && (
            <InputNumber
              error={getFieldError}
              label={I18n.t(
                'documentReviewPageTranslations.executionProofReview.numberOfContainers',
              )}
              maxLength={2}
              name='number_of_containers'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'documentReviewPageTranslations.executionProofReview.numberOfContainers',
              )}
              showCheckmark={isFieldValid}
              value={values.number_of_containers}
              withCheckmark
            />
          )}
          {/* Menge in m³ */}
          {showQuantityInCubicMeters && (
            <InputDecimal
              error={getFieldError}
              label={I18n.t(
                'documentReviewPageTranslations.executionProofReview.quantityInCubicMeters',
              )}
              maxValue={99.99}
              name='quantity_in_cubic_meters'
              onChange={handleChange}
              placeholder={I18n.t(
                'documentReviewPageTranslations.executionProofReview.quantityInCubicMeters',
              )}
              removeBrowserStyling
              showCheckmark={isFieldValid}
              value={values.quantity_in_cubic_meters}
              withCheckmark
            />
          )}
        </>
      )}
    </>
  )
}
