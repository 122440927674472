import './style.scss'

import React from 'react'
import { bool, func, string } from 'prop-types'

import Icon from 'components/common/Fontello/index'

import { USER_DROPDOWN_ID } from '../constants'

import { UserInfo } from './components/UserInfo'
import { UserMenu } from './components/UserMenu'

export const UserDropdown = ({
  onClick,
  isVisible,
  containerId = USER_DROPDOWN_ID,
  iconName = 'user',
  showChevronDown = true,
  showEventMarker = false,
  renderEventMarker = null,
}) => {
  const renderEventMarkerComponent = () => {
    if (renderEventMarker) return renderEventMarker()
    return <span className='dropdown-event-marker' />
  }

  return (
    <div className='dropdown-container uk-navbar-item'>
      <button
        type='button'
        className='dropdown-toggle-icon uk-navbar-toggle-icon'
        onClick={onClick}
      >
        <Icon name={iconName} />

        {showChevronDown && (
          <span className='chevron-decorator'>
            <Icon name='chevron-down' />
          </span>
        )}

        {showEventMarker && renderEventMarkerComponent()}
      </button>

      <div
        id={containerId}
        className={[
          'uk-navbar-dropdown',
          'uk-padding-remove',
          'dropdown-body-container',
          `${
            showChevronDown
              ? 'with-chevron-decorator'
              : 'without-chevron-decorator'
          }`,
          `${isVisible ? 'uk-open uk-animation-fade' : 'uk-hidden'}`,
        ].join(' ')}
        data-uk-dropdown='pos: bottom-left'
      >
        <div className='user-info-container uk-padding-small'>
          <UserInfo />
        </div>

        <nav
          className='user-menu-container uk-padding-small'
          data-uk-navbar='uk-navbar'
        >
          <UserMenu />
        </nav>
      </div>
    </div>
  )
}

UserDropdown.propTypes = {
  onClick: func.isRequired,
  isVisible: bool.isRequired,
  containerId: string,
  iconName: string,
  showChevronDown: bool,
  showEventMarker: bool,
  renderEventMarker: func,
}
