import keyMirror from 'keymirror'

const GET_ACTIVE_COMPANIES = keyMirror({
  GET_ACTIVE_COMPANIES_REQUEST: null,
  GET_ACTIVE_COMPANIES_SUCCESS: null,
  GET_ACTIVE_COMPANIES_ERROR: null,
  RESET_ACTIVE_COMPANIES: null,
})

const GET_ACTIVE_COMPANIES_FOR_FILTER = keyMirror({
  GET_ACTIVE_COMPANIES_FOR_FILTER_REQUEST: null,
  GET_ACTIVE_COMPANIES_FOR_FILTER_SUCCESS: null,
  GET_ACTIVE_CUSTOMER_FOR_FILTER_SUCCESS: null,
  GET_ACTIVE_PARTNER_FOR_FILTER_SUCCESS: null,
  GET_ACTIVE_COMPANIES_FOR_FILTER_ERROR: null,
  RESET_ACTIVE_COMPANIES_FOR_FILTER: null,
})

const GET_ALL_COMPANIES_FOR_FILTER = keyMirror({
  GET_ALL_COMPANIES_FOR_FILTER_REQUEST: null,
  GET_ALL_COMPANIES_FOR_FILTER_SUCCESS: null,
  GET_ALL_CUSTOMER_FOR_FILTER_SUCCESS: null,
  GET_ALL_PARTNER_FOR_FILTER_SUCCESS: null,
  GET_ALL_COMPANIES_FOR_FILTER_ERROR: null,
  RESET_ALL_COMPANIES_FOR_FILTER: null,
})

const GET_ACTIVE_CUSTOMER_FOR_FILTER = keyMirror({
  GET_ACTIVE_CUSTOMER_FOR_FILTER_REQUEST: null,
  GET_ACTIVE_CUSTOMER_FOR_FILTER_SUCCESS: null,
  GET_ACTIVE_CUSTOMER_FOR_FILTER_ERROR: null,
  RESET_ACTIVE_CUSTOMER_FOR_FILTER: null,
})

const GET_ACTIVE_PARTNER_FOR_FILTER = keyMirror({
  GET_ACTIVE_PARTNER_FOR_FILTER_REQUEST: null,
  GET_ACTIVE_PARTNER_FOR_FILTER_SUCCESS: null,
  GET_ACTIVE_PARTNER_FOR_FILTER_ERROR: null,
  RESET_ACTIVE_PARTNER_FOR_FILTER: null,
})

const GET_COMPANIES = keyMirror({
  GET_COMPANIES_REQUEST: null,
  GET_COMPANIES_SUCCESS: null,
  GET_COMPANIES_ERROR: null,
})

const GET_COMPANY = keyMirror({
  GET_COMPANY_REQUEST: null,
  GET_COMPANY_SUCCESS: null,
  GET_COMPANY_ERROR: null,
  RESET_COMPANY: null,
})

const UPDATE_COMPANY = keyMirror({
  UPDATE_COMPANY_REQUEST: null,
  UPDATE_COMPANY_SUCCESS: null,
  UPDATE_COMPANY_ERROR: null,
})

const EXPORT_MAKLER_PREMIUM_COMPANIES = keyMirror({
  EXPORT_MAKLER_PREMIUM_COMPANIES_REQUEST: null,
  EXPORT_MAKLER_PREMIUM_COMPANIES_SUCCESS: null,
  EXPORT_MAKLER_PREMIUM_COMPANIES_ERROR: null,
})

const PAUSE_COMPANY = keyMirror({
  PAUSE_COMPANY_REQUEST: null,
  PAUSE_COMPANY_SUCCESS: null,
  PAUSE_COMPANY_ERROR: null,
})

const ADD_ROLE_COMPANIES_TO_STATE = keyMirror({
  ADD_CUSTOMER_COMPANY: null,
  RESET_CUSTOMER_COMPANY: null,
  ADD_PARTNER_COMPANY: null,
  RESET_PARTNER_COMPANY: null,
})

const GET_MY_OFFERS_INQUIRED_COMPANIES = keyMirror({
  GET_MY_OFFERS_INQUIRED_COMPANIES_REQUEST: null,
  GET_MY_OFFERS_INQUIRED_COMPANIES_SUCCESS: null,
  GET_MY_OFFERS_INQUIRED_COMPANIES_ERROR: null,
})

const GET_MAKLER_PREMIUM_COMPANIES = keyMirror({
  GET_MAKLER_PREMIUM_COMPANIES_REQUEST: null,
  GET_MAKLER_PREMIUM_COMPANIES_SUCCESS: null,
  GET_MAKLER_PREMIUM_COMPANIES_ERROR: null,
  RESET_MAKLER_PREMIUM_COMPANIES: null,
})

const GET_COLLECTIVE_INVOICE_COMPANIES = keyMirror({
  GET_COLLECTIVE_INVOICE_COMPANIES_REQUEST: null,
  GET_COLLECTIVE_INVOICE_COMPANIES_SUCCESS: null,
  GET_COLLECTIVE_INVOICE_COMPANIES_ERROR: null,
  RESET_COLLECTIVE_INVOICE_COMPANIES: null,
})

const CREATE_COLLECTIVE_INVOICE = keyMirror({
  CREATE_COLLECTIVE_INVOICE_REQUEST: null,
  CREATE_COLLECTIVE_INVOICE_SUCCESS: null,
  CREATE_COLLECTIVE_INVOICE_ERROR: null,
})

const CREATE_TOTAL_COLLECTIVE_INVOICE = keyMirror({
  CREATE_TOTAL_COLLECTIVE_INVOICE_REQUEST: null,
  CREATE_TOTAL_COLLECTIVE_INVOICE_SUCCESS: null,
  CREATE_TOTAL_COLLECTIVE_INVOICE_ERROR: null,
})

const GET_COMPANIES_FOR_FILTER = keyMirror({
  GET_COMPANIES_FOR_FILTER_REQUEST: null,
  GET_COMPANIES_FOR_FILTER_SUCCESS: null,
  GET_COMPANIES_FOR_FILTER_ERROR: null,
  RESET_COMPANIES_FOR_FILTER: null,
})

const GET_CUSTOM_INVOICE_COMPANIES = keyMirror({
  GET_CUSTOM_INVOICE_COMPANIES_REQUEST: null,
  GET_CUSTOM_INVOICE_COMPANIES_SUCCESS: null,
  GET_CUSTOM_INVOICE_COMPANIES_ERROR: null,
  RESET_CUSTOM_INVOICE_COMPANIES: null,
})

const GET_COMPANY_NOTES = keyMirror({
  GET_COMPANY_NOTES_REQUEST: null,
  GET_COMPANY_NOTES_SUCCESS: null,
  GET_COMPANY_NOTES_ERROR: null,
  RESET_COMPANY_NOTES: null,
})

const DELETE_COMPANY_NOTE = keyMirror({
  DELETE_COMPANY_NOTE_REQUEST: null,
  DELETE_COMPANY_NOTE_SUCCESS: null,
  DELETE_COMPANY_NOTE_ERROR: null,
})

const ADD_COMPANY_NOTE = keyMirror({
  ADD_COMPANY_NOTE_REQUEST: null,
  ADD_COMPANY_NOTE_SUCCESS: null,
  ADD_COMPANY_NOTE_ERROR: null,
})

const GET_COM_CENTER_CONTACTS = keyMirror({
  GET_COM_CENTER_CONTACTS_REQUEST: null,
  GET_COM_CENTER_CONTACTS_SUCCESS: null,
  GET_COM_CENTER_CONTACTS_ERROR: null,
  RESET_COM_CENTER_CONTACTS: null,
})

const ADD_COM_CENTER_CONTACT = keyMirror({
  ADD_COM_CENTER_CONTACT_REQUEST: null,
  ADD_COM_CENTER_CONTACT_SUCCESS: null,
  ADD_COM_CENTER_CONTACT_ERROR: null,
})

const UPDATE_COM_CENTER_CONTACT = keyMirror({
  UPDATE_COM_CENTER_CONTACT_REQUEST: null,
  UPDATE_COM_CENTER_CONTACT_SUCCESS: null,
  UPDATE_COM_CENTER_CONTACT_ERROR: null,
})

const DELETE_COM_CENTER_CONTACT = keyMirror({
  DELETE_COM_CENTER_CONTACT_REQUEST: null,
  DELETE_COM_CENTER_CONTACT_SUCCESS: null,
  DELETE_COM_CENTER_CONTACT_ERROR: null,
})

const GET_COM_CENTER_LOG = keyMirror({
  GET_COM_CENTER_LOG_REQUEST: null,
  GET_COM_CENTER_LOG_SUCCESS: null,
  GET_COM_CENTER_LOG_ERROR: null,
})

const GET_TASK_LOGS = keyMirror({
  GET_TASK_LOGS_REQUEST: null,
  GET_TASK_LOGS_SUCCESS: null,
  GET_TASK_LOGS_ERROR: null,
})

const GET_TASK_LOG_MESSAGES = keyMirror({
  GET_TASK_LOG_MESSAGES_REQUEST: null,
  GET_TASK_LOG_MESSAGES_SUCCESS: null,
  GET_TASK_LOG_MESSAGES_ERROR: null,
})

const GET_COMPANY_HISTORY = keyMirror({
  GET_COMPANY_HISTORY_REQUEST: null,
  GET_COMPANY_HISTORY_SUCCESS: null,
  GET_COMPANY_HISTORY_ERROR: null,
})

export default {
  ...ADD_COM_CENTER_CONTACT,
  ...ADD_COMPANY_NOTE,
  ...ADD_ROLE_COMPANIES_TO_STATE,
  ...CREATE_COLLECTIVE_INVOICE,
  ...CREATE_TOTAL_COLLECTIVE_INVOICE,
  ...DELETE_COM_CENTER_CONTACT,
  ...DELETE_COMPANY_NOTE,
  ...EXPORT_MAKLER_PREMIUM_COMPANIES,
  ...GET_ACTIVE_COMPANIES,
  ...GET_ACTIVE_COMPANIES_FOR_FILTER,
  ...GET_ACTIVE_CUSTOMER_FOR_FILTER,
  ...GET_ACTIVE_PARTNER_FOR_FILTER,
  ...GET_ALL_COMPANIES_FOR_FILTER,
  ...GET_COLLECTIVE_INVOICE_COMPANIES,
  ...GET_COM_CENTER_CONTACTS,
  ...GET_COM_CENTER_LOG,
  ...GET_COMPANIES,
  ...GET_COMPANIES_FOR_FILTER,
  ...GET_COMPANY,
  ...GET_COMPANY_NOTES,
  ...GET_CUSTOM_INVOICE_COMPANIES,
  ...GET_MAKLER_PREMIUM_COMPANIES,
  ...GET_MY_OFFERS_INQUIRED_COMPANIES,
  ...GET_TASK_LOGS,
  ...GET_TASK_LOG_MESSAGES,
  ...PAUSE_COMPANY,
  ...UPDATE_COM_CENTER_CONTACT,
  ...UPDATE_COMPANY,
  ...GET_COMPANY_HISTORY,
}
