import './style.scss'

import classNames from 'classnames'
import {
  arrayOf,
  bool,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Icon from 'components/common/Fontello'

class ControlEditToggle extends Component {
  static propTypes = {
    children: oneOfType([arrayOf(node), node]),
    className: string,
    dataTestId: string,
    disableOverwrittenValue: bool,
    editable: bool,
    errorClass: string,
    fallback: oneOfType([string, number]), // If value is empty, display fallback instead
    iconName: string,
    label: oneOfType([string, object]), // If label is component we need object
    overwrittenValue: oneOfType([string, number]),
    path: string,
    restrictToSingleLine: bool,
    triggerEdit: bool,
    value: oneOfType([string, number]),
    reset: bool,
  }

  static defaultProps = {
    children: [],
    className: null,
    dataTestId: 'control.edit-toggle',
    disableOverwrittenValue: false,
    editable: false,
    errorClass: null,
    fallback: null,
    iconName: null,
    label: null,
    overwrittenValue: null,
    path: null,
    restrictToSingleLine: false,
    triggerEdit: false,
    value: null,
    reset: false,
  }

  state = {
    edit: false,
    overridingValue: null,
    overwrittenValue: null,
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    if (this.props.triggerEdit) {
      this.handleEditToggle()
    } else if (this.props.overwrittenValue) {
      this.setState({
        overwrittenValue: this.props.overwrittenValue,
        overridingValue: this.props.value,
      })
    } else {
      this.setState({ overridingValue: this.props.value })
    }
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillReceiveProps
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.overridingValue)
      this.setState({ overridingValue: nextProps.value })
    // TODO @janbuerling: nextProps.editable missing in condition.
    if (nextProps.triggerEdit && !this.state.edit) this.handleEditToggle()
    if (this.props.reset !== nextProps.reset && this.state.edit)
      this.setState({ edit: false, overwrittenValue: null })
  }

  /**
   * @description handles the edit toggle.
   */
  handleEditToggle = () => {
    const overwrittenValue =
      !this.state.edit && !this.state.overwrittenValue
        ? this.props.value
        : this.state.overwrittenValue

    this.setState({
      edit: !this.state.edit,
      overwrittenValue,
    })
  }

  /**
   * @description Renders a paragraph containing a value and an optional icon.
   * @param {*} value The value to display
   * @param {boolean} isOld If specified, the class 'old-value' is added to the paragraph.
   * @return {*}
   */
  renderValueLine = (value, isOld = false) => (
    <p
      className={classNames(
        { 'single-line': this.props.restrictToSingleLine },
        { 'old-value': isOld },
        this.props.errorClass,
      )}
    >
      {this.props.iconName && value && <Icon name={this.props.iconName} />}
      {value && value}
      {!value && this.props.fallback && this.props.fallback}
    </p>
  )

  render() {
    const {
      children,
      className,
      dataTestId,
      disableOverwrittenValue,
      editable,
      errorClass,
      label,
      path,
    } = this.props

    const { edit, overwrittenValue } = this.state

    return (
      <div
        className={`control-edit-toggle ${className}`}
        data-testid={dataTestId}
      >
        <div>
          <span className={`control-edit-label ${errorClass}`}>{label}</span>

          {editable && (
            <button
              type='button'
              className='icon-button'
              onClick={this.handleEditToggle}
            >
              <Icon name={edit ? 'checkmark' : 'pencil'} />
            </button>
          )}
        </div>

        {overwrittenValue &&
          !disableOverwrittenValue &&
          this.renderValueLine(this.state.overwrittenValue, true)}

        {!edit && !path && this.renderValueLine(this.state.overridingValue)}

        {!edit && path && (
          <Link to={path}>
            {this.renderValueLine(this.state.overridingValue)}
          </Link>
        )}

        {edit && <div className='control-edit-children'>{children}</div>}
      </div>
    )
  }
}

export default ControlEditToggle
