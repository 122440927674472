import ActionTypes from '../actiontypes/company'
import { replaceObjectInArray } from '../helper/general'

import { itemInitialState as addressInitialState } from './address'

const itemInitialState = {
  account_holder_name: '',
  about_us: null,
  bic: '',
  central_contact_phone: '',
  central_contact_email: '',
  contact_person: null,
  commercial_registry_number: '',
  director_first_name: '',
  director_last_name: '',
  efb_certiciate_valid_until_date: null,
  email: '',
  eta_service: false,
  iban: '',
  permanent_discount: '',
  invoice_recipient: '',
  main_address_object: addressInitialState, // Comes from the API, but fields are needed to update the address
  postal_address_object: addressInitialState, // Comes from the API, but fields are needed to update the address
  name: '',
  payment_method: null,
  profile_image: null,
  private_person: null,
  reference_number: '',
  registration_number: '',
  tax_id: '',
  tax_number: '',
  website_url: null,
  custom_agreement_optional_certificate: null,
  collective_invoicing_auto_send_email: false,
  collective_invoicing_mode: '',
}

export const initialState = {
  item: itemInitialState,
  activeFilterList: [],
  activeCustomerFilterList: [],
  activePartnerFilterList: [],
  activeItemList: [],
  addAgreementCompanies: {
    customerCompany: undefined,
    partnerCompany: undefined,
  },
  itemList: [],
  offersInquiredCompanyList: [],
  maklerPremiumCompaniesList: [],
  collectiveInvoiceCompanyList: [],
  filterList: [],
  maklerPremiumPagination: {
    count: 0,
    next: 0,
    current: 1,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
  collectiveInvoiceCompanyListPagination: {
    count: 0,
    next: 0,
    current: 1,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
  notes: undefined,
  com_center_contacts: undefined,
  taskLogs: {
    itemList: [],
    pagination: {
      count: 0,
      next: 0,
      current: 1,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
  taskLogMessages: {
    itemList: [],
    pagination: {
      count: 0,
      next: 0,
      current: 1,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
  history: {
    itemList: [],
    pagination: {
      count: 0,
      next: 0,
      current: 1,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
}

/**
 * @description A reducer for company.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        // We must fill the initial state if the company has no address yet, so we can fill the form.
        item: {
          ...action.payload,
          main_address_object:
            action.payload.main_address_object || addressInitialState,
          postal_address_object:
            action.payload.postal_address || addressInitialState,
        },
      }
    case ActionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    case ActionTypes.GET_ACTIVE_COMPANIES_SUCCESS:
    case ActionTypes.GET_CUSTOM_INVOICE_COMPANIES_SUCCESS:
      return {
        ...state,
        activeItemList: action.payload,
      }
    case ActionTypes.ADD_CUSTOMER_COMPANY:
      return {
        ...state,
        addAgreementCompanies: {
          ...state.addAgreementCompanies,
          customerCompany: action.company,
        },
      }
    case ActionTypes.RESET_CUSTOMER_COMPANY:
      return {
        ...state,
        addAgreementCompanies: {
          ...state.addAgreementCompanies,
          customerCompany: undefined,
        },
      }
    case ActionTypes.ADD_PARTNER_COMPANY:
      return {
        ...state,
        addAgreementCompanies: {
          ...state.addAgreementCompanies,
          partnerCompany: action.company,
        },
      }
    case ActionTypes.RESET_PARTNER_COMPANY:
      return {
        ...state,
        addAgreementCompanies: {
          ...state.addAgreementCompanies,
          partnerCompany: undefined,
        },
      }
    case ActionTypes.RESET_ACTIVE_COMPANIES:
    case ActionTypes.RESET_CUSTOM_INVOICE_COMPANIES:
      return {
        ...state,
        activeItemList: [],
      }
    case ActionTypes.GET_ACTIVE_CUSTOMER_FOR_FILTER_SUCCESS:
      return {
        ...state,
        activeCustomerFilterList: action.payload,
      }
    case ActionTypes.GET_ACTIVE_PARTNER_FOR_FILTER_SUCCESS:
      return {
        ...state,
        activePartnerFilterList: action.payload,
      }
    case ActionTypes.GET_ACTIVE_COMPANIES_FOR_FILTER_SUCCESS:
      return {
        ...state,
        activeFilterList: action.payload,
      }
    case ActionTypes.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        item: {
          ...action.payload,
          postal_address_object:
            action.payload.postal_address || addressInitialState,
        },
      }
    case ActionTypes.RESET_COMPANY:
      return {
        ...state,
        item: itemInitialState,
      }
    case ActionTypes.GET_MY_OFFERS_INQUIRED_COMPANIES_SUCCESS:
      return {
        ...state,
        offersInquiredCompanyList: action.payload,
      }
    case ActionTypes.GET_COLLECTIVE_INVOICE_COMPANIES_SUCCESS:
      return {
        ...state,
        collectiveInvoiceCompanyList: action.payload.data,
        collectiveInvoiceCompanyListPagination: {
          loaded: true,
          ...action.payload.pagination,
        },
      }
    case ActionTypes.RESET_COLLECTIVE_INVOICE_COMPANIES:
      return {
        ...state,
        collectiveInvoiceCompanyList: [],
        collectiveInvoiceCompanyListPagination: {
          count: 0,
          next: 0,
          current: 1,
          previous: 0,
          total_results: 0,
          loaded: false,
        },
      }
    case ActionTypes.CREATE_COLLECTIVE_INVOICE_SUCCESS:
      return {
        ...state,
        collectiveInvoiceCompanyList: replaceObjectInArray(
          action.payload,
          state.collectiveInvoiceCompanyList,
        ),
      }
    case ActionTypes.GET_MAKLER_PREMIUM_COMPANIES_SUCCESS:
      return {
        ...state,
        maklerPremiumCompaniesList: action.payload.data,
        maklerPremiumPagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.RESET_MAKLER_PREMIUM_COMPANIES:
      return {
        ...state,
        maklerPremiumCompaniesList: [],
      }
    case ActionTypes.GET_COMPANIES_FOR_FILTER_SUCCESS:
    case ActionTypes.GET_ALL_COMPANIES_FOR_FILTER_SUCCESS:
      return {
        ...state,
        filterList: action.payload,
      }
    case ActionTypes.GET_COMPANY_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      }
    case ActionTypes.DELETE_COMPANY_NOTE_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          data: state.notes.data.filter(item => item.id !== action.payload),
        },
      }
    case ActionTypes.ADD_COMPANY_NOTE_SUCCESS:
      return {
        ...state,
        notes: {
          ...state.notes,
          pagination: {
            count: 0,
            next: state.notes.pagination.total_results >= 3 ? 1 : null,
            current: 1,
            previous: null,
            total_results: state.notes.pagination.total_results + 1,
          },
          data: [action.payload, ...state.notes.data.slice(0, 2)],
        },
      }
    case ActionTypes.GET_COM_CENTER_CONTACTS_SUCCESS:
      return {
        ...state,
        com_center_contacts: action.payload.data,
      }
    case ActionTypes.DELETE_COM_CENTER_CONTACT_SUCCESS:
      return {
        ...state,
        com_center_contacts: state.com_center_contacts.filter(
          item => item.id !== action.payload,
        ),
      }
    case ActionTypes.ADD_COM_CENTER_CONTACT_SUCCESS:
      return {
        ...state,
        com_center_contacts: [action.payload, ...state.com_center_contacts],
      }
    case ActionTypes.UPDATE_COM_CENTER_CONTACT_SUCCESS:
      return {
        ...state,
        com_center_contacts: replaceObjectInArray(
          action.payload,
          state.com_center_contacts,
        ),
      }
    case ActionTypes.RESET_COMPANY_NOTES:
      return {
        ...state,
        notes: undefined,
      }
    case ActionTypes.RESET_COMPANIES_FOR_FILTER:
    case ActionTypes.RESET_ALL_COMPANIES_FOR_FILTER:
      return {
        ...state,
        filterList: [],
      }
    case ActionTypes.GET_COM_CENTER_LOG_SUCCESS:
      return {
        ...state,
        com_center_log: action.payload,
      }
    case ActionTypes.GET_TASK_LOGS_SUCCESS:
      return {
        ...state,
        taskLogs: {
          itemList: action.payload.data,
          pagination: {
            loaded: true,
            ...action.payload.pagination,
          },
        },
      }
    case ActionTypes.GET_TASK_LOG_MESSAGES_SUCCESS:
      return {
        ...state,
        taskLogMessages: {
          itemList: action.payload.data,
          pagination: {
            loaded: true,
            ...action.payload.pagination,
          },
        },
      }
    case ActionTypes.GET_COMPANY_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          itemList: action.payload.data,
          pagination: {
            loaded: true,
            ...action.payload.pagination,
          },
        },
      }
    default:
      return state
  }
}

export default companyReducer
