export const upcomingOrdersTranslations = {
  headline: 'Geplante Aufträge',
  description:
    'Hier finden Sie eine Übersicht der für die nächsten 2 Werktage geplanten Aufträge. Filteroptionen werden alle 20 Minuten aktualisiert.',
  totalOrders: 'Anzahl aller Aufträge: ',
  totalOrdersPostfix: ' Aufträge',
  noContentMessage:
    'Es konnten keine Aufträge, die in den nächsten zwei Werktagen stattfinden, gefunden werden.',
  columns: {
    orderNumber: 'Auftragsnummer',
    date: 'Datum',
    disposer: 'Partner',
    producer: 'Kunde',
    zip: 'Postleitzahl',
    location: 'Ort',
    fraction: 'Abfallart',
    container: 'Behälter',
    type: 'Aktion',
    upcomingOrderStatus: 'Status',
  },
  filter: {
    upcomingOrderStatus: 'Status',
    partner: 'Partner',
    type: 'Aktion',
    lastUpdate: 'Filter zuletzt aktualisiert',
  },
  upcomingOrderStatus: {
    open: 'Offen',
    confirmedActive: 'Bestätigt (Aktiv)',
    confirmedInquired: 'Bestätigt (Nachgefragt)',
  },
  modal: {
    header: 'Hat der Partner den Auftrag bestätigt?',
    description:
      'Mit einem Klick auf "Ja" wird der Status dieses Auftrags auf bestätigt gesetzt. ' +
      'Diese Änderung ist nur korrekt, wenn Sie vom Entsorger eine entsprechende Bestätigung per Telefon ' +
      'oder E-Mail erhalten haben!',
    confirm: 'Ja',
  },
  exportModal: {
    title: 'Geplante Aufträge Export',
  },
}
