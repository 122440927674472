const disposerProducerPageTranslations = {
  companyFilter: {
    label:
      'Suche nach Unternehmen / Kundennummer / Straße / Ort / Ansprechpartner / E-Mail',
    placeholder: 'Unternehmen',
    noResultsText: 'Kein Unternehmen zu diesem Suchbegriff gefunden',
  },
  lucidRegistrationNumber: {
    label: 'Suche nach LUCID Registrierungsnummer',
    placeholder: 'LUCID Registrierungsnummer',
    noResultsText:
      'Keine LUCID Registrierungsnummer zu diesem Suchbegriff gefunden',
  },
  wasteDisposersPageTranslations: {
    heading: 'Partnerverwaltung',
    exportModalTitle: 'Partner Export',
    partnerNumber: 'Partnernummer',
    partner: 'Partner',
    permanent_discount: 'Dauerrabatt',
    contactPerson: 'Ansprechpartner',
    companyName: 'Firmierung',
    blockAutoOffersPause: 'Pausierungszeitraum',
    list: {
      zipcodes: 'Postleitzahlen',
      communities: 'Landkreise',
    },
    csvImport: {
      title: 'CSV Import',
      description:
        '<p>Der CSV Import ermöglicht Ihnen die leichte Änderung bestehender Jobs. ' +
        'Bitte aktualisieren Sie einfach Ihre Werte wie Preise, Postleitzahlen oder die Gültigkeit Ihrer Jobs im ' +
        'Export und laden die bearbeitete Tabelle hier wieder hoch. Gerne unterstützt Sie der empto ' +
        'Kundenservice telefonisch unter <a href="tel:+498000056356">0800 00 56356</a>.</p>',
      label: 'CSV Datei',
    },
    actions: {
      document: 'Efb Zertifikat',
      priceagreement: 'Preisvereinbarung',
    },
  },
  wasteProducersPageTranslations: {
    heading: 'Kundenverwaltung',
    dszMynatureExport: {
      buttonLabel: 'mynature Exporte',
      exportModalTitle: 'Kunden Export (mynature Exporte)',
    },
    exportModalTitle: 'Kunden Export',

    customer: 'Kunde',
    permanent_discount: 'Dauerrabatt',
    contactPerson: 'Ansprechpartner',
    list: {
      zipcodes: 'Postleitzahlen',
      communities: 'Landkreise',
    },
    csvImport: {
      title: 'CSV Import',
      description:
        '<p>Der CSV Import ermöglicht Ihnen die leichte Änderung bestehender Jobs. ' +
        'Bitte aktualisieren Sie einfach Ihre Werte wie Preise, Postleitzahlen oder die Gültigkeit Ihrer Jobs im ' +
        'Export und laden die bearbeitete Tabelle hier wieder hoch. Gerne unterstützt Sie der empto ' +
        'Kundenservice telefonisch unter <a href="tel:+498000056356">0800 00 56356</a>.</p>',
      label: 'CSV Datei',
    },
    actions: {
      document: 'Dokument GewAbfV',
      priceagreement: 'Preisvereinbarung',
    },
  },
}

export default disposerProducerPageTranslations
