import keyMirror from 'keymirror'

const CREATE_CUSTOMER = keyMirror({
  CREATE_CUSTOMER_REQUEST: null,
  CREATE_CUSTOMER_SUCCESS: null,
  CREATE_CUSTOMER_ERROR: null,
})

const CREATE_PARTNER = keyMirror({
  CREATE_PARTNER_REQUEST: null,
  CREATE_PARTNER_SUCCESS: null,
  CREATE_PARTNER_ERROR: null,
})

const CREATE_COMBINED_OFFER = keyMirror({
  CREATE_COMBINED_OFFER_REQUEST: null,
  CREATE_COMBINED_OFFER_SUCCESS: null,
  CREATE_COMBINED_OFFER_ERROR: null,
})

const GET_PRICE_COMPARISON_TABLE = keyMirror({
  GET_PRICE_COMPARISON_TABLE_REQUEST: null,
  GET_PRICE_COMPARISON_TABLE_SUCCESS: null,
  GET_PRICE_COMPARISON_TABLE_ERROR: null,
})

const GET_PRICE_COMPARISON_TABLE_PAUSED = keyMirror({
  GET_PRICE_COMPARISON_TABLE_PAUSED_REQUEST: null,
  GET_PRICE_COMPARISON_TABLE_PAUSED_SUCCESS: null,
  GET_PRICE_COMPARISON_TABLE_PAUSED_ERROR: null,
})

const RESET_PRICE_COMPARISON_TABLE = keyMirror({
  RESET_PRICE_COMPARISON_TABLE: null,
})

const GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT = keyMirror({
  GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_REQUEST: null,
  GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_SUCCESS: null,
  GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_ERROR: null,
})

const VALIDATE_IBAN = keyMirror({
  VALIDATE_IBAN_REQUEST: null,
  VALIDATE_IBAN_SUCCESS: null,
  VALIDATE_IBAN_ERROR: null,
  VALIDATE_IBAN_RESET: null,
})

const CREATE_CUSTOM_INVOICE = keyMirror({
  CREATE_CUSTOM_INVOICE_REQUEST: null,
  CREATE_CUSTOM_INVOICE_SUCCESS: null,
  CREATE_CUSTOM_INVOICE_ERROR: null,
})

const GET_CUSTOM_TAX = keyMirror({
  GET_CUSTOM_TAX_REQUEST: null,
  GET_CUSTOM_TAX_SUCCESS: null,
  GET_CUSTOM_TAX_ERROR: null,
})

const GET_FRAMEWORKCONTRACT = keyMirror({
  GET_FRAMEWORKCONTRACT_REQUEST: null,
  GET_FRAMEWORKCONTRACT_SUCCESS: null,
  GET_FRAMEWORKCONTRACT_ERROR: null,
})

const GET_FRAMEWORKCONTRACT_POSITIONS_COUNT = keyMirror({
  GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_REQUEST: null,
  GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_SUCCESS: null,
  GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_ERROR: null,
})

const RESET_FRAMEWORKCONTRACT = keyMirror({
  RESET_FRAMEWORKCONTRACT: null,
})

const GET_CANCELLEDPAIDDOCUMENT = keyMirror({
  GET_CANCELLEDPAIDDOCUMENT_REQUEST: null,
  GET_CANCELLEDPAIDDOCUMENT_SUCCESS: null,
  GET_CANCELLEDPAIDDOCUMENT_ERROR: null,
})

const UPDATE_CANCELLEDPAIDDOCUMENT = keyMirror({
  UPDATE_CANCELLEDPAIDDOCUMENT_REQUEST: null,
  UPDATE_CANCELLEDPAIDDOCUMENT_SUCCESS: null,
  UPDATE_CANCELLEDPAIDDOCUMENT_ERROR: null,
  UPDATE_CANCELLEDPAIDDOCUMENT_RESET: null,
})

const CREATE_CUSTOMOFFER = keyMirror({
  CREATE_CUSTOMOFFER_REQUEST: null,
  CREATE_CUSTOMOFFER_SUCCESS: null,
  CREATE_CUSTOMOFFER_ERROR: null,
})

const UPDATE_CUSTOMOFFER = keyMirror({
  UPDATE_CUSTOMOFFER_REQUEST: null,
  UPDATE_CUSTOMOFFER_SUCCESS: null,
  UPDATE_CUSTOMOFFER_ERROR: null,
})

const GET_OFFER_PIPELINE = keyMirror({
  GET_OFFER_PIPELINE_REQUEST: null,
  GET_OFFER_PIPELINE_SUCCESS: null,
  GET_OFFER_PIPELINE_ERROR: null,
})

const EXPORT_OFFER_PIPELINE = keyMirror({
  EXPORT_OFFER_PIPELINE_REQUEST: null,
  EXPORT_OFFER_PIPELINE_SUCCESS: null,
  EXPORT_OFFER_PIPELINE_ERROR: null,
})

const EXPORT_POSTAL_STATISTICS = keyMirror({
  EXPORT_POSTAL_STATISTICS_REQUEST: null,
  EXPORT_POSTAL_STATISTICS_SUCCESS: null,
  EXPORT_POSTAL_STATISTICS_ERROR: null,
})

const EXPORT_CUSTOMER_DATA = keyMirror({
  EXPORT_CUSTOMER_DATA_REQUEST: null,
  EXPORT_CUSTOMER_DATA_SUCCESS: null,
  EXPORT_CUSTOMER_DATA_ERROR: null,
})

const EXPORT_COMMUNICATIONCENTER = keyMirror({
  EXPORT_COMMUNICATIONCENTER_REQUEST: null,
  EXPORT_COMMUNICATIONCENTER_SUCCESS: null,
  EXPORT_COMMUNICATIONCENTER_ERROR: null,
})

const CREATE_CUSTOM_AGREEMENT = keyMirror({
  CREATE_CUSTOM_AGREEMENT_REQUEST: null,
  CREATE_CUSTOM_AGREEMENT_SUCCESS: null,
  CREATE_CUSTOM_AGREEMENT_ERROR: null,
})

const EXPORT_UPCOMING_ORDERS = keyMirror({
  EXPORT_UPCOMING_ORDERS_REQUEST: null,
  EXPORT_UPCOMING_ORDERS_SUCCESS: null,
  EXPORT_UPCOMING_ORDERS_ERROR: null,
})

export default {
  ...CREATE_CUSTOMER,
  ...CREATE_PARTNER,
  ...CREATE_COMBINED_OFFER,
  ...GET_PRICE_COMPARISON_TABLE,
  ...GET_PRICE_COMPARISON_TABLE_PAUSED,
  ...RESET_PRICE_COMPARISON_TABLE,
  ...GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT,
  ...VALIDATE_IBAN,
  ...CREATE_CUSTOM_INVOICE,
  ...GET_FRAMEWORKCONTRACT,
  ...GET_FRAMEWORKCONTRACT_POSITIONS_COUNT,
  ...RESET_FRAMEWORKCONTRACT,
  ...GET_CANCELLEDPAIDDOCUMENT,
  ...UPDATE_CANCELLEDPAIDDOCUMENT,
  ...GET_CUSTOM_TAX,
  ...CREATE_CUSTOMOFFER,
  ...UPDATE_CUSTOMOFFER,
  ...GET_OFFER_PIPELINE,
  ...EXPORT_OFFER_PIPELINE,
  ...EXPORT_POSTAL_STATISTICS,
  ...EXPORT_CUSTOMER_DATA,
  ...EXPORT_COMMUNICATIONCENTER,
  ...CREATE_CUSTOM_AGREEMENT,
  ...EXPORT_UPCOMING_ORDERS,
}
