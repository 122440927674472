import React, { FC, useContext } from 'react'
import moment from 'moment/moment'

import { PaginatedTable } from 'components/common/PaginatedTable'
import { UpDownArrow } from 'components/common/UpDownArrow'

import { MarkupPageContext } from './MarkupPage'

export const MarkupTable: FC = () => {
  const {
    markups,
    page,
    setPage,
    pageCount,
    setShouldUpdate,
    setOrderByFilter,
  } = useContext(MarkupPageContext)

  // prettier-ignore
  const columns = [
    {id: 'code',            Header: (<span           >PLZ<UpDownArrow /></span>), Cell: (data: { original }) => data.original.code},
    {id: 'city',            Header: (<span           >Ort<UpDownArrow /></span>), Cell: (data: { original }) => data.original.cities.join(', ')},
    {id: 'community',       Header: (<span     >Landkreis<UpDownArrow /></span>), Cell: (data: { original }) => data.original.communities.join(', ')},
    {id: 'state',           Header: (<span    >Bundesland<UpDownArrow /></span>), Cell: (data: { original }) => data.original.states.join(', ')},
    {id: 'markup',          Header: (<span   >Aufschlag %<UpDownArrow /></span>), Cell: (data: { original }) => data.original.markup.replace('.', ',')},
    {id: 'lastmodified_at', Header: (<span   >geändert am<UpDownArrow /></span>), Cell: (data: { original }) => moment(data.original?.lastmodified_at).format('L LT') },
    {id: 'lastmodified_by', Header: (<span>geändert durch<UpDownArrow /></span>), Cell: (data: { original }) => data.original?.lastmodified_by},
  ]

  return (
    <MarkupPageContext.Consumer>
      {() => (
        <div className='uk-margin-large-top'>
          <PaginatedTable
            serverSidePagination
            page={page}
            pages={pageCount}
            handleShowPreviousPage={() => {
              setPage(page - 1)
              setShouldUpdate(true)
            }}
            handleShowNextPage={() => {
              setPage(page + 1)
              setShouldUpdate(true)
            }}
            table={{
              columns,
              data: markups,
              onFetchData: ({ sorted }) => {
                if (sorted && sorted.length > 0) {
                  setOrderByFilter(JSON.stringify(sorted))
                  setPage(1)
                  setShouldUpdate(true)
                }
              },
            }}
          />
        </div>
      )}
    </MarkupPageContext.Consumer>
  )
}
