import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { resetOrdersForInvoiceCheckList } from 'actions/order'
import {
  deleteInvoiceCheckPosition,
  getInvoiceCheck,
  reopenInvoiceCheck,
  updateInvoiceCheck,
} from 'actions/invoicecheck'
import { resetApiFetchLoading } from 'actions/app'
import { createLoadingSelector } from 'selectors/loading'
import { getInvoiceCheckSelector } from 'selectors/invoicecheck'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

import { BackButton } from '../../common/BackButton'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { ProgressButton } from '../../common/ProgressButton'
import { Textarea } from '../../common/Textarea'
import PageHeader from '../../layout/PageHeader'
import { INVOICE_CHECK_STATUS, INVOICE_CHECK_STATUSES } from '../constants'
import {
  InvoiceCheckAcquisitionFormikWrapper,
  InvoiceCheckDocumentType,
} from '../InvoiceCheckPage/InvoiceCheckAcquisition'

import { InvoiceCheckOverviewDocuments } from './InvoiceCheckOverviewDocuments'
import { InvoiceCheckOverviewInvoiceData } from './InvoiceCheckOverviewInvoiceCheck'
import { InvoiceCheckOverviewInvoiceOverview } from './InvoiceCheckOverviewInvoiceOverview'
import { InvoiceCheckOverviewInvoicePositions } from './InvoiceCheckOverviewInvoicePositions'
import { InvoiceCheckPositionsFormSteps } from './InvoiceCheckPositionsForm'
import { OrderType } from './InvoiceCheckPositionsForm/Step2'
import { NetPriceType } from './InvoiceCheckPositionsForm/Step2/OrderAndServicesForm'

/**
 * @description This component displays a list of invoices including the filter, loading indicator etc.
 * @function
 */
export const InvoiceCheckOverview: FC = () => {
  const { invoiceCheckId } = useParams<{ invoiceCheckId: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const invoiceCheck = useSelector(getInvoiceCheckSelector)
  const updateRequestStatus = useSelector(
    createRequestStatusSelector(['UPDATE_INVOICE_CHECK']),
  )
  const isLoading = useSelector(createLoadingSelector(['UPDATE_INVOICE_CHECK']))

  const [cancelReasonText, setCancelReasonText] = useState<string>('')

  useEffect(() => {
    dispatch(
      getInvoiceCheck(Number(invoiceCheckId), [
        'company_object__main_address_object',
        'positions__offer_object__customer_company_object',
      ]),
    )
  }, [dispatch, invoiceCheckId])

  // Redirect to invoice-check list page after update was called to reject or accept the invoice-check
  useEffect(() => {
    if (
      invoiceCheck &&
      (invoiceCheck.status === INVOICE_CHECK_STATUSES.STATUS_REJECTED ||
        invoiceCheck.status === INVOICE_CHECK_STATUSES.STATUS_ACCEPTED) &&
      updateRequestStatus === REQUEST_STATUS.SUCCESS
    ) {
      dispatch(resetApiFetchLoading('UPDATE_INVOICE_CHECK'))
      history.push('/invoicecheck/invoice_check')
    }
  }, [dispatch, history, invoiceCheck, updateRequestStatus])

  // InvoiceCheck Edit Modal
  const idInvoiceCheckEditFormModalHeadline = uniqueId()
  const [isEditInvoiceCheckModalOpen, setIsEditInvoiceCheckModalOpen] =
    useState(false)
  const handleOpenCloseInvoiceCheckEditFormModal = () => {
    setIsEditInvoiceCheckModalOpen(!isEditInvoiceCheckModalOpen)
  }

  // InvoiceCheckPositionsForm Modal
  const idInvoiceCheckPositionsFormModalHeadline = uniqueId()
  const [
    isAddInvoiceCheckPositionsFormModalOpen,
    setIsAddInvoiceCheckPositionsFormModalOpen,
  ] = useState(false)
  const handleOpenCloseInvoiceCheckPositionsFormModal = useCallback(() => {
    dispatch(resetOrdersForInvoiceCheckList())

    setIsAddInvoiceCheckPositionsFormModalOpen(
      !isAddInvoiceCheckPositionsFormModalOpen,
    )
  }, [isAddInvoiceCheckPositionsFormModalOpen, dispatch])

  // DeletePosition Modal
  const idDeletePositionModalHeadline = uniqueId()
  const [isDeletePositionModalOpen, setIsDeletePositionModalOpen] =
    useState(false)
  const handleOpenCloseDeletePositionModal = () => {
    setIsDeletePositionModalOpen(!isDeletePositionModalOpen)
  }

  // RejectInvoiceCheck Modal
  const idRejectInvoiceCheckModalHeadline = uniqueId()
  const [isRejectInvoiceCheckModalOpen, setIsRejectInvoiceCheckModalOpen] =
    useState(false)
  const handleOpenCloseRejectInvoiceCheckModal = () => {
    setIsRejectInvoiceCheckModalOpen(!isRejectInvoiceCheckModalOpen)
  }

  // ReleaseInvoiceCheck Modal
  const idReleaseInvoiceCheckModalHeadline = uniqueId()
  const [isReleaseInvoiceCheckModalOpen, setIsReleaseInvoiceCheckModalOpen] =
    useState(false)
  const handleOpenCloseReleaseInvoiceCheckModal = () => {
    setIsReleaseInvoiceCheckModalOpen(!isReleaseInvoiceCheckModalOpen)
  }

  const [invoiceCheckPosition, setInvoiceCheckPosition] =
    useState<InvoiceCheckPosition>()

  const [isDispatchingModal, setIsDispatchingModal] = useState(false)

  const rejectInvoiceCheck = () => {
    setIsDispatchingModal(true)
    dispatch(
      updateInvoiceCheck({
        ...invoiceCheck,
        status: INVOICE_CHECK_STATUSES.STATUS_REJECTED,
        cancel_reason_text: cancelReasonText,
        company: invoiceCheck.company_object.id,
      }),
    )
    setIsDispatchingModal(false)
  }

  const releaseInvoiceCheck = () => {
    setIsDispatchingModal(true)
    dispatch(
      updateInvoiceCheck({
        ...invoiceCheck,
        status: INVOICE_CHECK_STATUSES.STATUS_ACCEPTED,
        company: invoiceCheck.company_object.id,
      }),
    )
    setIsDispatchingModal(false)
  }

  if (!invoiceCheck) return null

  let invoicePositionNetSum = 0
  if (invoiceCheck.positions) {
    invoicePositionNetSum = invoiceCheck.positions.reduce(
      (accumulator, currentValue) => {
        if (
          (currentValue.order_type === OrderType.OPEN_ORDER &&
            invoiceCheck.document_type === InvoiceCheckDocumentType.INVOICE) ||
          (currentValue.net_price_type === NetPriceType.BURDEN &&
            [OrderType.OTHER_SERVICE, OrderType.RENT].includes(
              currentValue.order_type,
            ))
        ) {
          return accumulator + Number(currentValue.net_price)
        }

        if (
          (currentValue.order_type === OrderType.OPEN_ORDER &&
            invoiceCheck.document_type ===
              InvoiceCheckDocumentType.CREDIT_NOTE) ||
          (currentValue.net_price_type === NetPriceType.CREDIT &&
            [OrderType.OTHER_SERVICE, OrderType.RENT].includes(
              currentValue.order_type,
            ))
        ) {
          return accumulator - Number(currentValue.net_price)
        }

        return accumulator
      },
      0,
    )
  }

  const invoicePositionVat =
    invoicePositionNetSum * (Number(invoiceCheck.vat) / 100)
  const invoicePositionGrossSum = Number(
    (invoicePositionVat + invoicePositionNetSum).toFixed(2),
  )
  const difference = invoiceCheck.gross_price - invoicePositionGrossSum

  return (
    <>
      <Helmet>
        <title>
          {`${I18n.t('pageTitles.invoiceCheckOverview')} ${
            invoiceCheck.document_number
          }`}
        </title>
      </Helmet>
      <PageHeader
        title={`${I18n.t('pageTitles.invoiceCheckOverview')} ${
          invoiceCheck.document_number
        }`}
      >
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          {[
            INVOICE_CHECK_STATUS.INVOICE_CHECK_PENDING,
            INVOICE_CHECK_STATUS.INVOICE_CHECK_STATUS_REOPENED,
          ].includes(invoiceCheck.status) && (
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={handleOpenCloseReleaseInvoiceCheckModal}
              isDisabled={Math.abs(difference) > 0.02}
            >
              <Translate value='invoiceCheckOverviewTranslations.acceptInvoiceButton' />
            </Button>
          )}
          {invoiceCheck.status ===
            INVOICE_CHECK_STATUS.INVOICE_CHECK_PENDING && (
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={handleOpenCloseRejectInvoiceCheckModal}
            >
              <Translate value='invoiceCheckOverviewTranslations.denyInvoiceButton' />
            </Button>
          )}
          {invoiceCheck.status ===
            INVOICE_CHECK_STATUS.INVOICE_CHECK_STATUS_ACCEPTED && (
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={() =>
                dispatch(reopenInvoiceCheck(Number(invoiceCheckId)))
              }
            >
              <Translate value='invoiceCheckOverviewTranslations.reopenInvoiceButton' />
            </Button>
          )}
          <BackButton />
        </ButtonBar>
      </PageHeader>

      <div className='uk-grid'>
        <div className='uk-width-1-1 uk-width-1-2@m'>
          <InvoiceCheckOverviewDocuments invoiceCheck={invoiceCheck} />
          <InvoiceCheckOverviewInvoiceData
            handleOpenCloseInvoiceCheckEditFormModal={
              handleOpenCloseInvoiceCheckEditFormModal
            }
            invoiceCheck={invoiceCheck}
          />
          <InvoiceCheckOverviewInvoiceOverview
            invoiceCheck={invoiceCheck}
            invoicePositionVat={invoicePositionVat}
            invoicePositionGrossSum={invoicePositionGrossSum}
            invoicePositionNetSum={invoicePositionNetSum}
            difference={difference}
          />
        </div>

        <div className='uk-width-1-1 uk-width-1-2@m'>
          <InvoiceCheckOverviewInvoicePositions
            invoiceCheck={invoiceCheck}
            handleOpenCloseInvoiceCheckPositionsFormModal={
              handleOpenCloseInvoiceCheckPositionsFormModal
            }
            setInvoiceCheckPositionData={setInvoiceCheckPosition}
            handleOpenCloseDeletePositionModal={
              handleOpenCloseDeletePositionModal
            }
          />
        </div>
      </div>

      <Modal
        ariaDescribedBy={idDeletePositionModalHeadline}
        isOpen={isDeletePositionModalOpen}
        onClose={handleOpenCloseDeletePositionModal}
      >
        <ModalHeader
          onClose={handleOpenCloseDeletePositionModal}
          title={I18n.t('invoiceCheckTranslations.deletePositionModal.title')}
          titleId={idDeletePositionModalHeadline}
        />
        <div className='uk-modal-body inovicecheck-overview-delete-position-modal'>
          <p>
            {I18n.t('invoiceCheckTranslations.deletePositionModal.message')}
          </p>
          <div className='inovicecheck-overview-delete-position-modal__button-row'>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.DEFAULT}
              onClick={handleOpenCloseDeletePositionModal}
            >
              <Translate value='general.button.cancel' />
            </ProgressButton>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={() => {
                if (!invoiceCheckPosition) {
                  return
                }

                dispatch(
                  deleteInvoiceCheckPosition(
                    Number(invoiceCheckPosition.id) as React.ReactText,
                  ),
                )

                handleOpenCloseDeletePositionModal()
              }}
            >
              <Translate value='general.button.submit' />
            </ProgressButton>
          </div>
        </div>
      </Modal>

      <Modal
        ariaDescribedBy={idInvoiceCheckEditFormModalHeadline}
        isOpen={isEditInvoiceCheckModalOpen}
        onClose={handleOpenCloseInvoiceCheckEditFormModal}
      >
        <ModalHeader
          onClose={handleOpenCloseInvoiceCheckEditFormModal}
          title={I18n.t(
            'invoiceCheckTranslations.acquisitionForm.header.titleEdit',
          )}
          titleId={idInvoiceCheckEditFormModalHeadline}
        />
        <InvoiceCheckAcquisitionFormikWrapper
          invoiceCheck={invoiceCheck}
          handleCloseModal={() => {
            handleOpenCloseInvoiceCheckEditFormModal()
            // reload table with new data
            dispatch(getInvoiceCheck(Number(invoiceCheckId)))
          }}
        />
      </Modal>

      {/* Reject InvoiceCheck*/}
      <Modal
        ariaDescribedBy={idInvoiceCheckPositionsFormModalHeadline}
        isOpen={isRejectInvoiceCheckModalOpen}
        onClose={handleOpenCloseRejectInvoiceCheckModal}
      >
        <ModalHeader
          onClose={handleOpenCloseRejectInvoiceCheckModal}
          title={I18n.t('invoiceCheckTranslations.rejectForm.header')}
          titleId={idRejectInvoiceCheckModalHeadline}
        />
        <div className='uk-modal-body'>
          <p>{I18n.t('invoiceCheckTranslations.rejectForm.description')}</p>

          <Textarea
            className='universal-file-upload-modal__comment-input'
            name='universal-file-upload-comments'
            onChange={event => setCancelReasonText(event.currentTarget.value)}
            value={cancelReasonText}
            showRemainingCharacters
            maxLength={500}
            rows={3}
            label={I18n.t(
              'invoiceCheckTranslations.rejectForm.cancelReasonTextLabel',
            )}
            placeholder={I18n.t(
              'invoiceCheckTranslations.rejectForm.cancelReasonTextPlaceholder',
            )}
          />

          <ButtonBar align={BUTTON_BAR_ALIGN.CENTER}>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={handleOpenCloseRejectInvoiceCheckModal}
              isDisabled={isLoading || isDispatchingModal}
            >
              {I18n.t('general.button.cancel')}
            </Button>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={rejectInvoiceCheck}
              isDisabled={!cancelReasonText || isLoading || isDispatchingModal}
              isLoading={isLoading || isDispatchingModal}
            >
              <Translate value='invoiceCheckTranslations.rejectForm.acceptRejectionButton' />
            </ProgressButton>
          </ButtonBar>
        </div>
      </Modal>

      {/* Release-Modal (Rechnung freigeben) */}
      <Modal
        ariaDescribedBy={idInvoiceCheckPositionsFormModalHeadline}
        isOpen={isReleaseInvoiceCheckModalOpen}
        onClose={handleOpenCloseReleaseInvoiceCheckModal}
      >
        <ModalHeader
          onClose={handleOpenCloseReleaseInvoiceCheckModal}
          title={I18n.t('invoiceCheckTranslations.releaseForm.header')}
          titleId={idReleaseInvoiceCheckModalHeadline}
        />
        <div className='uk-modal-body'>
          <p>{I18n.t('invoiceCheckTranslations.releaseForm.description')}</p>

          <ButtonBar align={BUTTON_BAR_ALIGN.CENTER}>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={handleOpenCloseReleaseInvoiceCheckModal}
              isDisabled={isLoading || isDispatchingModal}
            >
              {I18n.t('general.button.cancel')}
            </Button>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={releaseInvoiceCheck}
              isDisabled={isLoading || isDispatchingModal}
              isLoading={isLoading || isDispatchingModal}
            >
              <Translate value='invoiceCheckTranslations.releaseForm.acceptReleaseButton' />
            </ProgressButton>
          </ButtonBar>
        </div>
      </Modal>

      {/* Add InvoiceCheckPosition*/}
      <Modal
        ariaDescribedBy={idInvoiceCheckPositionsFormModalHeadline}
        isOpen={isAddInvoiceCheckPositionsFormModalOpen}
        onClose={handleOpenCloseInvoiceCheckPositionsFormModal}
      >
        <ModalHeader
          onClose={handleOpenCloseInvoiceCheckPositionsFormModal}
          title={I18n.t('invoiceCheckTranslations.positionsForm.title')}
          titleId={idInvoiceCheckPositionsFormModalHeadline}
        />
        <InvoiceCheckPositionsFormSteps
          handleCloseModal={handleOpenCloseInvoiceCheckPositionsFormModal}
          invoiceCheck={invoiceCheck}
          invoiceCheckPosition={invoiceCheckPosition}
          setInvoiceCheckPosition={setInvoiceCheckPosition}
        />
      </Modal>
    </>
  )
}
