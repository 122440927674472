import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { Location } from 'history'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router'
import * as yup from 'yup'

import { MaklerPremiumGroups, UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import withErrorBoundary from 'helper/withErrorBoundary'

import { DocumentReview } from '../../common/DocumentReview'
import { OrderClaimReview } from '../../common/DocumentReview/OrderClaimReview'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { ORDER_CLAIM_TYPE } from '../../order/OrderDetailsPage/constants'
import { ORDER_CLAIM_PROOF_STATUS } from '../constants'

import SaveInternalCommentModal from './components/SaveInternalCommentModal'
import { connector } from './connector'

interface OrderClaimProofDetailsPageComponentProps extends FormikProps<any> {
  claim: OrderClaimList
  getFractions: () => void
  getContainers: () => void
  getOrderClaim: (claimId: number) => void
  updateOrderClaimInternalNote: (claim: FormikValues) => void
  updateReviewedOrderClaim: (claim: FormikValues, history: History) => void
  resetOrderClaimItem: () => void
  match: {
    params: {
      orderClaimId: string
    }
  }
  containerList: Container[]
  fractionList: Fraction[]
  user: User
  location: Location
  isLoading?: boolean
  isSaving?: boolean
  history: History
}

/**
 * @description This component displays the review page of certificates.
 */
export const OrderClaimProofDetailsPageComponent: FC<
  OrderClaimProofDetailsPageComponentProps
> = props => {
  const {
    claim,
    getFractions,
    getContainers,
    getOrderClaim,
    updateOrderClaimInternalNote,
    updateReviewedOrderClaim,
    resetOrderClaimItem,
    match,
    containerList,
    fractionList,
    user,
    location,
    isLoading = false,
    isSaving = false,
    history,
  } = props

  const [displayModal, setDisplayModal] = useState<boolean>(false)
  const userIsMaklerPremium = user.group_id in MaklerPremiumGroups

  // trigger reset certificate item on component unmount
  useEffect(() => () => resetOrderClaimItem(), [resetOrderClaimItem])

  useEffect(() => {
    if (fractionList.length === 0) getFractions()
  }, [fractionList.length, getFractions])
  useEffect(() => {
    if (containerList.length === 0) getContainers()
  }, [containerList.length, getContainers])
  useEffect(() => {
    const claimId = Number(match.params.orderClaimId)
    getOrderClaim(claimId)
  }, [getOrderClaim, match.params.orderClaimId])

  const handleSaveInternalNote = internalNoteValue => {
    if (claim.internal_note || !claim.internal_note) {
      updateOrderClaimInternalNote({
        id: Number(match.params.orderClaimId),
        internal_note: internalNoteValue,
      })
    } else {
      toastr.warning(
        '',
        I18n.t('orderClaimDetailsPageTranslations.internalNoteSavingWarning'),
      )
    }
    setDisplayModal(!displayModal)
  }

  const isReview =
    !claim.reviewed_at &&
    checkRequiredPermissions(user.permission_codenames, [
      UserPermission.REVIEW_ORDERCLAIM,
    ])

  if (isLoading || Number(match.params.orderClaimId) !== claim.id) return null
  return (
    <RequiredPermissions
      requiredPermissions={[
        UserPermission.REVIEW_ORDERCLAIM,
        UserPermission.VIEW_ORDERCLAIM,
      ]}
    >
      <div className='claim-review-page'>
        <Formik
          initialValues={{
            waiting_time_date: '',
            service_date: '',
            effort_duration: '',
            internal_note: claim.internal_note
              ? String(claim.internal_note)
              : '',
            status: String(ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING),
            review_message: '',
            enabled_deadhead_accepting: false,
            enabled_waiting_time_accepting: false,
            reject_reason: '',
          }}
          validationSchema={() =>
            yup.object().shape({
              waiting_time_date: yup.string().typeError(''),
              service_date: yup.string().typeError(''),
              effort_duration: yup.string().typeError(''),
              internal_note: yup.string().typeError(''),
              status: yup.string().typeError(''),
              review_message: yup.string().typeError(''),
              enabled_deadhead_accepting: yup.boolean().typeError(''),
              enabled_waiting_time_accepting: yup.boolean().typeError(''),
              reject_reason: yup.string().typeError(''),
            })
          }
          validate={values => {
            const errors: {
              waiting_time_date?: string
              service_date?: string
              effort_duration?: string
              internal_note?: string
              status?: string
              review_message?: string
              enabled_deadhead_accepting?: string
              enabled_waiting_time_accepting?: string
              reject_reason?: string
            } = {}

            if (
              Number(values.status) !== ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED
            ) {
              if (
                Number(claim.claim_type) === ORDER_CLAIM_TYPE.TYPE_WAITING_TIME
              ) {
                if (values.waiting_time_date === '') {
                  errors.waiting_time_date = I18n.t(
                    'orderClaimDetailsPageTranslations.validation.waitingTimeDate',
                  )
                }
                if (values.effort_duration === '') {
                  errors.effort_duration = I18n.t(
                    'orderClaimDetailsPageTranslations.validation.effortDuration',
                  )
                }
                if (!values.enabled_waiting_time_accepting) {
                  errors.enabled_waiting_time_accepting = I18n.t(
                    'orderClaimDetailsPageTranslations.validation.enabledWaitingTimeAccepting',
                  )
                }
              }

              if (Number(claim.claim_type) === ORDER_CLAIM_TYPE.TYPE_DEADHEAD) {
                if (values.service_date === '') {
                  errors.service_date = I18n.t(
                    'orderClaimDetailsPageTranslations.validation.serviceDate',
                  )
                }
                if (
                  !values.enabled_deadhead_accepting &&
                  !userIsMaklerPremium
                ) {
                  errors.enabled_deadhead_accepting = I18n.t(
                    'orderClaimDetailsPageTranslations.validation.enabledDeadheadAccepting',
                  )
                }
              }
              if (
                Number(values.status) ===
                ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING
              ) {
                errors.status = I18n.t(
                  'orderClaimDetailsPageTranslations.validation.status',
                )
              }
            }
            if (
              Number(values.status) === ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED
            ) {
              if (values.reject_reason === '') {
                errors.reject_reason = I18n.t(
                  'orderClaimDetailsPageTranslations.validation.rejectReason',
                )
              }
            }

            return errors
          }}
          onSubmit={values => {
            const valuesToSend: Partial<FormikValues> = values

            if (Number(claim.claim_type) === ORDER_CLAIM_TYPE.TYPE_DEADHEAD) {
              delete valuesToSend.waiting_time_date
              delete valuesToSend.effort_duration
              delete valuesToSend.enabled_waiting_time_accepting
              if (userIsMaklerPremium) {
                delete valuesToSend.enabled_deadhead_accepting
              }
            }

            if (
              Number(claim.claim_type) === ORDER_CLAIM_TYPE.TYPE_WAITING_TIME
            ) {
              delete valuesToSend.service_date
              delete valuesToSend.enabled_deadhead_accepting
            }

            if (
              Number(claim.claim_type) !== ORDER_CLAIM_TYPE.TYPE_WAITING_TIME &&
              Number(claim.claim_type) !== ORDER_CLAIM_TYPE.TYPE_DEADHEAD
            ) {
              delete valuesToSend.waiting_time_date
              delete valuesToSend.effort_duration
              delete valuesToSend.enabled_waiting_time_accepting
              delete valuesToSend.service_date
              delete valuesToSend.enabled_deadhead_accepting
            }

            if (
              Number(values.status) === ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED
            ) {
              delete valuesToSend.waiting_time_date
              delete valuesToSend.effort_duration
              delete valuesToSend.enabled_waiting_time_accepting
              delete valuesToSend.service_date
              delete valuesToSend.enabled_deadhead_accepting
            }

            updateReviewedOrderClaim({ ...valuesToSend, id: claim.id }, history)
          }}
        >
          {formikProps => (
            <>
              <DocumentReview
                location={location}
                breadcrumb={{
                  breadcrumbTitle: isReview
                    ? I18n.t(
                        'orderClaimDetailsPageTranslations.breadcrumb.title.review',
                      )
                    : I18n.t(
                        'orderClaimDetailsPageTranslations.breadcrumb.title.details',
                      ),
                  prevLinkTitle: userIsMaklerPremium
                    ? I18n.t(
                        'orderClaimDetailsPageTranslations.breadcrumb.prevLinkTitleMaklerPremium',
                      )
                    : I18n.t(
                        'orderClaimDetailsPageTranslations.breadcrumb.prevLinkTitleEmpto',
                      ),
                  prevLinkTo: userIsMaklerPremium
                    ? '/workload'
                    : '/claim-proof/all',
                  teaserText: '',
                  teaserTitle: '',
                }}
                pageTitle={I18n.t('pageTitles.claimProofDetails')}
                documentTitle={
                  isReview
                    ? I18n.t(
                        'orderClaimDetailsPageTranslations.pageTitle.review',
                        { number: claim.order },
                      )
                    : I18n.t(
                        'orderClaimDetailsPageTranslations.pageTitle.details',
                        { number: claim.order },
                      )
                }
                formSubmissionButton={{
                  buttonText:
                    isReview &&
                    Number(formikProps.values.status) ===
                      ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING
                      ? I18n.t(
                          'orderClaimDetailsPageTranslations.submitButtonText.isSaving',
                        )
                      : I18n.t(
                          'orderClaimDetailsPageTranslations.submitButtonText.isReviewing',
                        ),
                  action:
                    isReview &&
                    Number(formikProps.values.status) ===
                      ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING
                      ? () => setDisplayModal(!displayModal)
                      : formikProps.handleSubmit,
                  isLoading:
                    isReview &&
                    Number(formikProps.values.status) ===
                      ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING
                      ? isSaving
                      : formikProps.isSubmitting && isLoading,
                  isDisabled:
                    isReview &&
                    Number(formikProps.values.status) ===
                      ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING
                      ? isSaving
                      : (formikProps.submitCount > 0 && !formikProps.isValid) ||
                        (formikProps.isSubmitting && isLoading),
                }}
                showFormSubmitButton={isReview}
                showDocumentDetails
                showOrderDetails
                showOrderClaimDetails
                reviewDocument={claim}
              >
                <Form
                  className='uk-panel order-claim-review-form'
                  data-testid='order-claim-review-form'
                  noValidate
                >
                  <OrderClaimReview
                    claim={claim}
                    isReview={isReview}
                    formikProps={formikProps}
                  />
                </Form>
              </DocumentReview>

              <SaveInternalCommentModal
                openModal={displayModal}
                handleSaveInternalNote={() =>
                  handleSaveInternalNote(formikProps.values.internal_note)
                }
                handleModalToggle={() => setDisplayModal(!displayModal)}
              />
            </>
          )}
        </Formik>
      </div>
    </RequiredPermissions>
  )
}

export const OrderClaimProofDetailsPage = connector(
  withRouter(
    withErrorBoundary(
      withApiErrorHandling(OrderClaimProofDetailsPageComponent),
    ),
  ) as any,
)
