import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCompany, resetCustomInvoiceCompanies } from 'actions/company'
import { DropDownInput } from 'components/common/DropDownInput'
import {
  FormStepSummaryRow,
  GoToNextFormStepButton,
} from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { getCompanySelector } from 'selectors/company'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import {
  DSZAddInvoiceFormContext,
  DSZAddInvoiceFormValues,
} from '../DSZAddInvoiceFormSteps'

export const DSZInvoiceReceiverForm: FC = () => {
  const { errors, handleChange, setValues, setFieldValue, values } =
    useFormikContext<DSZAddInvoiceFormValues>()

  const dispatch = useDispatch()
  const company = useSelector(getCompanySelector)

  const { stepStatus, saveStep, isInvoice, toggleIsInvoice } = useContext(
    DSZAddInvoiceFormContext,
  )

  useEffect(() => {
    setFieldValue('selected_company', company)
    setFieldValue('company_name', company.name)
    setFieldValue('dsz_external_number', company.dsz_external_number)
    setFieldValue('invoice_address', company.main_address_object)
  }, [company, setFieldValue])

  const searchFields = [
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.dsz.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.dsz.steps.1.fields.dsz_external_number.label',
      ),
      optionValue: 'dsz_external_number',
    },
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.dsz.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  return (
    <>
      {/*SELECT SEARCHFIELD*/}
      <DropDownInput
        choices={searchFields}
        label={I18n.t(
          'addInvoicePageTranslations.dsz.steps.1.fields.search_field.label',
        )}
        name='search_field'
        onChange={e => {
          setValues(
            {
              ...values,
              company_name: '',
              dsz_external_number: undefined,
              zipcode: undefined,
              selected_company: undefined,
            },
            false,
          )
          dispatch(resetCustomInvoiceCompanies()) // Reset filtered results
          handleChange(e)
        }}
        showCheckmark
        value={values.search_field}
        withCheckmark
      />

      {/*SEARCH*/}
      {/*prettier-ignore*/}
      <Select
        urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/custom-invoice-companies/?${values.search_field}=`}
        urlPart2='&page_size=1000'
        getValue={(e) => e.id}
        getLabel={(e) => e.id === 0 ? e.name : `${e.empto_external_number}, ${e.name}, ${e.zipcode} ${e.location}`}
        label={I18n.t(`addInvoicePageTranslations.dsz.steps.1.fields.${values.search_field}.label`)}
        setValue={e => {
          if (e?.value) {
            dispatch(getCompany({ id: e?.value }))
            if (!isInvoice) {toggleIsInvoice()}}
          else {setFieldValue('selected_company', undefined);}
        }}
        selectedValue={values.selected_company?.id}
        selecting={'company'}
        error={errors.selected_company}
      />

      {/*SUMMARY FIELDS*/}
      <div className='add-invoice-step-summary'>
        {values.selected_company && (
          <>
            <div className='add-invoice-step-summary_grid-body'>
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.dsz.steps.1.fields.dsz_external_number.label',
                )}
                value={values.dsz_external_number}
              />
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.dsz.steps.1.fields.company_name.label',
                )}
                value={values.company_name}
              />
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.dsz.steps.1.fields.invoice_address.label',
                )}
                value={`${values.invoice_address?.display_name_dsz}`}
              />
              {values.selected_company?.tax_id && (
                <FormStepSummaryRow
                  label={I18n.t(
                    'addInvoicePageTranslations.dsz.steps.1.fields.tax_id.label',
                  )}
                  value={values.selected_company.tax_id}
                />
              )}
            </div>
          </>
        )}
      </div>
      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={!values?.selected_company}
      />
    </>
  )
}
