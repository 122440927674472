import './style.scss'

import { arrayOf, bool, func, shape } from 'prop-types'
import React from 'react'

import { AttachmentScheme } from 'components/common/SingleFileUpload/schemes'
import { get } from 'helper/general'
import FileThumbnail from 'components/common/SingleFileUpload/components/FileThumbnail'

/**
 * @description This component displays files.
 */
const FilesPreviewerComponent = ({
  files,
  selectedFile,
  onClick,
  withFilenameTooltip,
}) => {
  return (
    <div className='files-previewer'>
      <ul className='uk-thumbnav'>
        {files.map(file => (
          <li
            key={file.id}
            className={`uk-thumbnav-item ${
              file.id === get(() => selectedFile.id) ? 'uk-active' : ''
            }`}
            data-uk-tooltip={withFilenameTooltip ? file.name : undefined}
          >
            <button
              type='button'
              className='uk-button'
              onClick={() => onClick(file)}
            >
              <FileThumbnail file={file} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

FilesPreviewerComponent.propTypes = {
  files: arrayOf(shape(AttachmentScheme)),
  selectedFile: shape(AttachmentScheme),
  onClick: func,
  withFilenameTooltip: bool,
}

FilesPreviewerComponent.defaultProps = {
  files: [],
  selectedFile: null,
  onClick: () => undefined,
  withFilenameTooltip: false,
}

export default FilesPreviewerComponent
