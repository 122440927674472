import ActionTypes from '../actiontypes/inquiry'

// This is the structure of the store
export const initialState = {
  avv: [],
  response: null, // Response from API in case it is not the item
}

/**
 * @description A reducer for avv.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const avvReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_AVVS_SUCCESS:
      return {
        ...state,
        avv: action.payload,
      }
    default:
      return state
  }
}

export default avvReducer
