import classNames from 'classnames'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { Errors } from 'react-redux-form'

import Icon from 'components/common/Fontello'
import ControlEditToggle from 'components/common/Form/components/ControlEditToggle'
import CheckboxControl from 'components/common/Form/components/CheckboxControl'
import SelectControl from 'components/common/Form/components/SelectControl'
import TextboxControl from 'components/common/Form/components/TextboxControl'
import { SectionHeader } from 'components/common/SectionHeader'
import {
  BILLING_INTERVAL,
  COLLECTIVE_INVOICING_MODES,
  COMPANY_ROLE,
  DISCOUNT_CHOICES,
  PAYMENT_METHODS,
} from 'components/company/constants'
import { getName } from 'helper/translations'
import { getCompanyFormSelector, getCompanySelector } from 'selectors/company'

import { CompanyFormValidators } from '../../constants'

export const PaymentMethodComponent: FC = () => {
  const company = useSelector(getCompanySelector)
  const companyForm = useSelector(getCompanyFormSelector)

  return (
    <section className='uk-card uk-padding-remove payment-details'>
      <SectionHeader>
        <Icon name='checklist' />
        <Translate value='companyProfilePage.paymentMethod' />
      </SectionHeader>

      <div className='uk-padding details-container'>
        <div className='uk-grid uk-child-width-1-1 uk-child-width-1-3@m'>
          <ControlEditToggle
            className={classNames({
              'uk-margin-medium-bottom':
                company.billing_interval === BILLING_INTERVAL.MONTH,
              'uk-margin-small-bottom':
                company.billing_interval === BILLING_INTERVAL.ORDER,
            })}
            label={I18n.t('companyProfilePage.label.paymentMethod')}
            value={getName(company.payment_method, PAYMENT_METHODS, true)}
            errorClass={
              !companyForm.payment_method.valid ? 'uk-text-danger' : null
            }
            triggerEdit={!companyForm.payment_method.valid}
          >
            <SelectControl
              className='uk-select'
              field={companyForm.payment_method}
              options={PAYMENT_METHODS.map(item => ({
                id: item.id,
                label: `${I18n.t(item.name)}`,
              }))}
              validators={CompanyFormValidators.payment_method}
              label={I18n.t('companyProfilePage.label.paymentMethod')}
              placeholder={I18n.t(
                'companyProfilePage.placeholder.paymentMethod',
              )}
              actions={[]}
            />
          </ControlEditToggle>
          {company.makler_premium_company &&
            company.role === COMPANY_ROLE.WASTE_PRODUCER && (
              <>
                <ControlEditToggle
                  className={classNames({
                    'uk-margin-medium-bottom':
                      company.billing_interval === BILLING_INTERVAL.MONTH,
                    'uk-margin-small-bottom':
                      company.billing_interval === BILLING_INTERVAL.ORDER,
                  })}
                  label={I18n.t('companyProfilePage.label.permanentDiscount')}
                  value={`${company.permanent_discount} %`}
                  errorClass={
                    !companyForm.permanent_discount.valid
                      ? 'uk-text-danger'
                      : null
                  }
                  triggerEdit={!companyForm.permanent_discount.valid}
                  editable
                >
                  <SelectControl
                    className='uk-select'
                    field={companyForm.permanent_discount}
                    options={DISCOUNT_CHOICES.map(item => ({
                      id: item,
                      label: `${item} %`,
                    }))}
                    validators={CompanyFormValidators.permanent_discount}
                    label={I18n.t('companyProfilePage.label.permanentDiscount')}
                    placeholder={I18n.t(
                      'companyProfilePage.placeholder.permanentDiscount',
                    )}
                    actions={[]}
                  />
                </ControlEditToggle>
                {company.billing_interval === BILLING_INTERVAL.MONTH && (
                  <>
                    <ControlEditToggle
                      className='uk-margin-medium-bottom'
                      label={I18n.t(
                        'companyProfilePage.label.collectiveInvoicingAutoSendEmail',
                      )}
                      value={I18n.t(
                        `general.${
                          company.collective_invoicing_auto_send_email
                            ? 'yes'
                            : 'no'
                        }`,
                      )}
                      errorClass={
                        !companyForm.collective_invoicing_auto_send_email.valid
                          ? 'uk-text-danger'
                          : null
                      }
                      triggerEdit={
                        !companyForm.collective_invoicing_auto_send_email.valid
                      }
                      editable
                    >
                      <CheckboxControl
                        className='uk-checkbox'
                        field={companyForm.collective_invoicing_auto_send_email}
                        label={I18n.t(
                          'companyProfilePage.label.collectiveInvoicingAutoSendEmail',
                        )}
                        actions={[]}
                      />
                    </ControlEditToggle>
                    <ControlEditToggle
                      className='uk-margin-small-bottom'
                      label={I18n.t(
                        'companyProfilePage.label.collectiveInvoicingMode',
                      )}
                      value={getName(
                        company.collective_invoicing_mode,
                        COLLECTIVE_INVOICING_MODES,
                        true,
                      )}
                      errorClass={
                        !companyForm.collective_invoicing_mode.valid
                          ? 'uk-text-danger'
                          : null
                      }
                      triggerEdit={!companyForm.collective_invoicing_mode.valid}
                      editable
                    >
                      <SelectControl
                        className='uk-select'
                        field={companyForm.collective_invoicing_mode}
                        validators={
                          CompanyFormValidators.collective_invoicing_mode
                        }
                        label={I18n.t(
                          'companyProfilePage.label.collectiveInvoicingMode',
                        )}
                        placeholder={I18n.t(
                          'companyProfilePage.placeholder.collectiveInvoicingMode',
                        )}
                        options={COLLECTIVE_INVOICING_MODES.map(mode => ({
                          id: mode.id,
                          label: I18n.t(mode.name),
                        }))}
                        actions={[]}
                      />
                    </ControlEditToggle>
                  </>
                )}
              </>
            )}
          {company.makler_premium_company &&
            company.role === COMPANY_ROLE.WASTE_COMPANY && (
              <>
                <ControlEditToggle
                  className='uk-margin-small-bottom'
                  label={I18n.t('companyProfilePage.label.iban')}
                  value={company.iban}
                  errorClass={!companyForm.iban.valid ? 'uk-text-danger' : null}
                  triggerEdit={!companyForm.iban.valid}
                  editable
                >
                  <TextboxControl
                    className='uk-input'
                    field={companyForm.iban}
                    placeholder={I18n.t('companyProfilePage.placeholder.iban')}
                    validators={CompanyFormValidators.iban}
                    maxLength={50}
                    actions={[]}
                  />
                </ControlEditToggle>

                <ControlEditToggle
                  className='uk-margin-small-bottom'
                  label={I18n.t('companyProfilePage.label.bic')}
                  value={company.bic}
                  errorClass={!companyForm.bic.valid ? 'uk-text-danger' : null}
                  triggerEdit={!companyForm.bic.valid}
                  editable
                >
                  <TextboxControl
                    className='uk-input'
                    field={companyForm.bic}
                    placeholder={I18n.t('companyProfilePage.placeholder.bic')}
                    validators={CompanyFormValidators.bic}
                    maxLength={50}
                    actions={[]}
                  />
                </ControlEditToggle>
              </>
            )}
        </div>
        <Errors
          model='company.item'
          className='form-error'
          messages={{
            bic: I18n.t('companyProfilePage.validation.bic'),
            iban: I18n.t('companyProfilePage.validation.iban'),
            paymentDataFilled: I18n.t(
              'companyProfilePage.validation.paymentDataFilled',
            ),
          }}
        />
      </div>
    </section>
  )
}

export default PaymentMethodComponent
