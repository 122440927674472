import classNames from 'classnames'
import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { disposeOrder } from 'actions/order'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import InputDate from 'components/common/InputDate/index'
import { ProgressButton } from 'components/common/ProgressButton'
import { OFFER_TIMES_OF_DAY } from 'components/inquiry/constants'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

interface OrderDisposeFormProps {
  order: Order
  isOnModal?: boolean
  onCancel?: () => void
}

/**
 * Renders the OrderDisposeForm
 * @constructor
 */
export const OrderDisposeForm: FC<OrderDisposeFormProps> = ({
  isOnModal = false,
  onCancel = () => undefined,
  order,
}) => {
  const disposeErrorSelector = createErrorSelector(['DISPOSE_ORDER'])
  const disposeLoadingSelector = createLoadingSelector(['DISPOSE_ORDER'])
  const dispatch = useDispatch()
  const submitDisposeOrder = data => dispatch(disposeOrder(data))
  const isLoading = useSelector(state => disposeLoadingSelector(state))
  const apiError = useSelector(state => disposeErrorSelector(state))

  /**
   * Method to get the correct delivery day of dispose
   * @param values
   */
  const getExecutionDate = values =>
    values.delivery_date_disposed
      ? values.delivery_date_disposed
      : values.delivery_date

  /**
   * Method to get the correct time of the delivery day of dispose
   * @param values
   */
  const getTimeOfDay = values =>
    values.time_of_day_delivery_disposed
      ? values.time_of_day_delivery_disposed
      : values.time_of_day_delivery

  return (
    <Formik
      initialValues={{
        delivery_date_disposed: order.delivery_date,
        time_of_day_delivery_disposed: order.time_of_day_delivery,
      }}
      validationSchema={() =>
        yup.object().shape({
          delivery_date_disposed: yup
            .string()
            .typeError('${value} ist nicht vom Typ ${type}'), // eslint-disable-line no-template-curly-in-string
          time_of_day_delivery_disposed: yup
            .string()
            .typeError('${value} ist nicht vom Typ ${type}'), // eslint-disable-line no-template-curly-in-string
        })
      }
      validate={() => {
        const errors: {
          delivery_date_disposed?: string
          time_of_day_delivery_disposed?: string
        } = {}
        return errors
      }}
      onSubmit={values => {
        const data = {
          ...order,
          delivery_date_disposed: moment(getExecutionDate(values)).format('L'),
          time_of_day_delivery_disposed: getTimeOfDay(values),
        }
        submitDisposeOrder(data)
        onCancel()
      }}
    >
      {({
        handleChange,
        handleBlur,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        submitCount,
        values,
        touched,
        errors,
      }) => (
        <div
          className={classNames(
            { 'uk-modal-body': isOnModal },
            { 'order-status-task--on-order-details': !isOnModal },
          )}
        >
          <Form
            className='order-status-task'
            data-testid='order-status-task__order-dispose-form'
            noValidate // Disable browser validation
          >
            {!isOnModal && (
              <h4>
                <Translate
                  value={
                    order.is_deduction
                      ? 'orderStatusTasks.dispose.finalDeliveryHeadline'
                      : 'orderStatusTasks.dispose.headline'
                  }
                />
              </h4>
            )}
            {isOnModal && (
              <div className='order-status-task__text'>
                {isOnModal && <Translate value='orderDispose.text' />}
              </div>
            )}

            {!isOnModal && <ApiValidationMessages error={apiError} />}

            <div className='order-status-task__fields order-status-task__fields--side-by-side'>
              <InputDate
                dataTestId='execution-date-input'
                label={I18n.t('orderDispose.executionDate')}
                minDate={moment()}
                name='delivery_date_disposed'
                onChange={value => {
                  setFieldValue('delivery_date_disposed', value.format('L'))
                  setFieldTouched('delivery_date_disposed')
                }}
                placeholder={I18n.t('orderDispose.executionDate')}
                value={moment(getExecutionDate(values))}
                error={
                  submitCount > 0 && touched.delivery_date_disposed
                    ? errors.delivery_date_disposed
                    : ''
                }
              />
              <DropDownInput
                dataTestId='time-of-day-delivery-input'
                choices={OFFER_TIMES_OF_DAY.map(item => ({
                  optionValue: item.id,
                  optionLabel: I18n.t(item.name),
                }))}
                label={I18n.t('general.timeOfDay')}
                name='time_of_day_delivery_disposed'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t('general.timeOfDay')}
                value={getTimeOfDay(values)}
              />
            </div>
            <div
              className={classNames({
                'uk-modal-footer uk-text-right uk-padding-remove-right':
                  isOnModal,
              })}
            >
              {isOnModal && (
                <span className='uk-margin-right'>
                  <Button
                    backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                    onClick={onCancel}
                  >
                    {I18n.t('general.button.cancel')}
                  </Button>
                </span>
              )}
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='inform-waste-disposer-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                }
                isLoading={isSubmitting && isLoading}
                type={BUTTON_TYPE.SUBMIT}
                onClick={() => undefined}
              >
                {I18n.t(
                  `orderDispose.${
                    order.is_deduction
                      ? 'finalDeliverySubmitButton'
                      : 'submitButton'
                  }`,
                )}
              </ProgressButton>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
