const offerApiTranslations = {
  handleAutoDisposerChange: {
    error: 'Aufrag konnte leider nicht storniert werden',
  },
  handleCreateOffer: {
    success: 'Angebot wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen des Angebots leider fehlgeschlagen.',
  },
  handleGetOffers: {
    error: 'Angebote konnten leider nicht geladen werden.',
  },
  handleGetMyOffers: {
    error: 'Angebote konnten leider nicht geladen werden.',
  },
  handleGetAcceptedOffers: {
    error: 'Angebote konnten leider nicht geladen werden.',
  },
  handleGetOffer: {
    error: 'Angebot konnte leider nicht geladen werden.',
  },
  handleCancelOffer: {
    success: 'Angebot wurde erfolgreich storniert.',
    error: 'Stornieren des Angebots leider fehlgeschlagen.',
  },
  handleResendOffer: {
    success: 'Angebot wurde erfolgreich versendet.',
    error: 'Versenden des Angebots fehlgeschlagen.',
  },
  handleAcceptOffer: {
    success: 'Angebot wurde erfolgreich angenommen.',
    error: 'Annehmen des Angebots leider fehlgeschlagen.',
  },
  handleExportMyOffers: {
    success: 'Der Export der Angebote wurde erfolgreich gestartet.',
    error: 'Exportieren der Angebote leider fehlgeschlagen.',
  },
  handleExportAcceptedOffers: {
    success: 'Der Export der Vereinbarungen wurde erfolgreich gestartet.',
    error: 'Exportieren der Vereinbarungen leider fehlgeschlagen.',
  },
  handleExportRent: {
    success: 'Der Export der Mieten wurde erfolgreich gestartet.',
    error: 'Exportieren der Mieten leider fehlgeschlagen.',
  },
  handlePostponeIntervalOffer: {
    success: 'Die Wochentage wurden geändert.',
    error: 'Die Wochentage konnten nicht geändert werden.',
  },
  handleChangeIntervalOffer: {
    success: 'Der Rhythmus wurde geändert.',
    error: 'Der Rhythmus konnten nicht geändert werden.',
  },
  handleChangeDisposerOfferRequest: {
    success: 'Vereinbarung erfolgreich geändert.',
    error: 'Änderung der Vereinbarung leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleChangeAgreementPeriodForStandingOffers: {
    success: 'Vereinbarungszeitraum erfolgreich geändert.',
    error: 'Änderung des Vereinbarungszeitraums leider fehlgeschlagen.',
    INVALID_DATE_FORMAT: 'Kein gültiges Datumsformat',
    NOT_ON_DEMAND:
      'Es kann nur der Vereinbarungszeitraum von Daueraufträgen auf Abruf verändert werden',
    NOT_EDITABLE:
      'Die Vereinbarung muss aktiv sein oder der Vereinbarungsbeginn in der Zukunft liegen',
    PAST_NOT_EDITABLE:
      'Daten, die in der Vergangenheit liegen, können nicht verändert werden',
    NO_PAST_DATES: 'Die veränderten Daten müssen in der Zukunft liegen',
    MAX_ONE_YEAR:
      'Das Vereinbarungsende kann auf max. 1 Jahr nach Vereinbarungsbeginn gesetzt werden',
    successfullyCancelledOrders:
      'Folgende Aufträge wurden erfolgreich storniert: %{orderIDs}',
    successfullyCreatedOrders:
      'Folgende Aufträge wurden erfolgreich erstellt: %{orderIDs}',
    successfulButNoOrdersCreated: 'Es wurden keine Aufträge erstellt',
  },
}

export default offerApiTranslations
