import './style.scss'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import {
  getCompany,
  resetActiveCompanies,
  resetAgreementCompany,
} from 'actions/company'
import { DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { getCompanySelector } from 'selectors/company'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'

import {
  addCompanyToState,
  getChoices,
  getCompanyRole,
  getCompanyRoleKey,
  getErrors,
  getValues,
  ROLES,
} from './helpers'

export const SelectCustomerAndPartnerForm: FC = () => {
  const dispatch = useDispatch()

  const { errors, setFieldValue, values } =
    useFormikContext<AddAgreementFormValues>()

  const { stepStatus, saveStep } = useContext(AddAgreementFormContext)

  const company = useSelector(getCompanySelector)

  useEffect(() => {
    if (!company.id) return

    const roleKey = getCompanyRoleKey(Number(company.role))

    // Formik bug workaround: setFieldValue validation doesnt work as expected
    // selected_company is the only validated one, the others
    // make selected_company empty in values during validation
    setFieldValue(`${roleKey}.selected_company`, company, true)
    setFieldValue(`${roleKey}.company_name`, company?.name, false)
    setFieldValue(
      `${roleKey}.empto_external_number`,
      company?.empto_external_number,
      false,
    )

    addCompanyToState(dispatch, company)
  }, [company, dispatch, setFieldValue])

  return (
    <>
      {ROLES.map((role, index) => {
        const { searchField, onlyActiveCompanies } = getValues(role, values)
        const { selectedCompanyError } = getErrors(role, errors)
        const activeFilter = onlyActiveCompanies ? '&is_active=true' : ''

        return (
          <div key={role}>
            <p
              className={classNames('role-section-headline', {
                'role-section-headline__smaller-height': index === 0,
              })}
            >
              {I18n.t(
                `addAgreementPageTranslations.steps.1.fields.${role}_title`,
              )}
            </p>

            {/*SELECT SEARCH_FIELD*/}
            <>
              <DropDownInput
                choices={getChoices(role)}
                label={I18n.t(
                  `addAgreementPageTranslations.steps.1.fields.${role}_search_field.label`,
                )}
                name={`${role}_search_field`}
                onChange={e => {
                  // Reset other fields
                  setFieldValue(`${role}.company_name`, '')
                  setFieldValue(`${role}.empto_external_number`, undefined)
                  setFieldValue(`${role}.zipcode`, undefined)
                  setFieldValue(`${role}.selected_company`, undefined)

                  // Set search field value
                  setFieldValue(`${role}.search_field`, e.target.value)

                  // Reset filtered results
                  dispatch(resetActiveCompanies())
                  dispatch(resetAgreementCompany(role))
                }}
                showCheckmark
                value={searchField}
                withCheckmark
              />
            </>

            {/* prettier-ignore */}
            <Select
              urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/makler-premium-companies/?${searchField}=`}
              urlPart2={`&maklerpremium_role=${getCompanyRole(role)}&page_size=600&block_auto_offers=false${activeFilter}&page=1`}
              getValue={(e) => e.id}
              getLabel={(e) => `${e.empto_external_number}, ${e.name}, ${e.main_address_object?.zipcode} ${e.main_address_object?.location}`}
              label={
                I18n.t(`addAgreementPageTranslations.steps.1.fields.${role}.${searchField}.label`) +
                (values[`${role}`]?.company_name ? ` (${values[`${role}`].company_name})` : '')
              }
              error={selectedCompanyError}
              setValue={e => {
                if   (!e?.value) {setFieldValue('company', '')}
                else {
                  setFieldValue(`${role}_company`, e.value)
                  dispatch(getCompany({id: e.value}))
                }
              }}
              selectedValue={values[`${role}_company`] ? values[`${role}_company`] : null}
              selecting={'company'}
              withCheckmark
            >
            </Select>
          </div>
        )
      })}

      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={
          !(
            values?.customer.selected_company &&
            values?.partner.selected_company &&
            !errors.customer?.selected_company
          )
        }
      />
    </>
  )
}
