import { arrayOf, bool, func, shape } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { checkRequiredPermissions } from 'helper/permissions'
import { get } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { CertificateScheme } from 'schemes/certificate'
import { PaginationScheme } from 'schemes/pagination'
import { UserScheme } from 'schemes/user'

import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import CertificateFilter from '../../common/Filter/components/CertificateFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import PageHeader from '../../layout/PageHeader'
import { PROOF_TYPE } from '../../proofs/constants'
import { ProofsList } from '../../proofs/ProofsList'

import connector from './connector'

/**
 * @description This component displays a list of the archived (non-pending) certificates of all companies
 * @function
 * @param {Object} props the component props
 */
export const CertificatesPageComponent = ({
  getCertificates,
  exportCertificates,
  pagination = {},
  user = null,
  isLoading = {
    certificates: false,
    exportCertificates: false,
  },
  certificates = [],
}) => {
  const [currentFilterValues, setCurrentFilterValues] = useState({})
  const [sortedBy, setSortedBy] = useState([])

  useEffect(() => {
    getCertificates()
  }, [getCertificates])

  const getSortingValues = state => {
    if (state.sorted.length) {
      getCertificates(null, currentFilterValues, state.sorted)
      setSortedBy(state.sorted)
    }
  }

  const handleExport = () => {
    exportCertificates()
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.certificates')}</title>
      </Helmet>

      <div className='certificates-archive-page'>
        <PageHeader title={I18n.t('general.certificates')}>
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <Media
              key='media-extag'
              query={{ minWidth: BREAKPOINT.XLARGE }}
              render={() => (
                <RequiredPermissions
                  requiredPermissions={[UserPermission.EXPORT_CERTIFICATES]}
                >
                  <IconButton
                    iconName='export'
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={handleExport}
                    isDisabled={isLoading.exportCertificates}
                    isLoading={isLoading.exportCertificates}
                  >
                    <Translate value='general.export' />
                  </IconButton>
                </RequiredPermissions>
              )}
            />
          </ButtonBar>
        </PageHeader>

        <Filter isLoading={isLoading.certificates} length={certificates.length}>
          <CertificateFilter
            getCurrentFilterValues={values => setCurrentFilterValues(values)}
            showCustomerNumber
          />
        </Filter>

        <ProofsList
          proofsType={PROOF_TYPE.CERTIFICATE}
          proofsList={certificates}
          isLoading={isLoading.certificates}
          isReviewButtonVisible={checkRequiredPermissions(
            get(() => user.permission_codenames),
            [UserPermission.REVIEW_CERTIFICATE],
          )}
          isReviewerColumnVisible
          onFetchData={getSortingValues}
          onNextPageClick={() =>
            getCertificates(pagination.next, currentFilterValues, sortedBy)
          }
          onPreviousPageClick={() =>
            getCertificates(pagination.previous, currentFilterValues, sortedBy)
          }
          page={pagination.current}
          pages={pagination.count}
        />
      </div>
    </>
  )
}

CertificatesPageComponent.propTypes = {
  getCertificates: func.isRequired,
  exportCertificates: func.isRequired,
  pagination: shape(PaginationScheme),
  user: shape(UserScheme),
  isLoading: shape({
    certificates: bool,
    exportCertificates: bool,
  }),
  certificates: arrayOf(shape(CertificateScheme)),
}
export default compose(withErrorBoundary, connector)(CertificatesPageComponent)
