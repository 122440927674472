import './style.scss'

import classNames from 'classnames'
import { Location } from 'history'
import React, { FC, ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Media from 'react-media'

import { BREAKPOINT } from 'constants/design'

import { BreadcrumbTeaser } from '../../common/Breadcrumb'
import { BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { ProgressButton } from '../../common/ProgressButton'
import PageHeader from '../../layout/PageHeader'
import { BackButton } from '../BackButton'

import { CompanyDetails } from './CompanyDetails'
import { connector } from './connector'
import { DocumentDetails } from './DocumentDetails'
import { OrderClaimDetails } from './OrderClaimReview/OrderClaimDetails'
import { OrderDetails } from './OrderDetails'

interface DocumentReviewComponentProps {
  breadcrumb: {
    breadcrumbTitle: string
    prevLinkTitle: string
    prevLinkTo: string
    teaserText: string
    teaserTitle: string
  }
  pageTitle?: string
  documentTitle?: string
  formSubmissionButton: {
    buttonText?: string
    action?: () => void
    isLoading?: boolean
    isDisabled?: boolean
  }
  showFormSubmitButton?: boolean
  showOrderClaimDetails?: boolean
  showDocumentDetails?: boolean
  showCompanyDetails?: boolean
  showOrderDetails?: boolean
  reviewDocument: any
  children: ReactNode
  location: Location
  fractionList: Fraction[]
  containerList: Container[]
  getFractions: () => void
  getContainers: () => void
}

const DocumentReviewComponent: FC<DocumentReviewComponentProps> = ({
  children,
  getFractions,
  getContainers,
  fractionList,
  containerList,
  breadcrumb = {
    breadcrumbTitle: '',
    prevLinkTitle: '',
    prevLinkTo: '',
    teaserText: '',
    teaserTitle: '',
  },
  pageTitle = '',
  documentTitle = '',
  formSubmissionButton = {
    buttonText: '',
    action: () => undefined,
    isLoading: false,
    isDisabled: false,
  },
  showFormSubmitButton = false,
  showOrderClaimDetails = false,
  showDocumentDetails = false,
  showCompanyDetails = false,
  showOrderDetails = false,
  reviewDocument = {
    attachments: [],
    company_object: null,
    order_object: null,
  },
}) => {
  useEffect(() => {
    if (fractionList.length < 1) getFractions()
  }, [fractionList.length, getFractions])

  useEffect(() => {
    if (containerList.length < 1) getContainers()
  }, [containerList.length, getContainers])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className='document-review-page'>
        <BreadcrumbTeaser
          breadcrumbTitle={breadcrumb.breadcrumbTitle}
          prevLinkTitle={breadcrumb.prevLinkTitle}
          prevLinkTo={breadcrumb.prevLinkTo}
          teaserText={breadcrumb.teaserText}
          teaserTitle={breadcrumb.teaserTitle}
        />

        <Media query={{ maxWidth: BREAKPOINT.SMALL - 1 }}>
          {isSmallScreen => (
            <PageHeader sticky title={documentTitle}>
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                {showFormSubmitButton && (
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='invite-user-form-submit'
                    isDisabled={formSubmissionButton.isDisabled}
                    isLoading={formSubmissionButton.isLoading}
                    onClick={formSubmissionButton.action as () => void}
                    type={BUTTON_TYPE.SUBMIT}
                  >
                    {formSubmissionButton.buttonText}
                  </ProgressButton>
                )}
                {!isSmallScreen && <BackButton />}
              </ButtonBar>
            </PageHeader>
          )}
        </Media>

        <div
          className={classNames('document-review-page__details', {
            'document-review-page__details--no-document':
              reviewDocument.attachments.length < 1,
          })}
        >
          {/* UPLOADED DOCUMENT FILES */}
          {showDocumentDetails && reviewDocument.attachments.length > 0 && (
            <DocumentDetails files={reviewDocument.attachments} />
          )}

          {/* OTHER DETAILS */}
          <div>
            {/* COMPANY DETAILS */}
            {showCompanyDetails && reviewDocument.company_object && (
              <CompanyDetails company={reviewDocument.company_object} />
            )}

            {/* ORDER DETAILS */}
            {showOrderDetails && reviewDocument.order_object && (
              <OrderDetails
                order={reviewDocument.order_object}
                fractionList={fractionList}
                containerList={containerList}
              />
            )}

            {/* CLAIM DETAILS */}
            {showOrderClaimDetails && (
              <OrderClaimDetails claim={reviewDocument} />
            )}

            {/* DOCUMENT DETAILS */}
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export const DocumentReview = connector(DocumentReviewComponent)
