export const dszApiTranslations = {
  handleGetDszInvoices: {
    success: 'DSZ Rechnungen erfolgreich geladen ',
    error: 'Fehler beim Laden der DSZ Rechnungen',
  },
  handleCreateCustomInvoice: {
    success: 'Beleg wurde erfolgreich erstellt.',
    error: 'Das Erstellen des Belegs ist leider fehlgeschlagen.',
  },
  handleCreateDszFractionPrice: {
    success: 'DSZ Preis wurde erfolgreich erstellt.',
    error: 'Die eingegebene Preiskombination existiert bereit.',
  },
  handleUpdateDszFractionPrice: {
    success: 'DSZ Preis wurde erfolgreich aktualisiert.',
    error: 'Fehler beim Aktualisieren der DSZ Preise.',
  },
  handleUpdateDszDocument: {
    success: 'DSZ Dokument erfolgreich aktualisiert',
    error: 'Fehler beim Aktualisieren des DSZ Dokuments',
  },
  handleTriggerMynatureExports: {
    success: 'Der mynature Export wurde erfolgreich gestartet.',
    error: 'Der mynature Export konnte nicht gestartet werden',
    exportEmpty:
      'Der mynature Export wurde nicht gestartet, weil zu der Jahreszahl keine Verträge existieren',
  },
  handleGetContracts: {
    success: 'Verträge erfolgreich geladen ',
    error: 'Fehler beim Laden der Verträge',
  },
  handleGetFractionPrices: {
    success: 'Preise erfolgreich geladen',
    error: 'Fehler beim Laden der Preise',
  },
}
