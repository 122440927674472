import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import {
  BUTTON_BACKGROUND_COLOR,
  ICON_POSITION,
  IconButton,
} from 'components/common/IconButton'
import {
  OFFER_ORDER_TYPE,
  OFFER_ORDER_TYPES,
} from 'components/inquiry/constants'
import { getFractionOrContainerDetailsById } from 'components/inquiry/helpers'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { decimalToGermanFormat } from 'helper/general'
import { getName } from 'helper/translations'

import { AgreementCardProps } from '../AgreementCard/AgreementCard'
import {
  CardContainer,
  CardDetailsList,
  CardDetailsListItem,
  CardDistinctArea,
  CardHeader,
  CardStatusHeader,
  CardSwatch,
} from '../Card'

export interface AgreementDashboardCardProps extends AgreementCardProps {
  openAgreementModal: (item: AcceptedOffer) => void
}

export const AgreementDashboardCard: FC<AgreementDashboardCardProps> = ({
  coarseFraction,
  fraction,
  containerList,
  item,
  openAgreementModal,
}) => {
  const { capacity: containerCapacity, name: containerName } =
    getFractionOrContainerDetailsById(item.container, containerList)

  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(item.container),
  )

  return (
    <CardContainer>
      <Link
        className='new-card__click-area'
        to={{
          pathname: `/agreement/${item.id}`,
          state: {
            backLinkTitle: I18n.t('menu.dashboard'),
            backLinkTo: '/dashboard',
          },
        }}
      >
        <CardHeader
          title={
            coarseFraction ? coarseFraction.name : (item.fraction as string)
          }
          subtitle={fraction ? fraction.name : item.fine_fraction}
        ></CardHeader>
        <CardSwatch
          image={coarseFraction ? coarseFraction.image : 'img/DSC_8812.jpg'}
          name={
            coarseFraction ? coarseFraction.name : (item.fraction as string)
          }
        />
        <CardDistinctArea>
          <CardStatusHeader
            title={I18n.t('agreementDashboardCard.agreement', {
              id: item.id,
            })}
            state={getName(item.order_type, OFFER_ORDER_TYPES, true)}
          />
        </CardDistinctArea>
        <CardDetailsList
          items={[
            <CardDetailsListItem key={ICON_NAME.LOCATION}>
              <Icon name={ICON_NAME.LOCATION} />
              {item.collection_address_object.display_name}
            </CardDetailsListItem>,
            <CardDetailsListItem key={ICON_NAME.CONTAINER_SKIP}>
              <Icon name={ICON_NAME.CONTAINER_SKIP} />
              <Translate
                value={
                  showQuantityInCubicMeters
                    ? 'newAgreementCard.details.containerCubicMeters'
                    : 'newAgreementCard.details.container'
                }
                capacity={containerCapacity}
                name={containerName}
                amount={
                  showQuantityInCubicMeters
                    ? decimalToGermanFormat(item.quantity_in_cubic_meters)
                    : item.number_of_containers
                }
                dangerousHTML
              />
            </CardDetailsListItem>,
            <CardDetailsListItem hasButton key={ICON_NAME.PLUS}>
              {Number(item.order_type) === OFFER_ORDER_TYPE.TYPE_ON_DEMAND && (
                <IconButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  onClick={(event: MouseEvent) => {
                    event.preventDefault()
                    openAgreementModal(item)
                  }}
                  iconName={ICON_NAME.PLUS}
                  iconPosition={ICON_POSITION.RIGHT}
                  fullWidth
                >
                  <Translate value='agreementDashboardCard.newOrderButton' />
                </IconButton>
              )}
            </CardDetailsListItem>,
          ]}
        />
      </Link>
    </CardContainer>
  )
}
