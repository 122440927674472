export const createMaklerPremiumOfferPageTranslations = {
  header: {
    title: 'Angebot erstellen',
    description:
      'Erstellen Sie für Ihren Anrufer einfach ein Angebot, indem Sie die Schritte des Formulars ' +
      'durchlaufen und alle notwendigen Fragen klären.',
  },
  steps: {
    1: {
      title: 'Kundeninformationen',
      radiopanel: {
        returningCustomer: 'Bestandskunde',
        newCustomer: 'Neukunde',
        resetText: 'Zurücksetzen',
      },
      fields: {
        search_field: {
          label: 'Suchfeld auswählen',
          placeholder: 'Suchfeld',
        },
        empto_external_number: {
          label: 'Kundennummer',
          noOptionsMessage: 'Kein Kunde mit dieser Kundennummer gefunden.',
          placeholder: 'Kundennummer',
          error: 'Ungültige oder leere Kundennummer',
        },
        company_name: {
          label: 'Name des Kunden',
          noOptionsMessage: 'Kein Kunde mit diesem Namen gefunden.',
          placeholder: 'Name',
          error: 'Ungültiger oder leerer Name',
        },
        zipcode: {
          label: 'PLZ',
          noOptionsMessage: 'Kein Kunde mit dieser PLZ gefunden.',
          placeholder: 'PLZ',
          error: 'Ungültige oder leere PLZ',
        },
        collection_address: {
          error: 'Ungültige oder leere Lieferadresse',
        },
        existing_customer: {
          error: 'Kunde ist gesperrt. Kein Angebot möglich.',
        },
        creditLimitInfo:
          '<strong>Achtung</strong>: ' +
          '%{companyName} hat %{noOfUnpaidInvoices} Rechnungen in Höhe von %{sumOfUnpaidInvoices} € ' +
          'noch nicht bezahlt.',
        activeAgreementsInfo:
          '<strong>Achtung</strong>: Diese Leistungsadresse verfügt über ',
        activeAgreementsInfoLink: '%{agreementCount} aktive Vereinbarungen',
        activeAgreementsDisposer: 'Entsorgungspartner: ',
      },
    },
    2: {
      title: 'Abfallart & Behälter',
      frameworkContractHint: {
        title: 'Hinweis',
        text: 'Der Kunde hat einen gültigen Rahmenvertrag',
      },
      fields: {
        discount: 'Rabatt',
        frameworkContractPrice: 'Konditionen gemäß Rahmenvertrag',
        endPrice: 'Endpreis (Brutto)',
        priceComparison: 'Preisvergleich',
        infobox: {
          title: 'Hinweis',
          text:
            'Bitte erkundigen Sie sich, was der Kunde vor hat, da eventuell eine Sortierung günstiger ist. ' +
            'Bitte beachten: Bei Gewerbekunden muss gemäß Gewerbeabfallverordnung dokumentiert werden, warum er ' +
            'den Abfall nicht getrennt bereitstellt.',
          buttonText: 'Erledigt',
        },
        container: {
          label: 'Behälter',
          noInputResults: 'Es konnte kein passender Behälter gefunden werden.',
          placeholder: 'Behälter',
          subLabel: '(Informationen zur Behälter erhalten Sie bei der Auswahl)',
          validation: 'Bitte wählen Sie einen Behälter aus.',
        },
        containerSize: {
          label: 'Behältergröße',
          noInputResults:
            'Es konnte keine passende Behältergröße gefunden werden.',
          placeholder: 'Behältergröße',
          validation: 'Bitte wählen Sie eine Behältergröße aus.',
        },
        fineFraction: {
          label: 'Feinfraktion',
          noInputResults:
            'Es konnte keine passende Feinfraktion gefunden werden.',
          placeholder: 'Bitte auswählen',
          subLabel: '(Wir empfehlen das Hochladen von Fotos Ihrer Abfälle)',
        },
        fraction: {
          label: 'Abfallart',
          noInputResults: 'Es konnte keine passende Abfallart gefunden werden.',
          placeholder: 'Abfallart',
          subLabel:
            '(Informationen zur Abfallart erhalten Sie bei der Auswahl)',
          validation: 'Bitte wählen Sie eine Abfallart aus.',
        },
        numberOfContainers: {
          label: 'Anzahl',
          summary: 'Anzahl',
        },
        quantityInCubicMeters: {
          label: 'Menge in m\u00B3',
          summary: 'Menge in m\u00B3',
        },
        securityGroup: {
          label: 'Schutzklasse und Sicherheitsstufe',
          placeholder: 'Schutzklasse und Sicherheitsstufe',
          validation:
            'Bitte wählen Sie eine Schutzklasse und Sicherheitsstufe aus.',
          noInputResults:
            'Es konnte keine passende Schutzklasse und Sicherheitsstufe gefunden werden.',
        },
        withTop: {
          summary: ', mit Deckel',
        },
        positionOnPublicGround: {
          label: 'Stellung des Behälters',
        },
      },
      pausedDisposersModal: {
        buttonLabel: 'Anzeige pausierter Entsorger',
        modalHeader: 'Anzeige pausierter Entsorger',
        noResults:
          'Es konnten keine pausierten Enstorger zur ausgewählten Kombination gefunden werden.',
      },
      radiopanel: {
        onPublicGround: 'Stellung auf öffentlichem Grund',
        onPrivateGround: 'Stellung auf privatem Grund',
      },
    },
    3: {
      title: 'Angebot generieren',
      radiopanel: {
        privateCustomer: 'Privatkunde',
        businessCustomer: 'Geschäftskunde',
      },
      frameworkContractWarning: {
        title: 'Achtung',
        text: 'Ausgewähltes Lieferdatum liegt nicht mehr im Rahmenvertrag',
      },
      fields: {
        runtimeOfInquiry: {
          label: 'Gültigkeit des Angebots',
          placeholder: 'Laufzeit des Angebots',
        },
        email_note: {
          label:
            'Inhaltliche Ergänzung (wird in E-Mails an Kunde und Partner angezeigt!)',
          placeholder: 'Erlaubt sind Buchstaben, Zahlen und Sonderzeichen',
        },
        company_name: {
          label: 'Name des Unternehmens',
          placeholder: 'Name des Unternehmens',
        },
        customer_email: {
          label: 'E-Mail-Adresse',
          placeholder: 'E-Mail-Adresse',
        },
        customer_telephone: {
          label: 'Telefonnummer',
          placeholder: 'Telefonnummer',
        },
        different_invoicing_address: {
          label: 'Rechnungsadresse weicht von Lieferadresse ab',
        },
        invoice_recipient: {
          label: 'Empfängerzusatz',
          placeholder: 'Empfängerzusatz',
        },
        invoice_company_name: {
          label: 'Rechnungsempfänger (Unternehmen oder Name der Person)',
          placeholder: 'Rechnungsempfänger (Unternehmen oder Name der Person)',
        },
        invalidEmailDomain: 'Bitte geben Sie eine andere E-Mail Adresse an.',
      },
    },
  },
  button: {
    optional: 'Unverbindliches Angebot erstellen',
    compulsory: 'Verbindliches Angebot erstellen',
  },
}
