import moment from 'moment'
import React, { FC, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Spinner from 'react-spinkit'

import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { DATE_FORMATS, TABLE_PAGE_SIZE_10 } from 'constants/app'
import {
  exportOfferPipeline,
  exportPostalStatistics,
  getOfferPipeline,
} from 'actions/maklerpremium'
import { getActiveCompaniesForFilterSelector } from 'selectors/company'
import { createErrorSelector } from 'selectors/error'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import {
  getOfferPipelinePaginationSelector,
  getOfferPipelineSelector,
} from 'selectors/maklerpremium'
import { getCurrentUserSelector } from 'selectors/user'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { CompanyAndIdFilter } from '../../common/Filter/components/CompanyAndIdFilter'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { PaginatedTable } from '../../common/PaginatedTable'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UpDownArrow } from '../../common/UpDownArrow'
import { COMPANY_ROLE } from '../../company/constants'
import { getFractionName } from '../../executionproof/helpers'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import PageHeader from '../../layout/PageHeader'

import { CustomOfferCreateModal } from './CustomOfferCreateModal/CustomOfferCreateModal'
import { CustomOfferRuntiemModal } from './CustomOfferRuntimeModal/CustomOfferRuntimeModal'

export const OfferPipelinePage: FC = () => {
  const dispatch = useDispatch()

  const customers = useSelector(getActiveCompaniesForFilterSelector)
  const fractionList = useSelector(getFractionsSelector)
  const user = useSelector(getCurrentUserSelector)

  const offers = useSelector(getOfferPipelineSelector)
  const pagination = useSelector(getOfferPipelinePaginationSelector)
  const isLoading = {
    getOfferPipeline: useSelector(
      createLoadingSelector(['GET_OFFER_PIPELINE']),
    ),
    getExportOfferPipeline: useSelector(
      createLoadingSelector(['EXPORT_OFFER_PIPELINE']),
    ),
    getPostalStatisticsExport: useSelector(
      createLoadingSelector(['EXPORT_POSTAL_STATISTICS']),
    ),
  }
  const errors = useSelector(createErrorSelector(['GET_OFFER_PIPELINE']))
  const totals = {
    count: pagination.total_offers_count,
    priceForecast: pagination.total_retail_price_forecast,
  }

  const defaultSorted = [{ id: 'issued_at', desc: false }]
  const [currentFilters, setCurrentFilters] = useState<any>({
    makler_premium_company: user.company,
    order_by: JSON.stringify(defaultSorted),
  })
  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth >= INITIAL_FILTER_OPEN_WIDTH,
  )

  const [customOfferCreateModalOpen, setCustomOfferCreateModalOpen] =
    useState(false)
  const [customOfferRuntimeModalOpen, setCustomOfferRuntimeModalOpen] =
    useState(false)
  const [customOfferID, setCustomOfferID] = useState<number>()

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [
    isPostalStatisticsExportModalOpen,
    setIsPostalStatisticsExportModalOpen,
  ] = useState(false)

  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = () => {
    let filters = {
      ...currentFilters,
      company__makler_premium_company: user.company,
    }
    delete filters.order_by

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
      if (filter === 'id' && currentFilters[filter] === '') {
        delete filters.id
      }
      if (filter === 'company') {
        filters['company_id'] = currentFilters[filter]
        delete filters.company
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        created_at__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        created_at__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.created_at__gte
    delete filters.created_at__lte
    delete filters.company__makler_premium_company
    delete filters.makler_premium_company

    return filters
  }

  const columns = [
    {
      id: 'custom_offer_number',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.offerNumber' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'custom_offer_number',
      maxWidth: 180,
      Cell: data => {
        if (data?.original?.attachment_url) {
          return (
            <Link
              to={{ pathname: data.original.attachment_url }}
              onClick={event => {
                event.preventDefault()
                window.open(data.original.attachment_url)
              }}
            >
              {data.value}
            </Link>
          )
        }
        return <Link to={`/offer/${data.value}`}>{data.value}</Link>
      },
    },
    {
      id: 'issued_at',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.createdAt' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'issued_at',
      maxWidth: 100,
      Cell: data => moment(data.value, DATE_FORMATS).format('L'),
    },
    {
      id: 'company_name',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.customer' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'company_object',
      minWidth: 250,
      Cell: (data: { value: CompanySmall }) => (
        <Link to={`/company/${data.value.id}`}>{data.value.name}</Link>
      ),
    },
    {
      id: 'collection_address_zipcode',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.zipcode' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'collection_address_zipcode',
    },
    {
      id: 'collection_address_location',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.location' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'collection_address_location',
    },
    {
      id: 'fraction_name',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.fraction' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'fraction_name',
      Cell: data => {
        const fractionName = getFractionName(data.value, fractionList)
        return <span>{fractionName !== '--' ? fractionName : data.value}</span>
      },
    },
    {
      id: 'forecast_price',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.retailPrice' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'forecast_price',
      Cell: data => (
        <span>
          {new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          }).format(data.value ?? 0)}
        </span>
      ),
    },
    {
      id: 'created_by_last_name',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.createdBy' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 150,
      accessor: 'created_by_name',
    },
    {
      id: 'runtime',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.validUntil' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'runtime',
      Cell: data => {
        if (data?.original?.attachment_url) {
          return (
            <Link
              to='/'
              onClick={event => {
                event.preventDefault()
                setCustomOfferID(data.original.id)
                setCustomOfferRuntimeModalOpen(true)
              }}
            >
              {moment(data.value, DATE_FORMATS).format('L')}
            </Link>
          )
        }
        return moment(data.value, DATE_FORMATS).format('L')
      },
    },
  ]

  return (
    <>
      <Helmet>
        <title>{I18n.t('offerPipelineTranslations.headline')}</title>
      </Helmet>

      <div className='offer-pipeline'>
        <Media query={{ maxWidth: BREAKPOINT.MEDIUM }}>
          {isSmallScreen => (
            <PageHeader
              title={I18n.t('offerPipelineTranslations.headline')}
              subtitle={
                <>
                  <div className='uk-margin-small-top uk-margin-medium-bottom'>
                    {I18n.t('offerPipelineTranslations.subtitle.description')}
                  </div>
                  <div className='uk-grid-small uk-width-1-2@m' data-uk-grid=''>
                    <div className='uk-width-2-3@m'>
                      {I18n.t(
                        'offerPipelineTranslations.subtitle.totalResults',
                      )}
                    </div>
                    <div className='uk-width-1-3@m'>
                      {totals?.count ?? 0}
                      {I18n.t(
                        'offerPipelineTranslations.subtitle.totalResultsPostfix',
                      )}
                    </div>
                    <div className='uk-width-2-3@m'>
                      {I18n.t('offerPipelineTranslations.subtitle.totalValue')}
                    </div>
                    <div className='uk-width-1-3@m'>
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(totals?.priceForecast ?? 0)}
                    </div>
                  </div>
                </>
              }
              noMargin={!isSmallScreen}
            >
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <Media
                  key='media-extag'
                  query={{ minWidth: BREAKPOINT.XLARGE }}
                >
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.EXPORT_OFFER_PIPELINE]}
                  >
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={() => setIsExportModalOpen(true)}
                      isDisabled={isLoading.getExportOfferPipeline}
                      isLoading={isLoading.getExportOfferPipeline}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  </RequiredPermissions>
                </Media>
                <IconButton
                  iconName={ICON_NAME.EXPERIMENT}
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    setFilterOpen(!filterOpen)
                  }}
                >
                  <Translate
                    value={
                      filterOpen ? 'general.hideFilter' : 'general.showFilter'
                    }
                  />
                </IconButton>
                <RequiredPermissions
                  requiredPermissions={[
                    UserPermission.EXPORT_POSTAL_STATISTICS,
                  ]}
                >
                  <IconButton
                    iconName='export'
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={() => {
                      setIsPostalStatisticsExportModalOpen(true)
                    }}
                    isDisabled={isLoading.getPostalStatisticsExport}
                    isLoading={isLoading.getPostalStatisticsExport}
                  >
                    <Translate value='offerPipelineTranslations.exportPostalStatisticsModal.buttonText' />
                  </IconButton>
                </RequiredPermissions>
                <RequiredPermissions
                  requiredPermissions={[UserPermission.ADD_CUSTOMOFFER]}
                >
                  <Button
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='open-custom-offer-form-button'
                    onClick={() => setCustomOfferCreateModalOpen(true)}
                  >
                    <Translate value='offerPipelineTranslations.addCustomOfferButton' />
                  </Button>
                </RequiredPermissions>
              </ButtonBar>
            </PageHeader>
          )}
        </Media>

        {/* Filter */}
        <Collapse isOpened={filterOpen}>
          <Filter
            companyList={customers}
            isLoading={isLoading.getOfferPipeline}
            length={offers.length}
          >
            {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
            {/* @ts-ignore */}
            <CompanyAndIdFilter
              action={getOfferPipeline}
              idTranslation={I18n.t(
                'filterTranslations.companyAndIdFilter.offerNumber',
              )}
              setCurrentFilterValues={setCurrentFilters}
              currentFilterValues={currentFilters}
              resultType={COMPANY_ROLE.WASTE_PRODUCER}
            />
          </Filter>
        </Collapse>

        {/* Loading Indicator */}
        {(isLoading.getOfferPipeline && !offers.length) || !!errors ? (
          <div className='uk-flex uk-flex-center'>
            <Spinner name='circle' />
          </div>
        ) : (
          // Paginated Table
          <PaginatedTable
            serverSidePagination
            page={pagination.current}
            pages={pagination.count}
            loading={isLoading.getOfferPipeline}
            handleShowPreviousPage={() => {
              dispatch(
                getOfferPipeline(
                  pagination.previous,
                  currentFilters,
                  TABLE_PAGE_SIZE_10,
                ),
              )
            }}
            handleShowNextPage={() => {
              dispatch(
                getOfferPipeline(
                  pagination.next,
                  currentFilters,
                  TABLE_PAGE_SIZE_10,
                ),
              )
            }}
            table={{
              columns,
              data: offers,
              defaultSorted,
              onFetchData: ({ page, sorted }) => {
                if (sorted?.length > 0) {
                  const newFilters = {
                    ...currentFilters,
                    order_by: JSON.stringify(sorted),
                  }
                  if (
                    JSON.stringify(newFilters) !==
                      JSON.stringify(currentFilters) ||
                    !pagination.loaded
                  ) {
                    setCurrentFilters(newFilters)
                    dispatch(
                      getOfferPipeline(page, newFilters, TABLE_PAGE_SIZE_10),
                    )
                  }
                }
              },
            }}
          />
        )}
      </div>

      <CustomOfferCreateModal
        isOpen={customOfferCreateModalOpen}
        onClose={() => setCustomOfferCreateModalOpen(false)}
      />

      <CustomOfferRuntiemModal
        isOpen={customOfferRuntimeModalOpen}
        onClose={() => setCustomOfferRuntimeModalOpen(false)}
        customOfferID={customOfferID}
      />

      <AsyncExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        reduxSelector='EXPORT_OFFER_PIPELINE'
        title={I18n.t('offerPipelineTranslations.exportModalTitle')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportOfferPipeline(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
      <AsyncExportModal
        isOpen={isPostalStatisticsExportModalOpen}
        onClose={() => setIsPostalStatisticsExportModalOpen(false)}
        reduxSelector='EXPORT_POSTAL_STATISTICS'
        title={I18n.t(
          'offerPipelineTranslations.exportPostalStatisticsModal.title',
        )}
        logic={exportPostalStatistics()}
      />
    </>
  )
}
