import moment from 'moment/moment'
import { I18n } from 'react-i18nify'

import { WEEKDAYS } from 'constants/app'

import { MaklerPremiumOfferValues } from '../../maklerpremium/CreateMaklerPremiumOfferPage/OfferFormSteps/CreateMaklerPremiumOfferFormSteps'
import {
  OFFER_ORDER_TYPE,
  OFFER_ORDER_TYPES,
  OFFER_SERVICE_INTERVAL,
  OFFER_SERVICE_INTERVALS,
  OFFER_TIMES_OF_DAY,
} from '../constants'
import { getTimeOfDayNameIfNotAllDay } from '../helpers'

/**
 * @description: gets the name of the selected company
 */
export const getSelectedCompanyName = (
  companyId: number,
  companyList: BasicFilterResponse[] = [],
) => {
  if (companyList.length > 0) {
    const company = companyList.find(
      _address => Number(_address.id) === companyId,
    )
    if (company) {
      return company.name
    }
  }
  return ''
}

/**
 * @description: gets the name of the selected phone user
 */
export const getSelectedUserName = (
  userId: number,
  userList: UserSmall[] = [],
) => {
  if (userList.length > 0) {
    const user = userList.find(_address => Number(_address.id) === userId)
    if (user) {
      return `${user.first_name} ${user.last_name}`
    }
  }
  return ''
}

/**
 * @description: gets the name parts of the selected address
 */
export const getSelectedAddress = (
  id: number,
  addressList: (Address | AddressWithOrders)[] = [],
) => addressList.find(_address => Number(_address.id) === id)

/**
 * @description: gets the name of the selected address
 */
export const getSelectedAddressDisplayName = (
  addressId: number,
  addressList: (Address | AddressWithOrders)[] = [],
) => {
  const address = getSelectedAddress(addressId, addressList)
  if (address?.display_name) {
    return `<span>${address.display_name.replace(',', '<br />')}</span>`
  }
  return ''
}

/**
 * @description: gets the name of the selected order type
 */
export const getSelectedOrderTypeName = (orderTypeId: number) => {
  const orderType = OFFER_ORDER_TYPES.find(
    _orderType => _orderType.id === orderTypeId,
  )
  if (orderType) return I18n.t(orderType.name)
  return ''
}

/**
 * @description: gets the name of the selected time of day
 */
export const getTimeOfDayName = (timeOfDayId: number) => {
  const timeOfDay = OFFER_TIMES_OF_DAY.find(
    _timeOfDay => _timeOfDay.id === timeOfDayId,
  )
  if (timeOfDay) return timeOfDay.name
  return ''
}

/**
 * @description: gets the name of the selected interval
 */
export const getIntervalName = (intervalId: number) => {
  const interval = OFFER_SERVICE_INTERVALS.find(
    _interval => _interval.id === intervalId,
  )
  if (interval) return interval.name
  return ''
}

/**
 * @description: gets the name of the selected weekday
 * @param weekdayId
 * @returns {string|*}
 */
export const getWeekdayName = weekdayId => {
  if (weekdayId === '') return ''

  const weekday = WEEKDAYS.find(_weekday => _weekday.id === Number(weekdayId))

  return weekday ? weekday.name : ''
}

/**
 * @description: gets order type date label
 * @param orderType
 * @returns {string}
 */
export const getOrderTypeDateLabel = orderType => {
  switch (orderType) {
    case OFFER_ORDER_TYPE.TYPE_ONE_TIME:
      return I18n.t(
        'createInquiryTranslations.form.addressAndDeliveryFormGroup.summary.serviceDatesOneTime',
      )
    case OFFER_ORDER_TYPE.TYPE_QUICK:
      return I18n.t(
        'createInquiryTranslations.form.addressAndDeliveryFormGroup.summary.serviceDatesQuick',
      )
    case OFFER_ORDER_TYPE.TYPE_RECURRING:
      return I18n.t(
        'createInquiryTranslations.form.addressAndDeliveryFormGroup.summary.serviceDatesRecurring',
      )
    case OFFER_ORDER_TYPE.TYPE_ON_DEMAND:
      return I18n.t(
        'createInquiryTranslations.form.addressAndDeliveryFormGroup.summary.serviceDatesOnDemand',
      )
    default:
      return ''
  }
}

/**
 * @description prefix string with a comma if not empty
 * @param {string} string
 * @return {string}
 */
const commaPrefixNotEmpty = string => `${string !== '' ? ', ' : ''}${string}`

/**
 * @description: gets the summary of the selected order type dates
 */
export const getOrderTypeDates = (orderType: OFFER_ORDER_TYPE, values) => {
  const timeOfDayDelivery = commaPrefixNotEmpty(
    getTimeOfDayNameIfNotAllDay(Number(values.time_of_day_delivery)),
  )
  const timeOfDayCollection = commaPrefixNotEmpty(
    getTimeOfDayNameIfNotAllDay(Number(values.time_of_day_collection)),
  )
  const weekdayFirst =
    values.interval_weekday_first &&
    commaPrefixNotEmpty(I18n.t(getWeekdayName(values.interval_weekday_first)))
  const weekdaySecond =
    values.interval_weekday_second &&
    commaPrefixNotEmpty(I18n.t(getWeekdayName(values.interval_weekday_second)))

  switch (orderType) {
    case OFFER_ORDER_TYPE.TYPE_ONE_TIME:
      return `
        ${moment(values.delivery_date).format('L')}${timeOfDayDelivery} /
        ${moment(values.collection_date).format('L')}${timeOfDayCollection}
      `
    case OFFER_ORDER_TYPE.TYPE_QUICK:
      return `
        ${moment(values.delivery_date).format('L')}${timeOfDayDelivery}
      `
    case OFFER_ORDER_TYPE.TYPE_RECURRING:
      if (
        Number(values.interval) === OFFER_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
      ) {
        return `
          ${moment(values.turn_begin).format('L')} /
          ${moment(values.turn_end).format('L')},
          ${I18n.t(getIntervalName(Number(values.interval)))}${
          weekdayFirst ?? ''
        }${weekdaySecond ?? ''}
         `
      }
      return `
        ${moment(values.turn_begin).format('L')} /
        ${moment(values.turn_end).format('L')},
        ${I18n.t(getIntervalName(Number(values.interval)))}${weekdayFirst ?? ''}
       `
    case OFFER_ORDER_TYPE.TYPE_ON_DEMAND:
      return `
        ${moment(values.turn_begin).format('L')} /
        ${moment(values.turn_end).format('L')}
      `
    default:
      return ''
  }
}

export const validateInquiryDates = (values: MaklerPremiumOfferValues) => {
  const errors: { [key: string]: string } = {}

  // recurring --> Turn
  const isDateBefore = (startDate, endDate) =>
    moment(startDate).isBefore(moment(endDate), 'day')
  const isDateAfter = (startDate, endDate) =>
    moment(startDate).isAfter(moment(endDate), 'day')

  // Here the labels and errors are "turn" (on type recurring)
  if (Number(values.order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING) {
    if (!values.interval) {
      errors.interval = I18n.t('general.fieldError')
    }
  }

  // Here the labels and errors are "agreement" (on type demand)
  if (
    Number(values.order_type) === OFFER_ORDER_TYPE.TYPE_ON_DEMAND ||
    Number(values.order_type) === OFFER_ORDER_TYPE.TYPE_RECURRING
  ) {
    if (!values.turn_end) {
      errors.turn_end = I18n.t('general.fieldError')
    }
    if (!values.turn_begin) {
      errors.turn_begin = I18n.t('general.fieldError')
    }
    if (values.turn_end && isDateBefore(values.turn_end, values.turn_begin)) {
      errors.turn_end = I18n.t('general.fieldError')
    }
    if (values.turn_begin && isDateAfter(values.turn_begin, values.turn_end)) {
      errors.turn_begin = I18n.t('general.fieldError')
    }
  }

  if (Number(values.order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME) {
    if (!values.collection_date) {
      errors.collection_date = I18n.t('general.fieldError')
    }

    if (
      values.delivery_date &&
      isDateBefore(values.collection_date, values.delivery_date)
    ) {
      errors.delivery_date = I18n.t('general.fieldError')
    }
  }

  if (
    Number(values.order_type) === OFFER_ORDER_TYPE.TYPE_QUICK ||
    Number(values.order_type) === OFFER_ORDER_TYPE.TYPE_ONE_TIME
  ) {
    if (!values.delivery_date) {
      errors.delivery_date = I18n.t('general.fieldError')
    }
  }

  if (
    typeof values.interval_weekday_first !== 'undefined' &&
    values.interval_weekday_first !== '' &&
    values.interval_weekday_first === values.interval_weekday_second
  ) {
    errors.interval_weekday_first = I18n.t('general.fieldError')
    errors.interval_weekday_second = I18n.t('general.fieldError')
  }

  return errors
}
