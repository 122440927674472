import { acceptedOfferFilterTranslation } from './components/AgreementFilter/translation'
import { certificateFilterTranslation } from './components/CertificateFilter/translation'
import { collectiveInvoicingFilterTranslations } from './components/CollectiveInvoicingFilter/translation'
import { companyAndIdAndStatusAndOrderNumberFilterTranslations } from './components/CompanyAndIdAndStatusAndOrderNumberFilter/translation'
import { companyAndIdFilterTranslations } from './components/CompanyAndIdFilter/translation'
import { discountFilterTranslation } from './components/DiscountFilter/translation'
import { executionProofFilterTranslation } from './components/ExecutionProofFilter/translation'
import { invoiceCheckFilterTranslation } from './components/InvoiceCheckFilter/translation'
import { myAutoOfferFilterTranslation } from './components/MyAutoOfferFilter/translation'
import { myInquiryFilterTranslation } from './components/MyInquiryFilter/translation'
import { myOfferFilterTranslation } from './components/MyOfferFilter/translation'
import { orderClaimFilterTranslation } from './components/OrderClaimFilter/translation'
import { orderFilterTranslation } from './components/OrderFilter/translation'
import { userListFilterTranslation } from './components/UserFilter/translations'
import { workloadFilterTranslation } from './components/WorkloadFilter/translation'

export const filterTranslations = {
  acceptedOfferFilter: acceptedOfferFilterTranslation,
  certificateFilter: certificateFilterTranslation,
  companyAndIdFilter: companyAndIdFilterTranslations,
  companyAndIdAndStatusAndOrderNumberFilter:
    companyAndIdAndStatusAndOrderNumberFilterTranslations,
  collectiveInvoicingFilter: collectiveInvoicingFilterTranslations,
  discountFilter: discountFilterTranslation,
  executionProofFilter: executionProofFilterTranslation,
  invoiceCheckFilter: invoiceCheckFilterTranslation,
  myAutoOfferFilter: myAutoOfferFilterTranslation,
  myInquiryFilter: myInquiryFilterTranslation,
  myOfferFilter: myOfferFilterTranslation,
  orderClaimFilter: orderClaimFilterTranslation,
  orderFilter: orderFilterTranslation,
  userListFilter: userListFilterTranslation,
  workloadFilter: workloadFilterTranslation,
  noInputResults: 'Es wurden keine Ergebnisse gefunden',
}
