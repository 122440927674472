import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import AppActionTypes from '../actiontypes/app'
import OfferActionTypes from '../actiontypes/offer'
import { APP_CONSTANTS, CARD_LIST_PAGE_SIZE } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleCreateOffer
 * @description creates offer.
 */
export const handleCreateOffer = createLogic({
  type: OfferActionTypes.CREATE_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // In the user interface, we make a distinction between fine_fraction and fraction. In the model we only save the
    // most accurate fraction we know (so fine fraction if available).
    const newFraction = action.payload.offer.fine_fraction
      ? action.payload.offer.fine_fraction
      : action.payload.offer.fraction
    const newOffer = {
      ...action.payload.offer,
      fraction: newFraction,
    }
    const decimalValues = [
      'quantity_in_cubic_meters',
      'rent_price_container_month',
      'disposal_cost_container',
      'transport_price_piece',
      'disposal_cost_ton',
      'handle_cost_ton',
      'surcharge',
      'discount',
    ]

    decimalValues.forEach(item => {
      newOffer[item] = newOffer[item]
        ? String(newOffer[item]).replace(',', '.')
        : '0'
    })

    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/`, newOffer)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.CREATE_OFFER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.offer.handleCreateOffer.success'))

        action.payload.history.push('/inquiry/open')
      })
      .then(done)
      .catch(err => {
        let humanReadableError = I18n.t('api.offer.handleCreateOffer.error')
        // in case the user already created an offer to that inquiry, throw an error, warning that an offer already
        // exists for this user (useful when creating an offer by request with empto user)
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.CREATE_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOffers
 * @description Loads the uncancelled offers for all inquiries of the user's company.
 */
export const handleGetOffers = createLogic({
  type: OfferActionTypes.GET_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const expand = action.payload?.expand?.join()
    const filters = action.payload
      ? {
          order_by: JSON.stringify(action.payload.order_by) || null,
          expand: expand || null,
        }
      : {}

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/uncancelled/`,
        filters,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.GET_OFFERS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.offer.handleGetOffers.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.GET_OFFERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMyOffers
 * @description Loads all offers that were created by my company.
 */
export const handleGetMyOffers = createLogic({
  type: OfferActionTypes.GET_MY_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || CARD_LIST_PAGE_SIZE,
      }

      let filters = {}
      if (action.payload.filters) {
        const { company, order_type, ...rest } = action.payload.filters
        filters = {
          customer_company: company,
          order_type: order_type,
          ...rest,
        }
      }

      data = { ...data, ...filters }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.GET_MY_OFFERS_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_OFFER',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.offer.handleGetMyOffers.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.GET_MY_OFFERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetAcceptedOffers
 * @description Loads all offers that were created by my company.
 */
export const handleGetAcceptedOffers = createLogic({
  type: OfferActionTypes.GET_ACCEPTED_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = {
        page_size:
          (action.payload.filters && action.payload.filters.page_size) ||
          CARD_LIST_PAGE_SIZE,
        page: action.payload.page || 1,
      }

      const filters = action.payload.filters
        ? {
            id: action.payload.filters.id || null,
            coarse_fraction: action.payload.filters.coarse_fraction || null,
            collection_address:
              action.payload.filters.collection_address || null,
            order_type: action.payload.filters.order_type || null,
            ...action.payload.filters,
          }
        : {}

      if (filters) {
        data = { ...data, ...filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = {
          ...data,
          order_by: JSON.stringify(action.payload.sortedBy),
        }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/accepted/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.GET_ACCEPTED_OFFERS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.offer.handleGetAcceptedOffers.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.GET_ACCEPTED_OFFERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOffer
 * @description Loads offer by id that were created by my company.
 */
export const handleGetOffer = createLogic({
  type: OfferActionTypes.GET_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { id, expand } = action.payload
    const expandString = expand ? expand.join(',') : ''

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${id}/?expand=${expandString}`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.GET_OFFER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.offer.handleGetOffer.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.GET_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCancelOffer
 * @description Cancels Offer.
 */
export const handleCancelOffer = createLogic({
  type: OfferActionTypes.CANCEL_OFFER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${action.payload.offer.id}/cancel/`,
        action.payload.offer,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.CANCEL_OFFER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.offer.handleCancelOffer.success'))
      })
      .then(() =>
        action.payload.history.push(`/offer/${action.payload.offer.id}`),
      )
      .then(() => dispatch(actions.reset('inquiry.item.active_offer_id')))
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.offer.handleCancelOffer.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.CANCEL_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleResendOffer
 * @description Sends offer email again - #2052
 */
export const handleResendOffer = createLogic({
  type: OfferActionTypes.RESEND_OFFER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${action.payload.offer.id}/resend/`,
        action.payload.offer,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.RESEND_OFFER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.offer.handleResendOffer.success'))
      })
      .then(() =>
        action.payload.history.push(`/offer/${action.payload.offer.id}`),
      )
      .then(() => dispatch(actions.reset('inquiry.item.active_offer_id')))
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.offer.handleResendOffer.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.RESEND_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportMyOffers
 * @description triggers the offer export.
 */
export const handleExportMyOffers = createLogic({
  type: OfferActionTypes.EXPORT_MY_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/export/`, data)
      .then(() => {
        dispatch({
          type: OfferActionTypes.EXPORT_MY_OFFERS_SUCCESS,
        })
        toastr.success('', I18n.t('api.offer.handleExportMyOffers.success'))
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.offer.handleExportMyOffers.error'))

        done()
      })
  },
})

/**
 * @function
 * @name handleExportAcceptedOffers
 * @description triggers the accepted offer export.
 */
export const handleExportAcceptedOffers = createLogic({
  type: OfferActionTypes.EXPORT_ACCEPTED_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/export_accepted/`,
        data,
      )
      .then(() => {
        dispatch({
          type: OfferActionTypes.EXPORT_ACCEPTED_OFFERS_SUCCESS,
        })
        toastr.success(
          '',
          I18n.t('api.offer.handleExportAcceptedOffers.success'),
        )
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.offer.handleExportAcceptedOffers.error'))

        done()
      })
  },
})

/**
 * @function
 * @name handlePostponeIntervalOffer
 * @description
 */
export const handlePostponeIntervalOffer = createLogic({
  type: OfferActionTypes.POSTPONE_INTERVAL_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${action.payload.id}/postpone/`,
        action.payload,
      )
      .then(res => {
        dispatch({
          type: OfferActionTypes.POSTPONE_INTERVAL_OFFER_SUCCESS,
          payload: res.data,
        })
        toastr.success(
          '',
          I18n.t('api.offer.handlePostponeIntervalOffer.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.offer.handlePostponeIntervalOffer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.POSTPONE_INTERVAL_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleChangeIntervalOffer
 * @description
 */
export const handleChangeIntervalOffer = createLogic({
  type: OfferActionTypes.CHANGE_INTERVAL_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/recurring-offer/${action.payload.id}/change-interval/`,
        action.payload,
      )
      .then(res => {
        dispatch({
          type: OfferActionTypes.CHANGE_INTERVAL_OFFER_SUCCESS,
          payload: res.data,
        })
        toastr.success(
          '',
          I18n.t('api.offer.handleChangeIntervalOffer.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.offer.handleChangeIntervalOffer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.CHANGE_INTERVAL_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleChangeDisposerOffer
 * @description Changes disposer from Offer
 */
export const handleChangeDisposerOffer = createLogic({
  type: OfferActionTypes.CHANGE_DISPOSER_OFFER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${action.payload.id}/change-disposer/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.CHANGE_DISPOSER_OFFER_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.offer.handleChangeDisposerOfferRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.offer.handleChangeDisposerOfferRequest.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OfferActionTypes.CHANGE_DISPOSER_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleChangeAgreementForStandingOffersPeriod
 * @description
 */
export const handleChangeAgreementPeriodForStandingOffers = createLogic({
  type: OfferActionTypes.CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { id, serviceStartDate, serviceEndDate, offerIsRecurring } =
      action.payload

    const url = offerIsRecurring
      ? `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/recurring-offer/${id}/change-time-period/?expand=company_object`
      : `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${id}/change-agreement-period/?expand=company_object`

    const payload = offerIsRecurring
      ? {
          id: id,
          new_end_date: serviceEndDate,
        }
      : {
          id: id,
          service_start_date: serviceStartDate,
          service_end_date: serviceEndDate,
        }

    httpClient
      .patch(url, payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_SUCCESS,
          payload: {
            ...res,
          },
        })
        if (!offerIsRecurring) {
          toastr.success(
            '',
            I18n.t(
              'api.offer.handleChangeAgreementPeriodForStandingOffers.success',
            ),
          )
        } else {
          const shortenLog = res.process_log.shorten_log
          const extendLog = res.process_log.extend_log

          if (shortenLog) {
            if (shortenLog.cancelled_order_ids) {
              toastr.success(
                '',
                I18n.t(
                  'api.offer.handleChangeAgreementPeriodForStandingOffers.successfullyCancelledOrders',
                  {
                    orderIDs: shortenLog.cancelled_order_ids,
                  },
                ),
              )
            }
            if (shortenLog.no_orders_cancelled) {
              toastr.success('', shortenLog.no_orders_cancelled)
            }
            toastr.success('', shortenLog.email)
          }

          if (extendLog) {
            if (extendLog.created_order_ids !== '') {
              toastr.success(
                '',
                I18n.t(
                  'api.offer.handleChangeAgreementPeriodForStandingOffers.successfullyCreatedOrders',
                  {
                    orderIDs: extendLog.created_order_ids,
                  },
                ),
              )
            }

            if (extendLog && extendLog.created_order_ids === '') {
              toastr.success(
                '',
                I18n.t(
                  'api.offer.handleChangeAgreementPeriodForStandingOffers.successfulButNoOrdersCreated',
                ),
              )
            }
            toastr.success('', extendLog.email)
          }
        }
      })
      .then(done)
      .catch(err => {
        const error_code = err.response.data[0]
        const humanReadableError = I18n.t(
          `api.offer.handleChangeAgreementPeriodForStandingOffers.${error_code}`,
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OfferActionTypes.CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleAutoDisposerChange
 * @description Automatically changes the disposer of an offer to the next one available
 */
export const handleAutoDisposerChange = createLogic({
  type: OfferActionTypes.AUTO_CHANGE_DISPOSER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${action.payload.id}/auto-change-disposer/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.AUTO_CHANGE_DISPOSER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.offer.handleAutoDisposerChange.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.AUTO_CHANGE_DISPOSER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportRent
 * @description triggers the rent export.
 */
export const handleExportRent = createLogic({
  type: OfferActionTypes.EXPORT_RENT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/export_rent/`, data)
      .then(() => {
        dispatch({
          type: OfferActionTypes.EXPORT_RENT_SUCCESS,
        })
        toastr.success('', I18n.t('api.offer.handleExportRent.success'))
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.offer.handleExportRent.error'))

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateReviewedOffer
 * @description Updates a reviewed invoice check position
 */
export const handleUpdateReviewedOffer = createLogic({
  type: OfferActionTypes.UPDATE_REVIEWED_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/offer/${action.payload.id}/review/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OfferActionTypes.UPDATE_REVIEWED_OFFER_SUCCESS,
          payload: res,
        })

        let message = I18n.t(
          'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.relabeled.success',
        )
        if (res.container_changed) {
          message = I18n.t(
            'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.containerChanged.success',
          )
        } else if (res.additional_rent) {
          message = I18n.t(
            'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.additionalRent.success',
          )
        }
        toastr.success('', message)
      })
      .then(() => action.payload.history.goBack())
      .then(done)
      .catch(err => {
        dispatch(actions.setPending('offer.item', false))
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OfferActionTypes.UPDATE_REVIEWED_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleAutoDisposerChange,
  handleCreateOffer,
  handleGetOffers,
  handleGetMyOffers,
  handleGetOffer,
  handleCancelOffer,
  handleResendOffer,
  handleExportMyOffers,
  handleGetAcceptedOffers,
  handleExportAcceptedOffers,
  handlePostponeIntervalOffer,
  handleChangeIntervalOffer,
  handleChangeDisposerOffer,
  handleChangeAgreementPeriodForStandingOffers,
  handleExportRent,
  handleUpdateReviewedOffer,
]
