import { useFormikContext } from 'formik'
import React, { ReactText, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getWorkloads } from 'actions/workload'
import { InputNumber } from 'components/common/InputNumber'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import {
  WORK_LOAD_CATEGORIES,
  WORK_LOAD_STATES,
} from 'components/workload/WorkloadPage/constants'
import { APP_CONSTANTS, TABLE_PAGE_SIZE_10 } from 'constants/app'
import { Select } from 'components/Select/Select'

import { getCurrentValueObject, resetInputOption } from '../../helpers'
import { FilterProps } from '../../types'

export type WorkloadFilterValues = {
  order_id: ReactText
  id: ReactText
  status: string
  customer: string
  partner: string
  category: string
  orderBy: { [key: string]: ReactText }
  date_start: string
  date_end: string
}

interface WorkloadFilterProps extends Partial<FilterProps> {
  getCurrentFilterValues: (filters: Partial<WorkloadFilterValues>) => void
  currentFilterValues: any
}

export const WorkloadFilter = ({
  isLoading = false,
  length = 0,
  getCurrentFilterValues,
  currentFilterValues,
  shouldResetForm,
}: WorkloadFilterProps) => {
  const [currentSelectedWorkloadState, setCurrentSelectedWorkloadState] =
    useState<Option>()
  const [currentSelectedWorkloadCategory, setCurrentSelectedWorkloadCategory] =
    useState<Option>()
  const { handleBlur, handleChange, values } =
    useFormikContext<WorkloadFilterValues>()
  const dispatch = useDispatch()

  const { setFieldValue, setTouched } = useFormikContext()
  const getSelectionItems = () => ({
    WORKLOAD_STATES: [
      ...resetInputOption,
      ...WORK_LOAD_STATES.map(item => ({
        label: `${I18n.t(item.name)}`,
        value: `${item.id}`,
      })),
    ],
    WORKLOAD_CATEGORIES: [
      ...resetInputOption,
      ...WORK_LOAD_CATEGORIES.map(item => ({
        label: `${I18n.t(item.name)}`,
        value: `${item.id}`,
      })),
    ],
  })

  useEffect(() => {
    const requestValues = {
      ...currentFilterValues,
      order_id: values.order_id,
      status: values.status,
      category: values.category,
      customer: values.customer,
      partner: values.partner,
    }
    if (
      shouldResetForm ||
      JSON.stringify(currentFilterValues) !== JSON.stringify(requestValues)
    ) {
      getCurrentFilterValues(requestValues)
      dispatch(getWorkloads(undefined, requestValues, TABLE_PAGE_SIZE_10))
    }
  }, [
    values,
    getCurrentFilterValues,
    dispatch,
    shouldResetForm,
    currentFilterValues,
  ])

  useEffect(() => {
    setCurrentSelectedWorkloadState(
      getCurrentValueObject(getSelectionItems().WORKLOAD_STATES, values.status),
    )
  }, [values.status])

  useEffect(() => {
    setCurrentSelectedWorkloadCategory(
      getCurrentValueObject(
        getSelectionItems().WORKLOAD_CATEGORIES,
        values.category,
      ),
    )
  }, [values.category])

  return (
    <>
      <div className='uk-grid uk-child-width-1-1 uk-child-width-1-4@m'>
        <StaticCombobox
          label={I18n.t('filterTranslations.workloadFilter.status')}
          name='status'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={getSelectionItems().WORKLOAD_STATES}
          onSelectionChange={handleChange}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedWorkloadState}
        />
        <StaticCombobox
          label={I18n.t('filterTranslations.workloadFilter.category')}
          name='category'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={getSelectionItems().WORKLOAD_CATEGORIES}
          onSelectionChange={handleChange}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedWorkloadCategory}
          dataTestId='category-combobox'
        />
        {/* prettier-ignore */}
        <Select
          urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
          urlPart2='&role=1'
          getValue={(e) => e.id}
          getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location}`}
          label={I18n.t('filterTranslations.workloadFilter.customer')}
          setValue={e => {
            if   (!e?.value) {setFieldValue('customer', undefined);}
            else {setFieldValue('customer', e.value);}
            setTouched({ customer: true })
          }}
          selectedValue={values.customer}
          selecting={'company'}
        />
        {/* prettier-ignore */}
        <Select
          urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
          urlPart2='&role=2'
          getValue={(e) => e.id}
          getLabel={(e) => e.id === 0 ? e.name : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location}`}
          label={I18n.t('filterTranslations.workloadFilter.partner')}
          setValue={e => {
            if   (!e?.value) {setFieldValue('partner', undefined);}
            else {setFieldValue('partner', e.value);}
            setTouched({ partner: true })
          }}
          selectedValue={values.partner}
          selecting={'company'}
        />

        <InputNumber
          label={I18n.t('filterTranslations.workloadFilter.order')}
          maxLength={15}
          name='order_id'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.order_id}
        />
      </div>

      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
