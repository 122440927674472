import { useFormikContext } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getAllCompaniesForFilter } from 'actions/company'
import { UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getCurrentUserSelector } from 'selectors/user'
import { InputNumber } from 'components/common/InputNumber'
import { InputText } from 'components/common/InputText'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { COMPANY_ROLE } from 'components/company/constants'
import {
  DSZ_DOCUMENT_STATUS_TYPES,
  EPD_DOCUMENT_STATUS_TYPES,
} from 'components/invoice/constants'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { Select } from 'components/Select/Select'
import { APP_CONSTANTS } from 'constants/app'

import { getCurrentValueObject } from '../../helpers'
import { CommonFilterProps, FilterValues } from '../../types'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface CompanyAndIdAndStatusAndOrderNumberFilterProps
  extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  idField?: string
  documentStatus?: string
  idTranslation: string
  idFilterActive: boolean
  companyTranslation: string
  companyFilterActive: boolean
  setCurrentFilterValues: (currentFilters: any) => void
  resultType?: COMPANY_ROLE
  currentFilterValues?: FilterValues
}

export const CompanyAndIdAndStatusAndOrderNumberFilter: FC<
  CompanyAndIdAndStatusAndOrderNumberFilterProps
> = ({
  action,
  handleBlur,
  handleChange,
  idField = 'id',
  idFilterActive = true,
  companyFilterActive = true,
  isLoading,
  length,
  resetForm,
  setCurrentFilterValues,
  setFieldValue,
  values,
  currentFilterValues,
}) => {
  const dispatch = useDispatch()
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<Option>()

  const user = useSelector(getCurrentUserSelector)
  const formikContext = useFormikContext()
  const touched = formikContext.touched as FilterValues
  function doResetForm() {
    resetForm()
    // resetting the form might lead to company being set if it already had an initial company
    setFieldValue('company', '')
  }

  useEffect(
    () => {
      setCurrentSelectedStatus(
        getCurrentValueObject(statusChoices, values.status),
      )
    },
    // ESLint: The 'statusChoices' array makes the dependencies of useEffect Hook (at line 64) change on every render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.status],
  )

  useEffect(() => {
    dispatch(getAllCompaniesForFilter())
  }, [dispatch])

  useEffect(
    () => {
      const requestValues = {
        company: values.company,
        [idField]: values.id,
        document_status: values.status,
        makler_premium_company: user.company,
        order_numbers: values.order_numbers,
        archive: currentFilterValues?.archive,
      }
      setCurrentFilterValues(requestValues)
      dispatch(action(null, requestValues))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.id, values.company, values.status, values.order_numbers],
  )

  // prettier-ignore
  useEffect(
    () => {
      setFieldValue('id',            currentFilterValues?.id              ? currentFilterValues.id               : '')
      setFieldValue('company',       currentFilterValues?.company         ? currentFilterValues.company          : '')
      setFieldValue('status',        currentFilterValues?.status          ? currentFilterValues.status           : '')
      setFieldValue('order_numbers', currentFilterValues?.order_numbers   ? currentFilterValues.order_numbers    : '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const isDsz = useMemo(
    () =>
      checkRequiredPermissions(user.permission_codenames, [
        UserPermission.VIEW_DSZINVOICE,
      ]),
    [user],
  )
  const statusTypes = isDsz
    ? DSZ_DOCUMENT_STATUS_TYPES
    : EPD_DOCUMENT_STATUS_TYPES
  const statusChoices = [
    { label: I18n.t('general.placeholder.all'), value: '' },
    ...[...statusTypes].map(item => ({
      label: `${I18n.t(item.name)}`,
      value: `${item.id}`,
    })),
  ]

  return (
    <>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        {companyFilterActive && (
          <Select
            urlPart1={`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/?search=`}
            urlPart2='&onlyActive=false'
            getValue={e => e.id}
            getLabel={e =>
              e.id === 0
                ? e.name
                : `${e.name} / ${e?.empto_external_number} / ${e?.street} / ${e?.location} / ${e?.central_contact} / ${e?.central_contact_email}`
            }
            label={I18n.t(
              'filterTranslations.companyAndIdAndStatusAndOrderNumberFilter.companySelection.label',
            )}
            setValue={e => {
              setFieldValue('company', e?.value ? e.value : '')
              if (!e && touched?.company) {
                doResetForm()
              }
            }}
            selectedValue={currentFilterValues?.company}
            selecting={'company'}
          />
        )}
        {idFilterActive && (
          <InputText
            label={I18n.t(
              'filterTranslations.companyAndIdAndStatusAndOrderNumberFilter.invoiceNumber',
            )}
            maxLength={15}
            name='id'
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e)
              if (!e.target.value && !values.company) {
                doResetForm()
              }
            }}
            value={values.id}
          />
        )}
        <InputNumber
          label={I18n.t(
            'filterTranslations.companyAndIdAndStatusAndOrderNumberFilter.orderNumber',
          )}
          maxLength={15}
          name='order_numbers'
          onBlur={handleBlur}
          onChange={e => {
            handleChange(e)
            if (!e.target.value && !values.document_number && !values.company) {
              doResetForm()
            }
          }}
          value={values.order_numbers || ''}
        />
        <StaticCombobox
          isLoading={isLoading}
          label={I18n.t(
            'filterTranslations.companyAndIdAndStatusAndOrderNumberFilter.status',
          )}
          name='status'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={statusChoices}
          onSelectionChange={e => {
            handleChange(e)
            if (!e.target.value && !values.document_number && !values.company) {
              doResetForm()
            }
          }}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedStatus}
        />
        <FilterReset
          onResetFilter={() => {
            setFieldValue('company', '')
            doResetForm()
            setCurrentFilterValues({})
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilterValues) > 0
          }
        />
      </div>
      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
