import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'

import { MaklerPremiumGroups, UserPermission } from 'constants/user'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import withErrorBoundary from 'helper/withErrorBoundary'
import { LocationScheme } from 'schemes/location'
import { OrderScheme } from 'schemes/order'
import { OrderStatusUniScheme } from 'schemes/orderstatus'
import { UserScheme } from 'schemes/user'

import { BackButton } from '../../common/BackButton'
import { BreadcrumbNavigation } from '../../common/Breadcrumb'
import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { DetailSwitcher } from '../../common/DetailSwitcher'
import Icon from '../../common/Fontello'
import { Headline, HEADLINE_STYLE, HEADLINE_TAG } from '../../common/Headline'
import IndicationMessage from '../../common/IndicationMessage'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import Paragraph from '../../common/Paragraph'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UploadDocumentForm } from '../../common/UploadDocumentForm'
import { OFFER_ORDER_TYPE } from '../../inquiry/constants'
import PageHeader from '../../layout/PageHeader'
import { ORDER_STATUS } from '../constants'

import { CreateInvoicingDocumentsForm } from './components/CreateInvoicingDocumentsForm'
import { OrderCancelForm } from './components/OrderCancelForm'
import { OrderPostponeForm } from './components/OrderPostponeForm'
import { OrderStatusList } from './components/OrderStatusList'
import { OrderStatusTasks } from './components/OrderStatusTasks'
import { OrderSwitcherContent } from './components/OrderSwitcherContent'
import { OrderSwitcherNav } from './components/OrderSwitcherNav/OrderSwitcherNav'
import connector from './connector'
import { ORDER_SWITCH_LABELS } from './constants'

const ORDER_DETAILS_MODAL = {
  CLAIM: 1,
  POSTPONE: 2,
  CREATE_INVOICING_DOCUMENTS: 3,
  CANCEL: 4,
}

/**
 * @description This component renders the order details page.
 */
export class OrderDetailsPageComponent extends Component {
  static propTypes = {
    createOrderClaim: func.isRequired,
    getOrder: func.isRequired,
    getOrderClaims: func.isRequired,
    match: shape({
      params: shape({
        orderId: oneOfType([number, string]),
        offerId: oneOfType([number, string]),
      }),
    }),
    orderStates: arrayOf(shape(OrderStatusUniScheme)),
    user: shape(UserScheme).isRequired,
    isLoading: shape({
      getOrder: bool,
      getOrderClaimList: bool,
      createOrderClaim: bool,
    }),
    error: shape({
      createOrderClaim: bool,
    }),
    history: shape({
      length: number.isRequired,
      location: shape(LocationScheme).isRequired,
      action: string.isRequired,
    }).isRequired,
    order: shape(OrderScheme),
  }

  static defaultProps = {
    match: {
      params: {
        orderId: null,
        offerId: null,
      },
    },
    isLoading: {
      getOrder: false,
      getOrderClaimList: false,
      createOrderClaim: false,
    },
    error: {
      getOrder: false,
      getOrderClaimList: false,
      createOrderClaim: false,
    },
    order: null,
    orderStates: null,
  }

  state = {
    openModal: null,
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillMount
   */
  componentDidMount() {
    if (this.props.match.params.orderId) {
      this.props.getOrder(this.props.match.params.orderId, [
        'invoice_files',
        'order_status_objects__open_order_invoice_check_positions',
      ])
      this.props.getOrderClaims(null, {
        order: this.props.match.params.orderId,
        page_size: Number.MAX_SAFE_INTEGER,
      })
    }
  }

  /**
   * @param modal
   */
  handleModalToggle = (modal = null) => {
    const closeModal = this.state.openModal === modal || !modal

    this.setState({
      openModal: closeModal ? null : modal,
    })
  }

  renderSwitcherNav = () => (
    <>
      {this.props.order.executionproofs && (
        <OrderSwitcherNav
          hideExecutionProofsTab={this.props.order.executionproofs.length < 1}
          hideInvoiceCheckAttachmentsTab={
            !(this.props.user.group_id in MaklerPremiumGroups)
          }
        />
      )}
    </>
  )

  renderSwitcherContent = props => (
    <OrderSwitcherContent
      switcherDisabled={props?.disabled}
      hideExecutionProofsTab={this.props.order.executionproofs.length < 1}
      hideInvoiceCheckAttachmentsTab={
        !(this.props.user.group_id in MaklerPremiumGroups)
      }
    />
  )

  /**
   * @description Renders Order details block
   * @return {*}
   */
  renderOrderDetails() {
    const { history } = this.props
    const search = history.location.search
    return (
      <div className='order-details-container'>
        <p className='title uk-text-uppercase'>
          <Translate value='orderDetails.details.title' />
        </p>

        <div className='order-details-content'>
          <DetailSwitcher
            renderSwitcherNav={this.renderSwitcherNav}
            renderSwitcherContent={this.renderSwitcherContent}
            getDefaultActive={() => {
              if (search?.includes('show-label')) {
                return search.split('=')[1]
              }

              return String(ORDER_SWITCH_LABELS.SHORT_OVERVIEW)
            }}
          />
        </div>

        <RequiredPermissions
          requiredPermissions={[UserPermission.CREATE_INVOICING_DOCUMENTS]}
        >
          <div className='uk-margin-medium-top'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={() =>
                this.handleModalToggle(
                  ORDER_DETAILS_MODAL.CREATE_INVOICING_DOCUMENTS,
                )
              }
            >
              <Translate value='invoice.createDocuments.title' />
              <Icon name='copy' />
            </Button>
          </div>
        </RequiredPermissions>

        {this.props.history.length > 0 && (
          <div className='uk-margin-medium-top'>
            <BackButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              text={I18n.t('general.backToOverview')}
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * @description Renders the order claim form modal to add order claim on an order
   * @return {*}
   */
  renderOrderClaimFormModal() {
    const idOrderClaimFormModalHeadline = uniqueId()

    const { createOrderClaim, isLoading, error, order, user } = this.props

    const permissions = [UserPermission.ADD_ORDERCLAIM]
    if (
      ![
        order.offer_object.company_id,
        order.offer_object.customer_company,
      ].includes(user.company_id) &&
      user.group_id in MaklerPremiumGroups
    ) {
      permissions.push(UserPermission.REPORT_MAKLER_PREMIUM_CLAIMS)
    }

    return (
      <Modal
        ariaDescribedBy={idOrderClaimFormModalHeadline}
        isOpen={this.state.openModal === ORDER_DETAILS_MODAL.CLAIM}
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={I18n.t('orderClaim.title')}
          titleId={idOrderClaimFormModalHeadline}
          dataTestId='order-claim-modal-header'
        />

        <UploadDocumentForm
          document={order}
          onCloseForm={this.handleModalToggle}
          onDocumentSubmit={values =>
            createOrderClaim({
              ...values,
              order: order.id,
            })
          }
          showOrderClaimOptions
          apiError={error.createOrderClaim}
          description={I18n.t('orderClaim.description')}
          permissions={permissions}
          isLoading={isLoading.createOrderClaim}
          uploadLabel={I18n.t(
            'uploadReviewDocumentTranslations.formFields.attachments.label.orderClaim',
          )}
          showApiValidationMessage={!!error.createOrderClaim}
          aditionalApiFetchError='CREATE_ORDER_CLAIM'
        />
      </Modal>
    )
  }

  /**
   * @description Renders the order claim form modal to add order claim on an order
   * @return {*}
   */
  renderOrderPostponeModal() {
    const { order, orderStates } = this.props
    const isOrderTypeOneTime =
      order.order_type === OFFER_ORDER_TYPE.TYPE_ONE_TIME
    const idOrderPostponeModalHeadline = uniqueId()

    return (
      <Modal
        ariaDescribedBy={idOrderPostponeModalHeadline}
        isOpen={this.state.openModal === ORDER_DETAILS_MODAL.POSTPONE}
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={I18n.t('orderPostpone.title')}
          titleId={idOrderPostponeModalHeadline}
          dataTestId='order-postpone-modal-header'
        />
        <OrderPostponeForm
          isCollectionDisabled={!isOrderTypeOneTime}
          isDeliveryDisabled={
            isOrderTypeOneTime &&
            orderStates &&
            orderStates.some(status =>
              [ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY].includes(
                status.value,
              ),
            )
          }
          onCancel={this.handleModalToggle}
          onSuccess={this.handleModalToggle}
        />
      </Modal>
    )
  }

  /**
   * @description Renders create invoicing documents modal
   * @return {*}
   */
  renderCreateInvoicingDocumentsModal() {
    const { order } = this.props
    const idCreateInvoicingDocumentsModalHeadline = uniqueId()

    return (
      <Modal
        ariaDescribedBy={idCreateInvoicingDocumentsModalHeadline}
        isOpen={
          this.state.openModal ===
          ORDER_DETAILS_MODAL.CREATE_INVOICING_DOCUMENTS
        }
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={I18n.t('invoice.createDocuments.title')}
          titleId={idCreateInvoicingDocumentsModalHeadline}
        />

        <CreateInvoicingDocumentsForm
          orderId={order.id}
          onCancel={this.handleModalToggle}
          onSuccess={this.handleModalToggle}
        />
      </Modal>
    )
  }

  /**
   * @description Renders cancel order modal
   * @return {*}
   */
  renderOrderCancelModal() {
    const idOrderCancelModalHeadline = uniqueId()

    return (
      <Modal
        ariaDescribedBy={idOrderCancelModalHeadline}
        isOpen={this.state.openModal === ORDER_DETAILS_MODAL.CANCEL}
        onClose={this.handleModalToggle}
      >
        <ModalHeader
          onClose={this.handleModalToggle}
          title={I18n.t('orderCancel.title')}
          titleId={idOrderCancelModalHeadline}
          dataTestId='order-cancel-modal-header'
        />

        <OrderCancelForm
          order={this.props.order}
          onCloseForm={this.handleModalToggle}
          onSuccess={this.handleModalToggle}
        />
      </Modal>
    )
  }

  /**
   * @description Renders Order status block
   * @return {*}
   */
  renderOrderStatus() {
    const { order, orderStates } = this.props

    return (
      <div className='order-status-container'>
        <div>
          <p className='title uk-text-uppercase'>
            <Translate value='orderDetails.status.title' />
          </p>
        </div>

        <div className='order-status-content uk-margin-top'>
          <OrderStatusList order={order} orderStates={orderStates} />
          <OrderStatusTasks order={order} orderStates={orderStates} />
        </div>
      </div>
    )
  }

  render() {
    if (this.props.isLoading.getOrder || !this.props.order.offer_object) {
      return null
    }

    if (
      this.props.isLoading.getOrder ||
      this.props.isLoading.getOrderClaimList
    ) {
      return (
        <div className='uk-flex uk-flex-center uk-margin-top uk-margin-bottom'>
          <Spinner name='circle' />
        </div>
      )
    }

    const {
      order,
      orderStates,
      history,
      match: {
        params: { orderId },
      },
    } = this.props

    const orderIsCancelled = orderStates.some(status =>
      [
        ORDER_STATUS.AEZ_CANCELLED,
        ORDER_STATUS.CANCELLED_EMPTO,
        ORDER_STATUS.CANCELLED_EPD,
      ].includes(status.value),
    )

    return (
      <>
        <Helmet>
          <title>
            {I18n.t('pageTitles.orderDetails', {
              orderId,
            })}
          </title>
        </Helmet>
        {order.is_deduction && (
          <IndicationMessage
            className='warning'
            iconName='warning'
            statusClass='warning'
            statusIconName='warning'
          >
            <Headline headlineStyle={HEADLINE_STYLE.H5} tag={HEADLINE_TAG.H3}>
              <Paragraph>
                <Translate
                  value='orderDetails.indicationMessage'
                  dangerousHTML
                />
              </Paragraph>
            </Headline>
          </IndicationMessage>
        )}
        <div className='order-details-page'>
          <BreadcrumbNavigation
            breadcrumbTitle={
              <Translate
                value='orderDetails.heading.breadcrumb.title'
                orderId={orderId}
              />
            }
            prevLinkTitle={
              (history.location.state &&
                history.location.state.backLinkTitle) ||
              I18n.t('menu.orders')
            }
            prevLinkTo={
              (history.location.state && history.location.state.backLinkTo) ||
              '/order'
            }
          />

          <PageHeader title={I18n.t('orderDetails.heading.title', { orderId })}>
            <div className='uk-width-1-1'>
              {!orderIsCancelled && (
                <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                  {orderStates &&
                    !orderStates.find(
                      status =>
                        status.value ===
                        ORDER_STATUS.INVOICING_DOCUMENTS_CREATED,
                    ) && (
                      <RequiredPermissions
                        requiredPermissions={[
                          UserPermission.DISPOSE_ORDER,
                          UserPermission.DISPOSE_MAKLER_PREMIUM_ORDER,
                        ]}
                      >
                        <Button
                          className='postpone_order_button'
                          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                          onClick={() =>
                            this.handleModalToggle(ORDER_DETAILS_MODAL.POSTPONE)
                          }
                          dataTestId='order-details-page-postpone-button'
                        >
                          <Translate value='orderPostpone.title' />
                        </Button>
                      </RequiredPermissions>
                    )}

                  <RequiredPermissions
                    requiredPermissions={[
                      UserPermission.ADD_ORDERCLAIM,
                      UserPermission.REPORT_MAKLER_PREMIUM_CLAIMS,
                    ]}
                  >
                    <Button
                      className='order_claim_button'
                      backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                      onClick={() =>
                        this.handleModalToggle(ORDER_DETAILS_MODAL.CLAIM)
                      }
                      dataTestId='button-order-claim'
                    >
                      <Translate value='orderClaim.title' />
                    </Button>
                  </RequiredPermissions>
                  {/*
                  Check that order state is not `aez_cancelled/cancelled_by_producer`
                  and there is no completed invoice check for this order
                */}
                  {!orderIsCancelled &&
                    !orderStates.find(
                      status =>
                        status.value ===
                        ORDER_STATUS.INVOICING_DOCUMENTS_CREATED,
                    ) && (
                      <RequiredPermissions
                        requiredPermissions={[UserPermission.CANCEL_ORDER]}
                      >
                        <Button
                          className='order_cancel_button'
                          backgroundColor={BUTTON_BACKGROUND_COLOR.DANGER}
                          onClick={() =>
                            this.handleModalToggle(ORDER_DETAILS_MODAL.CANCEL)
                          }
                          dataTestId='button-order-cancel'
                        >
                          <Translate value='orderCancel.title' />
                        </Button>
                      </RequiredPermissions>
                    )}
                </ButtonBar>
              )}
            </div>
          </PageHeader>

          <div className='page-content'>
            <div className='uk-grid' data-uk-grid-match='{row: false}'>
              <div className='uk-width-1-1 uk-width-2-3@l uk-padding-remove'>
                {this.renderOrderDetails()}
              </div>
              <div className='uk-width-1-1 uk-width-1-3@l uk-padding-remove'>
                {this.renderOrderStatus()}
              </div>
            </div>
          </div>

          {/* Modals */}
          {this.renderOrderCancelModal()}
          {this.renderOrderClaimFormModal()}
          {this.renderOrderPostponeModal()}
          {this.renderCreateInvoicingDocumentsModal()}
        </div>
      </>
    )
  }
}

export default compose(
  withErrorBoundary,
  connector,
  withApiErrorHandling,
)(OrderDetailsPageComponent)
