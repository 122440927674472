import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'

import { AttachmentScheme } from '../components/common/SingleFileUpload/schemes'
import { FieldScheme } from '../components/common/Form/schemes'

import { OrderScheme } from './order'

export const ExecutionProofSmallScheme = {
  id: number,
  status: number,
  type: number,
  attachments: arrayOf(shape(AttachmentScheme)),
  order: number,
  internal_note: string,
  reject_reason: string,
  created_at: string,
  reviewed_at: string,
}

export const ExecutionProofScheme = {
  id: number,
  status: number,
  type: number,
  attachments: arrayOf(shape(AttachmentScheme)),
  order: number,
  order_object: shape(OrderScheme),
  internal_note: string,
  reject_reason: string,
  created_at: string,
  created_by_name: string,
  reviewed_at: string,
  reviewed_by_name: string,
  fraction_is_equal: string,
  weight_object_list: arrayOf(
    shape({
      weight_receipt: oneOfType([string, number]),
      weight: oneOfType([string, number]),
    }),
  ),
  weight_date: string,
  avv: oneOfType([string, number]),
  fine_fraction: oneOfType([string, number]),
  fraction: oneOfType([string, number]),
  number_of_containers: oneOfType([string, number]),
}

export const ExecutionProofFormScheme = {
  $form: shape({
    model: string,
    submitFailed: bool,
  }).isRequired,
  status: FieldScheme,
  internal_note: FieldScheme,
  reject_reason: FieldScheme,
}
