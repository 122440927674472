import { InvoiceCheckOverview } from '../components/invoicecheck/InvoiceCheckOverview'
import { InvoiceCheckPage } from '../components/invoicecheck/InvoiceCheckPage'
import { UserPermission } from '../constants/user'

/**
 * @constant
 * @description A constant to define invoicecheck routes.
 */
export const invoiceCheckRoutes = [
  {
    path: '/invoicecheck/invoice_check',
    component: InvoiceCheckPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_INVOICECHECK],
    showErrorPage: true,
  },
  {
    path: '/invoicecheck/invoice_check/overview/:invoiceCheckId/',
    component: InvoiceCheckOverview,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_INVOICECHECK],
    showErrorPage: true,
  },
]
