import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { exportWorkload, getWorkloads } from 'actions/workload'
import { TABLE_PAGE_SIZE_10 } from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { UserPermission } from 'constants/user'
import { get, localizeDateForBackend } from 'helper/general'
import { checkRequiredPermissions } from 'helper/permissions'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'
import {
  getWorkloadPaginationSelector,
  getWorkloadsSelector,
} from 'selectors/workload'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { FilterReset } from '../../common/Filter/components/FilterReset'
import { cleanUpAndCountFiltersForReset } from '../../common/Filter/components/FilterReset/helpers'
import {
  WorkloadFilter,
  WorkloadFilterValues,
} from '../../common/Filter/components/WorkloadFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import PageHeader from '../../layout/PageHeader'
import { PROOF_TYPE } from '../../proofs/constants'
import { ProofsList } from '../../proofs/ProofsList'

/**
 * @description This component displays a list of workload proofs including the filter, loading indicator etc.
 * @function
 */
export const WorkloadPage = () => {
  const dispatch = useDispatch()
  const workloads = useSelector(getWorkloadsSelector)
  const user = useSelector(getCurrentUserSelector)
  const pagination = useSelector(getWorkloadPaginationSelector)

  const history = useHistory()
  const location = useLocation<{
    workLoadFilters: Partial<WorkloadFilterValues>
  }>()

  const [currentFilters, setCurrentFilters] = useState<
    Partial<WorkloadFilterValues>
  >(location.state?.workLoadFilters ?? {})
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [defaultFilters, setDefaultFilters] =
    useState<Partial<WorkloadFilterValues>>()
  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = () => {
    let filters: Partial<WorkloadFilterValues> & {
      makler_premium_company?: string | number
      order_by?: string
    } = {
      ...currentFilters,
      makler_premium_company: user.company,
      order_by: JSON.stringify([{ id: 'id', desc: true }]),
    }

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
      if (filter === 'id' && typeof currentFilters[filter] !== 'number') {
        delete filters.id
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        date_start: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        date_end: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.date_start
    delete filters.date_end
    delete filters.makler_premium_company
    delete filters.order_by

    return filters
  }

  const isLoading = {
    getWorkloads: useSelector(createLoadingSelector(['GET_WORKLOADS'])),
    getExport: useSelector(createLoadingSelector(['EXPORT_WORKLOAD'])),
  }

  const apiError = {
    getWorkloads: useSelector(createErrorSelector(['GET_WORKLOADS'])),
  }

  // BEGIN filter memory
  const setFilters = useCallback(
    (filters: Partial<WorkloadFilterValues>) => {
      if (
        !location.state?.workLoadFilters ||
        // check all filters to see if any are set
        !Object.keys(filters).every(
          item =>
            (location.state?.workLoadFilters as Partial<WorkloadFilterValues>)[
              item
            ] === filters[item],
        )
      ) {
        history.replace(location.pathname + location.search, {
          workLoadFilters: filters,
        })
      }
      setCurrentFilters(filters)
    },
    [history, location.pathname, location.search, location.state],
  )

  useEffect(() => {
    if (!defaultFilters) {
      setDefaultFilters(location.state?.workLoadFilters)
    }
  }, [location.state, defaultFilters])

  //set filters if "company" query param is given
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('customer')) {
      setDefaultFilters({ customer: searchParams.get('customer') || undefined })
    }
    if (searchParams.has('partner')) {
      setDefaultFilters({ partner: searchParams.get('partner') || undefined })
    }
  }, [location.search])
  // END filter memory

  useEffect(() => {
    dispatch(getWorkloads())
  }, [dispatch])

  useEffect(() => {
    if (resetForm) setResetForm(false)
  }, [resetForm])

  const workloadTimestampFormatted = new Date(
    pagination?.total_results?.timestamp ?? 0,
  ).toLocaleTimeString()

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.workload')}</title>
      </Helmet>
      <PageHeader
        title={I18n.t('pageTitles.workload')}
        subtitle={
          <>
            <div className='uk-margin-small-top uk-margin-medium-bottom'>
              {I18n.t('workload.subtitle')}
            </div>
            {pagination.total_results.count && workloadTimestampFormatted && (
              <div className='uk-margin-small-top'>
                {I18n.t('workload.totalEntries')}
                {pagination.total_results.count}
                <br />
                {I18n.t('workload.totalEntriesTimestamp', {
                  timestamp: workloadTimestampFormatted,
                })}
              </div>
            )}
          </>
        }
      />
      <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
        <Media key='media-extag' query={{ minWidth: BREAKPOINT.XLARGE }}>
          <RequiredPermissions
            requiredPermissions={[UserPermission.EXPORT_WORKLOAD]}
          >
            <IconButton
              iconName='export'
              iconPosition={ICON_POSITION.RIGHT}
              onClick={() => {
                setIsExportModalOpen(true)
              }}
              isDisabled={isLoading.getExport}
              isLoading={isLoading.getExport}
            >
              <Translate value='general.export' />
            </IconButton>
          </RequiredPermissions>
        </Media>
      </ButtonBar>
      <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
        <FilterReset
          onResetFilter={() => {
            setCurrentFilters({})
            setResetForm(true)
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilters) > 0
          }
        />
      </ButtonBar>
      <div className='workload-page'>
        <Filter
          isLoading={isLoading.getWorkloads}
          length={workloads.length}
          shouldResetForm={resetForm}
          initialValues={defaultFilters}
        >
          <WorkloadFilter
            getCurrentFilterValues={setFilters}
            currentFilterValues={currentFilters}
          />
        </Filter>

        <ProofsList
          proofsType={PROOF_TYPE.WORKLOAD}
          proofsList={workloads}
          isLoading={isLoading.getWorkloads}
          apiError={apiError.getWorkloads}
          isReviewButtonVisible={checkRequiredPermissions(
            get(() => user.permission_codenames),
            [UserPermission.REVIEW_ORDERCLAIM],
          )}
          onNextPageClick={() =>
            dispatch(
              getWorkloads(pagination.next, currentFilters, TABLE_PAGE_SIZE_10),
            )
          }
          onPreviousPageClick={() =>
            dispatch(
              getWorkloads(
                pagination.previous,
                currentFilters,
                TABLE_PAGE_SIZE_10,
              ),
            )
          }
          page={pagination.current}
          pages={pagination.count}
          onFetchData={({ sorted }) => {
            if (sorted?.length > 0) {
              const newFilters = {
                ...currentFilters,
                order_by: JSON.stringify(sorted),
              }
              if (
                JSON.stringify(newFilters) !== JSON.stringify(currentFilters) ||
                !pagination.loaded
              ) {
                setCurrentFilters(newFilters)
                dispatch(
                  getWorkloads(undefined, newFilters, TABLE_PAGE_SIZE_10),
                )
              }
            }
          }}
        />
      </div>
      <AsyncExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        reduxSelector='EXPORT_WORKLOAD'
        title={I18n.t('workload.exportModal.title')}
        logic={exportWorkload({
          makler_premium_company: user.company,
          order_by: JSON.stringify([{ id: 'id', desc: true }]),
        })}
      />
      <AsyncExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        reduxSelector='EXPORT_WORKLOAD'
        title={I18n.t('workload.exportModal.title')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x =>
              x === null ||
              x === undefined ||
              x === 185 ||
              x === '[{"id":"id","desc":true}]',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportWorkload(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
    </>
  )
}
