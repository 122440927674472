import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'

import { FieldScheme, ValidatorScheme } from '../components/common/Form/schemes'

import { AddressSmallScheme } from './address'
import { CompanySmallScheme } from './company'
import { AbstractInquiryFormScheme, AbstractInquiryScheme } from './inquiry'
import { UserSmallScheme } from './user'

export const AbstractOfferScheme = {
  ...AbstractInquiryScheme,
  avv: oneOfType([number, string]),
  avv_number: string,
  compensation_container: oneOfType([number, string]),
  compensation_ton: oneOfType([number, string]),
  discount: oneOfType([number, string]),
  disposal_cost_container: oneOfType([number, string]),
  disposal_cost_ton: oneOfType([number, string]),
  handle_cost_ton: oneOfType([number, string]),
  index: oneOfType([number, string]),
  rent_price_container_month: oneOfType([number, string]),
  gross_retail_price: oneOfType([number, string]),
  surcharge: oneOfType([number, string]),
  transport_price_piece: oneOfType([number, string]),
}

export const OfferSmallScheme = {
  id: number,
  canceled_by_empto: number, // This contains the UID of whoever cancelled this offer
  created_by_empto: number, // This contains the UID of whoever created this offer
  company: number, // This is only the ID (write only)
  company_object: shape(CompanySmallScheme),
  customer_company_object: shape(CompanySmallScheme),
  fraction: oneOfType([number, string]),
  order_type: oneOfType([number, string]),
  status: oneOfType([number, string]),
  status_changed_at: string,
  runtime_of_inquiry: string,
  service_start_date: string, // The date of the actual start of the service depending on the order type.
  service_end_date: string,
  created_at: string,
  collection_address_object: shape(AddressSmallScheme), // This is the full object (read only)
  total_revenue: number,
  total_cost: number,
  net_cost: number,
  auto_generated: bool,
  number_of_containers: number,
}

export const AcceptedOfferScheme = {
  id: number,
  fraction: oneOfType([number, string]),
  order_type: oneOfType([number, string]),
  status: oneOfType([number, string]),
  service_start_date: string,
  service_end_date: string,
  collection_address_object: shape(AddressSmallScheme), // This is the full object (read only)
  container: oneOfType([number, string]),
  customer_company_object: shape(CompanySmallScheme),
  next_appointment: string,
  last_order: string,
}

export const OfferScheme = {
  ...AbstractOfferScheme,
  auto_generated: bool,
  collection_date: string,
  collection_address_object: shape(AddressSmallScheme),
  company: oneOfType([number, string]),
  company_object: shape(CompanySmallScheme),
  compensation: oneOfType([bool, string]),
  created_at: string,
  created_by_object: shape(UserSmallScheme),
  delivery_date: string,
  index: oneOfType([number, string]),
  interval: oneOfType([number, string]),
  net_cost: oneOfType([number, string]),
  rent_price_container_month: oneOfType([number, string]),
  special_note: string,
  status: oneOfType([number, string]),
  time_of_day_collection: oneOfType([number, string]),
  time_of_day_delivery: oneOfType([number, string]),
  total_cost: oneOfType([number, string]),
  total_revenue: oneOfType([number, string]),
  transport_price_piece: oneOfType([number, string]),
  turn_begin: string,
  turn_end: string,
  has_deduction_order: bool,
}

export const OfferFormScheme = {
  ...AbstractInquiryFormScheme,
  avv: FieldScheme,
  compensation_container: FieldScheme,
  compensation_ton: FieldScheme,
  discount: FieldScheme,
  disposal_cost_container: FieldScheme,
  disposal_cost_ton: FieldScheme,
  handle_cost_ton: FieldScheme,
  index: FieldScheme,
  rent_price_container_month: FieldScheme,
  surcharge: FieldScheme,
  transport_price_piece: FieldScheme,
}

export const OfferFormValidatorScheme = {
  avv: ValidatorScheme,
  collection_date: ValidatorScheme,
  compensation: ValidatorScheme,
  compensation_container: ValidatorScheme,
  compensation_ton: ValidatorScheme,
  container: ValidatorScheme,
  delivery_date: ValidatorScheme,
  discount: ValidatorScheme,
  disposal_cost_container: ValidatorScheme,
  disposal_cost_ton: ValidatorScheme,
  fine_fraction: ValidatorScheme,
  fraction: ValidatorScheme,
  handle_cost_ton: ValidatorScheme,
  index: ValidatorScheme,
  interval: ValidatorScheme,
  interval_weekday_first: ValidatorScheme,
  interval_weekday_second: ValidatorScheme,
  number_of_containers: ValidatorScheme,
  rent_price_container_month: ValidatorScheme,
  special_note: ValidatorScheme,
  status: ValidatorScheme,
  surcharge: ValidatorScheme,
  time_of_day_collection: ValidatorScheme,
  time_of_day_delivery: ValidatorScheme,
  transport_price_piece: ValidatorScheme,
  turn_begin: ValidatorScheme,
  turn_end: ValidatorScheme,
  with_estimated_time_of_arrival: ValidatorScheme,
  with_top: ValidatorScheme,
}

export const OfferIndexScheme = {
  id: number,
  name: string,
  fractions: arrayOf(number),
}

export const OfferIndexValuesScheme = {
  id: number,
  index: number,
  index_object: shape(OfferIndexScheme),
  value: oneOfType([string, number]),
  month: number,
  year: number,
  created_by_object: shape(UserSmallScheme),
  created_at: string,
  lastmodified_by_object: shape(UserSmallScheme),
  lastmodified_at: string,
}

export const OfferIndexAddScheme = {
  index: oneOfType([string, number]),
  value: oneOfType([string, number]),
  month: oneOfType([string, number]),
  year: oneOfType([string, number]),
}

export const OfferIndexAddFormScheme = {
  index: FieldScheme,
  value: FieldScheme,
  month: FieldScheme,
  year: FieldScheme,
}

export const OfferIndexChangeScheme = {
  id: number,
  value: number,
}

export const OfferIndexChangeFormScheme = {
  $form: shape({
    submitFailed: bool,
  }).isRequired,
  id: FieldScheme,
  value: FieldScheme,
}
