export const collectiveInvoicingTranslations = {
  invoice: {
    modal: {
      title: 'Start der Sammelabrechnung',
      warning:
        'Mit einem Klick auf den Button OK wird die Sammelabrechnung für diesen Kunden gestartet.<br>' +
        'Die Generierung der Belege kann nicht abgebrochen werden.',
    },
  },
  totalInvoice: {
    modal: {
      title: 'Gesamtfaktura starten',
      warning:
        'Möchten Sie die Gesamtfaktura starten? <strong>Alle</strong> Kunden mit monatlicher Abrechnung werden automatisch fakturiert!',
    },
    button: 'Gesamtfaktura',
  },
  exportCollectiveInvoicingCompanies: {
    exportButton: 'Export',
    modal: {
      title: 'Sammelabrechnung Export',
    },
  },
  exportCollectiveInvoicingActivities: {
    exportButton: 'Tätigkeiten Export',
    modal: {
      title: 'Sammelabrechnung Tätigkeiten Export',
    },
  },
  collectiveInvoicingForecastExport: {
    exportButton: 'Prognose Export',
    modal: {
      title: 'Prognose Export',
      explanation: `Dieser Export gibt Auskunft über das aktuelle Auftrags- und Umsatzvolumen der nächsten Sammelabrechnung. <br /><br />Export gemäß Filterung anfordern und an die Mailadresse %{userEmail} zustellen?<br /><br />
    Die Zustellung kann bis zu einer Stunde dauern!`,
    },
  },
  status: {
    success: 'Erfolgreich',
    running: 'Running',
    error: 'Fehlgeschlagen',
  },
  companyTable: {
    columns: {
      customerNumber: 'Kundennummer',
      customer: 'Kunde',
      customerStatus: 'Kundestatus',
      collectiveInvoicingMode: 'Rechnungsgenerierung',
      collectiveInvoicingAutoSendEmail: 'autom. Belegversand',
      lastInvoicingAt: 'letzte Abrechnung am',
      lastInvoicingBy: 'letzte Abrechnung von',
      lastInvoicingStatus: 'Status letzte Abrechnung',
      actions: {
        name: 'Aktionen',
        buttons: {
          collective_invoicing_start: 'Start der Sammelabrechung',
        },
      },
    },
  },
  activityTable: {
    title: 'Aktivitäten',
    columns: {
      timestamp: 'Zeitstempel',
      customerNumber: 'Kundennummer',
      customer: 'Kunde',
      status: 'Status',
      createdDocuments: 'Erstellte Belege',
      autoSendEmail: 'autom. versendet',
      username: 'Name',
      details: 'Details',
    },
  },
}
