export const maklerPremiumApiTranslations = {
  handleCreateCustomerRequest: {
    success: 'Kunde erfolgreich angelegt.',
    error: 'Hinzufügen des Kunden leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleCreatePartnerRequest: {
    success: 'Entsorger erfolgreich angelegt.',
    error: 'Hinzufügen des Entsorgers leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleCreateCombinedOfferRequest: {
    success: 'Angebot erfolgreich angelegt.',
    error: 'Hinzufügen des Angebots leider fehlgeschlagen.',
    frameworkContractError:
      'Es gab einen Fehler mit den Rahmenverträgen. Bitte mit Benjamin sprechen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleCreateInvoiceCheckRequest: {
    success: 'Belegprüfung erfolgreich angelegt.',
    error: 'Hinzufügen des Belegprüfung leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
    duplicate: 'Der eingegebene Beleg wurde bereits erfasst.',
  },
  handleUpdateInvoiceCheckRequest: {
    success: 'Belegprüfung erfolgreich geändert.',
    error: 'Änderung der Belegprüfung leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleReopenInvoiceCheckRequest: {
    success: 'Belegprüfung erfolgreich geändert.',
    error: 'Änderung der Belegprüfung leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleExportUpcomingOrdersRequest: {
    success: 'Export der geplanten Aufträge wurde erfolgreich gestartet.',
    error: 'Export der geplanten Aufträge konnte nicht gestartet werden.',
  },
  handleGetPriceComparisonTable: {
    success: {
      noResults: 'Keine Angebote gefunden',
      withResults:
        'Preisanfrage erfolgreich - Angebote von %{count} Unternehmen gefunden. ' +
        'Das beste Angebot wurde vorausgewählt.',
    },
    error: 'Preisabfrage fehlgeschlagen',
  },
  handleGetWorkloads: {
    error: 'Arbeitsvorräte konnten leider nicht geladen werden.',
  },
  handleGetInvoiceChecks: {
    error: 'Belegprüfungen konnten leider nicht geladen werden.',
  },
  handleGetInvoiceCheckPosition: {
    error: 'Belegprüfungsposition konnte leider nicht geladen werden.',
  },
  handleGetInvoiceCheckPositions: {
    error: 'Belegprüfungspositionen konnten leider nicht geladen werden.',
  },
  handleCreateInvoiceCheckPositionRequest: {
    success: 'Belegprüfungsposition erfolgreich erfasst.',
    error: 'Hinzufügen der Belegprüfungsposition leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleGetInvoiceCheckPositionPrice: {
    success: 'Preisberechnung erfolgreich abgeschlossen.',
    error: 'Preisberechnung leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleUpdateInvoiceCheckPositionRequest: {
    success: 'Belegprüfungsposition erfolgreich aktualisiert.',
    error: 'Aktualisieren der Belegprüfungsposition leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleDeleteInvoiceCheckPositionRequest: {
    success: 'Belegprüfungsposition erfolgreich gelöscht.',
    error: 'Löschen der Belegprüfungsposition leider fehlgeschlagen.',
    forbidden: 'Sie verfügen nicht über die notwendige Berechtigung.',
  },
  handleUpdateReviewedInvoiceCheckPosition: {
    relabeled: {
      success: 'Umdeklarierung wurde erfolgreich gespeichert.',
    },
    containerChanged: {
      success: 'Behälteränderung wurde erfolgreich gespeichert.',
    },
    additionalRent: {
      success: 'Stellzeit wurde erfolgreich gespeichert.',
    },
    error: 'Speichern der Umdeklarierung leider fehlgeschlagen.',
  },
  handleCreateCustomInvoiceRequest: {
    success: 'Beleg wurde erfolgreich erstellt.',
    error: 'Das Erstellen des Belegs ist leider fehlgeschlagen.',
  },
  handleUpdateCancelledInvoicePosition: {
    success: 'Stornierung (bezahlter Beleg) wurde erfolgreich gespeichert.',
    error:
      'Speichern der Stornierung (bezahlter Beleg) ist leider fehlgeschlagen.',
  },
  handleCreateCustomOfferRequest: {
    success: 'Manuelles Angebot wurde erfolgreich erstellt.',
    error:
      'Das Erstellen des manuellen Angebots ist leider fehlgeschlagen. %{error}',
  },
  handleUpdateCustomOfferRequest: {
    success: 'Manuelles Angebot wurde erfolgreich bearbeitet.',
    error:
      'Das Bearbeiten des manuellen Angebots ist leider fehlgeschlagen. %{error}',
  },
  handleExportOfferPipeline: {
    success: 'Der Export der Angebotspipeline wurde erfolgreich gestartet.',
    error: 'Der Export der Angebotspipeline konnte nicht gestartet werden',
  },
  handleExportWorkload: {
    success: 'Export wurde erfolgreich erstellt.',
    error: 'Export konnte nicht erstellt werden.',
  },
  handleCreateAgreement: {
    success:
      'Vereinbarung wurde erfolgreich erstellt. Vereinbarungsnr.:' +
      ' %{createdAgreementId}',
    error: 'Vereinbarung konnte nicht erstellt werden.',
  },
}
