import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'

import { TooltipIcon, TooltipIconProps } from '../TooltipIcon'

interface CheckboxProps {
  className?: string
  dataTestId?: string
  dataTestIdError?: string
  error?: string
  isChecked?: boolean
  isRequired?: boolean
  justifyCenter?: boolean
  label: React.ReactNode
  name: string
  onChange: React.ChangeEventHandler
  tooltip?: TooltipIconProps
}

/**
 * @description This component renders and controls a checkbox component.
 * @function
 * @param {Object} props the component props
 */
export const Checkbox: FC<CheckboxProps> = props => {
  const {
    className,
    dataTestId,
    dataTestIdError,
    error,
    isChecked = false,
    isRequired = false,
    justifyCenter = false,
    label,
    name,
    onChange,
    tooltip,
  } = props
  const id = uniqueId()

  return (
    <div
      className={classNames(
        'checkbox',
        { 'checkbox--justify-center': justifyCenter },
        { 'checkbox--with-error': justifyCenter },
        className,
      )}
    >
      <div className='checkbox__input-group'>
        <input
          className='checkbox__input'
          checked={isChecked}
          data-testid={dataTestId}
          id={id}
          name={name}
          onChange={onChange}
          required={isRequired}
          type='checkbox'
          tabIndex={0}
        />

        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className='checkbox__label' htmlFor={id}>
          {label}
        </label>

        {tooltip && (
          <div className='checkbox__tooltip'>
            <TooltipIcon {...tooltip} />
          </div>
        )}
      </div>
      {error && (
        <div className='input__error' data-testid={dataTestIdError}>
          {error}
        </div>
      )}
    </div>
  )
}
