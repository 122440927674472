import React from 'react'

import ActionTypes from '../actiontypes/maklerpremium'
import { CancelledPaidDocumentReviewFormValues } from '../components/maklerpremium/CancelPaidDocumentReview/CancelledPaidDocumentReviewPage'
import { AddAgreementFormValues } from '../components/maklerpremium/epd/AddAgreementPage/AddAgreementFormSteps/AddAgreementFormSteps'
import { EPDAddInvoicePosition } from '../components/maklerpremium/epd/EPDInvoiceFormSteps/EPDAddInvoiceFormSteps'
import { CreateCustomInvoiceValues } from '../components/maklerpremium/epd/EPDInvoiceFormSteps/Step4/CreateCustomInvoiceModal'
import { CustomOfferCreateFormValues } from '../components/maklerpremium/OfferPipelinePage/CustomOfferCreateModal/CustomOfferCreateModal'
import { TABLE_PAGE_SIZE_10 } from '../constants/app'

/** @function
 * @name createCustomer
 * @description Creates customer - Will create company, user and address
 * @param createCustomerData CreateCustomerScheme the object including its ID.
 * @param callback
 * @return {Object}
 */
export const createCustomer = (
  createCustomerData,
  callback?: { [key: string]: (...args: any) => void },
) => ({
  type: ActionTypes.CREATE_CUSTOMER_REQUEST,
  payload: {
    createCustomerData,
    callback,
  },
})

/** @function
 * @name createPartner
 * @description Creates partner for makler premium - Will create company, user and address
 * @param createPartnerData CreatePartnerScheme the object including its ID.
 * @param callback
 * @return {Object}
 */
export const createPartner = (
  createPartnerData,
  callback?: { [key: string]: (...args: any) => void },
) => ({
  type: ActionTypes.CREATE_PARTNER_REQUEST,
  payload: {
    createPartnerData,
    callback,
  },
})

/** @function
 * @name getPriceComparisonTable
 * @description Creates partner for makler premium - Will create company, user and address
 * @param priceComparisonRequest
 * @param callback
 * @return {Object}
 */
export const getPriceComparisonTable = (
  priceComparisonRequest,
  callback?: { [key: string]: (...args: any) => void },
) => ({
  // called in /Step2/FractionAndContainerFormGroup/FractionAndContainerFormFields.tsx:91
  type: ActionTypes.GET_PRICE_COMPARISON_TABLE_REQUEST,
  payload: {
    zipcode: priceComparisonRequest.zipcode,
    container_type: priceComparisonRequest.containerType,
    fraction_id: priceComparisonRequest.fractionId,
    position_on_public_ground: priceComparisonRequest.positionOnPublicGround,
    capacity: priceComparisonRequest.capacity,
    security_group: priceComparisonRequest.security_group,
    customer_id: priceComparisonRequest.customer_id,
    callback,
  },
})

/** @function
 * @name getPriceComparisonTable
 * @description Creates partner for makler premium - Will create company, user and address
 * @param priceComparisonRequest
 * @param callback
 * @return {Object}
 */
export const getPriceComparisonTablePaused = (
  priceComparisonRequest,
  callback?: { [key: string]: (...args: any) => void },
) => ({
  // called in /Step2/FractionAndContainerFormGroup/FractionAndContainerFormFields.tsx:91
  type: ActionTypes.GET_PRICE_COMPARISON_TABLE_PAUSED_REQUEST,
  payload: {
    zipcode: priceComparisonRequest.zipcode,
    container_type: priceComparisonRequest.containerType,
    fraction_id: priceComparisonRequest.fractionId,
    position_on_public_ground: priceComparisonRequest.positionOnPublicGround,
    capacity: priceComparisonRequest.capacity,
    security_group: priceComparisonRequest.security_group,
    customer_id: priceComparisonRequest.customer_id,
    callback,
  },
})

/** @function
 * @name createOffer
 * @description Creates a new combined offer for makler premium - Will create inquiry, offer, order
 * @param createOfferData CreateOfferScheme the object including its ID.
 * @param callback
 * @return {Object}
 */
export const createCombinedOffer = (
  createOfferData,
  callback?: () => void,
) => ({
  type: ActionTypes.CREATE_COMBINED_OFFER_REQUEST,
  payload: {
    createOfferData,
    callback,
  },
})

export const resetPriceComparisonTable = () => ({
  type: ActionTypes.RESET_PRICE_COMPARISON_TABLE,
})

/**
 * @function
 * @name getCustomInvoiceNetGrossPricesVat
 * @param data
 * @return {payload: *}
 */
export const getCustomInvoiceNetGrossPricesVat = (data: {
  [key: string]: EPDAddInvoicePosition[] | number
}) => ({
  type: ActionTypes.GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_REQUEST,
  payload: { data },
})

/**
 * @function
 * @name validateIBAN
 * @param data
 * @return {payload: *}
 */
export const validateIBAN = (data: { [key: string]: string }) => ({
  type: ActionTypes.VALIDATE_IBAN_REQUEST,
  payload: { data },
})

/** @function
 * @name resetValidateIBAN
 * @description An action method to reset validateIBAN.
 * @return {Object}
 */
export const resetValidateIBAN = () => ({
  type: ActionTypes.VALIDATE_IBAN_RESET,
})

/**
 * @function
 * @name createCustomInvoice
 * @param data
 * @return {payload: *}
 */
export const createCustomInvoice = (data: CreateCustomInvoiceValues) => ({
  type: ActionTypes.CREATE_CUSTOM_INVOICE_REQUEST,
  payload: { data },
})

/**
 * @function
 * @name getCustomTax
 * @return {payload: *}
 */
export const getCustomTax = () => ({
  type: ActionTypes.GET_CUSTOM_TAX_REQUEST,
})

/**
 * @function
 * @name getFrameworkContract
 * @param
 * @return {payload: *}
 */
export const getFrameworkContract = (filters?: FrameworkContractFilters) => ({
  type: ActionTypes.GET_FRAMEWORKCONTRACT_REQUEST,
  payload: { filters },
})

/**
 * @function
 * @name getFrameworkContractPositionsCount
 * @return {payload: *}
 */
export const getFrameworkContractPositionsCount = (companyId: number) => ({
  type: ActionTypes.GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_REQUEST,
  payload: { company: companyId },
})

export const resetFrameworkContract = () => ({
  type: ActionTypes.RESET_FRAMEWORKCONTRACT,
})

/**
 * @function
 * @name getCancelledPaidDocument
 * @param
 * @return {payload: *}
 */
export const getCancelledPaidDocument = (id: string) => ({
  type: ActionTypes.GET_CANCELLEDPAIDDOCUMENT_REQUEST,
  payload: { id },
})

/**
 * @function
 * @name resetCancelledPaidDocument
 * @param
 * @return {payload: *}
 */
export const resetCancelledPaidDocument = () => ({
  type: ActionTypes.UPDATE_CANCELLEDPAIDDOCUMENT_RESET,
})

/**
 * @function
 * @name updateCancelledPaidDocument
 * @param
 * @return {payload: *}
 */
export const updateCancelledPaidDocument = (
  cancelledPaidDocument: CancelledPaidDocumentReviewFormValues,
  id: React.ReactText,
  history,
) => ({
  type: ActionTypes.UPDATE_CANCELLEDPAIDDOCUMENT_REQUEST,
  payload: {
    data: cancelledPaidDocument,
    id,
    history,
  },
})

/**
 * @function
 * @name createCustomOffer
 * @param
 * @return {payload: *}
 */
export const createCustomOffer = (
  customOffer: CustomOfferCreateFormValues,
) => ({
  type: ActionTypes.CREATE_CUSTOMOFFER_REQUEST,
  payload: { data: customOffer },
})

/**
 * @function
 * @name updateCustomOffer
 * @param
 * @return {payload: *}
 */
export const updateCustomOffer = (
  customOfferID: number,
  newStatus: number,
) => ({
  type: ActionTypes.UPDATE_CUSTOMOFFER_REQUEST,
  payload: { customOfferID, data: { status: newStatus } },
})

/**
 * @function
 * @name getOfferPipeline
 * @description Loads a list of offers that were created by my company.
 * @return {Object}
 */
export const getOfferPipeline = (
  page?: number,
  filters?: Record<string, any>,
  pageSize = TABLE_PAGE_SIZE_10,
) => ({
  type: ActionTypes.GET_OFFER_PIPELINE_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
  },
})

/**
 * @function
 * @name exportUpcomingOrders
 * @return {Object}
 */
export const exportUpcomingOrders = filters => ({
  type: ActionTypes.EXPORT_UPCOMING_ORDERS_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name exportOfferPipeline
 * @return {Object}
 */
export const exportOfferPipeline = filters => ({
  type: ActionTypes.EXPORT_OFFER_PIPELINE_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name exportPostalStatistics
 * @return {Object}
 */
export const exportPostalStatistics = () => ({
  type: ActionTypes.EXPORT_POSTAL_STATISTICS_REQUEST,
})

/**
 * @function Triggers export of customer data
 * @name exportCustomerData
 * @return {Object}
 */
export const exportCustomerData = companyId => ({
  type: ActionTypes.EXPORT_CUSTOMER_DATA_REQUEST,
  payload: { company_id: companyId },
})

/**
 * @function Triggers creation of a custom agreement in the EPD context
 * @name createCustomAgreement
 * @return {Object}
 */
export const createCustomAgreement = (
  data: AddAgreementFormValues,
  history,
) => ({
  type: ActionTypes.CREATE_CUSTOM_AGREEMENT_REQUEST,
  payload: { data, history },
})

export const exportCommunicationcenter = companyId => ({
  type: ActionTypes.EXPORT_COMMUNICATIONCENTER_REQUEST,
  payload: { company_id: companyId },
})
